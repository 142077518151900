import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet, doPost, doPostFileUpload, doPut } from '../../helpers';
import { Document } from '../../../../models';
import { BackgroundCheckOwner } from '../../../../models/BackgroundCheckOwner';
import { BackgroundCheck } from '../../../../models/BackgroundCheck';

export const backgroundCheck = {
  get: (backgroundCheckId: IdentifierDTO) => () =>
    doGet<BackgroundCheck>(`/background-check/${backgroundCheckId}`),
  getDocument: (id: IdentifierDTO) => () =>
    doGet<Document>(`/documents/${id}`),
  updateDates: (backgroundCheckId: IdentifierDTO) => (dates: any) =>
    doPut(`/background-check/${backgroundCheckId}/dates`, dates),
  calculateScoring: (backgroundCheckId: IdentifierDTO) =>
    doPut(`/dashboard/background-check-scoring/${backgroundCheckId}`),
  emailRequest: (backgroundCheckId: IdentifierDTO) => (email: any) =>
    doPost(`/background-check/${backgroundCheckId}/email`, email),
  acceptTenant: (backgroundCheckId: IdentifierDTO) => (acceptationData: any) =>
    doPost(`/background-check/${backgroundCheckId}/accept`, acceptationData),
  attachInsuranceContract: (backgroundCheckId: IdentifierDTO) => (data) =>
    doPostFileUpload(`/background-check/${backgroundCheckId}/insurance-contract`, data),
  owner: {
    get: (backgroundCheckId: IdentifierDTO) => () =>
      doGet<BackgroundCheckOwner>(`/owner/background-check/${backgroundCheckId}`),
    accept: (backgroundCheckId: IdentifierDTO) => (body: any) =>
      doPut<void>(`/owner/background-check/${backgroundCheckId}/accept`, body),
    decline: (backgroundCheckId: IdentifierDTO) => (body) =>
      doPut<BackgroundCheckOwner>(`/owner/background-check/${backgroundCheckId}/decline`, body),
    sendWhatsAppLink: (backgroundCheckId: IdentifierDTO) =>
      doPost(`/owner/background-check/${backgroundCheckId}/send-whatsapp-link`)
  },
  getDirectLink: (backgroundCheckId: IdentifierDTO) =>
    doGet<{ link: string }>(`/background-check/${backgroundCheckId}/direct-link`),
  insuranceUpload: (backgroundCheckId: IdentifierDTO) => () =>
    doPost(`/background-check/${backgroundCheckId}/insurance-upload`)
};
