import { IdentifierDTO } from '@zazume/zzm-base';
import { doDelete, doGet, doPost, doPostFileUpload, doPut, doPutFileUpload, toPaginatedURL } from '../../helpers';
import { Contract, ContractUnit } from '../../../../models/Contract';
import { PaginationOptions } from '../../../../hooks';
import { Document } from '../../../../models';
import { BreakContractRequest } from './models/BreakContractRequest';
import { EditHomeInsuranceSchema } from '#/pages/workspaces/units/components/unitDetail/leaseTab/leaseDetailModal/components/insuranceDetail/homeInsurance/EditHomeInsuranceModal.schema';

export const contracts = {
  attachDocument: (leaseId: IdentifierDTO) => (document: any) =>
    doPostFileUpload(`/contracts/attach-document/lease/${leaseId}`, document),
  break: (contractId: IdentifierDTO) =>
    (request: BreakContractRequest) =>
      doPutFileUpload<void>(`/contracts/${contractId}`, request.build()),
  create: (onboardingId: IdentifierDTO) => (contract: any) =>
    doPostFileUpload(`/contracts/${onboardingId}`, contract),
  delete: () => (documentId: any) =>
    doDelete(`/contracts/remove/${documentId}`),
  edit: (leaseId: IdentifierDTO) => (leaseBody: any) =>
    doPut(`/contracts/${leaseId}/edit`, leaseBody),
  get: (contractId: IdentifierDTO) => () =>
    doGet<Contract>(`/contracts/${contractId}`),
  getAllContractsByUnit: (unitId: IdentifierDTO) => () =>
    doGet<Contract[]>(`/contracts/all/unit/${unitId}`),
  getCurrentContractByUnit: (unitId: IdentifierDTO) => () =>
    doGet<Contract>(`/contracts/current/unit/${unitId}`),
  getUpcomingContractsByUnit: (unitId: IdentifierDTO) => () =>
    doGet<Contract[]>(`/contracts/upcoming/unit/${unitId}`),
  getHistoricalByUnitPaginated: (unitId: IdentifierDTO) => (pagination: PaginationOptions) =>
    () =>
      doGet<ContractUnit[]>(toPaginatedURL(`/contracts/unit/historical/${unitId}`, pagination)),
  getDocument: (id: IdentifierDTO) => () =>
    doGet<Document>(`/documents/${id}`),
  getSignedContractDocumentsByTenant: (tenantId: IdentifierDTO) => () =>
    doGet<Document[]>(`/contracts/documents/tenant/${tenantId}`),
  homeInsurance: {
    update: (contractId: IdentifierDTO) => (request: EditHomeInsuranceSchema) =>
      doPut<void>(`/contracts/${contractId}/home-insurance`, request),
    addDocument: (contractId: IdentifierDTO) => (request: FormData) =>
      doPutFileUpload<void>(`/contracts/${contractId}/home-insurance/document`, request),
    deleteDocument: (contractId: IdentifierDTO) =>
      doDelete<void>(`/contracts/${contractId}/home-insurance/document`)
  },
  defaultInsurance: {
    update: (contractId: IdentifierDTO) => (request: any) =>
      doPut<void>(`/contracts/${contractId}/default-insurance`, request),
    addDocument: (contractId: IdentifierDTO) => (request: FormData) =>
      doPutFileUpload<void>(`/contracts/${contractId}/default-insurance/document`, request),
    deleteDocument: (contractId: IdentifierDTO) =>
      doDelete<void>(`/contracts/${contractId}/default-insurance/document`)
  },
  resend: (id: IdentifierDTO) => () =>
    doPost(`/contracts/resend/${id}`),
  reject: (id: IdentifierDTO) => reason =>
    doPut(`/contracts/reject/${id}`, reason),
  register: (unitId: IdentifierDTO) => (contract: any) =>
    doPostFileUpload(`/contracts/unit/${unitId}`, contract),
  removeAttachedDocument: (leaseId: IdentifierDTO) => (documentId: IdentifierDTO) =>
    doDelete(`/contracts/attach-document/lease/${leaseId}`, { documentId }),
  uploadBreakContract: (contractId: IdentifierDTO) => (formData: FormData) =>
    doPostFileUpload<void>(`/contracts/${contractId}/upload-break-contract`, formData)
};
