import { LongTermLeaseSettings } from '#/domain/organization/LongTermLeaseSettings';
import { MidTermLeaseSettings } from '#/domain/organization/MidTermLeaseSettings';
import { NonPaymentSettings } from '#/domain/organization/NonPaymentSettings';
import { OrganizationBankAccount } from '#/domain/organization/OrganizationBankAccount';
import { PaginationOptions } from '#/hooks';
import { AgentCommissionConcept, BasicUser, LegalSigner, Organization, Service } from '#/models';
import { OrganizationInvoiceSerie } from '#/models/organization/OrganizationInvoiceSerie';
import { CreateOrganizationBankAccountRequest } from '#lib/network/api/organizations/requests/CreateOrganizationBankAccountRequest';
import { UpdateMidTermRequest } from '#lib/network/api/organizations/requests/UpdateMidTermRequest';
import { UpdateOrganizationBankAccountRequest } from '#lib/network/api/organizations/requests/UpdateOrganizationBankAccountRequest';
import { UpdateOrganizationSettingsRequest } from '#lib/network/api/organizations/requests/UpdateOrganizationSettingsRequest';
import { doDelete, doGet, doPost, doPostFileUpload, doPut, parserDataList, toPaginatedURL } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';
import { toLongTermLeaseSettings, toMidTermLeaseSettings, toNonPaymentSettings, toOrganizationBankAccount } from './organizations.parsers';
import { UpdateNonPaymentRequest } from './requests/UpdateNonPaymentRequest';
import { OwnerServiceContractAgentOption } from '#/domain/organization/OwnerContractServicesAgentOptions';
import { EditCommissionFormValues } from '#/pages/organizationSettings/agent/components/EditCommissionModal.schema';

export const organizations = {
  addService: (organizationId: IdentifierDTO) => (service: Partial<Service>) =>
    doPut<Organization>(`/organizations/${organizationId}/services/${service._id}`),
  assignDefaultZazu: (organizationId: IdentifierDTO) => (zazume: Partial<BasicUser>) =>
    doPost(`/organizations/${organizationId}/zazume`, { zazumeId: zazume._id }),
  attachLogos: (organizationId: IdentifierDTO) =>
    (logos: FormData) =>
      doPostFileUpload(`/organizations/${organizationId}/logos`, logos),
  bankAccounts: {
    create: (organizationId: IdentifierDTO) => (request: CreateOrganizationBankAccountRequest) =>
      doPost<void>(`/dashboard/organization/bank-accounts/${organizationId}`, request),
    delete: (organizationId: IdentifierDTO, organizationBankAccountId: IdentifierDTO) => () =>
      doDelete<void>(`/dashboard/organization/bank-accounts/${organizationId}/${organizationBankAccountId}`),
    getAll: (organizationId: IdentifierDTO) => () =>
      doGet<OrganizationBankAccount[]>(`/dashboard/organization/bank-accounts/all/${organizationId}`, parserDataList(toOrganizationBankAccount)),
    getOne: (organizationBankAccountId: IdentifierDTO) => () =>
      doGet<OrganizationBankAccount>(`/dashboard/organization/bank-accounts/${organizationBankAccountId}`, toOrganizationBankAccount),
    update: (organizationBankAccountId: IdentifierDTO) => (request: UpdateOrganizationBankAccountRequest) =>
      doPut<void>(`/dashboard/organization/bank-accounts/${organizationBankAccountId}`, request)
  },
  deleteLogo: (organizationId: IdentifierDTO) =>
    doDelete<Organization>(`/organizations/${organizationId}/logo`),
  deleteLogoLandscape: (organizationId: IdentifierDTO) =>
    doDelete<Organization>(`/organizations/${organizationId}/logo-landscape`),
  edit: (organizationId: IdentifierDTO) => (organization: Partial<Organization>) =>
    doPut(`/organizations/${organizationId}`, organization),
  editAccountingData: (organizationId: IdentifierDTO) => (accountingData: Partial<Organization>) =>
    doPut<Organization>(`/organizations/${organizationId}/accounting-data`, accountingData),
  editBillingData: (organizationId: IdentifierDTO) => (billingData: Partial<Organization>) =>
    doPut<Organization>(`/organizations/${organizationId}/billing-data`, billingData),
  editSettings: (organizationId: IdentifierDTO) => (organization: Partial<Organization>) =>
    doPut(`/organizations/${organizationId}/settings`, organization),
  editPaymentData: (organizationId: IdentifierDTO) => (paymentData: Partial<Organization>) =>
    doPut<Organization>(`/organizations/${organizationId}/payment-data`, paymentData),
  editNonPaymentData: (organizationId: IdentifierDTO) => (request: UpdateNonPaymentRequest) =>
    doPut<void>(`/organizations/${organizationId}/non-payment`, request),
  get: (_id: string) => () =>
    doGet<Organization>(`/organizations/${_id}`),
  getAll: () =>
    doGet<Organization[]>('/organizations/all'),
  getOwnerContractServicesAgentOptions: (organizationId: IdentifierDTO) => () =>
    doGet<OwnerServiceContractAgentOption[]>(`/organizations/agent/service-options/${organizationId}`, parserDataList(OwnerServiceContractAgentOption.fromRaw)),
  getPaginated: () =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Organization[]>(toPaginatedURL('/organizations', pagination)),
  invoiceSeries: {
    getAllByOrganization: (organizationId: IdentifierDTO) => () =>
      doGet<OrganizationInvoiceSerie[]>(`/dashboard/organization/invoice-serie/${organizationId}`),
    create: (organizationId: IdentifierDTO, serie: string, nextInvoiceNumber: number, withYear?: boolean, separator?: string) =>
      doPost(`/dashboard/organization/invoice-serie/${organizationId}`, { serie, nextInvoiceNumber, withYear, separator }),
    update: (organizationInvoiceSerieId: IdentifierDTO, serie: string, nextInvoiceNumber: number, withYear?: boolean, separator?: string) =>
      doPut(`/dashboard/organization/invoice-serie/${organizationInvoiceSerieId}`, { serie, nextInvoiceNumber, withYear, separator })
  },
  leases: {
    midTerm: {
      get: (organizationId: IdentifierDTO) => () =>
        doGet<MidTermLeaseSettings>(`/organizations/leases/mid-term/${organizationId}`, toMidTermLeaseSettings),
      update: (organizationId: IdentifierDTO) => (request: UpdateMidTermRequest) =>
        doPut<void>(`/organizations/leases/mid-term/${organizationId}`, request)
    },
    longTerm: {
      get: (organizationId: IdentifierDTO) => () =>
        doGet<LongTermLeaseSettings>(`/organizations/leases/long-term/${organizationId}`, toLongTermLeaseSettings),
      update: (organizationId: IdentifierDTO) => (request: UpdateMidTermRequest) =>
        doPut<void>(`/organizations/leases/long-term/${organizationId}`, request)
    }
  },
  new: (body: Partial<Organization>) =>
    doPost<Organization>('/organizations', body),
  nonPayment: {
    get: (organizationId: IdentifierDTO) => () =>
      doGet<NonPaymentSettings>(`/organizations/${organizationId}/non-payment`, toNonPaymentSettings),
    update: (organizationId: IdentifierDTO) => (request: UpdateNonPaymentRequest) =>
      doPut<void>(`/organizations/${organizationId}/non-payment`, request)
  },
  removeService: (organizationId: IdentifierDTO) => (serviceId: IdentifierDTO) =>
    doDelete<Organization>(`/organizations/${organizationId}/services/${serviceId}`),
  sendInvitation: (organizationId: IdentifierDTO) =>
    doPut<Organization>(`/organizations/${organizationId}/send-invitation`),
  unassignDefaultZazu: (organizationId: IdentifierDTO) => (zazumeId: IdentifierDTO) =>
    doDelete(`/organizations/${organizationId}/zazume`, { zazumeId: zazumeId }),
  updateLegalSigner: (organizationId: IdentifierDTO) => (legalSigner: Partial<LegalSigner>) =>
    doPut(`/dashboard/organization/legal-signer/${organizationId}`, legalSigner),
  settings: {
    update: (organizationId: IdentifierDTO) => (request: UpdateOrganizationSettingsRequest) =>
      doPut<void>(`/organizations/settings/${organizationId}`, request)
  },
  updateAgentsCommissions: (organizationId: IdentifierDTO, concept: AgentCommissionConcept) => (request: EditCommissionFormValues) =>
    doPut<void>(`/organizations/${organizationId}/agents-commissions/${concept}`, request)
};
