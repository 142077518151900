import { OrganizationIntegrationsSettings } from '#/models/organization/OrganizationIntegrationsSettings';
import { RespondioIntegrationFormData } from '#/pages/organizationSettings/integrations/components/otherIntegrations/modals/respondioIntegrationModal/RespondioIntegration.schema';
import { DateVO } from '@zazume/zzm-utils';
import { doGet, doPut } from '../../helpers';
import { ZendeskIntegrationFormData } from '#/pages/organizationSettings/integrations/components/otherIntegrations/modals/zendeskIntegrationModal/ZendeskIntegration.schema';
import { OnlygalIntegrationFormData } from '#/pages/organizationSettings/integrations/components/otherIntegrations/modals/onlygalIntegrationModal/OnlygalIntegration.schema';

const parse = (raw: any): OrganizationIntegrationsSettings =>
  new OrganizationIntegrationsSettings({
    _id: raw._id,
    organizationId: raw._id,
    respondio: raw.respondio,
    zendesk: raw.zendesk,
    onlygal: raw.onlygal,
    createdAt: DateVO.fromPrimitive(raw)
  });

export const organizationIntegrationsSettings = {
  findIntegrationsSettings: () =>
    doGet<OrganizationIntegrationsSettings>('/dashboard/organization-integrations-settings', parse, undefined, { expectNotFound: true }),
  updateRespondioSettings: (body: RespondioIntegrationFormData) =>
    doPut<void>('/dashboard/organization-integrations-settings/respondio', body),
  updateZendeskSettings: (body: ZendeskIntegrationFormData) =>
    doPut<void>('/dashboard/organization-integrations-settings/zendesk', body),
  updateOnlygalSettings: (body: OnlygalIntegrationFormData) =>
    doPut<void>('/dashboard/organization-integrations-settings/onlygal', body)
};
