

import { doGet, doDelete, doPost, doPut, doPostFileUpload, doPutFileUpload, EndpointBuilder, toPaginatedURL } from '../../helpers';
import { AgentCommissionConcept, UserAgent } from '../../../../models';
import { IdentifierDTO } from '@zazume/zzm-base';
import { UpdateDocumentRequest } from '../documents/requests/UpdateDocumentRequest';
import { PaginationOptions } from '../../../../hooks';
import { CreateDocumentRequest } from '../documents/requests/CreateDocumentRequest';import { UserSearcherResult } from '../../../../models/UserSearcherResult';
import { EditCommissionFormValues } from '#/pages/organizationSettings/agent/components/EditCommissionModal.schema';


export const agents = {
  searchByName: (name: string) => () =>
    doGet<UserSearcherResult[]>(`/dashboard/agents/search?name=${name}`),
  find: (organizationId: IdentifierDTO, params?: EndpointBuilder) => () =>
    doGet<UserSearcherResult[]>(`/dashboard/agents/find/${organizationId}${params ? params.queryParams : ''}`),
  get: (id: IdentifierDTO) => () =>
    doGet<UserAgent>(`/dashboard/agents/${id}`),
  updateDocument: (agentId: IdentifierDTO) =>
    (request: UpdateDocumentRequest) =>
      doPutFileUpload<void>(`/dashboard/agents/documents/${agentId}/${request.documentId}`, request.build()),
  createDocument: (agentId: IdentifierDTO) =>
    (request: CreateDocumentRequest) =>
      doPostFileUpload<void>(`/dashboard/agents/documents/${agentId}/`, request.build()),
  commissions: {
    disable: (agentId: IdentifierDTO) =>
      doPut<void>(`/dashboard/agents/commissions/${agentId}/disable`),
    enable: (agentId: IdentifierDTO) =>
      doPut<void>(`/dashboard/agents/commissions/${agentId}/enable`),
    initiate: (agentId: IdentifierDTO) =>
      doPut<void>(`/dashboard/agents/commissions/${agentId}/initiate`),
    update: (agentId: IdentifierDTO, concept: AgentCommissionConcept) => (request: EditCommissionFormValues) =>
      doPut<void>(`/dashboard/agents/commissions/${agentId}/${concept}`, request)
  },
  findAll: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<UserAgent[]>(toPaginatedURL(`/dashboard/agents${params.queryParams}`, pagination)),
  getServiceAreas: () =>
    doGet<string[]>('/dashboard/agents/service-areas'),
  create: () => (body) =>
    doPost<UserAgent>('/dashboard/agents/create', body),
  update: (agentId: IdentifierDTO) => (body) =>
    doPut<UserAgent>(`/dashboard/agents/update/${agentId}`, body),
  updateBillingSettings: (agentId: IdentifierDTO) => (body) =>
    doPut<UserAgent>(`/dashboard/agents/billing-data/${agentId}`, body),
  invite: (agentId: IdentifierDTO): Promise<UserAgent> =>
    doPost(`/dashboard/agents/${agentId}/invite`),
  delete: (agentId: IdentifierDTO): Promise<UserAgent> =>
    doDelete(`/dashboard/agents/${agentId}`)
};
