import { Application, Unit } from '#/models';
import { IdentifierDTO } from '@zazume/zzm-base';
import { doDelete, doGet, doPost, doPostFileUpload, doPut } from '../../helpers';
import { bulkActions } from './bulkActions/bulkActions';
import { CreateCommercialisationFeeDocumentRequest } from './models/CreateCommercialisationFeeDocumentRequest';
import { CreateHoldingDepositDocumentRequest } from './models/CreateHoldingDepositDocumentRequest';
import { CreateLeaseContractDocumentRequest } from './models/CreateLeaseContractDocumentRequest';
import { RequestApplicationPayments } from './payments/RequestApplicationPayments';
import { UpdateContractDetailsRequest } from './requests/UpdateContractDetailsRequest';
import { UtilityData } from '#lib/network/api/application/models/UtilityData';
import { RequestManagementSuppliesBody, InsuraceChangeRequest } from '#/hooks/application/useRequestUtilitiesChange';
import { MoveInHomeInsurancePlan } from '#/models/application/MoveIn';

export const application = {
  getById: (applicationId: IdentifierDTO) => () =>
    doGet<Application>(`/onboarding/${applicationId}`),
  updateContractDetails: (applicationId: IdentifierDTO) => (body: UpdateContractDetailsRequest) =>
    doPut<void>(`/dashboard/application/${applicationId}/contract-details`, body.build()),
  commercialisationFee: {
    createDocument: (applicationId: IdentifierDTO) =>
      (request: CreateCommercialisationFeeDocumentRequest): Promise<void> =>
        doPostFileUpload(`/onboarding/${applicationId}/commercialisation-fee-document`, request.build())
  },
  holdingDeposit: {
    createDocument: (applicationId: IdentifierDTO) =>
      (request: CreateHoldingDepositDocumentRequest): Promise<void> =>
        doPostFileUpload(`/onboarding/${applicationId}/holding-deposit-contract`, request.build())
  },
  leaseContract: {
    create: (applicationId: IdentifierDTO) =>
      (request: CreateLeaseContractDocumentRequest): Promise<void> =>
        doPostFileUpload(`/contracts/${applicationId}`, request.build()),
    rollBack: (applicationId: IdentifierDTO) =>
      (): Promise<void> =>
        doPost(`/dashboard/application/${applicationId}/contract-roll-back`)
  },
  moveIn: {
    getUtilityData: (applicationId: IdentifierDTO, utilityType: string) => () =>
      doGet<UtilityData[]>(`/dashboard/application/${applicationId}/move-in/utility-data/${utilityType}`),
    requestManagementSupplies: (applicationId: IdentifierDTO) =>
      (body: RequestManagementSuppliesBody) =>
        doPost<void>(`/dashboard/application/${applicationId}/move-in/request-management-supplies`, body),
    requestNoManagementSupplies: (applicationId: IdentifierDTO) =>
      () =>
        doPost<void>(`/dashboard/application/${applicationId}/move-in/request-no-management-supplies`),
    homeInsurance: {
      dismiss: (applicationId: IdentifierDTO) =>
        doDelete<void>(`/dashboard/application/${applicationId}/move-in/home-insurance-plans/dismiss`),
      getRequestedHomeInsurancePlans: (applicationId: IdentifierDTO) => () =>
        doGet<MoveInHomeInsurancePlan[]>(`/dashboard/application/${applicationId}/move-in/home-insurance-plans`),
      initHomeInsurancePlans: (applicationId: IdentifierDTO) => (request: InsuraceChangeRequest) =>
        doPost<void>(`/dashboard/application/${applicationId}/move-in/home-insurance-plans/init`, request),
      issueHomeInsurancePlan: (applicationId: IdentifierDTO) => (planUUID: string) =>
        doPost<void>(`/dashboard/application/${applicationId}/move-in/home-insurance-plans/issue`, { planUUID })
    }
  },
  unit: {
    get: (applicationId: IdentifierDTO) => () =>
      doGet<Unit>(`/onboarding/${applicationId}/unit`)
  },
  bulkActions,
  payments: {
    requestPayments: (applicationId: IdentifierDTO) => (body: RequestApplicationPayments) =>
      doPut<void>(`/onboarding/request-payments/${applicationId}`, body)
  }
};
