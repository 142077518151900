import { IdentifierDTO } from '@zazume/zzm-base';
import { DateVO } from '@zazume/zzm-utils';

interface Base {
  enabled: boolean;
}

export interface Respondio extends Base {
  workspaceId: string;
}

export interface Zendesk extends Base {
  username: string;
  subdomain: string;
}

export interface Onlygal extends Base {
  username: string;
  password: string;
}

interface OrganizationIntegrationsSettingsProps {
  _id: IdentifierDTO;
  organizationId: IdentifierDTO;
  respondio?: Respondio;
  zendesk?: Zendesk;
  onlygal?: Onlygal;
  createdAt: DateVO;
}

export class OrganizationIntegrationsSettings {
  _id: IdentifierDTO;
  organizationId: IdentifierDTO;
  respondio?: Respondio;
  zendesk?: Zendesk;
  onlygal?: Onlygal;
  createdAt: DateVO;

  constructor({ _id, organizationId, respondio, zendesk, onlygal, createdAt }: OrganizationIntegrationsSettingsProps) {
    this._id = _id;
    this.organizationId = organizationId;
    this.respondio = respondio;
    this.zendesk = zendesk;
    this.onlygal = onlygal;
    this.createdAt = createdAt;
  }

  isRespondioEnabled(): boolean {
    return Boolean(this.respondio?.enabled);
  }

  isRespondioIntegrated(): boolean {
    return Boolean(this.respondio);
  }

  isZendeskEnabled(): boolean {
    return Boolean(this.zendesk?.enabled);
  }

  isOnlygalEnabled(): boolean {
    return Boolean(this.onlygal?.enabled);
  }

  isOnlygalIntegrated(): boolean {
    return Boolean(this.onlygal);
  }

  isZendeskIntegrated(): boolean {
    return Boolean(this.zendesk);
  }

  hasOtherIntegrationToCreate(): boolean {
    return !this.isRespondioIntegrated()
      || !this.isZendeskIntegrated()
      || !this.isOnlygalIntegrated();
  }

}
