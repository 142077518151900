import { IdentifierDTO } from '@zazume/zzm-base';

// TODO move to API folder
export const OrganizationKeys = {
  agents: (organizationId: IdentifierDTO) => [...OrganizationKeys.byDetails(organizationId), 'managers'],
  all: ['organization', 'all'],
  allByOwner: (ownerAccountId: IdentifierDTO) => [...OrganizationKeys.all, ownerAccountId],
  base: ['organization'],
  byDetails: (organizationId: IdentifierDTO) => [...OrganizationKeys.base, organizationId],
  byPagination: () => [
    ...OrganizationKeys.base,
    'paginated'
  ],
  invoiceSeries: (organizationId: IdentifierDTO) => [OrganizationKeys.base, 'invoiceSeries', organizationId],
  leases: {
    longTerm: (organizationId: IdentifierDTO) => [...OrganizationKeys.base, 'long-term', organizationId],
    midTerm: (organizationId: IdentifierDTO) => [...OrganizationKeys.base, 'mid-term', organizationId]
  },
  nonPayment: (organizationId: IdentifierDTO) => [...OrganizationKeys.base, 'non-payment', organizationId],
  ownerContractServicesAgentOptions: (organizationId: IdentifierDTO) => [...OrganizationKeys.base, 'ownerContractServicesAgentOptions', organizationId]
};
