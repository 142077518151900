import { useI18n } from '#/hooks/useI18n';
import { FC } from 'react';
import { FooterNavigation } from '../components/FooterNavigation';
import { SectionGroup } from '../components/SectionGroup';
import { TopNavigation } from '../components/TopNavigation';
import { Sections } from '../Sections';

interface Props {
  isMobile: boolean;
  isOpen: boolean;
  onClickItem?: () => void;
}

export const AgentNavigation: FC<Props> = ({ isMobile, isOpen, onClickItem }) => {
  const { t } = useI18n();

  return <>
    <TopNavigation dataId="menu" open={isOpen}>
      <Sections.Home onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.Calendar onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.Investment onClickItem={onClickItem} isMobile={isMobile}/>
      <SectionGroup id="commercialisation" icon="key" isNavigationOpen={isOpen} title={t('asideNavigation.commercialisation')} isMobile={isMobile} canHasRoles={['agent']} shouldHideToBlacklistUsers>
        <Sections.Applications onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Valuations onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Solvency onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <SectionGroup id="acquisition" icon="target" isNavigationOpen={isOpen} title={t('general.acquisition')} isMobile={isMobile} canHasRoles={['agent']}>
        <Sections.AgentOwnerLeads onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.AgentMarketing onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <SectionGroup id="accounting" icon="directOrder" title={t('accounting.title')} isNavigationOpen={isOpen} isMobile={isMobile} canHasRoles={['agent']}>
        <Sections.Payments onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
        <Sections.Invoices onClickItem={onClickItem} isMobile={isMobile} depth={1}/>
      </SectionGroup>
      <Sections.Portfolio onClickItem={onClickItem} isMobile={isMobile}/>
      <Sections.People onClickItem={onClickItem} isMobile={isMobile}/>
    </TopNavigation>
    <FooterNavigation>
      <Sections.AgentAccountSettings onClickItem={onClickItem} isMobile={isMobile}/>
    </FooterNavigation>
  </>;
};
