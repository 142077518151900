import { ResponsiveBody } from '#/templates/ResponsiveBody';
import React, { FC, useState } from 'react';
import { AsyncComponent } from '#/components/async/AsyncComponent';
import { useI18n } from '#hooks/useI18n';
import { InvestmentAlert } from '#/models/investment/alert/InvestmentAlert';
import { PageDetailMainWithDrawer } from '#/components/pages/detail/PageDetailMainWithDrawer';
import { PageDetailBody, PageDetailHeader } from '#/components/pages/detail';
import { ResponsiveContainer } from '#/templates/ResponsiveContainer';
import { Private } from '#/containers/router/routes/Private';
import { MyAlertsTable } from '#/pages/investment/alerts/components/table/MyAlertsTable';
import { useGetPaginatedInvestmentAlerts } from '#hooks/api/investmentAlerts/useGetPaginatedInvestmentAlerts';
import { Skeleton } from '#/components/skeleton/Skeleton';
import { Bell, PrimaryButton, useModal } from '@zazume/zzm-base';
import { EditAlertModal } from '#/pages/investment/alerts/components/modal/EditAlertModal';
import { CreateAlertModal } from '#/pages/investment/alerts/components/modal/CreateAlertModal';
import { usePurchase } from '#/pages/investment/hooks/usePurchase';
import { PurchaseInvestmentModal } from '#/pages/investment/components/productChoose/PurchaseInvestmentModal';
import { useFilter } from '#hooks/useFilter';
import MetricsHelper from '#/utils/metrics/metricsHelper';

export const InvestmentAlerts: FC = () => {
  const { t } = useI18n();
  const { filter: cityId } = useFilter('CITY_ID');
  const { canPurchase } = usePurchase(cityId, true);
  const [editAlert, setEditAlert] = useState<InvestmentAlert>();

  const apiResult = useGetPaginatedInvestmentAlerts();
  const { open: openEdit, close: closeEdit, RenderModal: RenderEditModal } = useModal({ modalSize: 'm' });
  const { open: openCreate, close: closeCreate, RenderModal: RenderCreateModal } = useModal({ modalSize: 'm' });
  const { RenderModal: RenderPurchaseModal, open: openPurchase, close: closePurchase } = useModal({ modalSize: 'm' });

  const alertsTableHeaders = ['Place', 'Max. Budget', 'Profitability', 'Channel', 'Status'];

  const goToOpenPurchase = () => {
    MetricsHelper.track('Purchase Investment Product Btn');
    openPurchase();
  };

  const openCreateAlertModal = () => {
    MetricsHelper.track('Add Investment Alert Btn');
    openCreate();
  };

  const onOpenAlertCreator = () => {
    canPurchase && goToOpenPurchase();
    !canPurchase && openCreateAlertModal();
  };

  const onEditAlert = (alert: InvestmentAlert) => {
    setEditAlert(alert);
    openEdit();
  };

  const onCloseEdit = () => {
    setEditAlert(undefined);
    closeEdit();
    apiResult.refetch();
  };

  const onCreateAlert = () => apiResult.refetch();

  return <ResponsiveBody padding={false}>
    <PageDetailBody>
      <PageDetailMainWithDrawer>
        <ResponsiveContainer asideNav>
          <PageDetailHeader
            title={t('investment.myAlerts.title')}
            goBackRoute={Private.INVESTMENT.route} actionButton={<PrimaryButton onClick={onOpenAlertCreator}><Bell/>{t('investment.listing.newAlert')}</PrimaryButton>}>
          </PageDetailHeader>
          <AsyncComponent
            apiResult={apiResult}
            loadingComponent={<Skeleton.Table headerTitles={alertsTableHeaders}/>}
            emptyStateComponent={<MyAlertsTable.EmptyState headerTitles={alertsTableHeaders}/>}
          >
            {(data, { currentPage, nextPage, previousPage }) =>
              <MyAlertsTable
                alerts={data}
                currentPage={currentPage}
                onNextPage={nextPage}
                onPreviousPage={previousPage}
                onEditAlert={onEditAlert}
              />
            }
          </AsyncComponent>
        </ResponsiveContainer>
      </PageDetailMainWithDrawer>
    </PageDetailBody>
    <RenderEditModal>
      {editAlert ? <EditAlertModal alert={editAlert} onClose={onCloseEdit}/> : null}
    </RenderEditModal>
    <RenderCreateModal>
      <CreateAlertModal onClose={closeCreate} onSubmit={onCreateAlert}/>
    </RenderCreateModal>
    <RenderPurchaseModal>
      <PurchaseInvestmentModal onClose={closePurchase}/>
    </RenderPurchaseModal>
  </ResponsiveBody>;
};
