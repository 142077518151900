import { CommercialisationService } from '#/domain/ownerServiceContract/CommercialisationService';
import { ManagementService } from '#/domain/ownerServiceContract/ManagementService';
import { OwnerServiceContract } from '#/domain/ownerServiceContract/OwnerServiceContract';
import { RentAdvanceService } from '#/domain/ownerServiceContract/RentAdvanceService';
import { Service } from '#/domain/ownerServiceContract/Service';
import { PaginationOptions } from '#/hooks';
import { BasicUnit } from '#/models';
import { Payment } from '#/models/Payment';
import { CreateDocumentRequest } from '#lib/network/api/documents/requests/CreateDocumentRequest';
import { fromRaw } from '#lib/network/api/ownerServiceContract/parser';
import { doDelete, doGet, doPost, doPostFileUpload, doPut, doPutFileUpload, EndpointBuilder, parserDataList, toPaginatedURL } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';
import { BrokeCommercialisationContractRequest } from './requests/BrokeCommercialisationContractRequest';
import { BrokeManagementContractRequest } from './requests/BrokeManagementContractRequest';
import { BrokeRentAdvanceContractRequest } from './requests/BrokeRentAdvanceContractRequest';
import { CreateCommercialisationServiceRequest } from './requests/CreateCommercialisationServiceRequest';
import { CreateManagementServiceRequest } from './requests/CreateManagementServiceRequest';
import { CreateRentalAdvanceServiceRequest } from './requests/CreateRentalAdvanceServiceRequest';
import { UpdateManagementServiceRequest } from './requests/UpdateManagementServiceRequest';

export const ownerServiceContract = {
  attachDocuments: (ownerServiceContract: IdentifierDTO) =>
    (request: CreateDocumentRequest) =>
      doPutFileUpload<void>(`/dashboard/owner-service-contract/${ownerServiceContract}/attach-documents`, request.build()),
  getAllByUnitId: (unitId: IdentifierDTO) => () =>
    doGet<OwnerServiceContract<Service>[]>(`/dashboard/owner-service-contract/unit/${unitId}`, parserDataList(fromRaw)),
  findAllActiveByUnitId: (unitId: IdentifierDTO) => () =>
    doGet<OwnerServiceContract<Service>[]>(`/dashboard/owner-service-contract/active/unit/${unitId}`, parserDataList(fromRaw)),
  deleteDraft: (id: IdentifierDTO) =>
    doDelete<void>(`/dashboard/owner-service-contract/${id}/remove-draft`),
  rentAdvance: {
    create: (unitId: IdentifierDTO) => (request: CreateRentalAdvanceServiceRequest) =>
      doPostFileUpload<void>(`/dashboard/owner-service-contract/unit/${unitId}/rent-advance`, request.toFormData()),
    findAllTerminatedByUnitId: (unitId: IdentifierDTO) => () =>
      doGet<OwnerServiceContract<RentAdvanceService>[]>(`/dashboard/owner-service-contract/terminated/rentAdvance/unit/${unitId}`, parserDataList(fromRaw)),
    getPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
      doGet<OwnerServiceContract<RentAdvanceService>[]>(toPaginatedURL(`/dashboard/rent-advance${params.queryParams}`, pagination), parserDataList(fromRaw)),
    getProgressIncomes: (id: IdentifierDTO) => () =>
      doGet<Payment[]>(`/dashboard/owner-service-contract/rent-advance/${id}/progress-incomes`),
    getUnits: () =>
      doGet<BasicUnit[]>('/dashboard/rent-advance/units'),
    brokeContract: (ownerServiceContract: IdentifierDTO) =>
      (request: BrokeRentAdvanceContractRequest) =>
        doPutFileUpload<void>(`/dashboard/rent-advance/break-contract/${ownerServiceContract}`, request.toFormData())
  },
  commercialisation: {
    create: (unitId: IdentifierDTO) => (request: CreateCommercialisationServiceRequest) =>
      doPostFileUpload<void>(`/dashboard/owner-service-contract/unit/${unitId}/commercialisation`, request.toFormData()),
    createForAgent: (unitId: IdentifierDTO) => (request: any) =>
      doPost<void>(`/dashboard/owner-service-contract/agent/unit/${unitId}/commercialisation`, request),
    findAllTerminatedByUnitId: (unitId: IdentifierDTO) => () =>
      doGet<OwnerServiceContract<CommercialisationService>[]>(`/dashboard/owner-service-contract/terminated/commercialisation/unit/${unitId}`, parserDataList(fromRaw)),
    findLastOneByUnitId: (unitId: IdentifierDTO) => () =>
      doGet<OwnerServiceContract<CommercialisationService> | undefined>(`/dashboard/owner-service-contract/unit/${unitId}/commercialisation/last`, fromRaw),
    brokeContract: (ownerServiceContractId: IdentifierDTO) =>
      (request: BrokeCommercialisationContractRequest) =>
        doPutFileUpload<void>(`/dashboard/commercialisation/break-contract/${ownerServiceContractId}`, request.toFormData())
  },
  management: {
    create: (unitId: IdentifierDTO) => (request: CreateManagementServiceRequest) =>
      doPostFileUpload<void>(`/dashboard/owner-service-contract/unit/${unitId}/management`, request.toFormData()),
    createForAgent: (unitId: IdentifierDTO) => (request: any) =>
      doPost<void>(`/dashboard/owner-service-contract/agent/unit/${unitId}/management`, request),
    updateContract: (ownerServiceContractId: IdentifierDTO) => (request: UpdateManagementServiceRequest) =>
      doPut<void>(`/dashboard/management/${ownerServiceContractId}`, request),
    findAllTerminatedByUnitId: (unitId: IdentifierDTO) => () =>
      doGet<OwnerServiceContract<ManagementService>[]>(`/dashboard/owner-service-contract/terminated/management/unit/${unitId}`, parserDataList(fromRaw)),
    findLastOneByUnitId: (unitId: IdentifierDTO) => () =>
      doGet<OwnerServiceContract<ManagementService> | undefined>(`/dashboard/owner-service-contract/unit/${unitId}/management/last`, fromRaw),
    findOneBlockerToCreationByUnitId: (unitId: IdentifierDTO) => () =>
      doGet<OwnerServiceContract<ManagementService> | undefined>(`/dashboard/owner-service-contract/unit/${unitId}/management/blocker-to-creation`, fromRaw),
    brokeContract: (ownerServiceContractId: IdentifierDTO) =>
      (request: BrokeManagementContractRequest) =>
        doPutFileUpload<void>(`/dashboard/management/break-contract/${ownerServiceContractId}`, request.toFormData())
  }
};
