import { PaginationOptions } from '#/hooks';
import { AvailabilitySlots, BasicUnit, BasicUserVO, Document, OwnerPreScoring, Staff, Tenant, Unit, UnitNotesBody, UnitPortalRequestBody, User } from '#/models';
import { Availability } from '#/models/Availability';
import { Deletable } from '#/models/common';
import { OwnerAccountAndLiteUnitFormSchema } from '#/pages/people/ownerAccount/components/addOwnerAccountAndLiteUnitModal/components/AddOwnerAccountAndLiteUnitForm.schema';
import { OwnerAssignmentForm } from '#/pages/workspaces/units/components/unitDetail/peopleTab/components/modals/OwnerAssignmentModal.schema';
import { IdentifierDTO } from '@zazume/zzm-base';
import { doDelete, doGet, doPost, doPostFileUpload, doPut, doPutFileUpload, EndpointBuilder, parserDataList, toPaginatedURL } from '../../helpers';
import { UpdateDocumentRequest } from '../documents/requests/UpdateDocumentRequest';
import { BulkAssignManagerRequest } from './models/BulkAssignManagerRequest';
import { CreateUnitDocumentRequest } from './models/CreateUnitDocumentRequest';

const toBasicUser = (rawBasicUser: any): BasicUserVO =>
  new BasicUserVO({ ...rawBasicUser });

export const units = {
  //TODO: Following method getByWorkspace should be avoid because it does not have any pagination
  //TODO: Create a non paginated method with a selected limit.
  getByWorkspace: (organizationId: IdentifierDTO, workspaceId: IdentifierDTO, unitLimit = 100) => () =>
    doGet<Unit[]>(toPaginatedURL(`/units/organization/${organizationId}/workspace/${workspaceId}`, { page: 1, size: unitLimit })),
  getByOrganization: (organizationId: IdentifierDTO, unitLimit = 100) => () =>
    doGet<Unit[]>(toPaginatedURL(`/units/organization/${organizationId}`, { page: 1, size: unitLimit })),
  getPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Unit[]>(toPaginatedURL(`/units${params.url}${params.queryParams}`, pagination)),
  get: (unitId: IdentifierDTO) => () =>
    doGet<Unit>(`/units/${unitId}`),
  getBasic: (unitId: IdentifierDTO) => () =>
    doGet<BasicUnit>(`/dashboard/unit/basic-unit/${unitId}`),
  create: () => (unit: Partial<Unit>) =>
    doPost('/units', unit),
  createLite: (body: OwnerAccountAndLiteUnitFormSchema) =>
    doPost('/units/lite', body),
  edit: (unitId: IdentifierDTO) => (unit: Partial<Unit>) =>
    doPut<Unit>(`/units/${unitId}`, unit),
  delete: (unitId: IdentifierDTO) =>
    doDelete<Unit>(`/units/${unitId}`),
  assingTenant: (unitId: IdentifierDTO) => (tenant: Partial<Tenant>) =>
    doPost(`/units/${unitId}/tenant`, { tenantId: tenant._id }),
  unassingTenant: (unitId: IdentifierDTO) => (tenantId: IdentifierDTO) =>
    doDelete(`/units/${unitId}/tenant`, { tenantId: tenantId }),
  assingZazu: (unitId: IdentifierDTO) => (zazu: Partial<Staff>) =>
    doPost(`/units/${unitId}/zazu`, { zazuId: zazu._id }),
  unassingZazu: (unitId: IdentifierDTO) => (zazuId: IdentifierDTO) =>
    doDelete(`/units/${unitId}/zazu`, { zazuId: zazuId }),
  assingOwnerAccount: (unitId: IdentifierDTO) => (ownerAssignmentForm: Partial<OwnerAssignmentForm>) =>
    doPost(`/units/${unitId}/owner-account`, ownerAssignmentForm),
  unassingOwnerAccount: (unitId: IdentifierDTO) => (ownerAccountId: IdentifierDTO) =>
    doDelete(`/units/${unitId}/owner-account`, { ownerAccountId: ownerAccountId }),
  unassingOwnerLead: (unitId: IdentifierDTO) => (ownerLeadId: IdentifierDTO) =>
    doDelete(`/units/${unitId}/owner-lead`, { ownerLeadId: ownerLeadId }),
  assingManager: (unitId: IdentifierDTO) => (manager: Partial<User>) =>
    doPost(`/units/${unitId}/manager`, { managerId: manager._id }),
  unassingManager: (unitId: IdentifierDTO) => (managerId: IdentifierDTO) =>
    doDelete(`/units/${unitId}/manager`, { managerId: managerId }),
  assignAgent: (unitId: IdentifierDTO) => (userId: IdentifierDTO) =>
    doPost(`/units/${unitId}/agent/${userId}`),
  unassignAgent: (unitId: IdentifierDTO) => (userId: IdentifierDTO) =>
    doDelete(`/units/${unitId}/agent/${userId}`),
  assingAccountManager: (unitId: IdentifierDTO) => (userId) =>
    doPost(`/units/${unitId}/account-manager`, { userId }),
  unassingAccountManager: (unitId: IdentifierDTO) => (userId) =>
    doDelete(`/units/${unitId}/account-manager`, { userId }),
  assingIncidentsManager: (unitId: IdentifierDTO) => (incidentsManagerId) =>
    doPost(`/units/${unitId}/incidents-manager`, { incidentsManagerId }),
  unassingIncidentsManager: (unitId: IdentifierDTO) => (incidentsManagerId) =>
    doDelete(`/units/${unitId}/incidents-manager`, { incidentsManagerId }),
  searchEmptyOrganizationUnitsByName: (organizationId: IdentifierDTO) => (name: string) => () =>
    doGet<Unit[]>(`/units/organization/${organizationId}/search?name=${name}&status=empty`),
  //TODO: Check if can be removed and use other end point where is being used.
  getRecentByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<Unit[]>(`/units/organization/${organizationId}/search`),
  getEmptyOrganizationUnits: (organizationId: IdentifierDTO) => () =>
    doGet<Unit[]>(`/units/organization/${organizationId}/search?&status=empty`),
  searchOrganizationUnitsByName: (organizationId: IdentifierDTO) => (name: string) => () =>
    doGet<Unit[]>(`/units/organization/${organizationId}/search?name=${name}`),
  getOrganizationUnits: (organizationId: IdentifierDTO) => () =>
    doGet<Unit[]>(`/units/organization/${organizationId}/search?`),
  attachHeroImage: (unitId: IdentifierDTO) => (heroImage: FormData) =>
    doPostFileUpload(`/units/${unitId}/heroImage`, heroImage),
  deleteHeroImage: (unitId: IdentifierDTO) => (unit: Partial<Unit>) =>
    doDelete<Unit>(`/units/${unitId}/heroImage`),
  getDocuments: (unitId: IdentifierDTO, types?: string) => () =>
    doGet<Document[]>(`/units/documents/${unitId}?types=${types ? types : ''}`),
  createDocument: (unitId: IdentifierDTO) =>
    (request: CreateUnitDocumentRequest) =>
      doPostFileUpload<void>(`/units/documents/${unitId}/`, request.build()),
  updateDocument: (unitId: IdentifierDTO, documentId: IdentifierDTO) => (request: UpdateDocumentRequest) =>
    doPutFileUpload<void>(`/units/documents/${unitId}/${documentId}`, request.build()),
  uploadPhotos: (unitId: IdentifierDTO) => (data: any) =>
    doPostFileUpload<Unit>(`/units/${unitId}/photos`, data.formData),
  uploadVideos: (unitId: IdentifierDTO) => (data: { body: { videos: any[] } }) =>
    doPut<Unit>(`/units/${unitId}/videos`, data.body),
  getVideoPresignedPostURL: (id: IdentifierDTO) =>
    (contentType: string, filename: string) =>
      doPost<{ url: string, fields: Record<string, string>, publicURL: string }>(`/units/to-upload-url/${id}`, { contentType, filename }),
  sortPhotos: (unitId: IdentifierDTO) => (data: any) =>
    doPost(`/units/${unitId}/photos/sort`, data),
  deletePhoto: (unitId: IdentifierDTO) => (photoId) =>
    doDelete<any>(`/units/${unitId}/photos/${photoId}`),
  deleteVideo: (unitId: IdentifierDTO) => (videoId) =>
    doDelete<any>(`/units/${unitId}/videos/${videoId}`),
  deleteAllPhotos: (unitId: IdentifierDTO) => () =>
    doDelete(`/units/${unitId}/photos`),
  updatePhotoTag: (unitId: IdentifierDTO, photoId: IdentifierDTO) => (body: any) =>
    doPut<any>(`/units/${unitId}/photos/${photoId}`, body),
  updateVirtualTour: (unitId: IdentifierDTO) => (virtualTour: any) =>
    doPut<any>(`/units/${unitId}/virtual-tour`, { virtualTour }),
  deleteVirtualTour: (unitId: IdentifierDTO) =>
    doDelete<any>(`/units/${unitId}/virtual-tour`),
  canBeDeleted: (unitId: IdentifierDTO) =>
    doGet<Deletable>(`/onboarding/unit/${unitId}`),
  savePortalConfiguration: (unitId: IdentifierDTO) => (portal: UnitPortalRequestBody) =>
    doPut<Unit>(`/units/${unitId}/portal-configuration`, portal),
  saveInmofactoryPortalConfiguration: (unitId: IdentifierDTO) =>
    doPut<Unit>(`/units/${unitId}/portal-configuration/inmofactory`),
  validatePortals: (unitId: IdentifierDTO) => () =>
    doGet<any>(`/units/${unitId}/validate/portals`),
  updateNotes: (unitId: IdentifierDTO) => (body: Partial<UnitNotesBody>) =>
    doPut<Unit>(`/units/${unitId}/notes`, body),
  updateOwnerPreScoring: (unitId: IdentifierDTO) => (preScoring: Partial<OwnerPreScoring>) =>
    doPut(`/units/${unitId}/pre-scoring`, preScoring),
  createAvailability: (unitId: IdentifierDTO) => (body) =>
    doPost(`/availabilities/unit/${unitId}`, body),
  updateAvailability: (availabilityId: IdentifierDTO) => (body) =>
    doPut(`/availabilities/unit/${availabilityId}`, body),
  getAvailabilityById: (availabilityId: IdentifierDTO) => () =>
    doGet<Availability>(`/availabilities/unit/availability/${availabilityId}`),
  getAvailabilityByUnitId: (unitId: IdentifierDTO) => () =>
    doGet<Availability>(`/availabilities/unit/${unitId}`),
  getAvailabilitySlots: (availabilityId: IdentifierDTO) => () =>
    doGet<AvailabilitySlots>(`/availabilities/unit/slots/${availabilityId}`),
  requestServiceUpgrade: (unitId: IdentifierDTO) => (body) =>
    doPost(`/units/${unitId}/upgrade`, body),
  findCommercializedUnitsByOwnerAccount: () => () =>
    doGet<Unit[]>('/units/commercialized/owner-account'),
  findManagersInUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUserVO[]>(`/units/organization/${organizationId}/manager`, parserDataList(toBasicUser)),
  findProvidedByInUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUserVO[]>(`/units/organization/${organizationId}/provided-by`, parserDataList(toBasicUser)),
  findAccountManagersInUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUserVO[]>(`/units/organization/${organizationId}/account-manager`, parserDataList(toBasicUser)),
  findIncidentsManagersInUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUserVO[]>(`/units/organization/${organizationId}/incidents-manager`, parserDataList(toBasicUser)),
  findAgentsInUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUserVO[]>(`/units/organization/${organizationId}/agent`, parserDataList(toBasicUser)),
  blockPayouts: (unitId: IdentifierDTO) =>
    doPut(`/units/block-payouts/${unitId}`),
  unblockPayouts: (unitId: IdentifierDTO) =>
    doPut(`/units/unblock-payouts/${unitId}`),
  bulkActions: {
    assignManager: (organizationId: IdentifierDTO) => (body: BulkAssignManagerRequest) =>
      doPut<void>(`/units/bulk-actions/assign-manager/${organizationId}`, body)
  },
  assignProvidedBy: (unitId: IdentifierDTO) => (userId) =>
    doPut<void>(`/units/${unitId}/providedBy/${userId}`),
  unassignProvidedBy: (unitId: IdentifierDTO) => (userId) =>
    doDelete<void>(`/units/${unitId}/providedBy/${userId}`)
};
