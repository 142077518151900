import { PaginationOptions } from '#/hooks';
import { AgentOwnerLead, AgentOwnerLeadStatusType } from '#/models/AgentOwnerLead';
import { AgentOwnerLeadFormInputs } from '#/pages/agentOwnerLead/components/modals/AgentOwnerLeadForm.schema';
import { AgentOwnerLeadStageFormInputs } from '#/pages/agentOwnerLead/components/modals/AgentOwnerLeadStageForm.schema';
import { PromoteToOwnerRequest } from '#lib/network/api/agentOwnerLead/requests/PromoteToOwnerRequest';
import { IdentifierDTO } from '@zazume/zzm-base';
import { doDelete, doGet, doPost, doPut, EndpointBuilder, toPaginatedURL } from '../../helpers';

export const agentOwnerLeads = {
  getPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<AgentOwnerLead[]>(toPaginatedURL(`/dashboard/agent-owner-leads${params.url}${params.queryParams}`, pagination)),
  get: (agentOwnerLeadId: IdentifierDTO) => (): Promise<AgentOwnerLead> =>
    doGet<AgentOwnerLead>(`/dashboard/agent-owner-leads/${agentOwnerLeadId}`),
  create: () => (newAgentOwnerLead: AgentOwnerLeadFormInputs): Promise<AgentOwnerLead> =>
    doPost('/dashboard/agent-owner-leads', newAgentOwnerLead),
  delete: (agentOwnerLeadId: IdentifierDTO): Promise<void> =>
    doDelete(`/dashboard/agent-owner-leads/${agentOwnerLeadId}`),
  deleteAll: (idsToDelete: IdentifierDTO[]): Promise<void> =>
    doPost<void>('/dashboard/agent-owner-leads/bulk/delete', { leadIds: idsToDelete }),
  update: (agentOwnerLeadId: IdentifierDTO) => (updatedAgentOwnerLead: AgentOwnerLeadFormInputs) =>
    doPut<AgentOwnerLead>(`/dashboard/agent-owner-leads/${agentOwnerLeadId}`, updatedAgentOwnerLead),
  updateStage: (agentOwnerLeadId: IdentifierDTO) => (body: AgentOwnerLeadStageFormInputs) =>
    doPut<AgentOwnerLead>(`/dashboard/agent-owner-leads/${agentOwnerLeadId}/stage`, body),
  updateStatus: (agentOwnerLeadId: IdentifierDTO) => (status: AgentOwnerLeadStatusType) =>
    doPut<AgentOwnerLead>(`/dashboard/agent-owner-leads/${agentOwnerLeadId}/status`, { status }),
  promote: (agentOwnerLeadId: IdentifierDTO) => (request: PromoteToOwnerRequest) =>
    doPost<{ unitId: IdentifierDTO }>(`/dashboard/agent-owner-leads/${agentOwnerLeadId}/promote-to-owner`, request)
};
