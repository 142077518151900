import { IdentifierDTO } from '@zazume/zzm-base';

const unitBaseKey = 'units';

export const UnitKeys = {
  _id: unitBaseKey,
  base: [unitBaseKey],
  byId: (unitId: IdentifierDTO) =>
    [...UnitKeys.base, unitId],
  basicById: (unitId: IdentifierDTO) =>
    [...UnitKeys.base, 'basic', unitId],
  getAllUnits: (
    workspace?: IdentifierDTO,
    organization?: IdentifierDTO,
    building?: IdentifierDTO,
    status?: string,
    publishedStatus?: string,
    consent?: string,
    propertyType?: string,
    unitManager?: IdentifierDTO,
    accountManager?: IdentifierDTO,
    incidentManager?: IdentifierDTO,
    agent?: IdentifierDTO,
    providedBy?: IdentifierDTO
  ) => [...UnitKeys.base, workspace, organization, building, status, publishedStatus, consent, propertyType, unitManager, accountManager, incidentManager, agent, providedBy].filter(Boolean) as string[],
  notes: (unitId: IdentifierDTO) =>
    [...UnitKeys.base, unitId, 'notes'],
  portalPublicationStatus: (organizationId: IdentifierDTO) =>
    ['ExceedUnitsPublishedInPortal', organizationId],
  accountingNotes: (unitId: IdentifierDTO) =>
    [...UnitKeys.base, unitId, 'accountingNotes'],
  availability: (unitId: IdentifierDTO) =>
    [...UnitKeys.base, unitId, 'availability'],
  availabilitySlots: (unitId: IdentifierDTO) =>
    [...UnitKeys.base, unitId, 'availabilitySlots'],
  allCommercializedByOwnerAccount: (ownerAccountId: IdentifierDTO) =>
    [...UnitKeys.base, ownerAccountId, 'commercialized'],
  activeP2PBooking: (unitId: IdentifierDTO) =>
    [...UnitKeys.base, unitId, 'p2p-booking'],
  portalsValidation: (unitId: IdentifierDTO) =>
    [...UnitKeys.base, unitId, 'portals-validation'],
  documents: (unitId: IdentifierDTO) =>
    [...UnitKeys.base, unitId, 'documents'],
  managers: (organizationId: IdentifierDTO) =>
    [...UnitKeys.base, organizationId, 'managers'],
  incidentManagers: (organizationId: IdentifierDTO) =>
    [...UnitKeys.base, organizationId, 'incidentManagers'],
  accountManagers: (organizationId: IdentifierDTO) =>
    [...UnitKeys.base, organizationId, 'accountManagers'],
  agents: (organizationId: IdentifierDTO) =>
    [...UnitKeys.base, organizationId, 'agents'],
  providedBy: (organizationId: IdentifierDTO) =>
    [...UnitKeys.base, organizationId, 'providedBy']
};
