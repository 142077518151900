import { MergeTagMenu, MergeTag as MergeTagPrimitive } from '@zazume/zzm-utils';

interface ConstructorParams {
  list: MergeTagMenu[];
  conditionalBlocks: MergeTagPrimitive['conditionalBlocks']
}

export class MergeTag {
  list: MergeTagMenu[];
  conditionalBlocks: MergeTagPrimitive['conditionalBlocks'];

  constructor(params: ConstructorParams){
    this.list = params.list;
    this.conditionalBlocks = params.conditionalBlocks;
  }

  static fromRaw(mergeTag: any): MergeTag {
    return new MergeTag({
      list: mergeTag.list,
      conditionalBlocks: mergeTag.conditionalBlocks
    });
  }

  findVariableReadableName(
    variable: string,
    menus: MergeTagMenu[]
  ): string | undefined {
    const variableMenu = menus.find(item => item.value === variable);
    if (variableMenu) {
      return variableMenu.title;
    }
    for (const submenu of menus) {
      if (submenu.menu) {
        const variableName = this.findVariableReadableName(variable, submenu.menu);
        if (variableName) {
          return variableName;
        }
      }
    }
    return undefined;
  }

}
