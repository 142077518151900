import { PaginationOptions } from '#/hooks';
import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet, doPost, doPut, EndpointBuilder, toPaginatedURL } from '../../helpers';
import { BackgroundCheckStatus, SolvencyStudy } from '#/models/SolvencyStudy';
import { BasicUnit } from '#/domain/unit/BasicUnit';
import { CreationRequestWithNewTenant } from './requests/CreationRequestWithNewTenant';
import { CreationWithExistingTenantRequest } from './requests/creationWithExistingTenantRequest';
import { Document, Participants, User } from '#/models';

export const solvencies = {
  getPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<SolvencyStudy[]>(toPaginatedURL(`/dashboard/solvency-studies${params.url}${params.queryParams}`, pagination)),
  get: (solvencyStudyId: IdentifierDTO) => (): Promise<SolvencyStudy> =>
    doGet<SolvencyStudy>(`/dashboard/solvency-studies/${solvencyStudyId}`),
  create: () => (newSolvencyStudy: CreationWithExistingTenantRequest): Promise<SolvencyStudy> =>
    doPost('/dashboard/solvency-studies', newSolvencyStudy),
  createWithNewTenant: () => (newSolvencyStudy: CreationRequestWithNewTenant): Promise<SolvencyStudy> =>
    doPost('/dashboard/solvency-studies/with-new-tenant', newSolvencyStudy),
  getSolvencyUnits: () =>
    doGet<BasicUnit[]>('/dashboard/solvency-studies/all/units'),
  getSolvencyParticipantsBy: (solvencyStudyId: IdentifierDTO) => (): Promise<Participants> =>
    doGet<Participants>(`/dashboard/solvency-studies/${solvencyStudyId}/participants`),
  getDocuments: (id: IdentifierDTO) => () =>
    doGet<Document[]>(`/dashboard/solvency-studies/${id}/documents`),
  getAuthor: (id: IdentifierDTO) => () =>
    doGet<User>(`/dashboard/solvency-studies/${id}/author`),
  updateStatus: (id: IdentifierDTO) => (status: BackgroundCheckStatus) =>
    doPut<User>(`/dashboard/solvency-studies/${id}/status/${status}`),
  updateScoringPassed: (id: IdentifierDTO) => (status: BackgroundCheckStatus) =>
    doPut<User>(`/dashboard/solvency-studies/${id}/scoring-result/${status}`),
  decline: (id: IdentifierDTO) =>
    doPut<User>(`/dashboard/solvency-studies/${id}/decline`),
  insuranceInitialize: (id: IdentifierDTO) =>
    doPost<User>(`/dashboard/solvency-studies/${id}/insurance-intialize`)
};
