import { useAuth } from '#/contexts/AuthProvider';
import { isOwnerUser, User } from '#/models';
import { UserHelper } from '#/models/helpers/user/UserHelper';

export interface UseSessionResponse {
  isOwner: boolean;
  isAgent: boolean;
  isAdmin: boolean;
  isBuildingManager: boolean;
  isZazume: boolean;
  isCommercial: boolean;
  isStaff: boolean;
  isTenant: boolean;
  isViewer: boolean;
  user: User;
  userHelper: UserHelper;
}

export const useSession = (): UseSessionResponse => {
  const { user } = useAuth();

  if (!user) {
    throw new Error('useSession must be used in a private route');
  }

  const userHelper = new UserHelper(user);

  return {
    isOwner: isOwnerUser(user),
    isAdmin: user.roles.includes('admin'),
    isBuildingManager: user.roles.includes('buildingManager'),
    isZazume: user.roles.includes('zazume'),
    isAgent: userHelper.isAgent(),
    isCommercial: user.roles.includes('commercial'),
    isStaff: user.roles.includes('staff'),
    isTenant: user.roles.includes('tenant'),
    isViewer: user.roles.includes('viewer'),
    user,
    userHelper
  };
};
