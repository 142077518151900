import { UseAPIResult } from '#/hooks';
import { FC, ReactElement } from 'react';
import { UseAPIResultWithPagination } from '#lib/reactQuery/hooks/usePagination';

interface BaseAsyncComponentProps {
  loadingComponent?: JSX.Element;
  errorComponent?: JSX.Element;
  emptyStateComponent?: JSX.Element;
  optionalData?: boolean;
}

interface StandardAPIProps<T> extends BaseAsyncComponentProps {
  children: (data: T, apiResult: UseAPIResult<T>) => ReactElement | null;
  apiResult: UseAPIResult<T> & {
    nextPage?: never;
    previousPage?: never;
    toPage?: never;
    currentPage?: never;
    usedQueryKey?: never;
  };
}

interface PaginatedAPIProps<T> extends BaseAsyncComponentProps {
  children: (data: T, apiResult: UseAPIResultWithPagination<T>) => ReactElement | null;
  apiResult: UseAPIResultWithPagination<T>;
}

interface ImplementationProps<T> extends BaseAsyncComponentProps {
  children: (data: T, apiResult: any) => ReactElement | null;
  apiResult: any;
}

const Loading: FC = () => {
  return <div>...</div>;
};

const Error: FC = () => {
  return <div>...</div>;
};

const isEmptyData = (data: any): boolean => {
  return !data || (Array.isArray(data) && data.length === 0);
};

export function AsyncComponent<T>(props: StandardAPIProps<T>): ReactElement | null;
export function AsyncComponent<T>(props: PaginatedAPIProps<T>): ReactElement | null;
export function AsyncComponent<T>({
  apiResult,
  loadingComponent,
  errorComponent,
  children,
  emptyStateComponent,
  optionalData
}: ImplementationProps<T>): ReactElement | null {
  if (apiResult.isLoading) {
    return loadingComponent || <Loading/>;
  }

  if (apiResult.isError) {
    return errorComponent || <Error/>;
  }

  if (isEmptyData(apiResult.data) && !optionalData && emptyStateComponent) {
    return emptyStateComponent;
  }

  const toRender = children(apiResult.data as T, apiResult);

  if (Array.isArray(toRender)) {
    return <>{toRender}</>;
  }

  return toRender;
}
