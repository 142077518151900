import { PaginationOptions } from '#/hooks';
import { BasicUnit, Document } from '#/models';
import { Card } from '#/models/Card';
import { Payer, Payment, PaymentReconciliation } from '#/models/Payment';
import { ExportInvoicesInputs } from '#/pages/accounting/invoices/tabs/issued/components/ExportInvoices.schema';
import { EditIncomeForm } from '#/pages/accounting/payments/modal/common/EditIncome.schema';
import { doDelete, doGet, doPatchFileUpload, doPost, doPostFileUpload, doPut, EndpointBuilder, toPaginatedURL } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';
import { BulkCreateReconciledPaymentRequest } from './requests/BulkCreateReconciledPaymentRequest';
import { LossReconciliationFormFields } from '#/pages/accounting/payments/modal/AddLossReconciliationModal/AddLossReconciliation.schema';

export const payments = {
  addMultiplePaymentsFromBankTransactions: (paymentId: IdentifierDTO) => (formData: any) =>
    doPut(`/payments/reconcile/${paymentId}/bank-transactions`, formData),
  bulk: {
    createReconciled: (request: BulkCreateReconciledPaymentRequest) =>
      doPost<void>('/payments/bulk/reconcile', request)
  },
  cancel: (cancelData: any) =>
    doPut<Payment>(`/payments/cancel/${cancelData.paymentId}`, { note: cancelData.note }),
  create: (organizationId: IdentifierDTO) => (payment: Partial<Payment>) =>
    doPost<Payment>(`/payments/${organizationId}/create`, payment),
  createDelayInterest: (organizationId: IdentifierDTO, incomeId: IdentifierDTO) => () =>
    doPost<Payment>(`/payments/${organizationId}/create-delay-interest/${incomeId}`),
  createDirectDebitMandate: (paymentId: string, token: string) => (directDebitData: any) =>
    doPost<string>(`/payments-public/${paymentId}/create-direct-debit?token=${token}`, directDebitData),
  createFromBankTransaction: (organizationId: IdentifierDTO, transactionId: IdentifierDTO) => (payment: Partial<Payment>) =>
    doPost<Payment>(`/payments/${organizationId}/create/transaction/${transactionId}`, payment),
  createMultipleReconciliation: () => (newReconciliations) =>
    doPut('/payments/reconcile-multiple', newReconciliations),
  createReconciliation: (paymentId: IdentifierDTO) => (newReconciliation: Partial<PaymentReconciliation>) =>
    doPut<Payment>(`/payments/reconcile/${paymentId}`, newReconciliation),
  createReconciliationAsLoss: (paymentId: IdentifierDTO) => (newReconciliation: Partial<LossReconciliationFormFields>) =>
    doPut<Payment>(`/payments/reconcile-as-loss/${paymentId}`, newReconciliation),
  delete: (incomeId: IdentifierDTO) => () =>
    doDelete<void>(`/payments/${incomeId}`),
  deleteAttachment: (paymentId: IdentifierDTO) => (attachmentId: IdentifierDTO) =>
    doDelete<Document>(`/payments/attachments/${paymentId}/${attachmentId}`),
  deleteReconciliation: (paymentId: IdentifierDTO) => (formData: any) =>
    doDelete<Payment>(`/payments/${paymentId}/reconciliation/${formData.reconciliationId}`, { forced: formData.forced }),
  directDebit: {
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<Payment[]>(toPaginatedURL(`/payments/direct-debit${params.url}${params.queryParams}`, pagination)),
    getAllCreditors: (organizationId: IdentifierDTO) => () =>
      doGet<any[]>(`/payments/direct-debit/creditors/${organizationId}`),
    createSepa: (creditorIdentifier: string, organizationId: IdentifierDTO) => (date) =>
      doPost<any>(`/sepa/${organizationId}/${creditorIdentifier}`, { collectionDate: date })
  },
  edit: (paymentId: IdentifierDTO) => (editedPayment: EditIncomeForm) =>
    doPut<Payment>(`/payments/${paymentId}`, editedPayment),
  editReconciliation: (paymentId: IdentifierDTO, reconciliationId: IdentifierDTO) => (editedReconciliation: Partial<Payment>) =>
    doPut<Payment>(`/payments/${paymentId}/reconciliation/${reconciliationId}`, editedReconciliation),
  editLossReconciliation: (paymentId: IdentifierDTO, reconciliationId: IdentifierDTO) => (editedReconciliation: Partial<LossReconciliationFormFields>) =>
    doPut<Payment>(`/payments/${paymentId}/loss-reconciliation/${reconciliationId}`, editedReconciliation),
  export: (body: ExportInvoicesInputs, organizationId: IdentifierDTO) =>
    doPost(`/payments/export/${organizationId}`, body),
  getAttachments: (paymentId: IdentifierDTO) => () =>
    doGet<Document[]>(`/payments/attachments/${paymentId}`),
  getMandateSignUrl: (paymentId: string, token: string) => () =>
    doGet<string>(`/payments-public/${paymentId}?token=${token}`),
  getMetrics: (organizationId) => () =>
    doGet<Card[]>(`/payments/payments-metrics/${organizationId}`),
  getOne: (paymentId: IdentifierDTO) => () =>
    doGet<Payment>(`/payments/detail/${paymentId}`),
  getOrganizationIBAN: (paymentId: IdentifierDTO) => () =>
    doGet<string>(`/payments/organization-iban/${paymentId}`),
  getPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Payment[]>(toPaginatedURL(`/payments${params.url}${params.queryParams}`, pagination)),
  getPaymentUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUnit[]>(`/payments/units/organization/${organizationId}`),
  getReconcilablePaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Payment[]>(toPaginatedURL(`/payments/reconcilable${params.url}${params.queryParams}`, pagination)),
  getReconcilablePaymentTenantsBy: (organizationId: IdentifierDTO) => () =>
    doGet<Payer[]>(`/payments/reconcilable/tenants/organization/${organizationId}`),
  getReconcilablePaymentUnitsBy: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUnit[]>(`/payments/reconcilable/units/organization/${organizationId}`),
  import: () => (formData: FormData) =>
    doPostFileUpload<void>('/payments/import', formData),
  requestPayment: (incomeId: IdentifierDTO) =>
    doPut<void>(`/payments/${incomeId}/request-payment`),
  save: (organizationId: IdentifierDTO) => (payment: Partial<Payment>) =>
    doPost<Payment>(`/payments/${organizationId}/save`, payment),
  sendReminder: (incomeId: IdentifierDTO) =>
    doPut<void>(`/payments/${incomeId}/send-reminder`),
  uploadAttachments: (paymentId: IdentifierDTO) => (document: any) =>
    doPatchFileUpload<Document>(`/payments/attachments/${paymentId}`, document),
  getPaginatedNonPaidByUnitAndContract: (unitId: IdentifierDTO, contractId: IdentifierDTO) => (pagination: PaginationOptions) =>
    () => doGet<Payment[]>(toPaginatedURL(`/payments/non-paid/unit/${unitId}/contract/${contractId}`, pagination)),
  getByNonPayment: (id: IdentifierDTO) => () =>
    doGet<Payment[]>(`/payments/non-payment/${id}`)
};
