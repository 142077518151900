import { Unit } from '#/models';
import { Card } from '#/models/Card';
import { Walkthrough } from '#/models/walkthrough';
import { doGet, doPost, EndpointBuilder } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';
import { toShareMeProfitability } from './dashboard.parsers';
import { Dashboard } from './models/dashboard';
import { ShareMeProfitability } from './models/ShareMeProfitability';

export const dashboard = {
  getDashboard: (props: EndpointBuilder) => () =>
    doGet<Card[]>(`/dashboard${props.url}`),
  agent: {
    old_general: (params: EndpointBuilder) => () =>
      doGet<Dashboard>(`/dashboard/agent/general/old${params.queryParams}`),
    general: (params: EndpointBuilder) => () =>
      doGet<Dashboard>(`/dashboard/agent/general${params.queryParams}`),
    viewings: () =>
      doGet<Dashboard>('/dashboard/agent/viewings')
  },
  manager: {
    accounting: (params: EndpointBuilder) => () =>
      doGet<Dashboard>(`/dashboard/manager/accounting${params.queryParams}`),
    commercialisation: (params: EndpointBuilder) => () =>
      doGet<Dashboard>(`/dashboard/manager/commercialisation${params.queryParams}`),
    management: (params: EndpointBuilder) => () =>
      doGet<Dashboard>(`/dashboard/manager/management${params.queryParams}`)
  },
  owner: {
    me: (params: EndpointBuilder) => () =>
      doGet<Dashboard>(`/dashboard/owner/me${params.queryParams}`),
    createShareMeAnnualProfitability: (params: EndpointBuilder) =>
      doPost<ShareMeProfitability>(`/dashboard/owner/share-me-annual-profitability${params.queryParams}`, undefined, toShareMeProfitability),
    missingDataUnits: (params: EndpointBuilder) => () =>
      doGet<Unit[]>(`/dashboard/owner/profitability-missing-data-units${params.queryParams}`),
    walkthrough: {
      get: () =>
        doGet<Walkthrough>('/dashboard/walkthrough/me'),
      discard: (id: IdentifierDTO) =>
        doPost<Walkthrough>(`/dashboard/walkthrough/discard/${id}`)
    }
  }
};
