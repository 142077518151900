import { useSession } from '#hooks/session/useSession';
import { To } from 'history';
import React, { FC } from 'react';
import { Navigate as RouterDomNavigate } from 'react-router-dom';

interface NavigateProps {
  to: To;
  ownerTo?: To;
  replace?: boolean;
}

const useFinalTo = (props: NavigateProps) => {
  const session = useSession();
  if (props.ownerTo && session.isOwner) {
    return props.ownerTo;
  }
  return props.to;
};

export const Navigate: FC<NavigateProps> = (props) => {
  const to = useFinalTo(props);
  return <RouterDomNavigate to={to} replace={props.replace}/>;
};
