import { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface AgentDocumentsProps extends SectionProps {
}

export const AgentDocuments: FC<AgentDocumentsProps> = ({ onClickItem, isMobile, depth }) => {
  const { t } = useI18n();

  return <Can hasRoles={['buildingManager', 'admin']}>
    <NavigationLink
      to={Private.AGENT_DOCUMENTS.route}
      onClick={onClickItem}
      icon="marketplace"
      title={t('general.documents')}
      isMobile={isMobile}
      depth={depth}
    />
  </Can>;
};
