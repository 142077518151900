import { OwnerContractAgentServiceOption, PropertyManagementPaymentFee } from '#/models/Organization';
import { IdentifierDTO } from '@zazume/zzm-base';

type ServiceOperation = 'commercialisation' | 'management';

interface ConstructorParams {
  type: OwnerContractAgentServiceOption;
  operation: ServiceOperation;
  templateId?: IdentifierDTO;
  fee?: PropertyManagementPaymentFee;
}

export class OwnerServiceContractAgentOption {
  type: OwnerContractAgentServiceOption;
  operation: ServiceOperation;
  templateId?: IdentifierDTO;
  fee?: PropertyManagementPaymentFee;

  constructor(params: ConstructorParams){
    this.type = params.type;
    this.operation = params.operation;
    this.templateId = params.templateId;
    this.fee = params.fee;
  }

  static fromRaw(serviceOption: any): OwnerServiceContractAgentOption {
    return new OwnerServiceContractAgentOption({
      type: serviceOption.type,
      operation: serviceOption.operation,
      templateId: serviceOption.templateId,
      fee: serviceOption.fee
    });
  }

  isCommercialisation(): boolean {
    return this.operation === 'commercialisation';
  }

  isManagement(): boolean {
    return this.operation === 'management';
  }
}
