import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface Props extends SectionProps {
}

export const BankAccounts: FC<Props> = ({ onClickItem, depth, isMobile }) => {
  const { t } = useI18n();
  return <Can hasRoles={['buildingManager', 'admin', 'ownerManager', 'agent']}>
    <NavigationLink to={Private.BANK_ACCOUNTS.route} title={t('bankAccounts.title')} icon="savings" onClick={onClickItem} depth={depth} isMobile={isMobile}/>
  </Can>;
};
