export default {
  accounting: {
    actions: {
      sendReminder: "Enviar recordatorio",
      sendReminderSuccess: "Recordatorio enviado con éxito"
    },
    amount: {
      paid: "Pagado",
      pending: "Pendiente",
      pendingLong: "Importe pendiente por pagar"
    },
    attachments: {
      help: "Aquí puedes adjuntar un comprobante de transferencia bancaria, una factura o cualquier otro archivo sobre este gasto."
    },
    beneficiary: "Beneficiario",
    beneficiaryPlaceholder: "Elige quién recibirá el dinero",
    beneficiaryTypes: {
      agent: "Agente",
      organization: "Organización",
      owner: "Propietario/a",
      platform: "Plataforma",
      supplier: "Proveedor",
      tenant: "Inquilino"
    },
    breakdown: {
      amount: "Importe",
      discount: "Descuento {{discountConcept}} ({{discount}})",
      title: "Desglose"
    },
    "delete": {
      expense: {
        generic: "Este gasto no puede ser eliminado.",
        linkedToInvoice: "Este gasto no se puede eliminar porque hay una factura vinculada.",
        linkedToPayment: "Este gasto no puede ser eliminado porque hay un pago de reconciliación vinculado. Para eliminar este gasto, debe eliminar todas las reconciliaciones. No te preocupes, no desaparecerán de tu cuenta bancaria, podrás volver a adjuntarlos."
      },
      income: {
        attributed: "Este ingreso proviene de un gasto atribuido. Si desea eliminar el ingreso, debe ir a los detalles del gasto para eliminar ambos.",
        generic: "Este ingreso no puede ser eliminado.",
        linkedToInvoice: "Este ingreso no se puede eliminar porque hay una factura vinculada.",
        recurrent: "Este ingreso no puede ser eliminado porque hay un pago de reconciliación vinculado. Para eliminar este ingreso, debe eliminar todas las reconciliaciones. No te preocupes, no desaparecerán de tu cuenta bancaria, podrás volver a adjuntarlos."
      },
      linkedPayoutWarning: "Este {{accountingType}} no se puede editar ya que está vinculado a una liquidación.",
      recurrentLeaseWarning: "Este {{accountingType}} no se puede eliminar ya que es un {{accountingType}} automático creado a partir de un contrato. La única forma de eliminarlo es rescindiendo dicho contrato.",
      recurrentWarning: "Este {{accountingType}} no se puede eliminar ya que ha sido creado a partir de un {{accountingType}} recurrente. Para eliminar este {{accountingType}} debes modificar la orden en la página de gastos recurrentes."
    },
    details: {
      excluded: "Excluido",
      ownerVisibility: "Visibilidad del propietario"
    },
    discount: {
      addDiscount: "Añadir descuento",
      addModal: {
        acceptButton: "Aplicar descuento",
        amount: "Selecciona y especifica el monto del descuento",
        howManyTimes: "¿Cuántas veces quieres aplicar este descuento?",
        messages: {
          add: "No existe ningún descuento aplicado, puedes añadirlo desde las opciones del detalle de la recurrencia",
          remove: "Existe un descuento aplicado, puedes eliminarlo desde las opciones del detalle de la recurrencia"
        },
        numberOfTimes: "Selecciona un número personalizado de veces",
        startAt: "Elige en qué pago debe empezar a aplicarse este descuento",
        title: "Añadir descuento",
        toast: "Descuento añadido correctamente"
      },
      discount: "Descuentos",
      nextChargeModified: "Este importe ha sido modificado desde la sección \"Próximo cargo\" de la edición de la recurrencia",
      removeConfirmationModal: {
        actionButton: "Si, eliminar descuento",
        description: "Al eliminar el descuento, los próximos cargos dónde el descuento esté aplicado se modificarán. Esta acción no se puede deshacer.",
        title: "¿Seguro que quieres eliminar el descuento?",
        toast: "Descuento eliminado correctamente"
      },
      removeDiscount: "Eliminar descuento",
      subtitleBreakdown: "Resto de {{time}}",
      timesOptions: {
        always: "Siempre",
        custom: "Personalizado",
        once: "Una sola vez",
        thrice: "Tres veces",
        twice: "Dos veces"
      },
      withDiscount: "Con descuento",
      withoutDiscount: "Sin descuento"
    },
    edit: {
      expense: {
        generic: "Este ingreso no puede ser editado."
      },
      income: {
        attributed: "Este ingreso proviene de un gasto atribuido. Si quieres editar el ingreso, debes ir a los detalles del gasto para editar ambos.",
        cancelledState: "Este ingreso no puede ser editado porque ha sido cancelado.",
        generic: "Este ingreso no puede ser editado.",
        recurrent: "Este ingreso no se puede editar ya que tiene una reconciliación vinculada. Para editar este pago, debes eliminar todas las reconciliaciones. No te preocupes, no desaparecerán de tu cuenta bancaria, podrás volver a adjuntarlos."
      },
      linkedPayoutWarning: "Este {{accountingType}} no se puede editar ya que está vinculado a una liquidación.",
      recurrentLeaseWarning: "Este {{accountingType}} no se puede editar ya que es un {{accountingType}} automático creado a partir de un contrato. La única forma de editarlo es creando un nuevo contrato.",
      recurrentWarning: "Este {{accountingType}} no se puede editar ya que ha sido creado a partir de un {{accountingType}} recurrente. Para editarlo debes modificar la orden en la página de {{accountingType}} recurrentes."
    },
    expenses: {
      "delete": {
        impossibleToDeleteModal: {
          description: "Este gasto no se puede eliminar porque ha sido creado por un gasto recurrente.",
          descriptionLinkedIncome: "Hay un ingreso asociado que ya se ha pagado. Si desea eliminar el gasto, primero debe eliminar la reconciliación de ingresos para poder eliminarlo.",
          title: "El gasto no puedo ser eliminado."
        },
        linkedIncomeWarning: {
          description: "Hay un ingreso no pagado asociado a este gasto. Al eliminar este gasto, también se borrará  el ingreso asociado. También cancelaremos la solicitud de pago enviando un correo electrónico al pagador. Esta acción no se puede deshacer.",
          title: "Eliminar gasto e ingreso asociados"
        }
      }
    },
    forms: {
      chooseExistentProvider: "Elige un proveedor existente",
      chooseProvider: "Elige un proveedor",
      discount: "Descuento",
      errors: {
        discountPercentage: "El porcentaje de descuento debe estar entre 0 y 100",
        discountTooHigh: "El descuento no puede ser superior al valor del importe"
      },
      excludedFromPayout: "Excluir este {{entity}} de las liquidaciones",
      ownerVisibilityLabel: "Excluir este {{entity}} de la contabilidad del propietario",
      searchProvider: "Buscar proveedor",
      whichProvider: "¿Qué proveedor?",
      whichTenant: "¿Qué inquilino?"
    },
    payer: "Pagador",
    payerPlaceholder: "Elige quién pagará este gasto",
    payerTypes: {
      agent: "Agente",
      organization: "Organización",
      owner: "Propietario",
      platform: "Plataforma"
    },
    tabs: {
      expenses: "Gastos",
      income: "Ingresos",
      payouts: "Liquidaciones",
      rentAdvance: "Adelanto de rentas"
    },
    title: "Contabilidad"
  },
  activityAndComments: {
    activity: "Actividad",
    comments: "Comentarios",
    inputPlaceholder: "Añade un comentario",
    title: "Actualizaciones"
  },
  agentOwnerLeads: {
    archive: {
      title: "Leads archivados"
    },
    deleteAllModal: {
      body: "Estás a punto de eliminar <1>{{count}} leads</1>",
      inputLabel: "Eliminar {{count}} leads",
      notificationSuccess: "Los leads se proceden a eliminar, te notificaremos cuando esté completado",
      title: "Eliminar leads"
    },
    detail: {
      createdAt: "Creado:",
      personalDetails: "Detalles personales",
      propertyDetails: "Detalles de la propiedad",
      title: "Detalles del lead",
      viewPublication: "Ver publicación"
    },
    listing: {
      emptyState: "No hay ningún lead",
      filters: {
        agent: "Agente",
        allValue: "Todos",
        source: "Origen",
        sourceOptions: {
          agentLandingWeb: "Web",
          coldLeadAssignment: "Cold Lead",
          dashboard: "Manual"
        },
        stage: "Etapa",
        status: "Estado",
        statusOptions: {
          "call-again": "Volver a llamar",
          contacted: "Contactado",
          interested: "Interesado/a",
          "not-contacted": "No contactado"
        },
        value: "Rango de valores",
        zone: "Zona",
        zonePlaceholder: "Buscar una zona"
      },
      headers: {
        agent: "Agente",
        name: "Nombre",
        phone: "Teléfono",
        source: "Origen",
        stage: "Etapa",
        status: "Estado",
        value: "Valor",
        zone: "Zona"
      },
      options: {
        discard: "Descartar lead",
        goAd: "Ver anuncio de la propiedad",
        goDetails: "Ver detalles",
        promoteOwner: "Promocionar a propietario",
        updateStatus: "Cambiar etapa"
      },
      sourceTime: {
        daysAgo: "hace {{days}} días",
        today: "Hoy",
        yesterday: "Ayer"
      }
    },
    modals: {
      afterPromotion: {
        cancel: "No, gracias.",
        confirm: "Sí, quiero ir a la propiedad.",
        description: "Para continuar con el proceso y firmar el contrato de servicio, debes dirigirte a la pestaña de contratos dentro de los detalles de la propiedad.",
        title: "¿Quieres firmar el contrato de servicio?"
      },
      create: {
        successMessage: "Propietario potencial creado con éxito"
      },
      edit: {
        stage: {
          successMessage: "Etapa actualizada correctamente",
          title: "Actualizar etapa"
        },
        status: {
          successMessage: "Estado actualizado correctamente",
          title: "Actualizar estado"
        },
        successMessage: "Propietario potencial editado con éxito",
        title: "Editar propietario potencial"
      },
      remove: {
        description: "Esto eliminará el propietario potencial seleccionado de forma permanente. Esta acción no se puede deshacer.",
        successMessage: "El propietario potencial se ha eliminado correctamente",
        title: "¿Eliminar el propietario potencial?"
      }
    },
    promote: {
      steps: {
        confirmation: {
          header: "Una vez enviado el formulario, ocurrirá lo siguiente:",
          listStep1: "Se creará un perfil de propietario que podrás encontrar en la página de <1>Personas</1> del menú.",
          listStep2: "Se creará la propiedad que podrás encontrar en la página de <1> Propiedades </1> del menú."
        }
      },
      success: "El lead ha sido promocionado correctamente"
    },
    statuses: {
      active: "Activo",
      lost: "Perdido",
      won: "Ganado"
    }
  },
  agents: {
    addContractService: {
      modal: {
        contractPreview: "Vista previa del contrato",
        ownerError: {
          cta: "Ir a detalles del propietario",
          message: "Para continuar con el proceso <b>falta información sobre el propietario</b>:"
        },
        selectService: "Elige un servicio:",
        submit: "Enviar contrato de servicio",
        title: "Añadir contrato de servicio"
      },
      services: {
        commercialisation_long_term: "Comercialización - larga estancia",
        commercialisation_short_term: "Comercialización - media estancia",
        full_coverage: "Cobertura total ({{value}}%)",
        guaranteed_rental: "Gestión garantizada ({{value}}%)",
        guaranteed_rental_self_management: "Alquiler garantizado ({{value}}%)",
        rental_management: "Gestión del alquiler ({{value}}%)",
        unlimited_guaranteed_rental: "Gestión garantizada ilimitada ({{value}}%)",
        unlimited_guaranteed_rental_self_management: "Alquiler garantizado ilimitado ({{value}}%)"
      }
    },
    common: {
      activateLanding: "Activar página web",
      agentCommission: "Comisión de agente",
      billingData: "Datos de Facturación",
      company: "Empresa",
      details: "Detalles del agente",
      idNumber: "CIF / Número de identificación",
      invitationAccepted: "Se aceptó invitación",
      invitationPending: "Invitación pendiente",
      invitationResend: "Volver a enviar",
      invitationSent: "Invitación enviada",
      landingPage: "Página web",
      publicName: "Nombre público",
      search: "Buscar agente",
      serviceArea: "Áreas de servicio",
      serviceAreaHelper: "Añada un área de servicio pulsando enter o usando comas",
      slug: "Enlace personal",
      type: {
        basic: "Básico",
        gold: "Gold",
        title: "Tipo de agente"
      },
      workingCities: {
        title: "Ciudades cubiertas"
      }
    },
    detail: {
      edit: "Editar agente",
      title: "Detalles del agente"
    },
    marketing: {
      campaigns: {
        bottomText: "Da el siguiente paso ahora y conviértete en el referente que los propietarios buscan. ¡Hagamos crecer tu negocio juntos!",
        card: {
          button: "Solicitar asistencia",
          content: "Rellenar este formulario y explicarnos tus objetivos y necesidades. Tan pronto como sea posible nuestro equipo de expertos se pondrá en contacto contigo para proporcionarte soluciones personalizadas.",
          title: "Solicita asistencia en marketing"
        },
        description: "¿Quieres destacar en el mercado inmobiliario y captar más propietarios? En Zazume, entendemos tus necesidades como agente inmobiliario y estamos aquí para ayudarte a potenciar tu visibilidad y aumentar tus oportunidades de negocio.",
        list: {
          item1: "<1>Equipo experto dedicado:</1> Te ayudamos a desarrollar materiales visuales y textos persuasivos que refuercen tu imagen profesional.",
          item2: "<1>Campañas personalizadas:</1> Diseñamos estrategias adaptadas a tus objetivos para captar la atención de los propietarios que buscas.",
          item3: "<1>Soporte continuo:</1> Te acompañamos en cada paso del proceso, desde la creación de anuncios hasta la optimización en los canales de promoción.",
          title: "¿Cómo podemos ayudarte?"
        },
        title: "Te ayudamos a captar más propietarios: marketing a medida"
      }
    },
    modals: {
      add: {
        addButton: "Añadir agente",
        services: "Servicios ofrecidos",
        title: "Añadir nuevo agente"
      },
      remove: {
        description: "Esto eliminará el agente seleccionado de forma permanente. Esta acción no se puede deshacer.",
        successMessage: "El agente se ha eliminado correctamente",
        title: "¿Eliminar al agente?"
      },
      workingCities: {
        add: "Añadir ciudad",
        noCities: "No hay ciudades seleccionadas",
        search: "Busca una ciudad",
        select: "Selecciona ciudades"
      }
    },
    table: {
      add: "Añadir agente",
      emptyState: "Aún no se ha añadido ningún agente",
      filters: {
        serviceArea: "Áreas de servicio"
      },
      headers: {
        agent: "Agente",
        email: "Correo electrónico",
        phoneNumber: "Número de teléfono",
        serviceArea: "Áreas de servicio"
      }
    },
    title: "Agentes"
  },
  application: {
    endingDate: "Fecha de finalización del contrato",
    maxMonthsExtension: "Prórroga máxima (meses)",
    monthsExtension: "Prórroga (meses)",
    notifyEndLeaseExtensionMonths: "Fin de la prórroga (meses)",
    notifyEndLeaseMonths: "Fin del contrato (meses)",
    startingDate: "Fecha de inicio del contrato",
    withEndLeaseNotifications: "Notificar el final del contrato (y la prórroga)",
    withLeaseExtension: "Añadir prórroga al contrato"
  },
  archived: {
    detail: {
      title: "Detalles de la aplicación"
    },
    option: "Archivar",
    title: "Aplicaciones archivadas",
    toast: {
      archive: "La aplicación se ha archivado correctamente",
      "delete": "La aplicación se ha eliminado correctamente"
    }
  },
  asideNavigation: {
    admin: "Admin",
    bookings: "Reservas",
    buildings: "Edificios",
    calendar: "Calendario",
    commercialisation: "Comercialización",
    dashboard: "Inicio",
    documents: "Documentos",
    facilities: "Instalaciones",
    incidents: "Incidencias",
    investment: "Inversión",
    management: "Gestión",
    marketPlace: "Marketplace",
    myProperties: "Mis propiedades",
    onboarding: "Solicitudes",
    organization: "Organización",
    organizationServices: "Servicios (organización)",
    organizationSettings: "Configuración de la organización",
    ownerAccounts: "Cuentas de propietario",
    ownerInvitations: "Gana 100€",
    people: "Personas",
    portfolio: "Portfolio",
    properties: "Propiedades",
    services: "Servicios",
    settings: "Configuración",
    solvency: "Solvencia",
    staff: "Personal",
    taskManager: "Administrador de tareas",
    tasks: "Tareas",
    team: "Equipo",
    templates: "Plantillas",
    tenants: "Inquilinos",
    units: "Propiedades",
    valuations: "Valoraciones",
    workspaces: "Workspaces"
  },
  assignation: {
    chooseRole: "Elige un rol",
    whichRole: "¿Qué rol?"
  },
  auth: {
    backLogin: "Volver al inicio de sesión",
    confirmPassword: "Confirmar contraseña",
    createPassword: "Crear una contraseña",
    forgot: "¿Olvidaste la contraseña?",
    forgotPage: {
      success: "Hemos enviado un enlace para restablecer tu contraseña a {{email}}",
      title: "Introduce tu email y te enviaremos un enlace para restablecer la contraseña"
    },
    newPassword: "Nueva contraseña",
    password: "Mínimo 8 caracteres con letras mayúsculas y minúsculas, y al menos un número",
    privacy: "la Política de privacidad",
    resetPassword: "Restablece tu contraseña",
    resetPasswordSuccess: "Tu contraseña ha sido actualizada",
    signIn: "Iniciar sesión",
    termsAgree: "He leído y acepto los",
    termsAndConditions: "Términos y Condiciones"
  },
  autoReconciliations: {
    cancel: "Cancelar reconciliación automática",
    cancelDescription: "Dejaremos de reconciliar automáticamente este patrón de movimiento bancario de ahora en adelante. Esto no desvinculará los pagos ya reconciliados.",
    canceled: "Reconciliación automática cancelada con éxito",
    debtor: "Emisor",
    emptyState: "Aún no se han creado reconciliaciones automáticas.",
    pageTitle: "Configuración de pagos con reconciliación automática",
    title: "Auto-reconciliación"
  },
  availability: {
    add: "Añadir disponibilidad",
    agent: {
      title: "Quién realizará la visita"
    },
    applicantsRequirement: "Las visitas solo estarán disponibles para...",
    applicantsRequirementDescriptions: {
      everyone: "Recibe todas las solicitudes de personas interesadas en tu vivienda. Te recomendamos publicar primero con esta opción y luego, en caso de necesitarlo, activar uno de los siguientes filtros.",
      onlyQualified: "Recibe sólo visitas de personas que se ajusten a tus preferencias. Un 70% de personas interesadas completa esta información antes de las visitas.",
      onlyWithDocumentation: "Recibe sólo visitas de personas que suban la documentación para verificar su solvencia antes de la visita. Un 40% de personas interesadas completan esta información antes de las visitas."
    },
    applicantsRequirementTypes: {
      everyone: "Cualquier persona interesada en la propiedad",
      onlyQualified: "Solicitantes que cumplan con los requisitos de precualificación",
      onlyWithDocumentation: "Solicitantes que suben sus documentos de solvencia antes de la visita"
    },
    availableDays: "Días disponibles",
    availableSlots: "Franjas horarias disponibles",
    calendarRange: "Rango de calendario",
    chooseDate: "Elige una fecha",
    created: "Disponibilidad creada",
    duration: "Duración",
    edit: "Editar disponibilidad",
    errorModal: {
      message: "No se puede crear la disponibilidad de visitas para una propiedad publicada sin configurar primero los datos de cualificación del solicitante. Completa esta información en la pestaña 'Sobre' para crear la disponibilidad de visitas.",
      title: "La disponibilidad de visitas no puede ser creada"
    },
    errors: {
      invalid: "Período inválido",
      overlap: "Período se solapa con otro",
      "short": "Período más corto que la duración"
    },
    forms: {
      selectSlot: "Selecciona un horario disponible para programar la visita.",
      slotsCount: "{{count}} libre",
      slotsCount_other: "{{count}} libres",
      whichDay: "¿Qué día?",
      whichHour: "¿A qué hora?"
    },
    hideAvailability: "Ocultar disponibilidad",
    isOnlyEnableForQualifiedTenants: {
      description: "Sólo los inquilinos cualificados pueden programar una visita",
      title: "Sólo disponible para inquilinos cualificados"
    },
    noticePeriod: "Antelación para agendar",
    period: {
      from: "De",
      to: "Hasta"
    },
    periodRange: "¿Qué período quieres mostrar?",
    saveDetails: "Guardar detalles",
    showAvailability: "Mostrar disponibilidad",
    simultaneousViewings: "Visitas simultáneas",
    startingOn: "A partir de",
    unavailable: "No disponible",
    unitTimeZone: "Zona horaria de la propiedad:",
    updateDetails: "Actualizar detalles",
    updated: "Disponibilidad actualizada",
    videoCalls: "Videollamadas",
    viewingDetails: "Detalles de visitas",
    viewingsAvailability: "Disponibilidad visitas",
    viewingsAvailabilityUpdate: "Actualizar disponibilidad de visitas",
    viewingsSetClick: "Para configurar la disponibilidad de las visitas",
    weekDays: {
      friday: "Viernes",
      monday: "Lunes",
      saturday: "Sábado",
      sunday: "Domingo",
      thursday: "Jueves",
      tuesday: "Martes",
      wednesday: "Miércoles"
    }
  },
  bankAccounts: {
    bankAccount: "Cuenta bancaria",
    bulkActions: {
      createReconciledIncomes: "Crear ingresos reconciliados",
      createReconciledIncomesModal: {
        notifications: {
          success: "Ingresos reconciliados creados con éxito"
        },
        steps: {
          bankTransactions: {
            information: "Selecciona las transacciones bancarias que quieres convertir en ingresos reconciliados e indica a que propiedad pertenecen.",
            title: "Transacciones bancarias"
          },
          incomeDetails: {
            excludedFromPayoutLabel: "Excluir este ingreso de las liquidaciones",
            incomeTypeLabel: "Tipologia del ingreso",
            incomeTypePlaceholder: "Selecciona un tipo de ingreso",
            information: "Para poder crear el ingreso reconciliado a partir de las transacciones bancarias seleccionadas debes rellenar algunos detalles del ingreso para aplicar a todos.",
            ownerVisibilityLabel: "Excluir este ingreso de la contabilidad del propietario",
            providerLabel: "¿Qué proveedor es el pagador de los ingresos?",
            providerPlaceholder: "Escoge a un proveedor",
            title: "Detalles del ingreso"
          },
          verification: {
            finishButton: "Crear {{total}} ingresos reconciliados",
            information: "Una vez que confirmes las transacciones bancarias y los detalles del ingreso se crearan automáticamente los ingresos reconciliados con la información que has completado.",
            table: {
              incomeType: "Tipo de ingreso",
              provider: "Beneficiario - Proveedor",
              title: "Movimientos bancarios seleccionados"
            },
            title: "Verificación"
          }
        }
      },
      createReconciledItems: "Crear pagos reconciliados",
      createReconciledPayment: "Crear gastos reconciliados",
      createReconciledPaymentModal: {
        notifications: {
          success: "Gastos reconciliados creados correctamente"
        },
        steps: {
          bankTransactions: {
            errors: {
              notAllBankTransactionsSelected: "Para poder continuar cada transaccion bancaria debe estar asociada a una propiedad. Si no la encuentras o no quieres incluirla, sólo debes deseleccionar la transaccion bancaria y podrás continuar."
            },
            information: "Selecciona las transacciones bancarias que quieres convertir en gastos reconciliados e indica a que propiedad pertenecen.",
            table: {
              concept: "Concepto y fecha",
              selectAUnitPlaceholder: "Selecciona una propiedad"
            },
            title: "Transacciones bancarias"
          },
          expenseDetails: {
            excludedFromPayoutLabel: "Excluir este gasto de las liquidaciones",
            expenseTypeLabel: "Tipología de gastos",
            expenseTypePlaceholder: "Selecciona un tipo de gasto",
            information: "Para poder crear el gasto reconciliado a partir de las transacciones bancarias seleccionadas debes rellenar algunos detalles del gasto para aplicar a todos.",
            ownerVisibilityLabel: "Excluir este gasto de la contabilidad del propietario",
            providerLabel: "¿Qué proveedor es el beneficiario de los gastos?",
            providerPlaceholder: "Escoge a un proveedor",
            title: "Detalles del gasto"
          },
          verification: {
            finishButton: "Crear {{total}} gastos reconciliados",
            information: "Una vez que confirmes las transacciones bancarias y los detalles del gasto se crearan automáticamente los gastos reconciliados con la información que has completado.",
            table: {
              expenseType: "Tipo de gasto",
              provider: "Beneficiario - Proveedor",
              title: "Movimientos bancarios seleccionados"
            },
            title: "Verificación"
          }
        }
      },
      createReconciledPaymentTooltip: "Para crear gastos o ingresos reconciliados debes seleccionar transferencias bancarias de un solo tipo: Ingresos o gastos, puedes utilizar el filtro de tipo de pago para esto.",
      discardAll: "Descartar todo"
    },
    concept: "Concepto",
    debtorOrCreditor: "Ordenante / Beneficiario",
    discardAllModal: {
      body: "Estás a punto de descartar una transacción <1> {{count}} bancaria </1>",
      body_other: "Estás a punto de descartar <1>{{count}} transacciones bancarias </1>",
      inputLabel: "Escribe \"{{count}}\" para confirmar",
      notificationSuccess: "Las transacciones bancarias se han descartado correctamente",
      title: "¿Quieres descartar estas transacciones bancarias?"
    },
    discardModal: {
      actionButton: "Descartar",
      description: "¿Seguro que quieres descartar esta transacción bancaria? Esta acción se puede deshacer.",
      title: "Descartar transacción bancaria"
    },
    discardToast: "La transacción bancaria se ha eliminado correctamente",
    emptyState: "Aún no hay transacciones bancarias.",
    enableModal: {
      actionButton: "Habilitar",
      description: "¿Seguro que quieres habilitar esta transacción bancaria? Esta acción se puede deshacer.",
      title: "Habilitar la transacción bancaria"
    },
    enableToast: "La transacción bancaria se ha habilitado correctamente",
    messages: {
      emptyState: {
        admin1: "No hay ninguna cuenta bancaria vinculada. Conéctala en",
        admin2: "para visualizar los movimientos bancarios.",
        pm: "No hay ninguna cuenta bancaria vinculada. Pide a tu administrador que la conecte para visualizar los movimientos bancarios."
      },
      expired: {
        admin1: "Las credenciales de {{accountName}} han caducado. Por favor, ve a la",
        admin2: "para volver a sincronizarlas. De lo contrario, no podemos mostrarte una vista actualizada de tus movimientos bancarios.",
        pm: "Las credenciales de {{accountName}} han caducado. Por favor, contacta con el administrador para resincronizarlas. De lo contrario, no podemos mostrarte una vista actualizada de tus movimientos bancarios."
      },
      expiring: {
        admin1: "Las credenciales de {{accountName}} caducan en {{daysCount}} días. Por favor, ve a la",
        admin2: "para volver a sincronizarlas. De lo contrario, no podemos mostrarte una vista actualizada de tus movimientos bancarios.",
        pm: "Las credenciales de {{accountName}} caducan en {{daysCount}} días. Por favor, contacta con el administrador para resincronizarlas. De lo contrario, no podemos mostrarte una vista actualizada de tus movimientos bancarios."
      }
    },
    reconciliation: {
      autoReconciliation: {
        title: "Auto-reconciliar pagos futuros",
        tooltip: "Los pagos como este con las mismas características (importe, concepto, tipo de pago, emisor) se reconciliarán automáticamente"
      },
      checkboxLabel: "Confirmo la reconciliación parcial de la transacción. Esto implicará un desajuste entre la contabilidad de la plataforma y el saldo bancario",
      coveredByInsuranceTooltip: "Marca esta opción para confirmar que el pago ha sido cubierto por la compañía de seguros",
      cta: "Reconciliar pago",
      descriptionStep1: "Selecciona un pago para vincularlo al movimiento bancario elegido",
      descriptionStep2: {
        line1: "¿Quieres vincular este movimiento bancario...",
        line2: "... a este pago?",
        line2_plural: "... a estos pagos?"
      },
      filters: {
        endDate: "Fecha Final",
        startDate: "Fecha Inicial"
      },
      messages: {
        bankTransacionPartiallyPaid: "El movimientos bancario no se ajusta a los importes totales de estos pagos. Complétalo añadiendo más pagos o confirme la acción seleccionando la casilla de verificación.",
        exceedAmount: "El movimiento bancario no es suficiente para cubrir todos los pagos. Deselecciona algunos pagos para continuar.",
        itemsBelongToDifferentUnits: "Los pagos seleccionados pertenecen a diferentes propiedades. Deben pertenecer a la misma propiedad.",
        paid: "Felicidades. Este movimiento bancario cubre el importe total de este pago. Su estado será pagado",
        partiallyPaid: "El movimiento bancario no se ajusta al importe total de este pago. Su estado será parcialmente pagado",
        rightPartiallyPaid: "El movimiento bancario no se ajusta al importe total de este pago. Su estado será parcialmente pagado",
        wrongPartiallyPaid: "El movimientos bancario no se ajustan a los importes totales de estos pagos. Concilie manualmente."
      },
      table: {
        headers: {
          amount: "Importe",
          concept: "Concepto",
          date: "Fecha",
          status: "Estado",
          unit: "ID de Propiedad"
        },
        selectedRows: {
          plural: "pagos pendientes seleccionados",
          singular: "pago pendiente seleccionado"
        }
      },
      titleStep1: "Pagos pendientes",
      titleStep2: "Reconciliar pagos",
      types: {
        expense: "Gasto",
        payment: "Ingreso",
        payout: "Liquidación"
      }
    },
    statusFilter: {
      pending: "Pendiente",
      reconciled: "Reconciliada"
    },
    table: {
      action: "Reconciliar pago",
      createExpense: "Crear gasto",
      createIncome: "Crear ingreso",
      discardAction: "Descartar",
      enableAction: "Volver a habilitar"
    },
    title: "Cuentas bancarias"
  },
  bookings: {
    detail: {
      amountDiscount: "Descuento de {{amount}}",
      breakdown: {
        day: "día",
        days: "días",
        hour: "hora",
        hours: "horas",
        kg: "kg",
        kgs: "kgs",
        month: "mes",
        months: "meses",
        unit: "unidad",
        units: "unidades"
      },
      cancel: "Cancelar servicio",
      instructions: "Instrucciones del cliente",
      modal: {
        cancel: "No, regresar",
        confirm: "Sí, cancelar",
        description: "Esto enviará un mensaje automático al inquilino y se realizará manualmente un reembolso. Esta acción no se puede deshacer.",
        title: "¿Cancelar servicio?"
      },
      note: "Nota del cliente",
      percentDiscount: "Descuento del {{amount}}%",
      priceBreakdown: "Desglose",
      product: "Producto seleccionado",
      service: "Detalles del servicio",
      slot: "Programado para",
      task: "Tarea vinculada",
      title: "Detalles de la reserva",
      total: "Total",
      vat: "IVA"
    },
    table: {
      empty: "Aún no hay reservas :)",
      filterActive: "Activas",
      filterPassed: "Pasadas",
      price: "Precio",
      scheduled: "Programado para",
      service: "Tipo de servicio",
      status: "Estado",
      tags: {
        all: "Todos los estados",
        cancel_req: "Petición de cancelación",
        canceled: "Cancelado",
        done: "Terminado",
        pendingPayment: "Pago pendiente",
        scheduled: "Programado",
        unknown: "Desconocido"
      },
      today: "Hoy",
      tomorrow: "Mañana",
      unit: "Propiedad"
    },
    title: "Reservas de servicios"
  },
  brands: {
    content: {
      accentColor: "Color de acento",
      contactEmail: "Correo electrónico de contacto",
      contactPhone: "Teléfono de contacto",
      dashboardURL: "Dashboard URL",
      domains: "Otros dominios",
      modifySignaturitAccessToken: "Modificar el token de acceso a Signaturit",
      modifySignaturitPassword: "Modificar la contraseña de Signaturit",
      portalURL: "Portal URL",
      primaryColor: "Color primario",
      privacyURL: "Privacidad URL",
      signaturit: "Signaturit",
      signaturitAccessToken: "Token de acceso",
      signaturitBrandingId: "Identificador de marca",
      signaturitPassword: "Contraseña",
      termsURL: "Términos URL",
      whatsapp: "Whatsapp",
      whatsappSender: "Remitente de whatsapp"
    },
    modals: {
      addDomain: "Añadir dominio",
      createTitle: "Crear marca",
      editTitle: "Editar marca"
    },
    title: "Marcas"
  },
  buildings: {
    acquisition: "Portal de adquisición de inquilinos",
    acquisitionHint: "Configurar la página de inicio de adquisición de inquilinos para este edificio",
    add: "Añadir un nuevo edificio",
    addBuildingCardLabel: "Añadir un edificio",
    addCta: "Añadir edificio",
    address: "Dirección",
    all: "Ver todos los edificios",
    confirmationModal: {
      description: "Esto eliminará el edificio seleccionado de forma permanente y anulará la asignación de todos los inquilinos relacionados con él. Esta acción no se puede deshacer.",
      title: "¿Eliminar edificio?",
      toast: "Este edificio tiene propiedades asignadas y no puede ser eliminado"
    },
    "delete": "Eliminar edificio",
    edit: "Editar edificio",
    editAcquisition: "Editar la adquisición de inquilinos",
    editCta: "Guardar cambios",
    featureFlags: "Habilitar/Deshabilitar módulos",
    featureFlagsHint: "Controla las funciones o secciones que aparecen en la app del inquilino",
    image: "Sube una imagen",
    name: "Dale un nombre",
    propertyManagerLogo: "Sube el logotipo del gestor de propiedades",
    settings: "Configuración del edificio",
    slug: "URL de adquisición",
    title: "Edificios",
    units: "propiedades",
    workspaceForLanding: "Workspace"
  },
  buttons: {
    accept: "Aceptar",
    accountingPage: "Ir a la página de Contabilidad",
    add: "Añadir",
    back: "Volver atrás",
    cancel: "Cancelar",
    changeStaff: "Cambiar personal",
    changeUnit: "Cambiar propiedad",
    changeZazu: "Cambiar Zazu",
    close: "Cerrar",
    copyLink: "Copiar enlace",
    "delete": "Eliminar",
    deleteBtn: "Eliminar",
    discard: "Descartar",
    done: "Listo",
    edit: "Editar {{name}}",
    finishAndSend: "Finalizar y enviar",
    loadMore: "Cargar más",
    next: "Siguiente",
    noMore: "Nada más que cargar",
    ok: "Ok",
    onBack: "Atrás",
    pay: "Pagar",
    remove: "Eliminar",
    tryItAgain: "Reintentar",
    unitDetailsPage: "Ir a Detalles de la Propiedad",
    unitLeasingDetailsPage: "Ir a Contratos",
    update: "Actualizar"
  },
  calendar: {
    buttons: {
      day: "Día",
      list: "Lista",
      month: "Mes",
      today: "Hoy",
      week: "Semana",
      year: "Año"
    },
    event: {
      allDay: "Todo el día",
      from: "Desde:",
      timezoneInfo: "Este evento tendrá lugar en la zona horaria {{eventTimezone}}:",
      to: "Hasta:",
      viewDetails: "Ver detalles"
    },
    eventTypes: {
      all: "Todas",
      checkout: "Checkout",
      keyDelivery: "Entrega de llaves",
      tasks: "Tareas",
      unitAvailability: "Disponibilidad de la propiedad",
      viewings: "Visitas"
    },
    filters: {
      eventType: {
        label: "Categoría"
      },
      staff: {
        label: "Asignado a",
        removeSelection: "Eliminar selección"
      }
    },
    title: "Calendario"
  },
  checkout: {
    content: "Haciendo algunos cambios en tu cuenta para desbloquear tu nuevo servicio...",
    headline: "Servicio comprado con éxito",
    title: "Compra"
  },
  components: {
    dropdown: {
      search: {
        placeholder: "Buscar"
      }
    },
    textEditor: {
      paragraph: "Párrafo",
      title: "Título {{number}}"
    }
  },
  contracts: {
    contractSelect: {
      noContracts: "No hay contratos asociados a esta propiedad."
    },
    defaultInsurance: {
      maxNonPayments: "Meses de cobertura",
      maxNonPaymentsLabel: "Número de meses de impagos cubiertos",
      title: "Seguro de impago"
    },
    homeInsurance: {
      title: "Seguro de hogar del inquilino"
    }
  },
  copilot: {
    created: "Creado el {{date}}",
    emptyConversation: "Inicia una conversación enviando un mensaje.",
    emptystate: "Envía un mensaje a Zazume Copilot e inicia una conversación",
    remove: {
      description: "Esta acción eliminará la conversación seleccionada de forma permanente. Esta acción no se puede deshacer.",
      title: "¿Eliminar la conversación?",
      toast: "Conversación eliminada"
    },
    systemUser: "Zazume Copilot"
  },
  dashboard: {
    accounting: {
      billing: "Facturación",
      invoicedTotal: "Total facturado",
      latePayments: "Pagos en mora"
    },
    average: "Media",
    balance: {
      title: "Balance",
      yearly: "Balance anual"
    },
    commercialisation: {
      published: "Publicados",
      publishedValue: "Valor de publicados",
      title: "Comercialización"
    },
    commissions: {
      estimated: "Comisiones estimadas",
      estimatedTooltip: "La información que aparece es una estimación, es posible que, al firmar el contrato de arrendamiento, el alquiler mensual y por tanto la comisión se vean afectadas.",
      paid: "Comisiones pagadas",
      pendingSettlement: "Comisiones pendientes de liquidar"
    },
    contracts: {
      signedServiceContracts: "Contratos de servicio firmados"
    },
    currency: {
      euros: "€ Euros",
      eurosMonth: "€ Euros/mes"
    },
    emptyState: "Nada que mostrar",
    expenses: {
      title: "Gastos"
    },
    incidents: {
      hint: "Incidencias abiertas",
      lastIncidents: "Últimas incidencias",
      openIncidents: "Incidencias abiertas",
      title: "Incidencias"
    },
    incomes: {
      title: "Ingresos"
    },
    leads: {
      interested: "Leads interesados",
      notContacted: "Cold Leads no contactados",
      title: "Leads"
    },
    management: {
      departureProgress: "Salidas en curso",
      departures: "Salidas",
      managedUnits: "Propiedades bajo gestión",
      myComecializatingUnit: "Propiedades en comercialización ({{count}})",
      title: "Gestión"
    },
    payments: {
      passDuePayments: "Pagos atrasados"
    },
    reserved: {
      title: "Reservado"
    },
    units: {
      occupancyRate: "Tasa de alquiler",
      occupied: "Alquilado",
      published: "Publicado",
      rented: "Alquilado",
      title: "Propiedades",
      total: "Propiedades totales"
    },
    viewings: {
      nextWeekCount: "Próximos 7 días ({{count}})",
      scheduledViewings: "Visitas agendadas",
      todayCount: "Hoy ({{count}})",
      total: "Visitas totales",
      viewingsCount: "{{count}} visita",
      viewingsCount_other: "{{count}} visitas",
      yesterdayCount: "Ayer ({{count}} )"
    }
  },
  documentUploadLink: {
    create: {
      description: "Si lo deseas, puedes agregar información adicional sobre qué documentos deben cargar los usuarios, puedes completarla en los campos de abajo.",
      filesDescription: "Descripción de los archivos",
      submitAction: "Generar y copiar enlace",
      success: "Nuevo enlace de subida de ficheros creado con éxito",
      title: "Crear nuevo enlace para subir documentos"
    },
    detail: {
      action: "Crear enlace nuevo",
      description: "Estos son los enlaces creados para subir documentos",
      title: "Enlaces de subida de documentos"
    },
    emptyState: "Para crear un enlace de subida de documentos <1>haz clic aquí</1>",
    fields: {
      createdBy: "Creado por",
      expiresOn: "Expira el",
      link: "Enlace",
      status: "Estado",
      subcategory: "Subcategoría"
    },
    statuses: {
      available: "Disponible",
      expired: "Expirado"
    }
  },
  documents: {
    agent: {
      categories: {
        agent: "Agente"
      }
    },
    clickHere: "clic aquí",
    common: {
      categories: {
        other: "Otro"
      }
    },
    confirmRejectModal: {
      confirmButton: "Rechazar",
      description: "Esto rechazará las firmas de los documentos de forma permanente. Esta acción no se puede deshacer.",
      rejectLabel: "¿Por qué quieres rechazar la firma de este documento?",
      rejectTitle: "Rechazar el documento"
    },
    created: "Documento creado correctamente",
    downloadAuditTrail: "Descargar certificado de firma",
    emptyState: "Para añadir documentos a esta sección",
    emptyStateNotEditable: "No se han cargado documentos en esta sección",
    expirableDocumentsLength: "Sólo puedes subir un documento a la vez cuando tiene fecha de caducidad.",
    expirationDate: "Fecha de caducidad",
    expired: "Caducado el",
    expiredDetail: "Está caducado",
    expiresIn: "Caduca en {{count}} día",
    expiresIn_other: "Caduca en {{count}} días",
    expiring: "Caduca el",
    incident: {
      categories: {
        incident: "Incidencia"
      }
    },
    isHiddenFromOwner: "Oculto para el propietario",
    isHiddenFromOwnerToggle: {
      active: "Los propietarios no verán este documento",
      disabled: "Los propietarios verán este documento",
      label: "Ocultar la visibilidad a los propietarios"
    },
    manageLinks: "Gestionar enlaces de subida de ficheros",
    newDocument: "Nuevo documento",
    nonPayment: {
      categories: {
        nonPayment: "Impago"
      }
    },
    oneDocumentPerUploadError: "Solo puedes subir un documento a la vez si tiene una fecha de caducidad o una solicitud de firma",
    onlyPdfSigningFilesError: "Solo se aceptan archivos PDF para las solicitudes de firma",
    ownerAccount: {
      categories: {
        idCards: "Tarjetas de identificación",
        others: "Otros"
      }
    },
    ownerServiceContract: {
      categories: {
        other: "Otros",
        ownerServiceCommercialisationContract: "Contrato de servicio del propietario",
        ownerServiceContract: "Contrato de servicio del propietario",
        ownerServiceManagementContract: "Contrato de servicio al propietario"
      }
    },
    provider: {
      categories: {
        provider: "Proveedor"
      }
    },
    rejectDocument: "Rechazar el documento",
    removed: "Documento eliminado correctamente",
    signature: {
      description: "Elige dónde quieres colocar la firma en el documento",
      options: {
        free: "Firma libre",
        pageEnd: "En una nueva página al final del documento"
      },
      title: "Firma"
    },
    signatures: "Firmas",
    signingDocumentStates: {
      draft: "Esperando",
      expired_signature: "Firma expirada",
      rejected: "Rechazado",
      signed: "Firmado"
    },
    size: "Tamaño",
    tenant: {
      categories: {
        backgroundCheck: "Verificación de solvencia",
        idCards: "Tarjetas de identificación",
        insurance: "Seguro",
        invoices: "Facturas",
        others: "Otros",
        rentalContract: "Contrato de alquiler",
        rentalInsurance: "Seguro de alquiler"
      }
    },
    type: "Tipo",
    types: {
      IdBackSide: "Parte trasera del documento",
      IdFrontSide: "Parte frontal del documento",
      agent: "Agente",
      breakContract: "Ruptura de contrato",
      budget: "Presupuesto",
      cadastralReport: "Informe catastral",
      commercialisationFeeContract: "Contrato de honorario de comercialización",
      commercializationOwnerLease: "Contrato de comercialización",
      contraInventory: "Contra inventario",
      contract: "Contrato",
      defaultInsurance: "Seguro de impago",
      document: "Documento",
      electricityCupsCIEInvoice: "Electricidad CUPS & CIE",
      employmentContract: "Contrato de trabajo",
      energyPerformanceCertificate: "Certificado de rendimiento energético",
      expenseAttachment: "Adjunto de gastos",
      freelanceInvoices: "Facturas de freelance",
      gasCupsInvoice: "Gas CUPS & CIE",
      holdingDepositContract: "Contrato de reserva",
      homeInsurance: "Seguro de hogar",
      idBack: "Parte trasera de identificación",
      idFront: "Lado frontal de identificación",
      internetConnectionInvoice: "Factura de suministro de internet",
      inventory: "Inventario",
      invoice: "Factura",
      managementOwnerLease: "Contrato de gestión inmobiliaria",
      occupancyCertificate: "Certificado de ocupación",
      other: "Otros",
      otherContract: "Otro contrato",
      otherInsurance: "Otros seguros",
      otherOwnerLease: "Otro tipo de contrato",
      ownerServiceContract_breakContract: "Rotura de contrato",
      ownerServiceContract_commercialisationContract: "Contrato de comercialización",
      ownerServiceContract_contract: "Contrato de adelanto de renta",
      ownerServiceContract_managementContract: "Contrato de gestión",
      partnershipContract: "Contrato de colaboración",
      passport: "Pasaporte",
      paymentAttachment: "Adjunto de ingreso",
      payoutReceipt: "Recibo de liquidación",
      payslip: "Recibo de nómina",
      pensionForm: "Justificante de pensión",
      powerOfAttorney: "Poder notarial",
      propertyValuationReport: "Informe de valoración de propiedad",
      provider: "Proveedor",
      remodelingGeneral: "Remodelación general",
      rentReceipt: "Recibo de alquiler",
      sepa: "Sepa",
      sepaMandate: "Mandato SEPA",
      severance: "Finiquito",
      signedContract: "Contrato firmado",
      signedContractAttachment: "Contrato firmado",
      taxForm100: "Modelo 100",
      taxForm200: "Modelo 200",
      unsignedContract: "Contrato sin firmar",
      unsignedContractAttachment: "Contrato sin firmar",
      viewingsContract: "Contrato de visitas",
      waterSupplyInvoice: "Factura de suministro de agua",
      workingLifeReport: "Informe vida laboral"
    },
    unit: {
      categories: {
        building: "Sobre el edificio",
        commercialisationContract: "Contratos de comercialización",
        insurance: "Seguro",
        inventory: "Inventario",
        invoices: "Facturas",
        management: "Gestión",
        other: "Otros",
        remodeling: "Remodelación"
      }
    },
    updated: "Cambios guardados correctamente"
  },
  documentsModals: {
    category: "Categoría",
    create: {
      description: "Selecciona el documento que deseas guardar en esta propiedad.",
      title: "Subir nuevo documento"
    },
    documentSpecs: "Sólo archivos PDF, JPG, PNG y todos los archivos Office con un tamaño máximo de {{maxFileSizeMB}}MB",
    documentType: "Tipo de documento",
    downloadDocument: "Descargar documento",
    edit: {
      description: "Modifique los campos de documento que desea actualizar.",
      title: "Editar documento"
    },
    fileTooBig: "El límite de tamaño máximo del archivo es {{maxFileSizeMB}}MB",
    filesToUpload: "Archivos permitidos: ",
    filesTooBig: "El tamaño máximo de los archivos son {{maxBunchFilesSizeMB}}MB",
    isExpirable: "Este documento tiene fecha de caducidad",
    isSignable: "Este documento debe ser firmado",
    loadingError: "Se ha producido un error al intentar cargar el documento",
    remove: {
      description: "Esto eliminará el documento seleccionado de forma permanente. Esta acción no se puede deshacer.",
      title: "¿Eliminar documento?"
    },
    selectCategory: "Selecciona una categoría",
    selectDate: "Selecciona una fecha",
    selectDocumentType: "Selecciona un tipo",
    uploadDocument: "Sube tu documento",
    when: "¿Cuándo?",
    wrongFile: "Archivo no admitido"
  },
  downloadApp: {
    text: "Para empezar a obtener todos los beneficios de su comunidad, descargue cualquiera de nuestras aplicaciones utilizando los siguientes enlaces.",
    title: "Descargue la aplicación de Zazume"
  },
  drafts: {
    documents: {
      addNewDocument: {
        documentTitle: "Título del documento",
        linkedTo: "Documento vinculado a",
        preview: "Previsualizar",
        previewOutdated: "La vista previa está desactualizada",
        previewUpToDate: "La vista previa está actualizada",
        success: "Documento creado correctamente",
        table: {
          nameTitle: "Nombre del documento",
          options: {
            "delete": "Eliminar",
            download: "Descargar",
            duplicate: "Duplicar",
            edit: "Editar"
          },
          templateName: "Plantilla",
          typeTitle: "Tipo de documento"
        },
        template: "Plantilla",
        templatePlaceholder: "Selecciona una plantilla",
        title: "Añadir un nuevo documento",
        whichAgent: "¿Qué agente?",
        whichApplication: "¿Qué aplicación?",
        whichContract: "¿Qué contrato?",
        whichUnit: "¿Qué propiedad?"
      },
      editDocuments: {
        success: "Documento actualizado correctamente",
        title: "Editar documento"
      },
      filters: {
        author: "Creado por"
      },
      generatingDocument: "Generando el documento",
      previewModal: {
        changesToUpdate: "Hay variables desactualizadas. Pulse el botón para actualizarlas",
        cta: "Descargar",
        isDraft: "¿Es un borrador?",
        refreshCompleted: "¡Actualización completada! ¡Recuerda guardar los cambios",
        title: "Vista previa del documento"
      },
      saveAndDownload: "Guardar y descargar el borrador",
      table: {
        options: {
          "delete": {
            description: "Esto eliminará el documento seleccionado de forma permanente. Esta acción no se puede deshacer.",
            title: "Eliminar el documento"
          }
        }
      },
      template: {
        noVariables: "Faltan algunos datos. Revisa todas las etiquetas de información añadidas y complétalas con la información que corresponda."
      },
      title: "Documentos"
    },
    title: "Borradores"
  },
  errorCodes: {
    "1000": "Factura no encontrada",
    "100000": "El IBAN ya existe",
    "100001": "El alias ya existe",
    "100002": "Algunas transacciones bancarias están vinculadas a ingresos o gastos. Debes desvincularlas si quieres eliminar la cuenta bancaria. O proceder sin eliminar las transacciones bancarias.",
    "10002": "No se ha facilitado el IBAN de la organización",
    "10003": "No se ha encontrado ningún contrato de servicio de gestión",
    "1001": "No se puede crear la factura",
    "1002": "Este movimiento ya tiene una factura generada",
    "1003": "El movimiento no incluye impuestos",
    "1004": "El movimiento no ha sido pagado",
    "1005": "El tipo de movimiento no es válido",
    "1006": "El acreedor del movimiento no es válido",
    "1007": "La propiedad no está asociada a una cuenta de propietario",
    "1008": "La organización no tiene información de facturación",
    "1009": "El propietario no tiene dirección de facturación",
    "1010": "El cliente no tiene dirección de facturación",
    "1011": "Se ha producido un error al generar el documento",
    "1012": "La organización no tiene una serie de facturas",
    "1013": "El inquilino no tiene CurrentAddress o IDNumber",
    "1014": "El propietario no tiene información de facturación",
    "1015": "No hay inquilino o proveedor asignado a este ingreso",
    "1016": "El pagador del ingreso no tiene una dirección de correo electrónico",
    "1017": "El proveedor no tiene completa la información de facturación",
    "1018": "El movimiento está cancelado",
    "11": "El usuario no tiene un número de teléfono válido",
    "110001": "No tenemos suficiente información para ofrecerte una valoración de esta propiedad.",
    "111": "La cuenta de propietario tiene asignadas una o varias propiedades",
    "112": "No puedes realizar esta acción. Si deseas modificar tu IBAN, ponte en contacto con help@zazume.com.",
    "13": "El usuario ya existe",
    "1301": "La dirección proporcionada no está disponible para valoración",
    "14": "Usuario no autorizado",
    "15": "Usuario no encontrado",
    "1500002": "El cliente potencial ya fue ascendido a propietario",
    "16": "El usuario no tiene el rol requerido",
    "1601": "Proveedor no encontrado",
    "1602": "Este proveedor no se puede eliminar porque está vinculado a un pago. Para eliminar este proveedor debes editar y desvincular el proveedor o eliminar el pago.",
    "17": "Token caducado",
    "1900": "Marca no encontrada",
    "1901": "La marca ya existe",
    "1902": "Algunos dominios ya están siendo utilizados por otra marca.",
    "20": "El usuario no tiene datos de tarjeta de identificación válidos",
    "20010": "Una propiedad no puede estar sin propietario cuando hay una liquidación recurrente",
    "20023": "Ya existe una propiedad con la misma dirección, bloque, piso y puerta",
    "202": "Se ha proporcionado un tipo de documento no válido",
    "203": "No se ha encontrado el documento",
    "204": "No se ha cargado ningún archivo",
    "2200": "Usuario ya invitado",
    "2201": "La invitación ya ha sido aceptada",
    "2300": "No se encontró el contacto. Puedes intentarlo más tarde o crearlo directamente en el proveedor",
    "25": "El usuario no tiene un teléfono válido",
    "3000": "No se ha podido conectar con el proveedor externo, inténtelo de nuevo más tarde.",
    "30003": "Ya existe un propietario con este teléfono",
    "30004": "Ya existe un propietario con este correo electrónico",
    "30005": "Ya existe un propietario con este IBAN",
    "30006": "Ya existe un propietario con este documento de identidad",
    "304": "No se puede solicitar la firma de un documento con emails duplicados",
    "400": "Valoración para esta reserva ya creada",
    "40001": "La entrega de claves se superpone con la disponibilidad de visitas del agente seleccionado",
    "412": "No se han encontrado comparables para esta propiedad",
    "423": "No se ha encontrado ningún avm para esta propiedad",
    "50": "Campo no válido",
    "500001": "El impago no se puede eliminar porque tiene pagos vinculados",
    "50001": "No se puede eliminar el XML exportado porque está vinculada a un pago procesado",
    "502": "Ya existe un solicitante vinculado a la misma propiedad",
    "505": "Aplicación ya completada o rechazada",
    "51": "Identificador proporcionado inválido",
    "52": "Fecha no válida",
    "522": "El agente ya ha completado la visita",
    "53": "Contraseña no válida.",
    "55": "La dirección proporcionada no es válida",
    "600001": "El usuario ya existe con el rol de agente",
    "600002": "El usuario ya existe con el rol de propietario",
    "600011": "El enlace personal ya está en uso por otro agente",
    "601": "El pago ya ha sido cancelado o completado",
    "605": "El pago ya se ha pagado o se ha pagado parcialmente. Elimina las reconciliaciones para proceder con la declinación",
    "611": "No se puede crear el XML. Falta información en algunos pagos",
    "612": "El inquilino {{tenant}} no ha proporcionado el número IBAN",
    "613": "Al propietario le faltan algunos datos de facturación.",
    "614": "A la organización le faltan algunos datos de facturación",
    "615": "El inquilino {{tenant}} no ha firmado el mandato SEPA",
    "70000": "El documento no está listo para cancelarse. Inténtelo de nuevo más tarde",
    "70001": "El documento no está listo para ser eliminado. Lo están utilizando algunos documentos. Elimina primero los documentos",
    "70002": "El firmante no está presente en el documento solicitado",
    "70003": "El email ya está en uso para otro firmante en el documento",
    "73": "No se ha encontrado la propiedad",
    "74": "El edificio tiene propiedades asignadas",
    "75": "Inquilino ya asignado",
    "76": "El espacio de trabajo tiene inquilinos asignados",
    "77": "El espacio de trabajo tiene propiedades asignadas",
    "79": "La propiedad tiene aplicaciones en curso y no puede ser eliminada",
    "791": "La propiedad tiene un contrato vigente y no se puede eliminar",
    "794": "La propiedad tiene un contrato futuro y no se puede eliminar",
    "80": "La URL para agendar visitas ya existe",
    "801": "El inquilino está en una solicitud activa. Se debe rechazar la solicitud antes de borrar al inquilino",
    "802": "El inquilino vive en una propiedad. Retírelo de la propiedad antes de eliminarlo",
    "83": "La propiedad está publicada y no puede ser eliminada, llama a tu gestor para despublicarla",
    "84": "La propiedad tiene inquilinos asignados y no se puede eliminar, desasignalos para eliminarla",
    "85": "La propiedad no tiene un propietario asignado",
    "86": "La propiedad no tiene una dirección completa",
    "90000": "Permiso denegado",
    "90001": "Solicitud incorrecta",
    "90002": "No encontrado",
    "903": "No se ha podido crear el SEPA pues contiene una liquidación en negativo",
    "904": "No se puede crear el SEPA, no se han encontrado algunas liquidaciones seleccionadas, por favor, vuelve a la selección de pagos",
    "905": "No se puede crear una SEPA con pagos ya pagados o en proceso de validación",
    "91": "Error inesperado"
  },
  errorMessages: {
    clientError: "Ha ocurrido un error en el navegador",
    length: "Este campo debe tener al menos {{length}} caracteres",
    mandatory: "Campo obligatorio",
    network: "¡Vaya! parece que no tienes conexión a internet. Por esa razón, no se ha podido completar esta acción."
  },
  errorPage: {
    buttonHome: "Volver al inicio",
    buttonLogin: "Ir a iniciar sesión",
    headline: "404",
    subtitle: "¡Oye! Parece que algo salió mal. Estamos trabajando en ello.",
    title: "Error"
  },
  expenses: {
    addExpense: "Añadir gasto",
    attachments: {
      addAttachment: "Adjuntar documento",
      title: "Documentos"
    },
    attributable: "Atribuible al inquilino",
    attributableExpenseMessage: "Para atribuir un gasto a un inquilino, es obligatorio adjuntar la factura",
    attributed: "Atribuido a ingreso",
    baseAmount: "Base imponible",
    cancelModal: {
      button: "Cancelar gasto",
      label: "Añade una nota",
      text: "Esta acción no se puede deshacer.",
      title: "¿Quieres cancelar este gasto?",
      toast: "Gasto cancelado"
    },
    chooseAnExpense: "Elige un gasto",
    concept: "Concepto",
    conceptPlaceholder: "Ej. gastos inesperados",
    createInvoice: "Crear factura",
    deleteModal: {
      description: "Se va a eliminar este gasto. Esta acción no se puede deshacer.",
      title: "Eliminar gasto"
    },
    deleteToast: "El gasto se eliminó correctamente",
    detail: {
      alert: {
        cancel: {
          because: "porque",
          description: "Este gasto ha sido cancelado por"
        }
      },
      attachments: {
        add: "Adjuntar documento",
        addExternalInvoice: {
          attachButton: "Adjuntar factura",
          dateLabel: "Fecha de la factura",
          fileDescription: "Selecciona la factura externa",
          numberLabel: "Número de factura",
          title: "Añadir factura externa"
        }
      },
      edit: "Editar gasto",
      title: "Detalle del gasto"
    },
    editExpense: "Editar gasto",
    editModal: {
      attributedWarning1: "Hay un ingreso asociado a este gasto. Al modificar algunos campos de este gasto, como el importe, el ingresos se actualizará automáticamente para reflejar también los cambios.",
      attributedWarning2: "Hay un ingreso asociado a este gasto que ya se han pagado, por lo que algunos campos no pueden ser modificados. Si lo necesita, elimina la reconciliación del ingreso y podrá modificar más campos en ambos.",
      infoModal: {
        description: "Los cambios en el gasto también se reflejarán en el ingreso asociado.",
        priceModifiedDescription: "Notificaremos al pagador que el importe se ha modificado y le enviaremos una solicitud de pago con el importe actual.",
        priceModifiedTitle: "Se han actualizado los gastos e ingresos. Hemos notificado al pagador que pague la nueva cantidad.",
        title: "Se han actualizado el gastos e ingreso asociados"
      },
      warningPart1: "Este gasto está {{stateName}}",
      warningPart2: ", por lo que algunos campos no se pueden editar. Si necesitas editarlos, primero debes eliminar las reconciliaciones."
    },
    expense: "Gasto",
    expenseDate: "Fecha del gasto",
    expenseDetail: "Detalle del gasto",
    expenseType: "Tipo de gasto",
    expense_other: "Gastos",
    "export": {
      button: "Exportar gastos",
      description: "Selecciona un rango de fechas",
      error: "Se ha producido un error al exportar los gastos",
      from: "Desde",
      success: "En unos minutos recibirá un email con el archivo de gastos.",
      title: "Exportar gastos",
      to: "Hasta"
    },
    "import": {
      button: "Importar gastos",
      description: "Sigue estos sencillos pasos: <br> <br> <strong>1. Descarga la plantilla: </strong> <CustomLink> Haz clic aquí </CustomLink> para descargar la plantilla en formato Excel <br> <br> <strong>2. Rellena la plantilla: </strong> completa todos los campos necesarios con la información de tus gastos. <br><br><strong>3. Sube la plantilla: </strong> Una vez completada, expórtala como CSV y vuelve aquí para subir el archivo. <br><br>Si tienes algún problema, puedes enviar un correo electrónico a help@zazume.com con tu archivo rellenado y te ayudaremos.",
      error: "Se ha producido un error al importar los gastos",
      success: "En unos minutos recibirá una notificación con los resultados de la importación",
      title: "Importar gastos"
    },
    linkExistingExpense: "Para vincular un gasto existente",
    linkExpense: "Vincular gasto",
    linkExpenseModalDescription: "Selecciona un gasto existente de {{unitId}}:",
    linkExpenseSectionTooltip: "Solo miembros del equipo pueden ver esta información",
    linkMultipleExpensesError: "Por favor, selecciona sólo un gasto para vincular",
    linkServiceCost: "Vincular costo de servicio",
    linkWithExistingExpense: "Vincular con un gasto existente",
    linkedExpense: "Gasto vinculado",
    linkedExpenseSuccess: "Gasto vinculado con éxito",
    listingEmptyMessage: "Todavía no se ha creado ningún gasto",
    messages: {
      addExpense: {
        success: "Gasto añadido"
      },
      addRecurrentExpense: {
        success: "Gasto recurrente añadido"
      }
    },
    payments: {
      addPayment: "Añadir pago",
      addedPayment: "Pago añadido",
      deletedMessage: "Pago eliminado",
      help: "Para añadir un nuevo pago",
      help2: "Aún no hay reconciliaciones"
    },
    recurrentExpense: "Gasto recurrente",
    requestPayment: "Solicitar el pago al inquilino",
    singleExpense: "Gasto único",
    split: {
      deleteConfirmation: {
        all: "todos",
        one: "uno",
        writeAll: "Escribe “todos“ para confirmar",
        writeOne: "Escribe “uno“ para confirmar"
      },
      detail: {
        description: "{{times}} pagos mensuales",
        title: "División de pagos"
      },
      howManyTimes: "¿En cuántas veces?",
      options: "En {{number}} pagos",
      removeModal: {
        deleteAllWarning: {
          description: "Esto se debe a que hay gastos con reconciliaciones, incluidos en una liquidación y/o tienen una factura.",
          title: "No es posible eliminar todos los gastos relacionados."
        },
        options: {
          all: "Elimina todos los gastos",
          one: "Elimina sólo este gasto"
        },
        title: "¿Quieres eliminar este gasto?",
        warning: {
          description: "Ten en cuenta que si eliminas sólo este gasto los demás asociados no se actualizarán, deberás editarlos manualmente. Está acción no se puede deshacer.",
          title: "Este gasto forma parte de un gasto dividido en más pagos."
        }
      },
      toggle: "Divide el pago",
      warning: {
        description: "Si editas sólo este pago los cambios no se aplicaran a los demás gastos, debes hacerlo manualmente. Ten en cuenta que si editas la cantidad, el total del gasto se verá afectada.",
        title: "Este gasto forma parte de un pago dividido en más pagos."
      }
    },
    statuses: {
      cancelled: "Cancelado",
      not_paid: "No pagado",
      paid: "Pagado",
      partially_paid: "Parcialmente pagado"
    },
    toBeSettled: {
      deleteModal: {
        actionButtonText: "Sí, quiero desmarcar",
        description: "Al hacerlo se eliminará la configuración previa y es probable que se incluya este gasto por defecto en la próxima liquidación.",
        title: "¿Seguro que quieres desmarcar este gasto “por liquidar”?"
      },
      editModal: {
        acceptButton: "Marcar por liquidar",
        datePicker: {
          label: "Selecciona una fecha",
          placeholder: "Selecciona una fecha"
        },
        description: "Para poder marcar el pago como por liquidar es necesario que indiques una fecha a partir de la cual, este gasto será incluido en la liquidación de forma automática.",
        editToBeSettled: {
          acceptButton: "Editar fecha",
          description: "Edita la fecha a partir de la cual este gasto se incluirá automáticamente en el pago.",
          title: "Editar fecha de liquidación"
        },
        title: "Marcar gasto como pendiente de liquidar",
        updateRelatedExpenses: "Actualizar automáticamente los gastos relacionados"
      },
      editToBeSettled: "Editar fecha de liquidación",
      mark: "Marcar por liquidar",
      tooltip: "Será añadido a una liquidación a partir de {{date}}",
      unmark: "Desmarcar por liquidar",
      updated: "El gasto se ha actualizado correctamente"
    },
    totalAmount: "Importe total",
    types: {
      additionalGuarantee: "Garantía adicional",
      additional_guarantees: "Garantías adicionales",
      alarm: "Alarma",
      application_payment: "Pagos de la solicitud",
      cleaning: "Limpieza",
      commercialization_fees: "Honorarios de comercialización",
      commission_fee: "Comisión",
      community: "Comunidad",
      "default": "Mora",
      deposit: "Fianza",
      electricity: "Electricidad",
      expansionAndImprovement: "Ampliación y mejora",
      fees: "Tarifas",
      furniture: "Mobiliario",
      gas: "Gas",
      general: "General",
      heating: "Calefacción",
      holding_deposit: "Reserva",
      incident: "Incidencia",
      insurance: "Seguro",
      internet: "Internet",
      legalDefense: "Defensa jurídica",
      loan: "Préstamo",
      maintenance: "Mantenimiento",
      management: "Gestión",
      marketing: "Márketing",
      monthly_rent: "Alquiler mensual",
      mortgage: "Hipoteca",
      mortgage_interests: "Intereses de la hipoteca",
      other: "Otro",
      phone: "Teléfono",
      provisions: "Provisiones",
      rental: "Alquiler",
      repairAndMaintenance: "Reparación y conservación",
      security: "Seguridad",
      services: "Servicios",
      supplies: "Suministros",
      supply: "Suministro",
      taxes: "Impuestos",
      trash: "Basura",
      water: "Agua"
    },
    unlinkExpense: "Desvincular gasto",
    unlinkRecurrentExpense: "Desvincular gasto recurrente",
    unlinkServiceCost: "Desvincular costo de servicio",
    unlinkedExpenseSuccess: "Gasto desvinculado con éxito",
    viewDetails: "Ver detalles del gasto",
    wasExpensePaid: "¿Ya se pagó este gasto?"
  },
  general: {
    about: "Sobre",
    acquisition: "Captación",
    active: "Activo",
    add: "Añadir",
    addLead: "Añadir lead",
    addOwner: "Añadir propietario",
    addOwnerLead: "Añadir propietario potencial",
    address: "Dirección",
    administrator: "Administrador",
    agent: "Agente",
    agents: "Agentes",
    all: "Todos",
    all_female: "Todas",
    allright: "De acuerdo",
    amortization: "Amortización capital",
    amount: "Cantidad",
    and: "y",
    applicant: "Solicitante",
    applicantAdmin: "Administrador de la aplicación",
    applicantCount: "{{count}} solicitante",
    applicantCount_other: "{{count}} solicitantes",
    applicant_plural: "Solicitantes",
    application: "Solicitud",
    applicationManager: "Gestor de solicitudes",
    applications: "Solicitudes",
    assign: "Asignar",
    assignableEntities: {
      accountManager: "Gestor de cuenta",
      agent: "Agente",
      applicant: "Solicitante",
      applicationManager: "Gestor de la solicitud",
      incidentsManager: "Gestor de incidencias",
      ownerAccount: "Cuenta de propietario",
      ownerLead: "Futuro propietario",
      staff: "Personal",
      tenant: "Inquilino",
      unitManager: "Gestor de la propiedad",
      zazume: "Zazume"
    },
    avatarTypes: {
      accountManager: "Gestor de cuenta",
      agent: "Agente",
      applicationManager: "Gestor de la solicitud",
      guarantor: "Avalista",
      incidentsManager: "Gestor de incidencias",
      owner: "Propietario/a",
      ownerAccount: "Cuenta de propietario",
      ownerLead: "Futuro propietario",
      sharer: "Co-inquilino/a",
      staff: "Personal",
      tenant: "Inquilino",
      unitManager: "Gestor de la propiedad"
    },
    back: "Volver",
    bank: "Banco",
    bathrooms: "Baños",
    bedrooms: "Habitaciones",
    billingData: "Datos de facturación",
    birthDate: "Fecha de nacimiento",
    board: "Tabla",
    bookings: "Reservas",
    breakdown: "Desglose",
    broken: "Roto",
    building: "Edificio",
    campaigns: "Campañas",
    cancel: "Cancelar",
    category: "Categoría",
    characteristics: "Características",
    city: "Ciudad",
    clickHere: "haz clic aquí",
    clickingHere: "haciendo clic aquí",
    close: "Cerrar",
    closedBy: "Cerrado por",
    coTenant: "Coinquilino",
    coldLead: "Cold lead",
    commissionFee: "Tarifa de comisión",
    commissions: "Comisiones",
    completed: "Completado",
    configuration: "Configuración",
    confirm: "Confirmar",
    contactNumber: "Número de contacto",
    "continue": "Continuar",
    contracts: "Contratos",
    copiedToClipboard: "Copiado en el portapapeles",
    created: "Creada",
    createdBy: "Creado por",
    creationDate: "Fecha de creación",
    creditor: "Acreedor",
    creditorId: "ID del Acreedor",
    creditorName: "Nombre del acreedor",
    currentAddress: "Dirección actual",
    date: "Fecha",
    day: "{{count}} día",
    day_other: "{{count}} días",
    decline: "Rechazar",
    declineEntity: "Rechazar {{entity}}",
    declined: "Rechazado",
    defaultInsurance: "Seguro de impago",
    "delete": "Eliminar",
    deleteEntity: "Eliminar {{entity}}",
    deleteEntityError: "El {{entity}} no puede ser eliminado",
    description: "Descripción",
    deselectAll: "Deseleccionar todos",
    details: "Detalles",
    disabled: "Deshabilitado",
    discount: "Descuento",
    docs: "Docs",
    document: "Documento",
    documentation: "Documentación",
    documents: "Documentos",
    download: "Descargar",
    downloadImage: "Descargar imagen",
    draft: "Borrador",
    dueDate: "Fecha de vencimiento",
    duplicate: "Duplicar",
    duration: "Duración",
    edit: "Editar",
    editEntity: "Editar {{entity}}",
    editEntityError: "El {{entity}} no puede ser editada",
    email: "Email",
    employmentSituation: "Situación laboral",
    empty: "Vacío",
    emptyTable: "Sin datos",
    enabled: "Habilitado",
    entities: "Sociedades",
    entity: "Sociedad",
    error: "Error",
    errorCount: "{{count}} error",
    errorCount_other: "{{count}} errores",
    errorOcurred: "Ha ocurrido un error",
    expenses: "Gastos",
    expirationDate: "Fecha de expiración",
    expired: "Expirado",
    fee: "Tasa",
    filters: {
      amount: {
        maximum: "Importe máximo",
        minimum: "Importe mínimo"
      },
      clear: "Borrar",
      datesFilter: {
        deleteButton: "Eliminar",
        endDateLabel: "Hasta",
        endDatePreposition: "Hasta",
        label: "Fechas",
        placeholder: "Todas",
        startDateLabel: "Desde",
        startDatePreposition: "Desde",
        submitButton: "Aplicar filtro"
      },
      submitButton: "Aplicar filtro",
      title: "Filtros",
      titles: {
        advertiser: "Anunciante",
        applications: "Filtros de solicitudes",
        autoReconciliations: "Filtros de auto-reconciliación",
        bookings: "Filtros de reservas",
        elevator: "Ascensor",
        expenses: "Filtros de gastos",
        incidents: "Filtros de incidencias",
        invoices: "Filtros de facturas",
        minRooms: "Habitaciones mínimas",
        payments: "Filtros de ingresos",
        rentAdvance: "Filtros de adelanto de rentas",
        tenants: "Filtros de inquilinos",
        unitCondition: "Estado de la propiedad",
        units: "Filtros de propiedades"
      }
    },
    finish: "Fin",
    firstName: "Primer nombre",
    fiscalName: "Nombre fiscal",
    flatmate: "Compañero de piso",
    flatmates: "Compañeros de piso",
    forms: {
      addFile: "Añadir archivo",
      addressNumberMandatory: "La dirección debe contener un número",
      attachmentsAdded: "Documentos añadidos",
      attachmentsRemoved: "Documentos eliminados",
      automatic: "Automático",
      browse: "navegar",
      changesSaved: "Cambios guardados correctamente",
      chooseADate: "Selecciona una fecha",
      chooseAnOption: "Elige una opción",
      chooseSubcategory: "Elige una subcategoría",
      commercialMandatory: "Por favor, selecciona un comercial",
      creditorIdentifierIsMandatory: "Los identificadores del acreedor son obligatorios cuando hay un número de identificación",
      discardChanges: "Descartar cambios",
      discountConcept: "Concepto del descuento",
      dragDropOrBrowse: "Arrastra y suelta o navega",
      dragNdropTitle: "Arrastra y suelta tu archivo aquí",
      dragnDrogOr: "Arrastrar y soltar o",
      emailError: "Correo electrónico no válido: name@email.com",
      emailExisting: "El correo electrónico ya está en uso",
      exists: "ya existe",
      extraIformation: "Agregue información adicional aquí",
      fileInput: "Seleccionar o soltar un archivo",
      fileTooBig: "El tamaño máximo de archivo es {{maxFileSizeMB}}MB",
      fileTypeCSV: "Solo archivos CSV con un tamaño máximo de {{maxFileSizeMB}} MB",
      fileTypeN43: "Solo archivos Norma 43 (.n43) con un tamaño máximo de {{maxFileSizeMB}}MB",
      fileTypePDF: "Solo archivos PDF con un tamaño máximo de {{maxFileSizeMB}}MB",
      filesTooBig: "El tamaño máximo de todos los archivos es {{maxBunchFilesSizeMB}}MB",
      iban: "Número de cuenta (IBAN)",
      ibanNotValid: "Número IBAN no válido",
      idNotDefinedType: "Selecciona un tipo de documento",
      idNotValid: "Número de ID no válido",
      imageOptional: "Opcional",
      invalid: "Valor inválido",
      invalidCharacters: "El campo contiene caracteres inválidos",
      invalidDate: "No es una fecha válida",
      invalidGooglePlacesSelection: "Dirección no válida",
      invalidIntegerNumber: "El número debe ser entero",
      invalidNegativeNumber: "El número debe ser mayor que 0",
      invalidNumber: "No es un número válido",
      invalidToken: "Token proporcionado es inválido o expirado",
      invalidUrl: "URL incorrecta",
      itHas: "Sí, lo tiene",
      itHasNot: "No, no lo tiene",
      mandatory: "Campo obligatorio",
      mandatoryGooglePlacesSelection: "Selecciona una de las opciones que aparecen a continuación",
      missingBillingAgent: "Al agente le falta información de facturación, asegúrate de completarla antes de continuar",
      missingBillingOrganization: "Falta información de facturación en la organización, asegúrete de completarla antes de crear el pago",
      missingBillingOwner: "Falta información de facturación en tu cuenta, asegúrate completarla antes de continuar",
      missingBillingOwner_pm: "Al propietario le falta información de facturación, asegúrate de completarla antes de continuar",
      missingContanctInfo: "Proporciona al menos un número de teléfono o email válidos",
      noFile: "Ningún archivo seleccionado",
      noPastDates: "Fecha invalida. No podemos viajar al pasado, todavía",
      onlyImages: "Solo imágenes",
      passwordRules: "Debe contener al menos un carácter de cada tipo: mayúsculas, minúsculas y número.",
      passwordsIdentical: "Las contraseñas deben ser idénticas",
      placeholders: {
        address: "Escribe aquí y selecciona una opción",
        cups: "Ej. ES0397956973059057TM",
        datePicker: "dd/mm/aaaa",
        datePickerWithOur: "dd/mm/aaaa - hh:mm",
        discountConcept: "Escribe un título corto para este descuento",
        documentIDType: "DNI",
        email: "juan@email.com",
        example: "Ej. {{value}}",
        iban: "Introduce el número IBAN",
        insuranceCompany: "Introduce el nombre de la aseguradora",
        insurancePolicy: "Indica su número de póliza",
        name: "Introduce un nombre",
        nationality: "Selecciona una nacionalidad",
        phone: "Introduce un número de teléfono",
        publicationName: "Nombre de contacto que se mostrará en la página de publicación",
        textarea: "Haz clic aquí para empezar a escribir"
      },
      priceAmount: "Precio: {{amount}}",
      saveChanges: "Guardar cambios",
      searcher: {
        recentlyAdded: "Añadido recientemente"
      },
      selectAnOption: "Selecciona una opción",
      setAPercentage: "Establece un porcentaje",
      setAnAmount: "Establece una cantidad",
      "short": "Campo demasiado corto",
      startDate: "Fecha de inicio",
      typeAndSelect: "Escribe aquí y selecciona una opción",
      unitHasNoOwner: "La propiedad no tiene propietario asignado",
      updatingMessage: "Actualizando datos...",
      uploadImage: "Subir una imagen",
      whichAmount: "¿Qué cantidad?",
      workspaceInput: "Selecciona un workspace",
      writeHere: "Escribe aquí",
      wrongFile: "Archivo/s no admitido"
    },
    from: "Desde",
    general: "General",
    guarantor: "Avalista",
    guarantor_plural: "Avalistas",
    hidden: "Oculto",
    hour: "{{count}} hora",
    hourTitle: "Hora",
    hour_other: "{{count}} horas",
    iban: "IBAN",
    idCard: "Documento de identidad",
    idNumber: "Número de identificación",
    idType: "Tipo de documento",
    "import": "Importar",
    information: "Información",
    insurance: "Seguro de hogar",
    integration: "Integración",
    interests: "Intereses",
    landing: "Landing",
    language: "Idioma",
    leads: "Leads",
    lessor: "Arrendador",
    linkedToLease: "Vinculado a un contrato",
    list: "Lista",
    living: "Instalado",
    load: "Cargar",
    loading: "Cargando...",
    location: "Ubicación",
    manual: "Manual",
    marketPlace: "Marketplace",
    marketing: "Marketing",
    me: "Mí",
    min: "{{count}} min",
    month: {
      long0: "Enero",
      long1: "Febrero",
      long10: "Noviembre",
      long11: "Diciembre",
      long2: "Marzo",
      long3: "Abril",
      long4: "Mayo",
      long5: "Junio",
      long6: "Julio",
      long7: "Agosto",
      long8: "Septiembre",
      long9: "Octubre",
      short0: "Ene",
      short1: "Feb",
      short10: "Nov",
      short11: "Dic",
      short2: "Mar",
      short3: "Abr",
      short4: "May",
      short5: "Jun",
      short6: "Jul",
      short7: "Ago",
      short8: "Sep",
      short9: "Oct"
    },
    monthly: "Mensualmente",
    monthlyIncome: "Ingresos mensuales",
    monthlyRent: "Alquiler mensual",
    months: "Meses",
    name: "Nombre",
    nationality: "Nacionalidad",
    news: "¡Novedades!",
    next: "Siguiente",
    no: "No",
    noResults: "Sin resultados",
    none: "Ninguno",
    notifications: {
      deleteSuccess: "Se eliminó con éxito",
      emailNotifications: "Notificaciones de correo",
      label: "Notificaciones",
      pushNotifications: "Notificaciones push",
      zazumeInvited: "¡Invitación de Zazume enviada!"
    },
    occupied: "Alquilado",
    optional: "Opcional",
    organization: "Organización",
    origin: "Origen",
    other: "Otros",
    otherCompany: "Otra empresa",
    owner: "Propietario",
    ownerLeads: "Propietarios potenciales",
    owners: "Propietarios",
    paid: "Pagado",
    paidInsurance: "Pagado por el seguro",
    password: "Contraseña",
    payments: "Pagos",
    pending: "Pendiente",
    pendingAmount: "Importe pendiente",
    people: "Personas",
    personalDetails: "Datos personales",
    phone: "Teléfono",
    phoneNumber: "Número de teléfono",
    photos: "Fotos",
    platform: "La plataforma",
    play: "Reproducir",
    portal: "Portal",
    prequalification: {
      bad: "Mala precualificación",
      badIncomes: "Mala precualificación - Ingresos",
      badPets: "Mala precualificación - Mascotas",
      good: "Buena precualificación",
      no: "Sin precualificación",
      noSuitableSchedule: "No le encaja ningún horario",
      prequalified: "Precualificado",
      title: "Precualificación"
    },
    preview: "Vista previa",
    property: "Propiedad",
    propertyManager: "Administrador de la propiedad",
    propertyManageras: "Administradores de propiedades",
    propertyType: "Tipo de propiedad",
    provider: "Proveedor",
    providers: "Proveedores",
    province: "Provincia",
    publication: "Publicación",
    referenceNumber: "Nº de referencia",
    remainingAmount: "Importe restante",
    remove: "Eliminar",
    removeSelection: "Eliminar selección",
    rent: "Alquiler",
    request: "Solicitar",
    required: {
      information: "Información requerida"
    },
    role: "Rol",
    room: "Habitación",
    rooms: "Habitaciones",
    sale: "Venta",
    save: "Guardar",
    saveChanges: "Guardar cambios",
    search: "Buscar",
    selectAgent: "Selecciona un agente",
    selectAll: "Seleccionar todos",
    selectUnit: "Selecciona una propiedad",
    send: "Enviar",
    sendViaWhatsApp: "Enviar por WhatsApp",
    sent: "Enviado",
    service: "Servicio",
    services: "Servicios",
    setUp: "Configurar",
    showLess: "Ver menos",
    showMore: "Ver más",
    signature: "Firma",
    signed: "Firmado",
    solvency: "Solvencia",
    sort: {
      label: "Ordenar por",
      placeholder: "Seleccionar"
    },
    start: "Inicio",
    startedAt: "Desde",
    state: "Estado",
    status: "Estado",
    step: "Paso",
    supplies: "Suministros",
    surfaceArea: "Superficie",
    surnames: "Apellidos",
    tenant: "Inquilino",
    tenantAdmin: "Inquilino administrador",
    tenants: "Residentes",
    time: "a las",
    timezone: "Zona horaria",
    title: "Título",
    toBeSettled: "Por liquidar",
    today: "Hoy",
    tomorrow: "Mañana",
    total: "Total",
    type: "Tipo",
    unassigned: "Sin asignar",
    unit: "Propiedad",
    unitAvailability: "Disponibilidad de la propiedad",
    unitId: "ID de la propiedad",
    unit_plural: "Propiedades",
    unlink: "Desvincular",
    untitled: "Sin título",
    update: "Actualizar",
    upload: "Subir",
    user: "usuario",
    userCapital: "Usuario",
    video: "Video",
    view: "Ver",
    viewDocument: "Ver documento",
    warningCount: "{{count}} alerta",
    warningCount_other: "{{count}} alertas",
    welcome: "Hola {{name}},",
    whatsAppSent: "¡WhatsApp enviado!",
    workspace: "Workspace",
    year: "Año",
    yearly: "Anualmente",
    years: "Años",
    yes: "Sí",
    yesterday: "Ayer",
    you: "Tú"
  },
  globalSearch: {
    placeholder: "Buscar propiedades, inquilinos o solicitudes",
    placeholderAgent: "Buscar propiedades"
  },
  help: "Centro de ayuda",
  helpHub: {
    overlay: {
      email: "Reportar un problema",
      helpCenter: "Ir al centro de ayuda",
      newFeature: "Sugerir mejora",
      news: "Novedades de la plataforma",
      title: "Centro de ayuda",
      tutorials: "Ver tutoriales",
      whatsapp: "Contactar por WhatsApp"
    }
  },
  incidents: {
    categories: {
      appliance: "Electrodoméstico",
      blinds_and_awnings: "Persianas y toldos",
      brickwork: "Albañilería",
      carpentry: "Carpintería",
      cleaning: "Limpieza",
      consulting: "Consulta",
      electrician: "Electricista",
      glass: "Cristales",
      handyman: "Manitas",
      locksmith: "Cerrajería",
      move_in: "Mudanzas",
      others: "Otros",
      painting: "Pintura",
      plumbing: "Fontanería",
      reforms: "Reformas",
      supplies: "Suministros"
    },
    category: "Categoría",
    checkIn: "Check-in",
    createIncident: "Crear incidencia",
    createIncidentPage: {
      chooseOption: "Escoge una opción",
      options: {
        incident: "Tengo una <strong>incidencia</strong> (los inquilinos tendrán visibilidad)",
        inquiry: "Tengo una <strong>consulta</strong> (los inquilinos no tendrán visibilidad)"
      },
      saveButton: "Crear incidencia",
      title: "Nueva incidencia"
    },
    created: "Incidencia creada correctamente",
    dashboard: {
      view: "Ver todas las incidencias"
    },
    detail: {
      assign: "Asignar personal",
      attachments: "Imágenes",
      createTask: "Crear tarea",
      description: "Descripción",
      modal: {
        assignTitle: "Asignar personal",
        description: "Esto eliminará el personal seleccionado de la incidencia. Puede reasignarlos en cualquier momento.",
        removeTitle: "Eliminar personal?"
      },
      priority: {
        "0": "Prioridad baja",
        "1": "Prioridad alta",
        all: "Todas las prioridades",
        "short": {
          "0": "Baja",
          "1": "Alta"
        }
      },
      stateError: "No ha sido posible actualizar el estado.",
      states: {
        all: "Todos los estados",
        closed: "Cerrado",
        inProgress: "En progreso",
        in_progress: "En progreso",
        open: "Abierto",
        rejected: "Rechazado"
      },
      title: "Detalles de la incidencia",
      unit: "Propiedad"
    },
    details: "Detalles de la incidencia",
    edit: "Editar incidencia",
    empty: "No se han notificado incidencias aún:)",
    emptyWithFilters: "No hay incidencias con esos filtros aplicados",
    inquiryModal: {
      description: "Para hacernos llegar tu consulta debes escribir un correo a <1>{{email}}</1>",
      description2: "Nuestro equipo te responderá tan pronto nos sea posible",
      sendEmail: "Enviar correo",
      title: "Enviar una consulta"
    },
    isFromCheckIn: "Check in",
    isFromCheckInLabel: "Esta incidencia entra dentro del check in",
    isManagedByOrganization: "Gestionado por {{brandName}}",
    isManagedByOrganizationLabel: "Esta incidencia está gestionada por {{brandName}}",
    isManagedByOwner: "Gestionado por el propietario",
    isManagedByOwnerLabel: "Esta incidencia está gestionada por el propietario",
    managedBy: {
      organization: "Organización",
      owner: "Propietario"
    },
    managedByLabel: "Gestionada por",
    "new": {
      title: "Nueva incidencia"
    },
    newIncident: "Nueva incidencia",
    priorities: {
      high: "Prioridad alta",
      low: "Prioridad baja"
    },
    priority: "Prioridad",
    roles: {
      admin: "Administrador",
      agent: "Agente",
      buildingManager: "Gestor del edificio",
      commercial: "Ventas",
      managerOwner: "Propietario",
      managerOwnerBasic: "Propietario",
      managerOwnerMiddle: "Propietario",
      owner: "Propietario",
      ownerBasic: "Propietario",
      ownerManager: "Propietario",
      ownerMiddle: "Propietario",
      smallOwner: "Propietario",
      staff: "Personal",
      tenant: "Inquilino",
      viewer: "Espectador",
      zazume: "Zazume"
    },
    selectOneYourUnits: "Escoge una de tus propiedades",
    table: {
      headers: {
        priority: "Prioridad",
        status: "Estado",
        submitted: "Creada",
        tenant: "Inquilino",
        title: "Título",
        unit: "Propiedad"
      },
      view: "Ver incidencia"
    },
    title: "Incidencias",
    updates: {
      closed: "Incidencia cerrada",
      closedBy: "Incidencia cerrada por {{name}}",
      inProgress: "Incidencia en progreso",
      opened: "Incidencia abierta",
      photoUpload: "Se han añadido nuevas imágenes",
      placeholder: "Escribir mensaje",
      rejected: "Incidencia rechazada",
      rejectedBy: "Incidencia rechazada por {{actorName}}",
      roles: {
        buildingManager: "Gestor del edificio",
        tenant: "Inquilino"
      },
      self: "Tú",
      title: "Actualizaciones"
    },
    whichUnit: "¿En qué propiedad?"
  },
  incomes: {
    form: {
      conceptPlaceholder: "Escribe un título breve para este ingreso",
      payerPlaceholder: "Elige quién pagará estos ingresos",
      providerEmailError: "El proveedor no tiene email asignado para solicitar el pago. Para agregarlo, vaya a la página de detalles del proveedor y edite los datos para agregar el correo electrónico",
      unitNotSelectedMessage: "Por favor, primero selecciona una Propiedad para rellenar este campo"
    },
    income: "ingreso",
    payerTypes: {
      agent: "Agente",
      supplier: "Proveedor",
      user: "Inquilino"
    },
    types: {
      additional_guarantees: "Garantías adicionales",
      application_payment: "Pagos de la solicitud",
      attributed_to_expense: "Atribuido a gasto",
      commercialization_fees: "Honorarios de comercialización",
      commission_fee: "Comisión",
      delay_interest: "Intereses de demora",
      deposit: "Fianza",
      holding_deposit: "Reserva",
      insurance: "Seguro",
      monthly_rent: "Alquiler mensual",
      other: "Otro",
      rent_advance: "Adelanto de rentas",
      supplies: "Suministros"
    }
  },
  investment: {
    alertModal: {
      deleteSuccess: "Alerta eliminada correctamente",
      error: "Se ha producido un error al crear la alerta",
      form: {
        advertiser: "Anunciante",
        all: "Todos",
        both: "Ambos",
        condition: "Estado de la propiedad",
        elevator: "Ascensor",
        email: "Email",
        errors: {
          maxBudget: "Introduce un presupuesto máximo válido",
          minRentPrice: "Introduce un precio de alquiler mensual mínimo válido",
          profitability: "Introduzca una rentabilidad válida"
        },
        goodCondition: "Buen estado",
        isActive: "Activa",
        location: "Ciudad o Población",
        maxBudget: "Presupuesto máximo de compra",
        minRentPrice: "Precio mínimo de alquiler mensual",
        needsRenovation: "A reformar",
        newConstruction: "Obra nueva",
        noPreference: "Indiferente",
        notificationPreferences: "Preferencias de notificación",
        privateListingsOnly: "Sólo particulares",
        profitability: "Rentabilidad",
        rooms: "Habitaciones",
        status: "Estado",
        statusInfo: "Si quieres dejar de recibir notificaciones con los resultados de esta alerta puedes desactivarla y/o activarla si quieres reanudar tu búsqueda.",
        whatsapp: "WhatsApp",
        whereReceiveAlerts: "¿Por dónde quieres recibir las alertas?",
        withElevator: "Con ascensor",
        withoutElevator: "Sin ascensor"
      },
      success: "Alerta creada correctamente",
      title: "Configura tu alerta",
      updateSuccess: "Alerta actualizada correctamente"
    },
    detail: {
      aside: {
        cashOnCash: "Cash on cash",
        checkingStatus: "Estamos comprobando la disponibilidad de esta propiedad. La información de esta página puede no estar actualizada.",
        downPayment: "Entrada aportada",
        grossProfitability: "Rentabilidad bruta",
        monthlyCashFlow: "Flujo de caja mensual",
        monthlyExpenses: "Gastos mensuales",
        monthlyMortgagePayment: "Cuota mensual hipoteca",
        monthlyRent: "Alquiler mensual",
        mortgageAmount: "Importe hipoteca",
        netProfitability: "Rentabilidad neta",
        propertyPrice: "Precio del inmueble",
        renovationCost: "Coste de la reforma",
        seeFinancialData: "Ver datos financieros",
        seePublication: "Ver anuncio",
        taxesAndExpenses: "Impuestos y gastos",
        title: "Datos financieros",
        totalPropertyCost: "Coste total del inmueble",
        yearlyCashFlow: "Flujo de caja anual"
      },
      form: {
        averageRent: "Alquiler promedio (de propiedades alrededor)",
        averageRentPriceSqrMtr: "Precio medio/m2",
        community: "Comunidad",
        economicAnalysis: "Análisis económico",
        estimatedAnnualExpenses: "Estimación de gastos anuales",
        estimatedMonthlyRent: "Alquiler mensual estimado",
        ibi: "IBI",
        insurance: "Seguro",
        location: "Ubicación",
        mortgage: "Hipoteca",
        mortgageInterest: "Tipo de interés",
        mortgageYears: "Plazo en años",
        otherExpenses: "Otros gastos",
        rentAnalysis: "Análisis del alquiler de la zona",
        rentalPrice: "Precio del alquiler"
      },
      goBack: "Volver al listado",
      goBackConfirmation: {
        description: "Va a salir de la página sin guardar los cambios. ¿Está seguro de que desea continuar?",
        title: "¿Deseas salir sin guardar cambios?"
      },
      title: "{{typeOfProperty}} a la venta en {{address}}",
      unitInfo: {
        baths: "Baños",
        beds: "Habitaciones"
      }
    },
    emptyFilters: "Usa el buscador de zona para encontrar oportunidades de inversión dónde más te interesa",
    emptyResults: "No hay resultados en esta zona con los filtros seleccionados. Prueba a cambiar filtros o zona.",
    listing: {
      card: {
        bathsCount: "{{count}} Baño",
        bathsCount_other: "{{count}} Baños",
        contactType: {
          "private": "Particular",
          professional: "Agencia"
        },
        estimatedRent: "Alquiler estimado",
        portalMarket: {
          fotocasa: "Fotocasa",
          idealista: "Idealista",
          pisosCom: "Pisos.com"
        },
        profitability: "Rentabilidad",
        roomsCount: "{{count}} Habitación",
        roomsCount_other: "{{count}} Habitaciones",
        saveInvOpportunity: "Guardar oportunidad",
        title: "{{typeOfProperty}} en {{address}}"
      },
      filter: {
        characteristics: "Características",
        floor: "Planta",
        floorOptions: {
          groundFloor: "Planta baja",
          highFloors: "Plantas altas (+4)",
          intermediateFloors: "Plantas intermedias (1-3)",
          mezzanineFloor: "Entreplanta"
        },
        hasAirConditioning: "Aire acondicionado",
        hasBoxRoom: "Trastero",
        hasGarden: "Jardín",
        hasSwimmingPool: "Piscina",
        hasTerrace: "Terraza",
        isAuction: "En subasta",
        isBareOwnership: "Nuda propiedad",
        isFromBank: "Pertenece a un banco",
        isSocialHousing: "VPO",
        isSquatted: "Okupado",
        minProfitability: "Rentabilidad mínima",
        rentPrice: "Precio de alquiler",
        salePrice: "Precio de venta",
        specialConditions: "Condiciones especiales"
      },
      newAlert: "Nueva alerta"
    },
    myAlerts: {
      table: {
        channel: {
          both: "WhatsApp, Email",
          email: "Email",
          whatsapp: "WhatsApp"
        },
        editAlert: "Editar alerta",
        emptyState: {
          description: "Aún no has creado ninguna alerta. Crea una alerta para recibir notificaciones de nuevas propiedades que cumplan con tus criterios."
        },
        header: {
          channel: "Canal",
          location: "Lugar",
          maxBudget: "Presupuesto máx.",
          profitability: "Rentabilidad",
          status: "Estado"
        },
        status: {
          active: "Activa",
          disabled: "Deshabilitada"
        }
      },
      title: "Mis alertas"
    },
    mySavedOpportunities: {
      emptyResults: "Aún no has guardado ninguna oportunidad. Guarda las que más te gusten para tenerlas siempre a mano.",
      removeSavedOpportunity: {
        description: "Se eliminará la oportunidad guardada. Esta acción no se puede deshacer.",
        title: "¿Eliminar la oportunidad guardada?"
      },
      title: "Mis oportunidades guardadas"
    },
    status: {
      good: "Buen estado",
      newDevelopment: "Nueva promoción",
      renew: "Por renovar"
    },
    title: "Oportunidades de inversión"
  },
  invitations: {
    detail: {
      description: "Participarás en dar a conocer Zazume, la plataforma líder en alquiler residencial y <strong>ayudarás a otros propietarios a garantizar su alquiler</strong> antes, durante y después del contrato de arrendamiento. \n\n<strong>Ganarás 100€ por cada propietario</strong> que invites y decida alquilar su piso con nuestro equipo o 25€ si tus invitados ya tienen inquilinos y deciden gestionar su alquiler con Zazume siempre y cuando la renta mensual sea de al menos 600€.\n\n<strong>Tus invitados también</strong> obtendrán el mismo beneficio que tú, ya sean 100€ si alquilan o 25€ si gestionan su alquiler con Zazume.",
      termsLink: "Lee los términos y condiciones",
      title: "Comparte Zazume y gana 100€ por cada propietario",
      yourInvitations: {
        empty: "No hay invitaciones para mostrar",
        received: "Recibidas",
        sent: "Enviadas",
        title: "Tus invitaciones"
      }
    },
    pageTitle: "Invita a un propietario a probar Zazume",
    sendByEmail: {
      button: "Enviar invitación",
      label: "Escribe el correo del propietario",
      placeholder: "Ej: name@mail.com",
      success: "¡La invitación se ha enviado correctamente!",
      title: "Enviar invitación por correo electrónico"
    },
    shareByLink: {
      button: "Copiar enlace",
      title: "Comparte tu enlace de invitación"
    },
    title: "Invitaciones"
  },
  invoices: {
    deleteExternal: {
      description: "¿Estás seguro de que deseas eliminar esta factura externa?",
      success: "La factura externa se ha eliminado correctamente",
      title: "Eliminar factura externa"
    },
    "export": {
      button: "Exportar facturas",
      description: "Selecciona un rango de fechas",
      error: "Se ha producido un error al exportar facturas",
      from: "Desde",
      success: "En unos minutos recibirá un email con las facturas seleccionadas.",
      title: "Exportar facturas",
      to: "Hasta"
    },
    invoiceNumber: "Número de factura",
    modal: {
      cancelButton: "No, gracias",
      description: "Este pago ya se ha pagado, ¿quieres crear una factura?",
      descriptionUnpaid: "Este pago aún no se ha pagado. ¿Estás seguro de que deseas crear una factura?",
      submitButton: "Sí, crear factura",
      submitButton_other: "Sí, crear facturas",
      successMessage: "La factura se ha creado correctamente",
      successMessage_other: "Facturas creadas con éxito",
      title: "¿Quieres crear una factura?",
      title_other: "¿Quieres crear las facturas?",
      updateButton: "Sí, sobrescribir factura",
      updateDescription: "Ya existe una factura asociada a este gasto. ¿Quieres sobrescribirla?",
      updateDescription2: "El numero de serie se mantendrá.",
      updateTitle: "¿Quieres sobrescribir la factura actual?",
      updatedMessage: "Factura actualizada correctamente",
      updatedMessage_other: "Facturas actualizadas correctamente"
    },
    modalInTransactions: {
      description: "Un pago reconciliado se ha pagado en su totalidad, ¿quieres crear su factura?",
      description_other: "Los pagos reconciliados se han pagado en su totalidad, ¿quieres crear sus facturas?"
    },
    sendEmail: {
      description: "Escribe un correo electrónico y te enviaremos la factura.",
      error: "Ha ocurrido un error al enviar el email",
      success: "Email enviado con éxito",
      title: "Enviar factura por email"
    },
    table: {
      client: "Cliente",
      date: "Fecha",
      empty: "Aún no hay facturas generadas",
      goToClient: "Ir al detalle de cliente",
      goToPayment: "Ir al detalle de pago",
      issuer: "Emisor",
      number: "Número",
      sendEmail: "Enviar por email",
      show: "Ver factura",
      typeFilter: {
        issued: "Emitida",
        name: "Tipo de factura",
        received: "Recibida"
      }
    },
    tabs: {
      issued: "Emitidas",
      received: "Recibidas"
    },
    title: "Facturas",
    update: "Actualizar factura"
  },
  keyDelivery: {
    agentUpdated: "Agente actualizado con éxito",
    cancelDescription: "Esta acción no se puede deshacer. Puedes programar una nueva entrega de llave después de cancelar.",
    cancelTitle: "¿Cancelar la entrega de llaves?",
    canceled: "Entrega de llaves cancelada correctamente",
    changeAgent: "Cambiar",
    changeKeyDeliver: "Cambiar quién entregará las llaves",
    changeKeyReceiver: "Cambia quién recibirá las llaves",
    dateLabel: "Elige cuándo se entregan las llaves",
    deliverFromLabel: "¿Quién recibirá las llaves?",
    deliverToLabel: "¿Quién recibirá las llaves?",
    disabledWarning: "El paso de verificación de solvencia debe estar completado",
    forcedCreate: {
      description: "La fecha elegida se superpone con alguna disponibilidad de visitas asignada al agente seleccionado. ¿Está seguro de que deseas continuar?",
      title: "La entrega de llaves se superpone a las visitas"
    },
    scheduled: "Entrega de llaves programada con éxito",
    title: "Entrega de llaves"
  },
  language: {
    "default": "Elegir un idioma",
    en: "Inglés",
    es: "Español",
    field: "Idioma"
  },
  link: {
    goTo: "Ir a {{target}}",
    routes: {
      accounting: "Contabilidad",
      application: "Solicitudes",
      calendar: "Calendario",
      dashboard: "Dashboard",
      incidents: "Incidentes",
      onboarding: "Solicitudes",
      "owner-account-settings": "Configuración de cuenta de propietario",
      people: "Personas",
      portfolio: "Portfolio",
      settings: "Configuración",
      tasks: "Tareas",
      valuations: "Valoraciones"
    }
  },
  modals: {
    assign: {
      incidentPlaceholder: "Buscar incidencias",
      incidentRecents: "Recientes",
      incidentTitle: "Enlazar a una incidencia",
      staffHint: "Recientes",
      staffPlaceholder: "Buscar personal",
      staffTitle: "Asignar personal",
      unitHint: "Propiedades vacías",
      unitPlaceholder: "Buscar propiedades",
      unitRecentHint: "Propiedades recientes",
      unitTitle: "Asignar propiedad",
      zazuHint: "Recientes",
      zazuPlaceholder: "Buscar Zazú",
      zazuTitle: "Asignar Zazu"
    },
    close: {
      taskDescription: "Esta acción cerrará la tarea. Se puede abrir de nuevo en cualquier momento.",
      taskTitle: "Cerrar tarea?"
    },
    remove: {
      staffDescription: "Esta acción eliminará el personal seleccionado de la tarea. Puede reasignarlos en cualquier momento.",
      staffTitle: "Eliminar personal?",
      standardDescription: "Esta acción eliminará el elemento y éste ya no será accesible. Esta acción no se puede deshacer.",
      standardTitle: "¿Eliminar?",
      taskDescription: "Esto lo eliminará de la lista de tareas y ya no será accesible. Esta acción no se puede deshacer.",
      taskTitle: "¿Eliminar tarea?",
      unitDescription: "Esta acción eliminará la propiedad seleccionada de la tarea. Puede reasignarla en cualquier momento.",
      unitTitle: "Eliminar propiedad?",
      zazuDescription: "Esto eliminará el Zazu seleccionado de la tarea. Puedes reasignarlo en cualquier momento.",
      zazuTitle: "¿Eliminar Zazu?"
    },
    requestServiceUpgrade: {
      description: "Esta propiedad se encuentra actualmente bajo el servicio de <1>{{service}}</1>. El precio de este servicio es <1>{{price}}</1>. Puedes solicitar una mejora seleccionando una de las siguientes opciones.",
      pricing: "{{percentage}}% + IVA ({{amount}}) de tu alquiler mensual",
      selectProperty: "Selecciona la propiedad que quieres mejorar",
      selectService: "Selecciona un servicio",
      selectServiceHelper: "Primero debes seleccionar una propiedad",
      successMessage: "Tu solicitud se ha enviado correctamente",
      title: "Mejorar plan"
    },
    uploadDocuments: {
      description: "Subir documento"
    },
    uploadImages: {
      description: "Sube tus imágenes"
    },
    uploadVideos: {
      description: "Sube tus vídeos"
    }
  },
  nonPayment: {
    claimNumber: "Número de expediente",
    coveredByInsurance: "Cubierto por el seguro",
    coveredByInsuranceDescription: "Este pago ha sido cubierto por la compañía de seguros",
    debt: "Deuda",
    defaultPayments: "Pagos en mora",
    defaultUnitPayments: "Pagos en mora de la propiedad",
    deleted: "El impago se eliminó correctamente",
    detail: {
      detailsTab: {
        title: "Detalles del seguro de pago"
      },
      title: "Impago"
    },
    editModal: {
      button: "Editar impago",
      title: "Editar impago"
    },
    form: {
      contract: {
        label: "Selecciona un contrato",
        placeholder: "Selecciona un contrato"
      },
      details: "Detalles del impago",
      insuranceDetails: "Detalles del seguro de pago",
      maxNonPayments: "Número máximo de impagos",
      unit: {
        label: "Selecciona una propiedad",
        placeholder: "Selecciona una propiedad"
      }
    },
    insuranceCompany: "Compañía de seguros",
    linkPayment: "Enlazar pago",
    linkedTag: "Impago",
    markAsPaidByInsurance: "Marcar como pagado por el seguro",
    maxNonPayments: "Número máximo de impagos",
    maxNonPaymentsTooltip: "Si las rentas mensuales de alquiler incluidos en este impago llegan a este número, la plataforma enviará una notificación, pero no bloqueará la inclusión de nuevas rentas",
    "new": {
      button: "Nuevo impago"
    },
    newModal: {
      button: "Crear impago",
      title: "Nuevo impago"
    },
    paidByInsurance: "Pagado por el seguro",
    paidDebt: "Deuda saldada",
    policyNumber: "Número de póliza",
    statusUpdated: "El estado se actualizó correctamente",
    statuses: {
      opening: "Aperturando",
      pendingCollection: "Pendiente de cobro",
      referred: "Remitido",
      rejectedByInsurance: "Rechazado por el seguro",
      resolved: "Resuelto",
      toBeProcessed: "Por gestionar"
    },
    table: {
      emptyState: "No hay impagos para mostrar"
    },
    title: "Impagos",
    totalDebt: "Deuda total",
    unlinkModal: {
      description: "Si desvinculas este pago, podrás volver a vincularlo en el futuro.",
      title: "¿Estás seguro de que deseas desvincular este pago?"
    },
    updated: "Impago actualizado correctamente"
  },
  notifications: {
    markAllRead: "Marcar todas como leídas",
    noNotifications: "Nada que notificar todavía :)",
    reportCreated: "Informe creado con éxito",
    reportSent: "El informe se envió correctamente",
    reportUpdated: "Informe actualizado con éxito",
    seeAll: "Ver todas las notificaciones",
    title: "Notificaciones"
  },
  onboarding: {
    allBuildings: "Todos",
    applicationDeniedBy: "Esta solicitud ha sido rechazada por",
    backgroundCheck: {
      acceptTenant: "Aceptar inquilino",
      acceptedTenant: "Solicitante aceptado",
      completedRate: "({{rate}}% Completado)",
      contractDates: {
        button: "Solicitar información",
        disabledButton: "Información solicitada",
        endingDate: "Fin contrato de alquiler",
        instructions: "Rellene las fechas del contrato y solicite información al potencial inquilino. Luego, cuando envíen toda la información, verás su puntuación y tome una decisión.",
        startingDate: "Inicio contrato de alquiler",
        title: "Fechas del contrato"
      },
      declinedTenant: "Solicitante rechazado",
      directAccessLink: {
        copied: "Copiado en el portapapeles",
        cta: "Generar enlace directo",
        description: "Envía este enlace a los inquilinos para que puedan acceder directamente y completar sus datos. Este enlace caduca transcurridas 24 horas, por lo que tendrás que copiarlo y enviarlo de nuevo pasado ese tiempo. No te preocupes, la información completa no desaparecerá.",
        descriptionp2p: "Envía automáticamente este enlace a los inquilinos para que puedan acceder directamente y completar sus datos. Este enlace caduca a las 24 horas, por lo que tendrás que reenviarlo de nuevo pasado ese tiempo. No te preocupes, la información completada no desaparecerá.",
        expiration: "(Dura 24h)",
        title: "Acceso directo para completar la información"
      },
      employmentSituation: {
        business: "Empresa",
        employee: "Empleado/a",
        erte: "ERTE",
        freelance: "Autónomo",
        official: "Funcionario/a",
        pensions: "Pensionista",
        retired: "Jubilado/a",
        student: "Estudiante",
        widowhood: "Viudez",
        withoutIncomes: "Sin ingresos"
      },
      frequency: "anualmente",
      idDocumentOptions: {
        DNI: "DNI",
        NIE: "NIE",
        other: "Otro"
      },
      insurance: {
        button: "Se acepta el inquilino",
        descrition: "El seguro de protección de alquiler cubre los ingresos de la propiedad si tu inquilino deja de realizar el pago del alquiler mensual.",
        information: "Cuando recibas la respuesta del propietario, puedes reflejar su decisión aquí.",
        price: "Total: {{price}}",
        title: "Tomar una decisión",
        toggle: "Contratar un seguro de protección de alquiler"
      },
      insuranceContract: {
        button: "Subir contrato",
        information: "Una vez contratado el seguro, recuerde actualizar el estado y cargar el contrato firmado.",
        modalDescription: "Cargar contrato de protección de seguro",
        modalTitle: "Subir contrato",
        placeholder: "Seguro de protección de alquiler",
        title: "Administrador Zazume"
      },
      insuranceUpload: {
        cta: "Subir",
        description: "Envía los datos y documentos a la compañía de seguros. Luego, podrás seguir el proceso en la web de la aseguradora, pero ten en cuenta que cualquier cambio posterior no se sincronizará en la plataforma y deberá gestionarse directamente con la aseguradora",
        success: "Datos y documentos enviados a la compañía de seguros",
        title: "Subir datos a la aseguradora"
      },
      linkToDocument: {
        description: "Para ver la documentación adjunta, vaya a",
        tab: ".",
        title: "El seguro de protección de alquiler se ha contratado con éxito"
      },
      makeDecision: "Toma una decisión",
      makeDecisionSubtitle: "Cuando recibas la respuesta del solicitante, puedes reflejar tu decisión aquí.",
      ownerEmail: {
        button: "Enviar información",
        disabledButton: "Información enviada",
        information: "Enviarás la puntuación del inquilino y su información al propietario.",
        title: "Enviar información del inquilino al propietario"
      },
      participants: {
        guarantor: "Avalista",
        sharer: "Co-inquilino/a",
        tenant: "Inquilino"
      },
      services: {
        contracted: "{{zazumeService}} se ha seleccionado correctamente",
        description: "Por favor, elige uno de nuestros servicios antes de aceptar o rechazar al solicitante.",
        title: "Servicios"
      },
      tenantInformation: {
        title: "Información del inquilino"
      },
      tenantScoring: {
        information: "Nuestro sistema de puntuación predice la probabilidad de que un inquilino cumpla o no con sus obligaciones de arrendamiento. Por tanto evalúa el riesgo potencial del solicitante en comparación con otros.",
        rentalInsurancePrice: {
          description: "Precio del seguro de alquiler:",
          weak: "No se puede añadir un seguro de protección de alquiler"
        },
        score: {
          strong: "FUERTE",
          weak: "DÉBIL"
        },
        scoringCalculate: "Calcular puntuación",
        scoringCalculated: "Puntuación calculada con éxito",
        scoringOutdatedDescription: "El solicitante ha actualizado algunos de sus datos. Si quieres, puedes recalcular la puntuación de verificación de solvencia.",
        scoringRecalculate: "Recalcular puntuación",
        thisApplicationIs: "Esta solicitud es",
        title: "Puntuación de inquilinos"
      },
      title: "Verificación de solvencia",
      whatsApp: {
        p2pDirectLink: "Aquí tienes un enlace para, si quieres seguir el proceso de alquiler, seguir adelante con la documentación necesaria: {{link}}."
      }
    },
    because: "pues",
    boardStates: {
      backgroundCheck: "Solvencia",
      contractSigning: "Firma de contrato",
      deposit: "Reserva",
      invited: "Invitados",
      leads: "Interesados",
      living: "Viviendo",
      moveIn: "Instalación",
      states: {
        completed: "Completado",
        living: "Instalado",
        notStarted: "Pendiente",
        pendingSend: "Pendiente de enviar",
        rejected: "Rechazado",
        started: "En progreso",
        toComplete: "Para completar",
        waiting: "Esperando",
        waitingForSigners: "Esperando firmas",
        waitingForTenant: "Esperando a inquilino"
      },
      viewings: "Visitas"
    },
    bulkAction: {
      archive: {
        button: "Archivar",
        description: "Estás a punto de <1>archivar {{value}} solicitudes.</1> Está acción no se puede deshacer.\n\nSolo archivaremos aquellas solicitudes que estén rechazadas.\n\nRecibirás una notificación cuando esta acción finalice.",
        success: "Solicitud de archivado de solicitudes enviada",
        title: "¿Quieres archivar estas solicitudes?"
      },
      assignApplicationManager: {
        actionTitle: "Asignar gestor de solicitudes",
        description: "Estás a punto de asignar a {{managerName}} como gestor de solicitudes en <1>{{count}} solicitud</1>.",
        description_other: "Estás a punto de asignar a {{managerName}} como gestor de solicitudes en <1>{{count}} solicitudes</1>.",
        success: "Solicitud de asignación de gestor de solicitudes enviada",
        title: "¿Deseas asignar a {{managerName}} como gestor de solicitudes en estas solicitudes?"
      },
      confirmationError: "Debe introducir correctamente la información proporcionada.",
      confirmationLabel: "Escribe \"{{value}}\" para confirmar",
      confirmationPlaceholder: "Escribe aquí",
      decline: {
        button: "Rechazar",
        declineAndArchive: "Una vez rechazadas, archiva las solicitudes.",
        description: "Estás a punto de rechazar <1>{{value}} solicitudes.</1> Esta acción no se puede deshacer.\n\nRecibirás una notificación cuando se complete esta acción.",
        reasonTitle: "Escribe la razón por la que rechazas las solicitudes:",
        success: "Solicitud para archivar solicitudes enviada",
        title: "¿Quieres rechazar estas solicitudes?"
      },
      notificationInfo: "Recibirás una notificación cuando se complete esta acción.",
      selected: "seleccionadas",
      sendViewingReminder: {
        button: "Enviar recordatorio de visita",
        description: "Estás a punto de enviar un recordatorio de visita a <1> {{value}} solicitudes. </1>",
        errorDescription: "Para poder enviar un recordatorio de visita, es necesario que la propiedad seleccionada tenga configurada la disponibilidad de visitas.",
        errorTitle: "Esta propiedad no tiene configurado el calendario de visitas.",
        success: "Solicitud de recordatorio de visitas enviado",
        title: "¿Quieres enviar un recordatorio de visitas a estas solicitudes?",
        tooltip: "Para poder enviar un recordatorio de visita, debes filtrar las solicitudes de una propiedad y tener el filtro de paso a “Interesados”."
      },
      unselect: "Deseleccionar"
    },
    comments: {
      placeholder: "Escribe un comentario",
      title: "Comentarios"
    },
    commercialisationFee: {
      contractRejected: "El contrato de honorarios de comercialización fue rechazado, antes de proceder a enviarlo de nuevo, por favor, cancele el pago solicitado.",
      description: "Ajusta la tarifa de comercialización y adjunta cualquier documentación que deba ser firmada por el inquilino.",
      documentation: {
        addDocuments: "Añadir documentos a firmar",
        description: "Sube el documento listo para firmar. Lo enviaremos al inquilino con la solicitud de pago de los honorarios de comercialización.",
        title: "Documentación"
      },
      errorMessage: "No hay contrato de comercialización. Para solicitar el pago de los honorarios de comercialización, primero debe crear un contrato.",
      infoMessage: "Hay un contrato de comercialización pendiente de firma, pero puedes continuar con la solicitud de pago de los honorarios de comercialización.",
      infoMessageNoContractDocument: "No hay ningún documento de contrato de comercialización adjunto a un contrato existente, pero puedes continuar con la solicitud de pago de los honorarios de comercialización.",
      priceDescription: "Honorarios de comercialización",
      sendSuccessContract: "Contrato de honorarios de comercialización enviado",
      title: "Honorarios de comercialización",
      tooltips: {
        cancelled: "Honorarios de comercialización cancelados",
        "default": "Honorarios de comercialización en mora",
        exported: "Honorarios de comercialización exportados",
        not_paid: "Honorarios de comercialización no pagados",
        paid: "Honorarios de comercialización pagados",
        partially_paid: "Honorarios de comercialización parcialmente pagados",
        pending_approval: "Honorarios de comercialización pendiente de aprobación",
        to_validate: "Honorarios de comercialización a validar",
        waiting: "Esperando por los honorarios de comercialización"
      }
    },
    conditionsModal: {
      title: "Establecer las condiciones de pago de alquiler",
      total: "Total: {{amount}}",
      updatedMessage: "Cambios actualizados correctamente"
    },
    contactDetails: {
      editForm: {
        contactPreference: "Preferencia de contacto",
        save: "Guardar cambios",
        status: "Estado",
        title: "Editar preferencia de contacto"
      },
      filter: {
        label: "Estado del lead"
      },
      form: {
        contactPreference: "Preferencia de contacto",
        status: "Estado",
        title: "Preferencia de contacto"
      },
      leadSection: {
        contactPreference: "Preferencia de contacto",
        description: "En esta sección podrás añadir a través de que vía prefiere el solicitante ser contactado. Y si ya ha sido contactado o no.",
        edit: "Editar preferencia de contacto",
        status: "Estado",
        title: "Preferencia de contacto"
      },
      preferenceOptions: {
        phone: "Teléfono",
        portalChat: "Chat del portal",
        whatsapp: "WhatsApp"
      },
      statusOptions: {
        contactAgain: "Volver a contactar",
        contacted: "Contactado",
        pending: "Pendiente"
      }
    },
    contractSigning: {
      addPM: "Añadir administrador/propietario",
      addSigner: "Añadir nuevo firmante",
      addTenant: "Añadir nuevo inquilino",
      andInventory: "y el inventario ",
      applicantAdmin: "Administrador solicitante",
      attachDocument: "Adjuntar documento",
      confirmModal: {
        propertyManagerDescription: "Esto eliminará al administrador/propietario para firmar el contrato. Puedes volver a incluirlo antes de que se envíe el contrato.",
        propertyManagerTitle: "¿Quitar Administrador/Propietario?",
        rejectBtn: "Rechazar",
        rejectDescription: "Esto rechazará el contrato de forma permanente. Esta acción no se puede deshacer.",
        rejectTextAreaTitle: "¿Por qué quieres rechazar este contrato?",
        rejectTitle: "Rechazar",
        tenantDescription: "Esto eliminará al inquilino para firmar el contrato. Puedes volver a incluirlo antes de que se envíe el contrato.",
        tenantTitle: "¿Eliminar inquilino?"
      },
      contractDates: "Fechas del contrato",
      contractEnd: "Fin contrato de alquiler",
      contractInfo: "Sube el contrato de alquiler listo para firmar, establece las fechas y los firmantes. Una vez listo, el contrato se enviará a los destinatarios por correo electrónico para que lo firmen.",
      contractSettings: {
        contractExtension: "Detalles de la prórroga",
        extensionMonths: "Meses de prórroga: {{months}} meses",
        maxExtensionMonths: "Meses máximos de prórroga: {{months}} meses",
        notificationEndContractMonths: "Preaviso vencimiento de contrato: {{months}} meses",
        notificationEndExtensionMonths: "Preaviso vencimiento de la prórroga: {{months}} meses"
      },
      contractSignedInfo: "Aquí encontrarás una copia firmada del contrato {{inventory}}para descargar.",
      contractStart: "Inicio contrato de alquiler",
      documentSpecs: "Sólo archivos PDF con un tamaño máximo de 2MB",
      download: "Descarga",
      fileTooBig: "El tamaño máximo del archivo es de 2MB",
      filesTooBig: "El tamaño máximo de los archivos son 50MB",
      hasRejected: "ha rechazado el contrato",
      initialGraceMonthLabel: "Días de gracia para el mes inicial (La recurrencia de pagos comenzará el segundo mes después del mes de inicio del contrato)",
      inventory: {
        collapser: "artículo será añadido",
        collapserCompleted: "artículo ha sido añadido",
        collapserCompleted_plural: "artículos han sido añadidos",
        collapser_plural: "artículos serán añadidos",
        title: "Inventario",
        toggle: "Importar el inventario de la propiedad como un adjunto"
      },
      managementContractMessages: {
        error: {
          message1: "La solicitud está configurada para que el pago del alquiler mensual esté dirigido a la Organización, sin embargo no hay ningún contrato de gestión creado. Edita hacia quien va dirigido el alquiler o sube un contrato de gestión para continuar."
        },
        info: {
          message1: "Hay un contrato de gestión creado para esta propiedad que se aplicará, con un servicio <1>{{serviceName}}</1> y una tarifa de <2>{{managementFee}}</2>"
        },
        warning: {
          message1: "La solicitud está configurada para que el pago del alquiler mensual esté dirigido al propietario, sin embargo hay un contrato de gestión creado. Edita hacia quien va dirigido el alquiler si es necesario o si no, haz caso omiso a este mensaje.",
          message2: "Esta propiedad tiene un contrato de gestión como borrador, recuerda que el contrato debe estar subido y firmado por todas las partes.",
          message3: "Esta propiedad tiene un contrato de gestión pendiente de firma, recuerda que el contrato debe estar subido y firmado por todas las partes."
        }
      },
      mandatoryOwner: "Debes añadir un propietario a la propiedad para continuar con la firma del contrato.",
      notifications: {
        resend: "Se ha enviado un recordatorio",
        rollBack: "El paso de contrato ha sido revertido"
      },
      recurrencesOfPayments: "Recurrencias de pagos",
      reject: "Rechazar",
      rentalContract: "Contrato de alquiler",
      resend: "Enviar recordatorio",
      saveSigner: "Guardar firmante",
      seeContractDetails: "Ver detalles del contrato",
      send: "Enviar contrato",
      signatureBiometrics: "Firma con biometría",
      signatureBiometricsInfo: "Se hace con un trazo de lápiz, igual que firmar en papel. Es rápido y fácil de usar, y permite identificar de forma exclusiva al firmante.",
      signatureCertificate: "Firma con certificado digital",
      signatureCertificateInfo: "El firmante utiliza su certificado digital emitido por terceros. Un certificado digital es el único método que garantiza técnica y legalmente la identidad de una persona en la Internet.",
      signer: "Firmante",
      signerTitle: {
        propertyManager: "Arrendador",
        renter: "Arrendador",
        tenant: "Firmante"
      },
      signerTypes: {
        propertyManager: "Administrador/Propietario",
        renter: "Arrendador",
        tenant: "Residente"
      },
      signers: "Firmantes",
      states: {
        broken: "Roto",
        expired: "Firma expirada",
        not_signed: "Esperando",
        partially_signed: "Firmado",
        rejected: "Rechazado",
        signed: "Firmado"
      },
      title: "Firma de contrato",
      unremovableSigner: "Este firmante no se puede eliminar ni editar",
      wrongFile: "Solo PDF"
    },
    declineApplication: "Rechazar solicitud",
    declineModal: {
      addNote: "Añadir una nota personal",
      alreadyLiving: "Ya estoy viviendo en otra propiedad",
      confirmation: "Esto rechazará la aplicación seleccionada de forma permanente. Esta acción no se puede deshacer.",
      declineTenantBtn: "Rechazar inquilino",
      declinedSuccess: "La solicitud se ha rechazado correctamente",
      dislikeUnit: "No me gusta esta propiedad",
      explainTenantTitle: "Explica tus razones al inquilino",
      explainTitle: "Dé un motivo para rechazar esta solicitud:",
      infoMessage: "Solicitud con pagos asociados pagados o parcialmente pagados. Esto es solo un aviso, puedes continuar con la operación si así lo deseas.",
      noApplicantResponse: "El solicitante no responde",
      notAcceptPets: "La propiedad no acepta mascotas",
      notEconomic: "El solicitante no cumple los requisitos económicos necesarios",
      notFulfilRequirements: "El solicitante no cumple los requisitos de la propiedad",
      notInterested: "El solicitante no está realmente interesado",
      noteHelp: "Esta es una nota personal para los miembros del equipo de la organización. Los inquilinos no lo pueden ver.",
      noteLabel: "Nota personal",
      otherReason: "Otro motivo:",
      paymentPending: "Un pago parcialmente pagado está vinculado a esta solicitud. Reembólsalo y elimina las reconciliaciones para continuar con la declinación.",
      paymentPendingClickable: "Ver pago",
      removeNote: "Eliminar nota personal",
      scoringWeak: "La puntuación es demasiado débil",
      sendNotification: "Enviaremos un correo al solicitante para informarle que su solicitud ha sido rechazada.",
      sendNotificationLabel: "Notificar al solicitante por correo",
      tenantTitle: "¿Quieres rechazar a este inquilino?",
      title: "¿Desea rechazar esta aplicación?",
      tooShort: "El plazo es demasiado corto",
      unitRented: "La propiedad ya se ha alquilado",
      wrongContactDetails: "Los detalles de contacto son incorrectos"
    },
    "delete": "Eliminar",
    deleteModal: {
      description: "¿Seguro que quieres eliminar esta solicitud? Esta acción no se puede deshacer.",
      title: "Eliminar solicitud"
    },
    detail: {
      people: {
        addApplicationManager: "Añadir Gestor sol.",
        applicationManager: "Gestor de la solicitud",
        applicationManagerAssignSuccess: "Gestor de solicitudes asignado con éxito",
        applicationManagerUnassignSuccess: "Gestor de solicitudes desasignado con éxito",
        ownerLeadTitle: "Futuro propietario",
        ownerTitle: "Propietarios",
        participantsTitle: "Participantes"
      },
      searchServiceTag: "Servicio de búsqueda",
      tabs: {
        documentation: "Documentación",
        onboardingSteps: "Pasos de la solicitud",
        people: "Personas"
      },
      tenantDetails: "Detalles del inquilino",
      title: "Detalle de la solicitud",
      unitDetails: "Detalles de la propiedad"
    },
    documentation: {
      contract: "Contrato",
      documentType: "Tipo de documento",
      electricityCupsCIEInvoice: "Electricidad CUPS & CIE",
      gasCupsInvoice: "Gas CUPS & CIE",
      idCard: "Documento de identificación",
      internetConnectionInvoice: "Factura de suministro de internet",
      invoice: "Factura",
      other: "Otros",
      remove: "¿Eliminar documento?",
      removeDescription: "Esto eliminará el documento de forma permanente. Esta acción no se puede deshacer.",
      selectType: "Selecciona un tipo",
      title: "Documentación",
      uploadFile: "Cargar",
      waterSupplyInvoice: "Factura de suministro de agua"
    },
    documents: "Algunos documentos están presentes en esta solicitud",
    editDatesModal: {
      title: "Actualizar detalles del contrato"
    },
    editRentalConditions: {
      feeSectionDescription: "Esta sección muestra el servicio elegido por el propietario, sin embargo, si lo necesitas, puedes cambiarlo.",
      percentageFeeTip: "(sobre el alquiler mensual)",
      serviceToContract: "Servicio a contratar:"
    },
    emptyStateIcon: "👀",
    emptyStateMessage: {
      backgroundCheck: {
        emptyStateIcon: "💯",
        item1: "No hay inquilinos que puntuar",
        item2: "¡Bien hecho!"
      },
      contractSigning: {
        emptyStateIcon: "👏🏻",
        item1: "Todos los contratos firmados",
        item2: "¡Bien hecho!"
      },
      deposit: {
        emptyStateIcon: "💰",
        item1: "Nada más que cobrar.",
        item2: ""
      },
      invited: {
        emptyStateIcon: "👀",
        item1: "El estado \"invitado\" está vacío.",
        item2: "Los inquilinos invitados se mostrarán aquí hasta que acepten su invitación."
      },
      leads: {
        emptyStateIcon: "📥",
        item1: "Ninguna solicitud todavía",
        item2: ""
      },
      living: {
        emptyStateIcon: "👀",
        item1: "Las tarjetas duran 30 días después de que se haya completado la última fase.",
        item2: "Luego, desaparecerán"
      },
      moveIn: {
        emptyStateIcon: "👀",
        item1: "El estado de \"instalación\" está vacía.",
        item2: "Los inquilinos que han solicitado la gestión de cambio de suministros se mostrarán aquí."
      },
      payments: {
        emptyStateIcon: "💰",
        item1: "Nada más que cobrar.",
        item2: ""
      },
      viewings: {
        emptyStateIcon: "👀",
        item1: "El estado \"visitas\" está vacío.",
        item2: "Las visitas programadas de tus propiedades se mostrarán aquí."
      }
    },
    emptyStateOwner: "Para empezar a recibir solicitudes de inquilinos interesados en tu piso es necesario contratar un servicio de búsqueda de inquilinos.",
    extraEmptyStateMessage: {
      invited: {
        item1: "Añadir nuevos inquilinos",
        item2: " enviarles una invitación."
      }
    },
    holdingDeposit: {
      addContract: "Añadir contrato de reserva",
      asAdditionalGuarantee: "Utilizar el importe del depósito como garantía adicional",
      asAdditionalGuaranteeInfo: "Este depósito se utilizará como garantía adicional.",
      button: "Enviar al inquilino",
      contract: "Contrato",
      contractRejected: "El contrato de reserva fue rechazado, antes de proceder a enviarlo nuevamente, por favor, cancele el pago solicitado.",
      depositAmount: "Importe de la reserva",
      depositValue: "Valor de reservas",
      depositsThisMonth: "Reservas este mes",
      errorMessage: "No hay contrato de gestión. Si quieres solicitar el pago de una reserva a un IBAN determinado, primero debes crear un contrato.",
      excludeFromPayout: "Excluir la reserva (garantía adicional) de la liquidación al propietario",
      intro: "Introduzca la cantidad necesaria para que el solicitante reserve esta propiedad y continúe con la solicitud.",
      priceDescription: "Reserva:",
      rejectContractBlocked: "No se puede rechazar este contrato pues hay el pago vinculado está parcialmente pago o pagado. Para rechazarlo, debes cancelarlo antes.",
      sendSuccess: "Reserva enviada",
      sendSuccessContract: "Contrato de reserva enviado",
      title: "Reserva",
      title_other: "Reservas",
      tooltips: {
        cancelled: "Reserva cancelada",
        "default": "Reserva en mora",
        exported: "Reserva exportada",
        paid: "Reserva pagada",
        partially_paid: "Reserva parcialmente pagada",
        pending_approval: "Reserva pendiente de aprobación",
        to_validate: "Reserva a validar",
        waiting: "Esperando por la reserva"
      },
      total: "Total: {{price}}",
      transferSlip: "Comprobante de transferencia bancaria",
      uploadContract: "Sube el contrato de reserva listo para firmar. Se lo enviaremos al inquilino con la solicitud de pago de la reserva.",
      uploadHelp: "Sólo archivos PDF con tamaño máximo de 2MB"
    },
    invitationSent: "Invitación enviada",
    invite: "Invitar a un inquilino",
    inviteModal: {
      addTenantBtn: "Añadir inquilino",
      addressTitle: "Unidad y dirección",
      attach: "Adjuntar archivo",
      buildingSelection: "¿Esta propiedad pertenece a un edificio?",
      chooseProperty: "Elegir una propiedad",
      chooseTenant: "Elige un inquilino",
      chooseUnit: "Elegir una propiedad",
      cupsCie: "CUPS & CIE",
      documentsTitle: "Documentación de la propiedad",
      existingTenant: "Antiguo inquilino",
      existingUnit: "Propiedad existente",
      files: {
        electricity: "Electricidad CUPS & CIE",
        gas: "Gas CUPS & CIE",
        internet: "Factura de suministro de internet",
        water: "Factura de suministro de agua"
      },
      heroImage: "Imagen",
      internetInvoice: "Factura de suministro de internet",
      inviteTenantBtn: "Invitar inquilino",
      newTenant: "Nuevo inquilino",
      newUnit: "Nueva propiedad",
      onboardingEnabed: "¿Desea incluir a este inquilino en el proceso de onboarding?",
      onboardingOnTitle: "Onboarding",
      scheduleViewingOptional: "Opcional",
      scheduleViewingTitle: "Programar una visita",
      scheduleViewingToggle: "¿Hay una visita?",
      startingDate: "Fecha de inicio",
      submitBtn: "Invitar inquilino",
      successfullInvite: "Inquilino invitado con éxito",
      tenantDetails: "Detalles del inquilino",
      title: "Añadir inquilino a onboarding",
      unitLinkSelection: "¿Quieres vincular a este inquilino a una propiedad?",
      unitTitle: "Detalle de la propiedad",
      warningMessage: "Este inquilino está vinculado a la propiedad",
      waterInvoice: "Factura de suministro de agua"
    },
    leadOrigin: {
      dashboard: "Añadido manualmente a través del dashboard",
      noMessage: "El solicitante no ha enviado ningún mensaje.",
      title: "Origen del solicitante"
    },
    moveIn: {
      adminInstructions: "Estos son los servicios que ha elegido el inquilino. Puede actualizar el estado de cada uno de ellos. No se mostrará ningún servicio hasta que el inquilino complete la selección.",
      alerts: {
        cups: {
          content: "Para tramitar el cambio de suministros digitalmente necesitas introducir los datos del CUPS de la vivienda en los detalles de la propiedad.",
          cta: "Ir a los detalles de la propiedad"
        },
        tenantIban: {
          content: "Para tramitar el cambio de suministros digitalmente necesitas introducir el IBAN del inquilino para poder cargar la domiciliación de los recibos.",
          cta: "Ir a los detalles del inquilino"
        },
        tenantIdNumber: {
          content: "Para procesar el cambio de suministros de forma digital, debe tener el número de identificación del inquilino principal como documentos.",
          cta: "Ir a la documentación del inquilino"
        }
      },
      changeModeSelect: {
        auto: "Quiero gestionar el cambio automáticamente",
        manual: "Quiero que contactéis y asesoréis a mi inquilino",
        title: "¿Cómo te gustaría gestionar el cambio de suministros?"
      },
      choose: "Escoge sobre que suministros quieres hacer el cambio de suministros",
      documentation: {
        description: "Para poder tramitar los suministros escogidos necesitamos que subas la siguiente documentación:",
        uploadHelp: "Añade la última factura que tengas"
      },
      externalProvider: {
        errorCodes: {
          "11": "• El solicitante no tiene un número de teléfono válido. <1><2>Vete a Detalles del inquilino y verifícalo</2></1>.",
          "1400002": "• No se ha podido conectar con el proveedor externo, inténtelo de nuevo más tarde.",
          "20": "• El solicitante no tiene los datos de la tarjeta de identificación válidos. <1><2>Vete a los detalles del inquilino y verifíquela</2></1>.",
          "20013": "• La propiedad no tiene información de alarma. <1><2>Vete a los detalles de la propiedad y verifícalo</2></1>.",
          "20014": "• La propiedad no tiene el año de construcción <1><2>Vete a los detalles de la propiedad y verifícalo</2></1>.",
          "20015": "• La propiedad no tiene la información de nivel de planta.<1><2> Vete a los detalles de la propiedad y verifícalo</2></1>.",
          "20016": "• La propiedad no tiene información sobre el tipo especifico de propiedad.<1><2> Vete a los detalles de la propiedad y verifícalo</2></1>.",
          "20017": "• La propiedad no tiene la dirección completa.<1><2> Vete a los detalles de la propiedad y actualízala</2></1>.",
          "20018": "• La propiedad no tiene la dirección completa.<1><2> Vete a los detalles de la propiedad y actualízala</2></1>.",
          "20019": "• La propiedad no tiene el número de calle en la dirección.<1><2> Vete a los detalles de la propiedad y actualízala</2></1>.",
          "20020": "• La propiedad una dirección completa.<1><2> Vete a los detalles de la propiedad y actualízala</2></1>.",
          "3000": "- No se ha podido conectar con el proveedor externo, inténtelo de nuevo más tarde.",
          "600003": "• El solicitante no tiene una fecha de nacimiento válida.<1><2> Vete a Detalles del inquilino y verifícalo</2></1>.",
          "600004": "• El solicitante no tiene un número de identificación válido.<1><2> Vete a Detalles del inquilino y verifícalo</2></1>.",
          "600006": "• El solicitante no tiene número de teléfono válido.<1><2> Vete a Detalles del inquilino y verifícalo</2></1>.",
          "600007": "• El solicitante no tiene apellidos.<1><2> Ve a los detalles del inquilino y verifícalo.</2> </1>",
          "600008": "• El solicitante no tiene dirección actual.<1><2> Vete a los detalles del inquilino y verifícalo.</2> </1>",
          "600009": "• El solicitante no tiene un IBAN válido.<1><2> Vete a los detalles del inquilino y verifícalo.</2> </1>",
          "600010": "• El solicitante no tiene un tipo de identificación válido.<1><2> Vete a los detalles del inquilino y verifícalo</2></1>.",
          "600012": "• El solicitante no tiene nacionalidad.<1><2> Vete a los detalles del inquilino y verifícalo</2></1>.",
          "86": "• La propiedad no tiene una dirección completa. <1><2>Vete a Detalles de la unidad y actualízala</2></1>."
        },
        errorMessage: "Se ha producido un error al enviar los cambios de suministros al proveedor:",
        errorMessageOnValidation: "Antes de enviar la solicitud de cambio de suministros, debes verificar la siguiente información:"
      },
      homeinsurance: {
        issuePlan: {
          acceptPlan: "Aceptar condiciones del seguro",
          capitalContent: "Valor del contenido: {{value}}",
          capitalContinent: "Valor del contingente: {{value}}",
          civilLiability: "Valor de responsabilidad civil: {{value}}",
          downloadPlanDraft: "Descargar borrador",
          inProgressMessage: "Por favor, espera mientras nos comunicamos con nuestro proveedor para preparar los planes de seguro de hogar de esta solicitud.",
          planCoverage: "Coberturas del seguro",
          selectPlan: "¿Qué seguro te gustaría contratar?"
        },
        options: {
          reject: "Rechazar seguro de hogar",
          securityDoor: "¿La propiedad tiene puerta de seguridad?",
          windowsBars: "¿La propiedad tiene rejas de seguridad en las ventanas?"
        }
      },
      instructions: "Estos son los servicios que ha elegido el inquilino. Te mantendremos informado sobre el estado de cada uno de ellos. Si se produce algún incidente, le informaremos.",
      internet: {
        capacity: "Capacidad Fibra + Móvil",
        capacityPlaceholder: "Seleccione la capacidad",
        company: "Compañía",
        companyPlaceholder: "Selecciona una compañía",
        result: {
          detail: "<1>Detalles </1> {{detail}}",
          docsRequirement: "<1>Requerimientos</1> {{requirements}}",
          permanence: "<1>Permanencia</1>{{permanence}} meses",
          price: "<1>Precio</1> {{price}} €/mes"
        },
        selectOptions: "Seleccione la opción de proveedor de Internet para el inquilino"
      },
      manageOtherSuppliesSection: {
        title: "Añade algunos otros suministros para gestionarlos"
      },
      p2p: {
        manager: {
          instructionsNoResponse: "Hemos preguntado al propietario y al inquilino qué suministros quieren que gestionemos. Te notificaremos cuando nos respondan.",
          instructionsResponse: "Estos son los suministros que ha elegido <strong>{{role}}</strong>. Puedes actualizar el estado de cada uno de ellos.",
          noWantSuppliesManagement: "El <strong>{{role}}</strong> ha decidido no gestionar los suministros."
        },
        owner: {
          helpModal: {
            description: "1. Elige los suministros que quieres contratar o transferir con nosotros. <br><br>2. Llamaremos a tu inquilino y le ofreceremos lo mejor que podamos encontrar con respecto a los servicios que desea <br><br>3. Nos encargaremos de todo el papeleo y te mantendremos informado del proceso.",
            title: "Cómo funciona"
          },
          howDoesThisWorks: "¿Como funciona esto?",
          instructionsNoResponse: "Queremos asegurarnos que tus inquilinos se instalen de la mejor manera posible. Para lograrlo, ofrecemos un servicio sencillo para poner en marcha los suministros de tu propiedad sin casi ningún papeleo. Por eso, tanto tú como tus inquilinos podréis elegir los suministros que queráis que gestionemos.",
          noThanks: "No, gracias",
          skipped: "Se ha omitido la gestión de suministros",
          suppliesRequested: "Mientras tanto, te mantendremos informado sobre el estado de cada suministro. En algunos casos, este proceso puede tardar hasta un mes."
        }
      },
      preferredTimeToCall: {
        title: "Horario de preferencia para llamar al inquilino"
      },
      supplies: "Suministros",
      tenantInfo: "<1>El inquilino</1> ha decidido no gestionar los servicios y suministros. A continuación puedes seleccionar los suministros para tu propiedad.",
      title: "Instalación",
      water: {
        company: "Compañía",
        companyPlaceholder: "Selecciona una compañía",
        result: {
          docsRequirement: "<1>Requerimientos</1> {{requirements}}"
        },
        selectOptions: "Seleccione la opción de proveedor de agua para el inquilino"
      }
    },
    newApplication: "Nueva solicitud",
    newApplicationModal: {
      applicantDetails: "Datos del solicitante",
      chooseApplicant: "Elige un solicitante",
      create: "Crear solicitud",
      created: "Solicitud creada correctamente",
      formerApplicant: "Antiguo solicitante",
      newApplicant: "Nuevo solicitante"
    },
    owner: {
      applicantsInformation: {
        applicants: "Inquilinos/as",
        description: "Estamos solicitando toda esta información a los solicitantes. Una vez la complementen, podrás ver su puntuación y tomar una decisión.",
        employmentSituation: {
          modal: {
            contractType: "Tipo de contrato",
            description: "Salario y condiciones de trabajo",
            income: "Ingresos brutos mensuales",
            startDate: "Desde"
          }
        },
        title: "Información de los solicitantes"
      }
    },
    payments: {
      backTransferSlip: "Comprobante de transferencia bancaria",
      errorMessage: "No existe contrato de gestión. Para solicitar pagos, primero debes crear un contrato.",
      form: {
        defaultMonthlyRent: "Alquiler mensual ({{value}}{{unit}})",
        firstMonthlyRent: "Primera mensualidad del alquiler",
        otherAmount: "Otra cantidad ({{value}}{{unit}})"
      },
      includeDepositInPayout: "Inclúyelo en liquidaciones (para los propietarios que deben pagar la fianza por sí mismos)",
      includeInPayout: "Inclúyelo en liquidaciones",
      info: {
        deposit: "La solicitud de reserva está disponible en la pestaña de pagos",
        payments: "La solicitud del primer pago está disponible en la pestaña de pagos"
      },
      ownerWithNoBillingDataError: {
        asOwner: "No has completado tus datos de facturación. Para continuar, ve a <1>tu perfil</1> y complétalo antes de solicitar el pago.",
        others: "Este propietario no tiene completos sus datos facturación. Para continuar debes ir al <1><2>perfil del propietario</2></1> y completarlos antes de solicitar el pago."
      },
      paymentCompletedMessage: "Pago completado.",
      paymentsFormDescription: "Introduzca la cantidad necesaria para que el solicitante reserve esta propiedad y continúe con la solicitud.",
      paymentsRequested: "Pagos solicitados con éxito",
      paymentsRequestedMessage: "Pago solicitado. Una vez que el importe sea ingresado, deberás validar y conciliar este pago.",
      reconciliatePayment: "Reconciliar pago",
      registerPayment: "Registrar pago",
      requestPayments: "Solicitar pagos",
      tooltips: {
        cancelled: "Pago cancelado",
        "default": "Pago en mora",
        exported: "Pago exportado",
        paid: "Pago efectuado",
        partially_paid: "Pago parcialmente pagado",
        pending_approval: "Pago pendiente de aprobación",
        to_validate: "Pago a validar",
        waiting: "A la espera del pago"
      }
    },
    personalDetails: "Datos personales",
    personalNote: "Nota personal",
    preScoringBadge: "¡Cumple todas las preferencias!",
    preScoringNotMatchedBadge: "No cumple todas las preferencias",
    preScoringRejectReasons: {
      contractType: "El solicitante busca otro tipo de contrato",
      income: "Los ingresos totales no alcanzan el mínimo exigido",
      pets: "El solicitante ha seleccionado una política de mascotas diferente",
      tenantProfile: "El perfil del inquilino no coincide",
      tenantsTotal: "La cantidad total de inquilinos supera el máximo permitido"
    },
    profileValidated: "Perfil validado",
    rollBackStep: {
      contractSigning: {
        button: "Volver a la verificación de solvencia",
        description: "¿Necesita modificar la verificación de solvencia?"
      }
    },
    searchBuilding: {
      hint: "Edificios recientes",
      placeholder: "Buscar edificio",
      title: "Asignar un edificio"
    },
    skipStep: {
      backgroundCheck: {
        button: "Omitir el paso de verificación de solvencia",
        confirmationDescription: "¿Seguro que quieres omitir el paso de verificación de solvencia? Esta acción no se puede deshacer.",
        confirmationTitle: "Omitir paso de verificación de solvencia",
        description: "¿No necesitas comprobar los antecedentes?",
        skippedSection: "Se ha omitido la verificación de solvencia"
      },
      commercialisationPayment: {
        button: "Omitir el paso de los honorarios de comercialización",
        confirmationDescription: "¿Seguro que quieres omitir el paso de los honorarios de comercialización? Esta acción no se puede deshacer.",
        confirmationTitle: "Omitir el paso de los honorarios de comercialización",
        description: "¿No necesitas solicitar los honorarios de comercialización?",
        skippedSection: "Se ha omitido los honorarios de comercialización"
      },
      confirmNo: "No, mantenerlo",
      confirmYes: "Si, omitir",
      contractSigning: {
        button: "Omitir paso de firma de arrendamiento",
        confirmationDescription: "¿Seguro que quieres omitir el paso de firma del contrato de arrendamiento? Esta acción no se puede deshacer.",
        confirmationTitle: "Omitir paso de firma de contrato de alquiler",
        description: "¿No necesitas firmar el contrato de arrendamiento?",
        skippedSection: "Se ha omitido la firma del contrato de arrendamiento"
      },
      deposit: {
        button: "Omitir paso de reserva",
        confirmationDescription: "¿Seguro que quieres omitir el paso de la reserva? Esta acción no se puede deshacer.",
        confirmationTitle: "Omitir paso de reserva",
        description: "¿No necesita solicitar una reserva?",
        skippedSection: "Se ha omitido la reserva"
      },
      moveIn: {
        button: "Omitir el paso de instalación",
        confirmationDescription: "¿Seguro que quieres saltarte el paso de instalación? Esta acción no se puede deshacer.",
        confirmationTitle: "Omitir el paso de instalación",
        description: "¿No necesitas gestionar el cambio de suministros?",
        skippedSection: "El cambio de suministros ha sido omitido"
      },
      payments: {
        button: "Omitir pagos",
        confirmationDescription: "¿Seguro que quieres saltarte el paso de los pagos? Esta acción no se puede deshacer.",
        confirmationTitle: "Omitir paso de pagos",
        description: "¿No necesitas solicitar los pagos?",
        skippedSection: "Se han omitido los pagos"
      },
      viewings: {
        button: "Omitir paso de visitas",
        confirmationDescription: "¿Seguro que quieres omitir las visitas? Esta acción no se puede deshacer.",
        confirmationTitle: "Omitir paso de visitas",
        description: "¿No necesitas programar una visita?",
        skippedSection: "Se han omitido las visitas"
      }
    },
    starting: "Fecha de inicio {{formattedDate}}",
    table: {
      empty: "Aún no hay aplicaciones :)"
    },
    title: "Solicitudes",
    utilities: {
      alarm: "Alarma",
      electricity: "Electricidad",
      gas: "Gas",
      homeInsurance: "Seguro de hogar",
      insurance: "Seguro",
      internet: "Internet",
      water: "Agua"
    },
    utilityStates: {
      completed: "Completado",
      dismissed: "Dispensado",
      inProgress: "En progreso",
      incident: "Incidente",
      pending: "Pendiente",
      processed: "Tramitado",
      rejected: "Rechazado"
    },
    utilityStatesCaptions: {
      completed: "<1>Completado</1>: Alta finalizada en las compañías proveedoras.",
      inProgress: "<1>En progreso</1>: La solicitud se ha registrado correctamente. Iniciamos el trámite.",
      incident: "<1>Incidente</1>: Ha habido un problema, se informa del detalle (por ejemplo, no se ha podido contactar con el nuevo titular).",
      processed: "<1>Tramitado</1>: Se ha realizado el trámite, en los próximos días se realizará el alta definitiva con las compañías proveedoras.",
      rejected: "<1>Rechazado</1>: El trámite se ha cancelado (por ejemplo, el nuevo titular decidió realizar el trámite por su cuenta)."
    },
    utilityStatesCaptionsTitle: "¿Qué significa cada estado?",
    viewDetails: "Ver detalles",
    viewings: {
      addSchedulingViewingModal: {
        commercialOption: "La visita será realizada por un agente",
        cta: "Programar",
        ownerAccountOption: "La visita será realizada por el propietario",
        title: "Programar una visita"
      },
      address: "Dirección",
      agent: "Agente",
      agentAreaLabel: "Área donde el agente presta servicio",
      agentFields: {
        whoWillMake: "¿Quién realizará la visita?"
      },
      agentModal: {
        changeAgent: "Cambiar agente",
        placeholder: "Buscar agente",
        suggestedResultsHint: "Agentes recientes",
        title: "Asignar agente"
      },
      agentName: "Nombre del agente",
      agentType: {
        commercial: "Agente",
        ownerAccount: "Propietario"
      },
      availabilityFields: {
        addressVisibility: "Visibilidad de la dirección",
        howMuchTime: "¿Cuánto tiempo dura cada visita?",
        includeVideoCall: "Incluye visitas a través de videollamadas",
        schedulingThreshold: "¿Con cuánta antelación se puede agendar?",
        slotCapacity: "¿Cuántos solicitantes al mismo tiempo?",
        withSlotCapacity: "Permitir visitas simultáneas (más de un solicitante al mismo tiempo)"
      },
      cancelViewingModal: {
        cancelLabel: "Volver atrás",
        confirmationLabel: "Sí, cancelar la visita",
        description: "¿Seguro que quieres cancelar esta visita?",
        notification: "Se ha cancelado la visita",
        title: "Cancelar visita"
      },
      cancelledViewing: {
        applicant: "solicitante",
        by: "por",
        cancelled: "Cancelado",
        cancelledBy: "Cancelado por",
        organization: "organización",
        owner: "propietario",
        rescheduleViewing: "Reprogramar visita"
      },
      commercial: "Comercial",
      commercialModal: {
        changeCommercial: "Cambiar comercial",
        placeholder: "Buscar commercial",
        suggestedResultsHint: "Comerciales recientes",
        title: "Asignar comercial"
      },
      commercialName: "Nombre del comercial",
      confirmed: "Confirmada",
      date: "Fecha",
      dateAndHour: "Fecha y hora",
      door: "Puerta",
      floor: "Planta",
      hour: "Hora",
      location: "Ubicación",
      markedNoSuitableSchedule: "El inquilino ha marcado que no le encaja ningún horario.",
      noShowViewing: {
        admin: "organización",
        agent: "Agente",
        applicant: "Solicitante",
        buildingManager: "Gestor del edificio",
        by: "por el",
        confirmNo: "No, déjala así",
        confirmYes: "Sí, marcar como no presentada",
        noShow: "Marcado como no enseñado",
        owner: "Propietario",
        sure: "¿Seguro que quieres marcar esta visita como no presentada? Esta acción no se puede deshacer."
      },
      owner: "Propietario",
      reviewViewing: {
        agent: {
          afterAgentNote: {
            onboardingPromoted: "El solicitante ha finalizado este paso, si finalmente, el solicitante alquila esta propiedad nos pondremos en contacto, gracias.",
            reviewSent: "Se comunicó al gerente, gracias."
          },
          didTheAgentDoneTheViewing: "¿El agente hizo la vista?",
          didTheOwnerDoneTheViewing: "¿El propietario hizo la visita?",
          haveYouDoneThisViewing: "¿Has hecho la visita?",
          message: "Mensaje",
          no: "No",
          noIDidNot: "No, no la hice",
          placeholder: "Ej. Al inquilino no le gusta la orientación noreste...",
          sendToManager: "Enviar comentarios",
          sentCTA: "Ir a Zazume.com",
          sentDescription: "Hemos recibido tu feedback con éxito.",
          sentTitle: "¡Muchas gracias!",
          tellUsHowItWas: "Cuéntanos cómo fue",
          yes: "Sí",
          yesIDidIt: "Sí, la hice"
        },
        haveYouDoneThisViewing: "¿Has hecho la visita?",
        markAsNoShow: "Marcar como no enseñado",
        noButton: "No, reprogramar una nueva visita",
        pageDescription: "Gracias por realizar las visitas. Por favor, facilítanos tu opinión de las visitas, cómo ha ido con los solicitantes, y si han mostrado interés, etc.",
        progress: "Has completado {{completed}} de {{total}} valoraciones",
        reviewSent: "Valoración enviada, esperando al solicitante",
        yesButton: "Marcar como completado"
      },
      reviewedViewing: {
        interested: {
          no: "No",
          placeholder: "Selecciona una opción...",
          question: "¿Quieres seguir adelante con esta propiedad?",
          yes: "Sí"
        },
        message: "Mensaje",
        options: {
          badAppliances: "Los electrodomésticos no satisfacen mis necesidades",
          badBeds: "Camas viejas o en mal estado",
          badPlumbing: "La fontanería no funciona bien",
          badWalls: "Las paredes están en mal estado",
          badWindows: "Las ventanas no se cierran correctamente",
          higherExpectations: "Las imágenes no mostraban la realidad",
          noisy: "Es un barrio ruidoso",
          oldFurniture: "El mobiliario era viejo",
          question: "¿Hay algo que no te gusta?",
          tooDark: "Era muy oscuro",
          tooExpensive: "Demasiado caro para mí"
        },
        pricing: "¿Cuál es el precio adecuado para ti?",
        title: "ha valorado la visita"
      },
      scheduleViewing: {
        button: "Programar visita",
        description: "Programe una visita con el solicitante"
      },
      scheduleViewingForOwnerModal: {
        goBack: "Volver atrás",
        notification: "Se ha programado la visita",
        rescheduleDescription: "Introduce la nueva fecha y hora para esta visita.",
        scheduleButton: "Programar visita",
        scheduleDescription: "Introduce la nueva fecha y hora para esta visita.",
        title: "Programar una nueva visita"
      },
      scheduleViewingModal: {
        goBack: "Volver atrás",
        howMuchTime: "¿Cuánto tiempo dura la visita?",
        notification: "Se ha programado la visita",
        rescheduleDescription: "Introduce la nueva fecha y hora para esta visita.",
        scheduleButton: "Programar visita",
        scheduleDescription: "Introduce la nueva fecha, hora y agente para esta visita.",
        title: "Programar una nueva visita",
        warningMessage: "¡Vaya! La fecha que has escogido coincide con otra visita a las {{time}}. Escoge otra fecha y hora o modifica la otra visita"
      },
      scheduleViewings: "Visitas agendadas",
      states: {
        reschedule: "Por agendar"
      },
      title: "Visitas",
      unitTimezoneInfo: "Este calendario utiliza la zona horaria de la propiedad:",
      viewInMaps: "Ver ubicación en Maps",
      viewingDetails: {
        addressVisibility: "¿Qué información de la dirección debe ser visible?",
        cancelButton: "Cancelar visita",
        face_to_face: "Presencial",
        rescheduleButton: "Reagendar visita",
        scheduleTypeLabels: {
          title: "¿Cómo quieres programar esta visita?",
          withAvailability: "Selecciona a partir de la disponibilidad de la propiedad",
          withoutAvailability: "Selecciona una fecha libre"
        },
        simultaneous: "simultánea",
        title: "Detalles de la visita",
        video_call: "Videollamada",
        viewingTypeLabel: "¿Cómo te gustaría hacer la visita?",
        whoMakesVisit: "Quién hace la visita"
      }
    }
  },
  organizationServices: {
    addNew: "Añadir un servicio",
    detail: {
      configuration: {
        instructions: {
          text: "Esto añadirá un campo adicional en la app para que el residente agregue instrucciones al servicio reservado",
          title: "Instrucciones requeridas"
        },
        title: "Configuración del servicio"
      },
      formLabels: {
        availability: "Disponibilidad",
        availabilityEnd: "Hasta",
        availabilityStart: "Desde",
        cancellation: "Política de cancelación",
        during: "Durante",
        imgSupport: "Soporta .jpg y .png 2mb máx.",
        name: "Dale un nombre",
        playbook: "Playbook",
        price: "Establece un precio",
        selectCategory: "Selecciona una categoría",
        shortDescription: "Añadir una breve descripción",
        uploadImg: "Subir una imagen"
      },
      highlight: {
        add: "Añadir un destacado",
        description: "Destaque hasta 6 elementos del servicio para ayudar a sus clientes a entender su valor.",
        title: "Aspectos destacados"
      },
      policy: {
        created: "Política de cancelación creada",
        hoursLabel: "Horas límite para la cancelación con derecho a reembolso",
        save: "Guardar política",
        summaryLabel: "Resumen",
        summaryPlaceholder: "Resumen que se mostrará en la aplicación",
        updated: "Política de cancelación actualizada",
        write: "Escribir política de cancelación"
      },
      product: {
        add: "Añadir producto",
        addMore: "Añadir más",
        addTitle: "Añadir un nuevo producto",
        added: "Producto añadido correctamente",
        descriptionLabel: "Descripción",
        duplicate: "Duplicar",
        edit: "Editar producto",
        editTitle: "Editar un producto",
        hidden: "Producto ocultado correctamente",
        hide: "Ocultar producto",
        imageLabel: "Sube una imagen",
        information: "Información del producto",
        nameLabel: "Nombre de visualización",
        priceLabel: "Precio recomendado",
        productURL: "URL del producto",
        productURLHelp: "La URL de la página de pago del producto o de más información",
        removeTitle: "¿Eliminar producto?",
        removeWarning: "Esto eliminará permanentemente el elemento seleccionado de esta lista y todos los servicios publicados. Esta acción no se puede deshacer.",
        removed: "Producto eliminado correctamente",
        save: "Guardar producto",
        stripePriceIdLabel: "ID de precio de Stripe",
        unhidden: "Producto desocultado correctamente",
        unhide: "Desocultar producto",
        updated: "Producto actualizado correctamente"
      },
      service: {
        editTitle: "Editar un servicio",
        updated: "Servicio actualizado correctamente"
      },
      task: {
        add: "Añadir tarea",
        addTitle: "Añadir una nueva tarea",
        added: "Tarea añadida correctamente",
        editTitle: "Editar una tarea",
        removeTitle: "¿Eliminar tarea?",
        removeWarning: "Esto eliminará la tarea seleccionada permanentemente de este servicio. Esta acción no se puede deshacer.",
        removed: "Tarea eliminada correctamente",
        updated: "Tarea actualizada correctamente"
      },
      title: "Detalle del servicio"
    },
    "new": {
      addHighlight: "Añadir destacado",
      highlights: "Destacados",
      imageHelp: "Soporta .jpg y .png 2mb máx.",
      instructions: "Instrucciones",
      instructionsDescription: "Habilitar un campo de texto obligatorio, para que el usuario pueda proporcionar instrucciones específicas para este servicio.",
      newCategory: "Selecciona una categoría",
      newCustomerTarget: "Selecciona el tipo de cliente",
      newDescription: "Añadir una breve descripción",
      newImage: "Sube una imagen",
      newInternalName: "Dale un nombre interno",
      newName: "Dale un nombre",
      newTitle: "Añadir servicio",
      saveButton: "Guardar servicio",
      serviceInformation: "Información del servicio",
      submit: "Añadir servicio"
    },
    noServices: "No se ofrecen servicios",
    products: "productos",
    setUp: "Configurar el servicio",
    subcategories: {
      addSubcategory: "Nueva subcategoría",
      deleted: "Subcategoría eliminada correctamente",
      hide: "Ocultar subcategoría",
      newModalSubmit: "Añadir subcategoría",
      newModalTitle: "Añadir una nueva subcategoría",
      removeModal: {
        description: "Esto eliminará de forma permanente la subcategoría seleccionada de este servicio. Esta acción no se puede deshacer.",
        title: "¿Eliminar subcategoría?"
      },
      rename: "Re-nombrar subcategoría",
      renameModal: {
        submitButton: "Actualizar subcategoría",
        title: "Renombrar subcategoría",
        updated: "Subcategoría actualizada correctamente"
      },
      unhide: "Desocultar subcategoría"
    },
    title: "Servicios"
  },
  organizations: {
    add: "Añadir Organización",
    detail: {
      added: "Organización creada",
      brand: "Marca",
      cardAllowed: "Se permite tarjeta",
      edit: "Editar organización",
      edited: "Organización editada",
      enableOnBoarding: "Módulo de On-Boarding habilitado",
      enabledExternalUtilitiesManagement: "Cambio de suministros gestionado por proveedor externo",
      incidents: "Incidencias",
      incidentsTutorials: "Tutoriales de incidencias",
      information: "Información general",
      modules: "Módulos",
      onBoarding: "On-Boarding",
      paymentCommunication: "Enviar notificaciones a los inquilinos",
      save: "Guardar organización",
      searchBrand: "Buscar marca",
      selectBrand: "Seleccionar marca",
      selectZazuHelper: "No se encontró ningún Zazú",
      selectZazuLabel: "Seleccionar Zazú"
    },
    edit: "Editar organización",
    helper: {
      logo: "Solo archivos JPG o PNG con un tamaño máximo de {{maxFileSizeMB}} MB",
      logoLandscape: "Solo archivos SVG con un tamaño máximo de {{maxFileSizeMB}} MB",
      zazuNotFound: "No se encontró a ningún Zazú."
    },
    invitationResend: "Reenviar invitación",
    onBoarding: {
      backgroundCheck: "Verificación de solvencia",
      contractSigning: "Firma de contrato",
      deposit: "Fianza",
      invited: "Invitados",
      moveIn: "Mudanza",
      viewings: "Visitas"
    },
    table: {
      emptyState: "Aún no se ha creado ninguna organización.",
      headers: {
        country: "País",
        defaultZazu: "Zazu predeterminado",
        email: "Email del administrador (propietario)",
        logo: "Marca (relación de aspecto cuadrada)",
        logoLandscape: "Marca (relación de aspecto panoramica)",
        name: "Nombre",
        onBoarding: "On-Boarding"
      }
    },
    title: "Organizaciones"
  },
  ownerAccount: {
    add: "Añadir propietario",
    addModal: {
      categories: "Elegir categorías",
      currentAddress: "Dirección actual",
      mandatory: "Campo obligatorio",
      paymentDetails: "Detalles de pago",
      personalDetails: "Datos personales",
      send: "Enviar invitación",
      source: {
        label: "Origen",
        options: {
          b2b2c: "B2B2C",
          chat: "Chat",
          organic: "Orgánico",
          phone: "Teléfono",
          referral: "Recomendación"
        }
      },
      systemSettings: "Configuraciones del sistema",
      tenantLanguage: "Idioma",
      title: "Añadir nuevo propietario",
      workspaces: "Acceso a workspaces (opcional)"
    },
    addOwnerAccountAndLiteUnitModal: {
      confirmation: {
        header: "¡Genial! Casi has terminado.",
        listDescription: "Una vez enviado el formulario ocurrirá lo siguiente:",
        listStep1: "Revisaremos todo y <1>nos pondremos en contacto con el propietario/a</1> para informarle de nuestros servicios.",
        listStep2: "Una vez firmemos el contrato con él o ella te informaremos para que puedas recibir tus honorarios.",
        listStep3: "No dudes en <1> añadir documentos o fotos </1> en la nueva propiedad creada si lo necesitas.",
        title: "Confirmación"
      },
      createdMessage: "Operación realizada correctamente",
      initialConsent: {
        CTA: "Empezar",
        checkboxLabel: "Si, tengo el consentimiento del propietario/a para compartir sus datos de contacto con Zazume",
        description: "Al añadir información sobre terceras personas en la plataforma necesitamos asegurarnos que tienes el consentimiento para compartir esta información con el equipo de Zazume.",
        title: "Antes de empezar..."
      },
      ownerData: {
        title: "Datos del propietario"
      },
      serviceData: {
        commercializationService: "Comercialización del alquiler con nuevos inquilinos/as",
        managementService: "Gestión del alquiler con sus inquilinos/as actuales",
        moreInformation: "Información adicional",
        notes: "Si quieres puedes añadir cualquier otra información que debamos saber antes de contactar con el propietario/a.",
        notesPlaceholder: "Escribe la información aqui\n",
        rentAdvanceService: "Adelanto de rentas",
        serviceQuestion: "¿Qué servicio le interesa al propietario/a?",
        serviceUseDescription: "Marca tantas opciones como quieras",
        title: "Servicios"
      },
      unitData: {
        address: "Dirección de la propiedad",
        addressQuestion: "¿Conoces la dirección de la vivienda?",
        addressWarning: "Si incluyes la dirección de la vivienda esta será creada en tu página de propiedades.",
        monthlyRent: "Alquiler mensual",
        monthlyRentQuestion: "¿Conoces de cúanto es el alquiler mensual?",
        title: "Detalles de la propiedad"
      }
    },
    detail: {
      address: "Dirección actual",
      contactNumber: "Número de contacto",
      creditorIdentifier: "Identificador del acreedor",
      email: "Email",
      firstName: "Nombre",
      globalNotifications: "Notificaciones globales",
      iban: "IBAN",
      idNumber: "Número de identificación",
      language: "Idioma",
      ledgerAccount: "Cuenta contable",
      noNotificationSettings: "El usuario no tiene configuración de notificaciones",
      notificationSettings: "Configuración de notificaciones",
      options: {
        editNotificationSettings: "Editar la configuración de notificaciones",
        uploadUnitLink: "Copiar enlace de creación de propiedad"
      },
      paymentDetails: "Detalles del pago",
      personalDetails: "Datos personales",
      role: "Rol",
      surname: "Apellido",
      systemSettings: "Configuraciones del sistema",
      tabs: {
        about: "Sobre",
        entities: "Sociedades",
        ownersUnits: "Propiedades del propietario",
        units: "Propiedades"
      },
      title: "Detalles del propietario",
      typeOfId: "Tipo de identificación"
    },
    editModal: {
      ledgerAccount: {
        label: "Cuenta contable",
        placeholder: "Cuenta contable",
        tooltip: "Este campo sirve para asignar un número de identificación para la cuenta contable asociada a este propietario. A su vez, una cuenta contable es un registro que permite balancear la contabilidad de la empresa."
      },
      title: "Editar propietario"
    },
    entity: {
      add: "Añadir sociedad",
      addModal: {
        addCoOwner: "Añadir copropietario",
        entityName: "Nombre de la sociedad",
        entityNameAndType: "Nombre y tipo de sociedad",
        entityType: "Tipo de Sociedad",
        entityTypePlaceholder: "Selecciona un tipo",
        owners: "Propietarios",
        repeatedCoOwners: "Copropietarios repetidos",
        selectCoOwner: "Selecciona copropietario",
        selectOwner: "Selecciona propietario",
        selectOwnerAdmin: "Selecciona administrador",
        title: "Añadir nueva Sociedad"
      },
      detail: {
        edit: "Editar sociedad",
        editOwners: "Editar propietarios",
        name: "Nombre de la sociedad",
        ownerAdmin: "Propietario administrador",
        tabs: {
          about: "Sobre",
          units: "Propiedades"
        },
        title: "Detalle de la sociedad",
        type: "Tipo de sociedad"
      },
      editParticipantsModal: {
        markAsAdministrator: "Marcar como administrador",
        title: "Editar propietarios"
      },
      table: {
        headers: {
          members: "Miembros",
          units: "Propiedades"
        }
      },
      types: {
        communityOfGoods: "Comunidad de bienes",
        ltd: "Sociedad Limitada"
      }
    },
    invitationAccepted: "Se aceptó invitación",
    invitationNotSent: "Invitación no enviada",
    invitationPending: "Invitación pendiente",
    invitationResend: "Volver a enviar",
    invitationSent: "Invitación enviada",
    invite: "Invitar a un propietario",
    pending: "Invitación pendiente",
    removeDescription: "Esto eliminará la cuenta de propietario seleccionada. Esta acción no se puede deshacer.",
    removeTitle: "¿Eliminar propietario?",
    removed: "Cuenta de propietario eliminada",
    roleHelpMessage: {
      ownerBasic: "Los propietarios con este rol pueden ver las métricas y las incidencias en el panel. Ver propiedades. Ver documentos de la propiedad. Ver residentes y ver inventario.",
      ownerManager: "Los propietarios con este rol pueden ver, editar y rechazar solicitudes. Ver métricas e incidencias en el tablero. Ver y editar incidentes, enviar mensajes sobre incidencias. Ver, agregar, editar y eliminar propiedades. Ver y editar documentos de la propiedad. Vea, agregue y edite residentes, envíe invitaciones, vea y edite documentos de inquilinos. Ver y enviar mensajes. Ver inventario, crear, editar y eliminar artículos de inventario. Ver pagos, solicitar, registrar, editar, cancelar y eliminar pagos",
      ownerMiddle: "Los propietarios con este rol pueden ver las aplicaciones. Ver métricas e incidencias en el tablero. Ver incidencias. Ver y agregar propiedades. Ver documentos de la propiedad. Ver residentes, ver documentos de inquilinos. Ver inventario y ver pagos."
    },
    sendInvite: "Enviar invitación",
    table: {
      options: {
        remove: "Eliminar",
        view: "Ver cuenta de propietario"
      }
    },
    title: "Cuentas de propietario"
  },
  ownerAccountSettings: {
    title: "Ajustes de la cuenta"
  },
  ownerBooking: {
    ownerBookingDetail: {
      purchaseDate: "Fecha de compra",
      selectedService: "Servicio seleccionado"
    }
  },
  ownerDashboard: {
    cashflow: "Flujo de caja",
    modals: {
      cashOnCash: {
        description: "El 'cash on cash' es básicamente la cantidad de dinero en efectivo que estás sacando de una inversión inmobiliaria cada año, comparado con la cantidad de dinero que pusiste inicialmente para comprar la propiedad. Sirve para medir qué tan rápido estás recuperando tu inversión inicial.",
        link: "Descubre qué es el cash on cash y qué gastos debes incluir",
        title: "Cash on cash"
      },
      confirm: "De acuerdo",
      grossProfitability: {
        description: "La rentabilidad bruta es el ingreso total que obtienes de alquilar una propiedad antes de descontar todos los gastos, por decirlo de otro modo, el dinero que entra antes de pagar lo que toca.",
        link: "Descubre cómo se calcula la rentabilidad del alquiler y qué gastos debes incluir",
        title: "Rentabilidad bruta"
      },
      missingInfo: {
        acquisition: {
          bold: "Los detalles de adquisición de la vivienda",
          link1: "ir al formulario de edición de la propiedad.",
          part1: "para ello debes"
        },
        expenses: {
          bold: "Gastos de la vivienda",
          link1: "ir a la página de contabilidad",
          part1: "para ello necesitas",
          part2: "y registrar los gastos de tu vivienda (impuestos, mantenimiento, servicios de Zazume, letra de la hipoteca....)"
        },
        incomes: {
          bold: "Ingresos anuales de la vivienda",
          link1: "ir a la pestaña de Contratos",
          link2: "ir a la página de Contabilidad",
          part1: "para ello necesitas",
          part2: "y registrar un contrato o",
          part3: "y añadir los ingresos manualmente."
        },
        mortgage: {
          bold: "La cantidad total de tu hipoteca",
          link1: "ir al formulario de edición de la propiedad en la sección de detalles de la adquisición, sección hipotecas.",
          part1: "para ello debes"
        },
        title: "Para poder calcularlo necesitamos que completes la siguiente información:"
      },
      netProfitability: {
        description: "La rentabilidad neta es el dinero que realmente te queda después de restar todos los gastos, como impuestos, mantenimiento y otros costos relacionados con el alquiler.",
        link: "Descubre cómo se calcula la rentabilidad del alquiler y qué gastos debes incluir",
        title: "Rentabilidad neta"
      }
    },
    profitAndLoss: "Cuenta de resultados",
    rentAdvance: {
      amortization: "Amortización",
      monthlyRent: "Alquiler mensual",
      sectionTitle: "Propiedad {{unitName}}",
      title: "Adelanto de rentas",
      totalTranfer: "Total cesión "
    },
    rows: {
      accounting: {
        monthly: {
          cashflow: "Flujo de caja mensual",
          profitAndLoss: "Cuenta de resultados mensual"
        },
        profitability: {
          alert: "La información que aparece es una estimación basada en los datos introducidos durante este año en base a los ingresos y gastos de los meses anteriores.",
          cashOnCash: {
            hint: "Rentabilidad",
            title: "Cash on cash"
          },
          grossProfitability: {
            hint: "Rentabilidad",
            title: "Rentabilidad bruta"
          },
          missingInfo: "Falta información por completar",
          netProfitability: {
            hint: "Rentabilidad",
            title: "Rentabilidad neta"
          },
          rentIncomes: {
            title: "Rentas"
          },
          title: "Rentabilidad anual de mis propiedades"
        },
        yearly: {
          cashflow: "Flujo de caja anual",
          profitAndLoss: "Cuenta de resultados anual"
        }
      }
    },
    shareProfitability: {
      tweet: {
        description_one: "Llevo {{years}} año alquilando con @Zazume_es con:\n🏠 ingresos de alquiler {{rentIncomes}} mensuales\n📈 una rentabilidad bruta del {{grossProfitability}} ({{netProfitability}} neto)\n💰 un cash on cash de {{cashOnCash}}\n\n¡Únete a la revolución del #alquiler y llévate hasta 100€ a través de mi enlace!",
        description_other: "Llevo {{years}} años alquilando con @Zazume_es con:\n🏠 ingresos de alquiler {{rentIncomes}} mensuales\n📈 una rentabilidad bruta del {{grossProfitability}} ({{netProfitability}} neto)\n💰 un cash on cash de {{cashOnCash}}\n\n¡Únete a la revolución del #alquiler y llévate hasta 100€ a través de mi enlace!",
        hashtags: "alquiler"
      }
    }
  },
  ownerLead: {
    details: {
      contactNumber: "Teléfono",
      name: "Nombre",
      source: "Origen",
      title: "Detalles",
      unit: "Propiedad"
    },
    promoteModal: {
      email: "Email",
      promote: "Promocionar",
      title: "Promocionar a Propietario"
    },
    promoted: "Futuro propietario promocionado",
    removed: "Candidato a propietario eliminado",
    table: {
      headers: {
        name: "Nombre",
        phone: "Teléfono",
        source: "Origen",
        unit: "Propiedad"
      },
      options: {
        promote: "Promocionar"
      }
    },
    title: "Futuro propietario"
  },
  ownerServiceContracts: {
    broken: {
      alert: "El contrato se ha roto debido a <1>{{brokenReason}}</1>",
      breakContractConfirmation: {
        modal: {
          title: "¿Quieres romper el contrato de {{serviceName}} actual?"
        }
      },
      reasons: {
        custom: "Otra razón:",
        "plan-downgrade": "Downgrade del servicio",
        "plan-upgrade": "Upgrade del servicio",
        recommercialisation: "Recomercialización de la propiedad",
        "self-management-plan-migration": "El propietario prefiere la autogestión",
        unsatisfied: "El propietario no está satisfecho con el servicio"
      }
    },
    commercialization: {
      addCommercialization: {
        modal: {
          about: {
            title: "Sobre el servicio de comercialización"
          },
          addService: "Añadir servicio",
          addServiceSuccess: "El contrato de servicio se agregó correctamente",
          contract: {
            description: "Actualizar el documento",
            title: "Contrato de comercialización"
          },
          title: "Añadir servicio de comercialización"
        },
        name: "Comercialización"
      },
      detail: {
        hideOldContracts: "Ocultar contratos antiguos",
        modal: {
          basicInformation: "Información básica",
          monthRent: "{{months}} mes de alquiler",
          percentAnnualRent: "{{percentage}}% del alquiler anual",
          taxes: {
            included: "Incluido",
            retentions: "{{retentions}}% Retenciones",
            vat: "{{vat}}% IVA"
          },
          title: "Comercialización",
          typeOfService: "Búsqueda de inquilinos"
        },
        showOldContracts: "Mostrar contratos antiguos"
      },
      title: "Comercialización"
    },
    deleteModal: {
      description: "¿Seguro que quieres eliminarlo? Esta acción no se puede deshacer."
    },
    documentAttachedSuccessfully: "Documento adjuntado correctamente",
    management: {
      addManagement: {
        modal: {
          about: {
            title: "Sobre el servicio de gestión"
          },
          addService: "Añadir servicio",
          addServiceSuccess: "El contrato de servicio se agregó correctamente",
          contract: {
            description: "Actualizar el documento",
            title: "Contrato de gestión"
          },
          feeSelection: "Elige una tarifa de gestión",
          infoMessage: "<1>Falta información sobre las tarifas de configuración de la organización. Por favor, rellene estos datos en la </1><2> Configuración de la organización.</2> <1>Si no, puedes personalizarlo en cada propiedad nueva.</1>",
          paymentMethod: {
            selectBankAccount: "Selecciona una cuenta bancaria",
            selectBankAccountPlaceholder: "Selecciona una cuenta bancaria de la organización",
            title: "Método de pago"
          },
          serviceSelection: "Elige un servicio de gestión",
          title: "Añadir servicio de gestión"
        },
        name: "Gestión"
      },
      detail: {
        modal: {
          basicInformation: "Información básica",
          title: "Gestión"
        }
      },
      editManagement: {
        modal: {
          editService: "Editar contrato",
          editServiceSuccess: "El contrato de servicio se actualizó correctamente",
          title: "Editar servicio de gestión"
        }
      },
      title: "Gestión"
    },
    rentAdvance: {
      addRentAdvance: {
        modal: {
          about: {
            title: "Sobre la asignación de alquiler."
          },
          addService: "Agregar servicio",
          addServiceSuccess: "El contrato de servicio se agregó correctamente",
          contract: {
            description: "Actualizar el documento",
            signature: "Firma",
            signers: "Firmantes",
            title: "Contrato de anticipo de alquiler"
          },
          initialCommission: "Comisión inicial",
          monthsInAdvance: "Meses de antelación",
          noContracts: "Para agregar un servicio de anticipo de alquiler, debes tener un contrato de alquiler.",
          rentAssigment: "Asignación de alquiler:",
          startedAt: "Fecha a partir de la cual computan los pagos a cuenta del adelanto",
          title: "Añadir servicio de anticipo de alquiler",
          totalFee: "Tarifa total ({{value}}{{unit}}):",
          totalRent: "Alquiler total:"
        },
        name: "Anticipo de alquiler"
      },
      breakContract: {
        modal: {
          breakContract: "Documento de ruptura del contrato",
          breakContractSuccess: "El contrato se rompió con éxito",
          breakDetails: "Detalles de la ruptura",
          cta: "Romper contrato",
          endingContractDate: "Fecha de finalización del contrato",
          form: {
            contractDescription: "Sube el contrato y selecciona si este documento debe firmarse. <br/><br/>Una vez hecho, se enviará a los destinatarios para que lo firmen. Se adjuntará al contrato actual.",
            endingContractDateLabel: "Elige cuándo será el último día de este contrato",
            monthlyRentPaymentToLabel: "¿Dónde debe pagar el inquilino la renta mensual?"
          },
          monthlyRentPayment: "Pago mensual de alquiler",
          signature: "Firma",
          signers: "Firmantes",
          title: "Romper contrato"
        }
      },
      breakContractConfirmation: {
        modal: {
          confirmationCTA: "Sí, quiero",
          description: "Una vez iniciado el proceso, esta acción no se puede deshacer",
          personalNote: "Nota personal",
          personalNoteHelp: "Esta es una nota personal para los miembros del equipo de tu organización. Los propietarios no pueden verlas.",
          reason: "Da una razón para romper este contrato.",
          title: "¿Quieres romper el contrato de adelante de rentas actual?"
        }
      },
      detail: {
        modal: {
          basicInformation: "Información básica",
          title: "Anticipo de alquiler"
        }
      },
      listing: {
        empty: "Aún no se han creado adelantos de rentas",
        tooltip: {
          fee: "Comisión:",
          main: "Principal:",
          total: "Total pagado:"
        }
      },
      progressIncomes: {
        description: "Ingresos asociados al servicio de Adelanto de rentas."
      },
      status: {
        active: "Activo",
        activeOn: "Activo el {{date}}",
        activeUntil: "Activo hasta {{date}}",
        broken: "Roto",
        draft: "Borrador",
        error: "Error",
        expiredSignature: "Firma expirada",
        finished: "Finalizado",
        pendingSignature: "Pendiente de firma",
        rejected: "Rechazado",
        signRejected: "Firma rechazada"
      },
      title: "Anticipo de alquiler"
    },
    types: {
      Commercialisation: "Comercialización",
      Management: "Gestión",
      RentAdvance: "Anticipo de alquiler"
    }
  },
  ownerServices: {
    contractProductModal: {
      selectAUnit: "Seleccionar propiedad"
    },
    emptyList: "Aún no hay servicios disponibles",
    productCard: {
      buyButton: "Comprar",
      vatNotIncluded: "IVA no incluido"
    },
    productDetail: {
      breakdown: "Desglose",
      conditions: "Condiciones del servicio",
      title: "Detalle del servicio"
    }
  },
  ownerTaxes: {
    createSteps: {
      created: "Informe de impuestos creado con éxito",
      deductibleExpenses: {
        deductingAmountPastYearsTitle: "Importe pendiente de deducir de los ejercicios de otros años",
        deductingAmountTitle: "Importe que se aplica en esta declaración",
        description: "Los gastos deducibles del alquiler de una vivienda son todos aquellos que resultan imprescindibles para garantizar la habitabilidad de la vivienda. Entre estos estarían los gastos de conservación y reparación de la vivienda, las primas de seguros que protejan la casa (tanto el seguro de hogar como el de protección del alquiler), y también los gastos de servicios o suministros como agua, gas o luz.",
        expensesToDeductNegative: "No, no tengo",
        expensesToDeductPositive: "Sí, sí tengo",
        expensesToDeductTitle: "¿Tienes gastos a deducir de los ejercicios de otros años?",
        title: "Gastos deducibles"
      },
      propertyAmortization: {
        acquisitionAtTitle: "Fecha de la adquisición del inmueble",
        cadastralValueDescription1: "Para completar la información solicitada en este apartado necesitarás iniciar sesión con tu DNI en la",
        cadastralValueDescription2: "Sede Electrónica del Catastro",
        cadastralValueTitle: "Valor catastral",
        deductibleExpensesTitle: "Gastos deducibles",
        otherExpensesTitle: "Gastos inherentes a la adquisición",
        taxesTitle: "Impuestos inherentes a la adquisición",
        title: "Amortización del inmueble",
        typeInheritance: "Herencia, legado o donación",
        typeSale: "Compraventa o permuta",
        typeTitle: "¿Cómo adquiriste el inmueble?"
      },
      propertyData: {
        ownershipLabel: "Indica que porcentaje de propiedad tienes sobre el inmueble",
        ownershipTitle: "Propiedad del inmueble",
        title: "Datos del inmueble",
        unitTitle: "Selecciona la propiedad"
      },
      rentingData: {
        taxReductionDescription: "Según el articulo 23.2 de la ley del impuesto como arrendador tienes derecho a aplicarte una reducción del 60% sobre la base imponible de los rendimientos netos del alquiler de tu vivienda, siempre y cuando el arrendamiento cumpla estos requisitos:",
        taxReductionDescription1: "· El piso debe estar alquilado como vivienda habitual del inquilino, es decir, alquiler de larga estancia (a partir de de 12 meses)",
        taxReductionDescription2: "· Los rendimientos netos deben ser positivos, no negativos",
        taxReductionNegative: "No, no la tiene",
        taxReductionPositive: "Si, la tiene",
        taxReductionTitle: "¿El arrendamiento tiene derecho a una reducción?",
        title: "Datos del arrendamiento"
      }
    },
    currentYearWarning: "Este año todavía no ha finalizado por lo que los datos que aparecen no son fiables hasta que finalice el año.",
    disclaimer: "Aquí te mostramos un resumen del alquiler de tu(s) vivienda(s) para que a la hora de hacer la declaración de la renta te sea más fácil tener todos los datos y/o puedas exportar estos datos y pasárselos a tu contable.",
    edit: {
      actionButtonText: "Ir a los detalles de la propiedad",
      description: "Para editar esta información debes hacerlo desde los detalles de la propiedad seleccionada.",
      title: "¿Quieres editar esta información?",
      titles: {
        computableIncomes: "Ingresos computables",
        deductibleExpenses: "Gastos deducibles",
        leaseInformation: "Información sobre el arrendamiento",
        propertyAmortization: "Amortización del inmueble",
        propertyData: "Datos del inmueble"
      },
      warnings: {
        computableIncomes: "Los ingresos computables corresponden a la suma del alquiler mensual anual.",
        deductibleExpenses: "Algunos campos no pueden editarse porque son un cálculo automático de los gastos añadidos en el apartado de Contabilidad > Gastos de la plataforma. Para modificar o añadir algún gasto, debes hacerlo desde la página de Contabilidad.",
        leaseData: {
          "1": "Algunos campos no pueden editarse porque son un reflejo del",
          "2": "contrato de arrendamiento,",
          "3": "que por la naturaleza del mismo, no puede editarse.",
          secondMessage: {
            "1": "No hay un",
            "2": "contrato de arrendamiento vigente",
            "3": "y por tanto no podemos completar esta sección. Para añadir un contrato, ve a los detalles de tu propiedad y añadelo en la pestaña de contratos."
          }
        },
        propertyDetails: {
          "1": "Algunos campos no pueden editarse porque son un reflejo de la información añadida en",
          "2": "Para modificar o añadir información debes ir a los Detalles de la propiedad y clicar en"
        },
        propertyImprovements: {
          "1": "Para editar los importes correspondientes a las",
          "2": "Mejoras realizadas",
          "3": "deberás hacerlo desde la página de Contabilidad de la plataforma."
        }
      }
    },
    emptyState: {
      completeButton: "Completa la información",
      description: "Para ver tu informe de impuestos debes responder a las siguientes preguntas.",
      title: "Falta algo de información"
    },
    generalWarning: "Faltan algunos campos a rellenar. Para añadirlos, clica en <strong>Editar información</strong> en la sección que corresponda.",
    statementOfIncomes: {
      "export": {
        button: "Exportar impuestos",
        description: "Seleccionar año",
        error: "Se ha producido un error al exportar los impuestos",
        success: "En unos minutos recibirá un correo electrónico con el archivo de impuestos.",
        title: "Exportar impuestos"
      },
      sections: {
        amortization: {
          errors: {
            "118": "No se encontraron Condiciones de Adquisición en los detalles de la propiedad",
            "119": "No se encontraron Condiciones de Adquisición en los detalles de la propiedad",
            "120": "No se encontraron Condiciones de Adquisición en los detalles de la propiedad",
            "121": "No se encontraron Condiciones de Adquisición en los detalles de la propiedad",
            "122": "No se ha encontrado ningún contrato de arrendamiento para esta propiedad",
            "123": "No se encontraron Datos Cadastrales en los detalles de la propiedad",
            "124": "No se han encontrado Datos Cadastrales en los detalles de la propiedad",
            "125": "No se encontraron Datos Catastrales en los detalles de la propiedad",
            "126": "No se encontraron Condiciones de Adquisición en los detalles de la propiedad",
            "127": "No se encontraron Condiciones de Adquisición en los detalles de la propiedad",
            "130": "No se ha encontrado ningún contrato de arrendamiento para esta propiedad",
            "131": "No se ha encontrado ningún contrato de arrendamiento para esta propiedad"
          },
          labels: {
            "0": "Importe de las mejoras realizadas",
            "118": "Tipo de adquisición",
            "119": "Tipo de adquisición: Lucrativa (Herencia, legado, donación...)",
            "120": "Fecha adquisición del inmueble",
            "121": "Fecha transmisión del inmueble",
            "122": "Número de dias que el inmueble ha estado arrendado",
            "123": "Valor catastral",
            "124": "Valor catastral de la construcción",
            "125": "Valor Catastral de la construcción/valor catastral x100",
            "126": "Importe de adquisición",
            "127": "Gastos y tributos inherentes a la adquisición",
            "128": "Importe de las mejoras realizadas en años anteriores",
            "129": "Importe de las mejoras realizadas en 2021",
            "130": "Base de la amortización",
            "131": "Amortización del inmueble y la mejora",
            "132": "Amortización de casos especiales"
          },
          title: "Amortización de la propiedad",
          tooltip: "Esta información proviene de los datos de adquisición añadidos en el detalle de la propiedad y el cálculo de la amortización del inmueble",
          values: {
            "118": {
              inheritance: "Lucrativa (Herencia, legado, donación, etc.)",
              sale: "Onerosa (Compraventa, permuta , etc)"
            }
          }
        },
        computableGrossIncomes: {
          errors: {
            "102": "No se ha encontrado ningún contrato de arrendamiento para esta propiedad"
          },
          labels: {
            "102": "Ingresos anuales por el alquiler de la vivienda"
          },
          title: "Ingresos íntegros computables",
          tooltip: "Esta información proviene de la suma total de ingresos obtenido por el alquiler mensual de la propiedad."
        },
        deductibleExpenses: {
          labels: {
            "0": "Servicios y suministros (luz, agua y/o gas...)",
            "1": "Intereses de la hipoteca",
            "103": "Importe pendiente de deducir de los ejercicios de otros años",
            "104": "Importe que se aplica en esta declaracion",
            "105": "Intereses de los capitales invertidos en la adquisición o mejora del inmueble y demás gastos de financiación en 2021",
            "106": "Gastos de reparación y conservación correspondientes al ejercicio 2021",
            "107": "Intereses y gastos de reparación y conservación de 2021 que se aplica en esta declaración",
            "108": "Importe de 2021 pendiente de deducir en los 4 años siguientes",
            "109": "Gastos de comunidad",
            "110": "Gastos formalización contrato",
            "111": "Gastos defensa jurídica",
            "112": "Otras cantidades devengadas por terceros o por servicios personales",
            "113": "Servicios y suministros (luz, agua, internet, gas...)",
            "114": "Primas de contratos de seguro",
            "115": "Tributos, recargos y tasas",
            "116": "Saldos de dudoso cobro",
            "2": "Gastos de reparación y conservación",
            "3": "Gastos de ampliación y mejora"
          },
          title: "Gastos deducibles",
          tooltip: "Esta información proviene de la suma total de los gastos deducibles derivados de la propiedad."
        },
        leasing: {
          errors: {
            "100": "No se ha encontrado ningún contrato de arrendamiento para esta propiedad",
            "101": "No se ha encontrado ningún contrato de arrendamiento para esta propiedad",
            "93": "Ningún contrato encontrado para la propiedad seleccionada"
          },
          labels: {
            "0": "Arrendatario",
            "100": "El arrendamiento tiene derecho a reducción (artículo 23.2 de la Ley del Impuesto)",
            "101": "Número de días en que el inmueble ha estado arrendado",
            "91": "NIF arrendatario",
            "92": "¿Es un NIF de otro país?",
            "93": "Fecha del contrato",
            "94": "NIF arrendatario",
            "95": "¿Es un NIF de otro país?",
            "97": "NIF arrendatario",
            "98": "¿Es un NIF de otro país?"
          },
          title: "Uso del inmueble: Arrendamiento",
          tooltip: "Esta información es un resumen que proviene del contrato de arrendamiento"
        },
        otherData: {
          labels: {
            "148": "Otros gastos fiscalmente deducibles",
            "149": "Rendimiento Neto",
            "150": "Reducción por arrendamiento de inmuebles destinados a vivienda",
            "151": "Reducción por rendimientos generados en más de 2 años u obtenidos de forma notoriamente irregular",
            "152": "Rendimiento mínimo computable en caso de parentesco",
            "153": "Retenciones e ingresos a cuenta",
            "154": "Rendimiento Neto reducido del capital"
          },
          title: "Otros datos"
        },
        propertyData: {
          labels: {
            "62": "Contribuyente titular",
            "63": "Propiedad (%)",
            "64": "Usufructo (%)",
            "66": "Referecia catastral",
            "67": "Naturaleza del inmueble",
            "69": "Dirección vivienda",
            "75": "Usos del inmueble"
          },
          title: "Datos del inmueble",
          tooltip: "Esta información proviene de un resumen de los datos del inmueble introducidos en los detalles de la vivienda.",
          values: {
            "67": "Urbano",
            "75": "Arrendamiento"
          }
        }
      },
      table: {
        boxNumber: "Número de casilla",
        concept: "Concepto",
        result: "Resultado"
      }
    },
    title: "Impuestos",
    updated: "Reporte de impuestos actualizado correctamente"
  },
  payments: {
    addLossModal: {
      lossOn: "Pérdida en",
      title: "Añadir pérdida"
    },
    baseModal: {
      button: "Crear",
      register: "Registrar pago actual",
      request: "Solicitar nuevo pago",
      title: "Crear nuevo pago"
    },
    cancelModal: {
      button: "Cancelar pago",
      footnote: "Los inquilinos recibirán esta nota en su correo electrónico.",
      label: "Añadir nota",
      text: "Esta acción no se puede deshacer.",
      title: "¿Quieres cancelar este pago?",
      toast: "Pago cancelado"
    },
    createDelayInterestModal: {
      description: "Se va a crear un nuevo ingreso del {{percentage}}% del importe de este ingreso, con una cantidad mínima de {{minAmount}}, que se solicitará al inquilino junto con el ingreso original.",
      title: "Crear ingreso de intereses de demora"
    },
    delayInterestIncomeCreated: "Ingreso de intereses de demora creado y solicitado",
    deleteModal: {
      description: "¿Seguro que quieres eliminar este ingreso? Esta acción no se puede deshacer.",
      title: "Eliminar ingreso"
    },
    deleteReconciliationConfirmationModal: {
      descriptions: {
        footer: "Eliminando esta reconciliación cualquier otra reconciliación que comparta la misma transferencia bancaria será también eliminada. Pero no te preocupes,",
        footer2: "puedes volver a reconciliarlo desde la página de cuenta bancaria. ",
        header: "Este movimiento bancario se ha utilizado también para reconciliar otros pagos:"
      },
      list: {
        dataItem: "Pago de {{amount}} perteneciente a la propiedad: {{unit}}",
        titleItem: "Pago \"{{title}}\" pertenece a la propiedad {{unit}}"
      },
      title: "¿Seguro? También se eliminarán otras reconciliaciones"
    },
    deleteReconciliationModal: {
      deletedMessage: "Pago reconciliado eliminado",
      text: "Esto eliminará el pago reconciliado seleccionado de forma permanente. Esta acción no se puede deshacer.",
      title: "Eliminar pago reconciliado"
    },
    deleteToast: "El ingreso se eliminó correctamente",
    detail: {
      alert: {
        cancel: {
          because: "porque",
          description: "Este pago ha sido cancelado por"
        },
        dueDate: {
          reason: "La fecha de vencimiento ha expirado"
        },
        title: "Detalles del pago"
      },
      amount: "Importe",
      attachments: {
        add: "Añadir archivo",
        addExternalInvoice: {
          attachButton: "Adjuntar factura",
          dateLabel: "Fecha de la factura",
          fileDescription: "Selecciona la factura externa",
          numberLabel: "Número de factura",
          title: "Añadir factura externa"
        },
        help: "Para añadir archivos en este campo",
        modal: {
          added: "Archivos añadidos",
          help: "Sólo archivos PDF, JPG y PNG con un tamaño máximo de {{maxFileSizeMB}}MB",
          title: "Subir archivos"
        },
        remove: "Eliminar archivo",
        removeContent: "Esto eliminará el archivo seleccionado de forma permanente. Esta acción no se puede deshacer.",
        removeFailed: "No se puede eliminar el archivo. Inténtalo más tarde.",
        removeSuccessful: "Archivo eliminado correctamente",
        title: "Archivos adjuntos",
        typeSelector: {
          description: "Seleccione el tipo de archivo adjunto",
          externalInvoice: "Factura externa",
          other: "Otro",
          title: "Tipo de archivo adjunto"
        }
      },
      attributed: "Atribuido a gasto",
      created: "Creado: {{date}}",
      createdBy: "Creada por: {{author}}",
      createdByPlatform: "Automatización de plataforma",
      creditor: {
        agent: "Agente",
        organization: "Organización",
        owner: "Propietario/a",
        platform: "Plataforma"
      },
      creditorLabel: {
        bank_transfer: "Realizar transferencia a:",
        direct_debit: "Realizar domiciliación bancaria desde:"
      },
      delayInterest: "Intereses de demora",
      delayInterestOrigen: "Origen de los intereses de demora",
      dropdown: {
        cancel: "Cancelar pago"
      },
      dueDate: "Fecha de vencimiento",
      linkedPaymentsModal: {
        description: "Pagos asociados a la transferencia bancaria.",
        details: "Detalles",
        title: "Pagos vinculados"
      },
      n43DocumentUploadingModal: {
        description: "Enriquece la descripción de las transacciones bancarias subiendo un archivo Norma 43. El proceso puede tardar varios minutos una vez subido el fichero.",
        placeHolder: "Seleccionar cuenta bancaria",
        title: "Subir archivo N43"
      },
      reconciliation: {
        add: "Añadir reconciliación",
        bankDetails: {
          amount: "Importe",
          bankAccount: "Cuenta bancaria",
          concept: "Concepto",
          date: "Fecha",
          title: "Detalles de la transferencia bancaria"
        },
        help: "Para reconciliar un pago",
        help2: "Aún no hay reconciliaciones",
        table: {
          amount: "Importe",
          method: "Método de pago",
          options: {
            "delete": "Eliminar pago",
            download: "Descargar factura",
            edit: "Editar pago"
          },
          paidOn: "Pagado el",
          reconciliationType: {
            automatic: "Automático",
            linked: "Vinculado",
            loss: "Pérdida",
            manual: "Manual"
          },
          viewBankDetails: "Ver datos bancarios"
        },
        title: "Reconciliación",
        types: {
          automatic: {
            description: "Lo hemos auto-reconciliado con base en reconciliaciones anteriores.",
            title: "Automático"
          },
          linked: {
            description: "Se ha reconciliado vinculando el pago con un movimiento bancario.",
            title: "Vinculado"
          },
          loss: {
            description: "Se ha conciliado como pérdida.",
            title: "Pérdida"
          },
          manual: {
            description: "Se ha añadido de forma manual.",
            title: "Manual"
          }
        }
      },
      referenceNumber: "N.° de referencia",
      title: "Detalle del pago",
      type: "Tipo de pago",
      viewLinkedPayments: "Ver pagos vinculados"
    },
    directDebit: {
      button: "Domiciliación bancaria",
      exports: {
        dueDateLabel: "Fecha de vencimiento",
        dueDatePlaceholder: "Todos",
        selectCreditorLabel: "Selecciona el acreedor",
        title: "Exportaciones"
      },
      pendingPayments: {
        createXMLButton: "Crear fichero XML",
        created: "SEPA XML creado",
        selectCreditorLabel: "Selecciona el acreedor",
        subtitle: "Selecciona estos pagos domiciliados para crear el archivo XML",
        title: "Pagos pendientes",
        totalAmount: "Importe total",
        totalPayments: "Pagos totales",
        xmlModal: {
          description: "Para crear el archivo XML, es necesario elegir cuándo desea que los pagos seleccionados se tramiten a través de domiciliación bancaria. Esta fecha también será la fecha de vencimiento del pago",
          dueDateLabel: "Selecciona una fecha de vencimiento de pago",
          reminder: "Tenga en cuenta que para poder tramitar los pagos de domiciliación bancaria, debe agregar el archivo XML a la cuenta bancaria.",
          title: "Crear archivos XML"
        }
      },
      title: "Órdenes de domiciliación bancaria"
    },
    directDebitData: {
      confirmDataDescription: "Para proceder al pago por domiciliación bancaria, necesitaremos tu aprobación para completar algunos datos personales y firmar la orden de domiciliación.",
      currentAddress: "Dirección actual",
      learnMore: "Más información sobre Zazume",
      signMandate: "Firmar mandato de domiciliación bancaria",
      zazumeInfo: "Zazume es una aplicación que transforma pisos en viviendas con servicios, para que puedas instalarte cómodamente y vivir sin preocupaciones.",
      zipCode: "Código postal"
    },
    editLossModal: {
      editMessage: "Pérdida editada",
      title: "Editar pérdida"
    },
    editModal: {
      fromRecurrenceWarnings: {
        linkedToLease: "<1>Este ingreso se ha generado a partir de un contrato.</1> <2>Ten en cuenta que si decides modificar este pago podrías estar incumpliendo el contrato original y esto podría ocasionar problemas legales y/o conflictos entre las partes involucradas.</2>",
        warning: "<1>Este ingreso se ha generado a partir de un orden de ingreso recurrente.</1>  <2>Algunos campos no pueden ser editados, si lo necesitas puedes modificarlos en el detalle de la orden de ingreso recurrente.</2>"
      },
      successMessage: "Ingreso editado con éxito",
      title: "Editar ingreso",
      warningPart1: "Este ingreso está {{stateName}}",
      warningPart2: ", por lo que existen algunos campos que no pueden ser editados. Si necesitas editarlos, deberás cancelar el ingreso y crear uno nuevo."
    },
    editReconciliationModal: {
      editedMessage: "Pago reconciliado actualizado",
      newAmountError: "El importe proporcionado supera el importe total del pago",
      title: "Editar pago reconciliado"
    },
    "export": {
      button: "Exportar ingresos",
      description: "Selecciona un rango de fechas",
      error: "Se ha producido un error al exportar los ingresos",
      from: "Desde",
      success: "En unos minutos recibirá un email con el archivo de ingresos.",
      title: "Exportar ingresos",
      to: "Hasta"
    },
    "import": {
      button: "Importar ingresos",
      description: "Sigue estos sencillos pasos: <br> <br> <strong>1. Descarga la plantilla: </strong> <CustomLink> Haz clic aquí </CustomLink> para descargar la plantilla en formato Excel <br> <br> <strong>2. Rellena la plantilla: </strong> completa todos los campos necesarios con la información de tus ingresos. <br><br><strong>3. Sube la plantilla: </strong> Una vez completada, expórtala como CSV y vuelve aquí para subir el archivo. <br><br>Si tienes algún problema, puedes enviar un correo electrónico a help@zazume.com con tu archivo completo y te ayudaremos.",
      error: "Se ha producido un error al importar los ingresos",
      success: "En unos minutos recibirás una notificación con el resultado de tu importación",
      title: "Importar ingresos"
    },
    method: {
      bank_transfer: "Transferencia bancaria",
      credit_card: "Tarjeta de crédito",
      direct_debit: "Domiciliación bancaria",
      methodUnavailable: "Método de pago temporalmente no disponible"
    },
    newPayment: "Nuevo pago",
    newReconciliationModal: {
      createdMessage: "Pago de reconciliación creado",
      newAmountError: "El importe proporcionado supera el importe total del pago",
      title: "Reconciliar pago"
    },
    paymentRequested: "Pago solicitado con éxito",
    registerModal: {
      bodyTitle: "Detalles del pago",
      button: "Guardar pago",
      form: {
        paidOn: {
          label: "Pagado el",
          placeholder: "dd/mm/aaaa"
        }
      },
      messages: {
        register: {
          error: "Error en el registro de pago",
          success: "Registro del pago realizado"
        }
      },
      title: "Registrar pago"
    },
    requestDelayInterestIncome: "Solicitar intereses de demora",
    requestModal: {
      bodyTitle: "Detalles del pago",
      button: "Solicitar pago",
      form: {
        amount: {
          label: "Importe"
        },
        dueDate: {
          label: "Fecha de vencimiento",
          placeholder: "dd/mm/aaaa"
        },
        dueDateDays: {
          label: "Fecha de vencimiento después de",
          placeholder: "Elige cuántos días"
        },
        makeFrom: {
          bank_transfer: "Realizar transferencia bancaria a",
          direct_debit: "Realizar una orden de domiciliación bancaria desde"
        },
        method: {
          label: "Método de pago",
          placeholder: "Seleccionar un método de pago"
        },
        ownerInfo: {
          ibanLabel: "IBAN",
          ibanPlaceholder: "Escribe el IBAN del propietario",
          idLabel: "Número del documento de identidad",
          idPlaceholder: "Escribe el número del documento de identidad del propietario",
          nameLabel: "Nombre del propietario",
          namePlaceholder: "Escribe el nombre del propietario"
        },
        title: {
          label: "Añade un título al pago"
        },
        type: {
          label: "Tipo de pago",
          placeholder: "Selecciona la forma de pago"
        }
      },
      messages: {
        additionalGuarantee: {
          holdingDepositError: "La reserva se está usando como garantía adicional. Debes seleccionar por lo menos un mes de garantía adicional.",
          holdingDepositLowerError: "El importe debe ser superior a la reserva."
        },
        holdingDeposit: {
          asAdditionalGuarantee: "La cantidad de {{amount}} solicitada como reserva, se utilizará como garantía adicional. Este pago se deducirá de las garantías adicionales creadas en este paso."
        },
        request: {
          error: "Error en la solicitud de pago",
          success: "Solicitud de pago realizada"
        },
        requestRecurrentIncome: {
          error: "Error en la creación de ingreso recurrente",
          success: "Solicitud de ingreso recurrente realizada"
        }
      },
      title: "Nuevo pago",
      waringPopUp: {
        before: "antes de crear una propiedad.",
        organizationSettings: "Configuración de la organización",
        textAdmin: "Falta la información requerida. Por favor, rellene en",
        textPm: "Falta la información requerida. Por favor, contacte con el administrador de su cuenta para rellenar toda la información en"
      }
    },
    requestPayment: "Solicitar pago",
    status: {
      cancelled: "Cancelado",
      "default": "Mora",
      exported: "XML Exportado",
      loss: "Pérdida",
      paid: "Pagado",
      partial_loss: "Pérdida parcial",
      partially_paid: "Parcialmente pagado",
      pending_approval: "Pendiente de aprobación",
      to_validate: "Por validar",
      waiting: "En espera"
    },
    table: {
      amount: "Importe",
      buttons: {
        cancelPayment: "Cancelar pago",
        duplicatePayment: "Duplicar pago"
      },
      dueDate: "Fecha de vencimiento",
      empty: "Todavía no hay pagos :)",
      noPaymentsToShow: "No hay pagos para mostrar",
      tenant: "Inquilino",
      type: "Tipo de pago",
      unitId: "ID de Propiedad"
    },
    type: {
      additional_guarantees: "Garantías adicionales",
      application_payment: "Pagos de la solicitud",
      attributed_to_expense: "Atribuido a gasto",
      commercialization_fees: "Honorarios de comercialización",
      commission_fee: "Comisión",
      delay_interest: "Intereses de demora",
      deposit: "Fianza",
      holding_deposit: "Reserva",
      insurance: "Seguro",
      monthly_rent: "Alquiler mensual",
      other: "Otros",
      supplies: "Suministros"
    },
    warnings: {
      unitHasNoOwner: {
        "1": "No hay ningún propietario vinculado a esta propiedad. Puedes elegirlo en",
        "2": "Detalles de la propiedad",
        "3": "o crear un nuevo propietario en la",
        "4": "Página de personas."
      }
    }
  },
  payouts: {
    accountingNoteTable: {
      amount: "Importe",
      date: "Fecha",
      guaranteedPaymentTooltip: "Pago garantizado",
      status: "Estado",
      type: "Tipo"
    },
    addPayout: "Añadir liquidación",
    allocationPayments: {
      amount: "Cantidad",
      percentage: "Porcentaje",
      sectionTitle: "Distribución de pagos",
      unavailableIban: "IBAN no disponible"
    },
    attachments: {
      addAttachment: "Adjuntar documento",
      title: "Documentos"
    },
    bulkPayment: {
      success: "Transferencia realizada. Estamos verificando el pago."
    },
    chooseAgent: "Elige un agente",
    choosePeople: "Seleccionar personas",
    concept: "Concepto",
    conceptPlaceholder: "Ej: Liquidación a propietario de Julio",
    created: "Creada: {{date}}",
    createdBy: "Creada por: {{author}}",
    deleteModal: {
      description: "¿Seguro que quieres eliminar esta liquidación? Esta acción no se puede deshacer.",
      title: "Eliminar liquidación"
    },
    deleteToast: "La liquidación se ha eliminado correctamente",
    detail: {
      title: "Detalle de liquidación"
    },
    editPayments: "Editar pagos",
    editedPayment: "Pagos modificados",
    frequency: "Frecuencia",
    listingEmptyMessage: "Todavía no se ha creado ningún pago",
    payPayout: {
      description: "Siga las instrucciones y no cierre este modal",
      success: "El pago se pagó correctamente",
      title: "Pagar liquidación"
    },
    payments: {
      addPayment: "Añadir pago",
      addedPayment: "Pago añadido",
      deletedMessage: "Pago eliminado",
      help: "Para añadir un nuevo pago"
    },
    paymentsNoAvailable: "No hay pagos disponibles",
    paymentsUnitNoAvailable: "No hay pagos disponibles, selecciona una propiedad",
    payout: "Liquidación",
    payoutDate: "Fecha de liquidación",
    payoutDetail: "Detalle de la liquidación",
    payoutDueDate: "Fecha de liquidación",
    payoutLinked: "En liquidación",
    payoutSettled: "Pagos a liquidar",
    payoutType: "Tipo de liquidación",
    payout_other: "Liquidaciones",
    recipient: "Destinatario",
    recipientTypes: {
      agent: "Agentes",
      ownerAccount: "Propietarios",
      tenant: "Inquilinos/as"
    },
    reconcilePayouts: {
      button: "Reconciliar pagos",
      emptyDescription: "Transferencia realizada mediante un pago múltiple. Estamos verificando el pago.",
      error: "Error en tu cuenta bancaria. Revisa el saldo de tu cuenta bancaria y vuelve a intentarlo",
      modal: {
        downloadSEPA: {
          description: "Descarga el archivo SEPA antes de cerrar esta ventana y sigue las instrucciones.",
          instructions1: "1. Inicia sesión en tu cuenta bancaria o proveedor de pagos",
          instructions2: "2. Elige la opción de transferencia múltiple",
          instructions3: "3. Sube el archivo SEPA",
          instructions4: "4. Ejecute la transferencia SEPA",
          title: "Establecer la transferencia SEPA"
        },
        reportSummary: {
          categoryErrors: {
            generalErrors: {
              description: "Hay algunos errores que debes solucionar antes de continuar con el siguiente paso. También puedes saltarte estas liquidaciones al deseleccionarlas.",
              title: "Hemos encontrado algunos errores que deberías revisar"
            },
            invalidIban: {
              description: "Algunas de las liquidaciones que has seleccionado tienen el mismo IBAN recipiente, ten cuidado de no duplicarlos por error. Si todo está bien, puedes continuar.",
              title: "Estas liquidaciones tienen la misma cuenta IBAN"
            },
            paidExpense: {
              description: "Algunas de las liquidaciones que has seleccionado incluyen gastos pagados. Ten cuidado, si continúas, lo cobrarás dos veces. Puedes deseleccionar estas liquidaciones para seguir con seguridad .",
              title: "Algunas de las liquidaciones seleccionadas incluyen gastos que ya se han pagado"
            },
            unpaidIncome: {
              description: "Algunas de las liquidaciones que has seleccionado incluyen ingresos no pagados, si continúas, adelantará esta cantidad de dinero. También puedes saltarte estas liquidaciones al deseleccionarlas.",
              title: "Algunas de los liquidaciones seleccionadas incluyen ingresos no pagados"
            }
          },
          description: "Algunos pagos tienen problemas, debes solucionarlos antes de continuar",
          errors: {
            description: "Hay algunos errores que debes resolver antes de continuar con el siguiente paso. También puedes saltarte esos pagos deseleccionándolos.",
            descriptionOrganization: "Hay algunos errores en la información de facturación de la organización que debes resolver antes de continuar con el siguiente paso. ",
            descriptionSingle: "Hay algunos errores que debes resolver antes de continuar con el siguiente paso.",
            main: {
              admin: {
                isAccountLinkedBankValid: "Tu cuenta bancaria vinculada no es válida. Habla con el proveedor de tu banco o con nuestro servicio de atención al cliente en info@zazume.com para solucionarlo.",
                isAccountLinkedCurrencyValid: "Tu cuenta bancaria no acepta esta moneda. Habla con el proveedor de tu banco o con nuestro servicio de atención al cliente en info@zazume.com para solucionarlo.",
                isAccountLinkedIbanValid: "El IBAN de tu cuenta bancaria sincronizada no es válido. Habla con el proveedor de tu banco o con nuestro servicio de atención al cliente en info@zazume.com para solucionarlo.",
                isAccountLinkedValid: "Tu cuenta bancaria no está vinculada o sincronizada. Ve a la página de cuentas bancarias para resolverlo.",
                isBankNameValid: "El nombre de tu cuenta bancaria vinculada no es válido. Habla con el proveedor de tu banco o con nuestro servicio de atención al cliente en info@zazume.com para solucionarlo.",
                isBankValid: "Tu banco no es válido. Habla con el proveedor de tu banco o con nuestro servicio de atención al cliente en info@zazume.com para solucionarlo.",
                isOrganizationBillingDataValid: "Los datos de facturación de tu organización no están actualizados o completados. Ve a la configuración de la organización para resolverlo.",
                isOrganizationIbanValid: "El número IBAN de tu organización no es válido. Ve a la configuración de la organización para resolverlo.",
                isOrganizationIdValid: "Organización no válida",
                isPayoutListValid: "La lista de liquidaciones no es correcta"
              },
              manager: {
                isAccountLinkedBankValid: "Tu cuenta bancaria vinculada no es válida. Habla con tu administrador para actualizar las credenciales de tu cuenta bancaria.",
                isAccountLinkedCurrencyValid: "Tu cuenta bancaria no acepta esta moneda. Habla con el proveedor de tu banco o con nuestro servicio de atención al cliente en info@zazume.com para solucionarlo.",
                isAccountLinkedIbanValid: "Tu cuenta bancaria no está vinculada o sincronizada. Habla con tu administrador para actualizar las credenciales de tu cuenta bancaria.",
                isAccountLinkedValid: "Tu cuenta bancaria no está vinculada o sincronizada. Habla con tu administrador para actualizar las credenciales de tu cuenta bancaria.",
                isBankNameValid: "El nombre vinculado de tu banco no es válido. Habla con tu administrador para actualizar las credenciales de tu cuenta bancaria.",
                isBankValid: "Tu banco no es válido. Habla con tu administrador para actualizar las credenciales de tu cuenta bancaria.",
                isOrganizationBillingDataValid: "Los datos de facturación de tu organización no están actualizados o completados. Habla con tu administrador para actualizar los datos de facturación en la página de configuración. ",
                isOrganizationIbanValid: "El número IBAN de tu organización no es válido. Habla con tu administrador para actualizar tu número IBAN en la página de configuración. ",
                isOrganizationIdValid: "Organización no válida",
                isPayoutListValid: "La lista de liquidaciones no es correcta"
              }
            },
            payout: {
              global: "La cuenta de algunos propietarios no tiene un nombre, dirección de facturación, o número IBAN válidos. Ve a Detalles del propietario para resolverlo. Para obtener más información, pase el ratón por el icono de advertencia. ",
              hasOwnerAccountAddressValid: "La cuenta del propietario no tiene una dirección de facturación válida",
              hasOwnerAccountBillingDataValid: "La cuenta del propietario no tiene información de facturación",
              hasOwnerAccountIbanValid: "La cuenta del propietario no tiene un IBAN válido",
              hasOwnerAccountNameValid: "La cuenta del propietario no tiene un nombre válido",
              hasTenantValidAddress: "El inquilino no tiene una dirección actual válida",
              hasTenantValidIban: "El inquilino no tiene un IBAN válido",
              hasTenantValidName: "El inquilino no tiene un nombre válido",
              hasUniqueOwnerAccountIban: "El numero IBAN de la cuenta del propietario está asignado a otra cuenta",
              isOwnerAccountValid: "La cuenta del propietario no es válida",
              isOwnershipDistributionValid: "Hay pagos seleccionados con una distribución de propiedad incompleta, elimínelos de la selección para continuar.",
              ownerHeaderIssues: "Problemas relacionados con el propietario {{ownerName}}",
              payoutsBlocked: "La propiedad tiene los pagos bloqueados.",
              withNegativeAmountError: "Algunas de las liquidaciones seleccionadas son negativas, deselecciónalas para poder continuar."
            },
            title: "¡Ups! Hemos encontrado algunos errores que deberías revisar"
          },
          steps: {
            checkReport: {
              noPayoutErrors: "Todo está bien, puedes continuar con el siguiente paso.",
              noPayoutsSelected: "No se seleccionó ninguna liquidación, por favor vuelve al paso de selección.",
              reportError: "No se ha podido cargar el resumen del informe de liquidación. Vuelve a intentarlo.",
              title: "Verifica la selección"
            },
            confirmation: {
              description: "Una vez que confirmes las liquidaciones seleccionadas, se te redirigirá a tu cuenta bancaria para proceder al pago.",
              payoutsCount: "{{count}} liquidación seleccionada a {{recipients}}",
              payoutsCount_other: "{{count}} liquidaciones seleccionadas a {{recipients}}",
              payoutsToOwners: "Liquidaciones a propietarios",
              payoutsToTenants: "Liquidaciones a inquilinos",
              title: "Confirmación",
              totalSettled: "Importe total a liquidar: {{total}}"
            },
            payment: {
              bankTransferButton: "Realizar transferencia bancaria",
              bankTransferDescription: "Para seguir con el pago mediante transferencia bancaria, debes continuar con tu cuenta bancaria vinculada a la plataforma.",
              downloadSepaDescription: "Para seguir con el pago por domiciliación bancaria, debes descargar el archivo XML y subirlo a tu cuenta bancaria.",
              methodError: "Selecciona un método de pago válido en el paso anterior.",
              title: "Pago"
            },
            selection: {
              description: "Selecciona una liquidación pendiente para ejecutar la transferencia bancaria y conciliarla. Las propiedades con más de un propietario recibirán su porcentaje respectivo en función de su participación en la propiedad.",
              title: "Selecciona las liquidaciones"
            }
          },
          title: "Información no válida",
          warnings: {
            description: "Algunas de las liquidaciones que has seleccionado no están pagadas. Si continúas, adelantarás esta cantidad de dinero. También puedes saltarte estas liquidaciones si las deseleccionas.",
            descriptionSingle: "Algunos de los ingresos incluidos en el pago no están pagados. Si continúas, adelantarás esta cantidad de dinero.",
            ibanDescription: "Algunos de los pagos que has seleccionado tienen el mismo IBAN, ten cuidado de no duplicarlos por error. Si todo está correcto puedes continuar.",
            ibanTitle: "Estos pagos tienen la misma cuenta IBAN",
            paidExpenseDescription: "Algunas de las liquidaciones que has seleccionado incluyen gastos pagados. Verifica si se han pagado antes de hacer la liquidación para que dichos gastos no se liquiden nuevamente.",
            paidExpenseTitle: "Algunas de las liquidaciones seleccionadas incluyen gastos pagados",
            title: "Algunas de las liquidaciones seleccionados no están pagadas"
          }
        },
        step1: {
          description: "Selecciona un pago pendiente para ejecutar una transferencia bancaria y reconciliarlo.",
          title: "Realizar una transferencia para reconciliar varios pagos"
        },
        step2: {
          bankTransferMethod: "Transferencia bancaria",
          chooseBankAccount: "Elige una cuenta bancaria",
          chooseTransferMethod: "Elige un método de transferencia",
          defaultBankAccount: "Cuenta bancaria predeterminada",
          description: "Una vez que confirmes los pagos seleccionados, se te redirigirá a tu cuenta bancaria para proceder con la transferencia.",
          downloadSEPA: "Descargar SEPA {{amount}}",
          makeATransfer: "Realizar una transferencia de {{amount}}",
          ownerTransfers: "Transferencias a propietarios",
          selectedPayouts: "Pagos seleccionados",
          sepaTransferMethod: "Transferencia SEPA",
          tenantTransfers: "Transferencias a inquilinos",
          title: "Confirmar los pagos seleccionados",
          totalAmount: "Importe total"
        }
      }
    },
    settledPayments: "Pagos liquidados",
    settlementDate: "Fecha de liquidación",
    statuses: {
      paid: "Pagado",
      partially_paid: "Pagado parcialmente",
      to_validate: "Por validar",
      wait: "Esperando"
    },
    statusesTooltip: {
      paid: "La liquidación se ha pagado y reconciliado",
      partially_paid: "Pagado parcialmente",
      to_validate: "Transferencia realizada. Estamos verificando el pago.",
      wait: "Pendiente de liquidar"
    },
    table: {
      amount: "Importe",
      concept: "Concepto",
      date: "Fecha",
      noContentMessage: "No hay pagos que reconciliar",
      recipient: "Destinatario",
      selected: {
        plural: "pagos seleccionados {{total}}",
        singular: "pago seleccionado {{total}}"
      },
      status: "Estado",
      unitId: "Id de propiedad"
    },
    toBeSettledAt: "A partir de {{date}}",
    types: {
      recurrent: "Liquidación recurrente",
      single: "Liquidación única"
    },
    warnings: {
      existingNoPaidPayoutForThisUnit: "Hay una liquidación pendiente que no ha sido pagada para esta propiedad."
    },
    wrongAmountError: "Se debe seleccionar un pago y el importe total debe ser superior a 0"
  },
  pendingPayments: {
    table: {
      empty: "Para crear un archivo XML selecciona un acreedor y una fecha de vencimiento de pago"
    }
  },
  people: {
    title: "Personas"
  },
  portfolio: {
    cards: {
      available: "Disponible",
      total: "Total"
    },
    myProperties: "Mis propiedades",
    shareRentability: "Compartir rentabilidad",
    title: "Portfolio"
  },
  preScoring: {
    applicantQualification: "Cualificación del solicitante",
    furtherInformation: {
      presentation: {
        title: "Carta de presentación"
      },
      title: "Más información"
    },
    guarantor: "Avalista",
    maxTenants: {
      name: "Número máximo de inquilinos"
    },
    petsAllowed: "¿Se admiten mascotas?",
    petsType: {
      name: "Tipo de mascotas",
      types: {
        cat: "Gato",
        dog: "Perro",
        others: "Otras"
      }
    },
    rentalType: {
      name: "Tipo de alquiler",
      types: {
        longTerm: "Larga estancia",
        shortTerm: "Media estancia"
      }
    },
    tenantProfile: {
      name: "Perfil del inquilino",
      types: {
        couple: "Pareja",
        family: "Familia",
        individual: "Individual",
        roomates: "Compañeros de piso",
        students: "Estudiantes"
      }
    },
    whatsApp: {
      p2pDirectLink: "Hola, {{tenantName}}! Soy {{agentName}}, de Zazume. Te escribo porque hemos recibido tu solicitud para el piso de {{location}}. Aquí puedes revisarlo: {{unitUrl}}%0aMuchas gracias por contactarnos.%0a%0aTe comento 2 requisitos fundamentales para la solicitud:%0a*Estancia mínima de 12 meses*%0a*Ingresos demostrables*%0a%0aAntes de la visita, al propietario le gustaría conocer un poco el perfil de sus futuros inquilinos. ¿Serías tan amable de rellenar este breve cuestionario? {{prescoringUrl}}%0a%0aUna vez completado el cuestionario podrás agendar una visita desde la plataforma comprobando la disponibilidad que pueda encajarte. ¡Gracias!"
    }
  },
  products: {
    availability: "Disponibilidad",
    edit: "Editar producto",
    price: "Precio",
    product: "producto",
    products: "productos",
    title: "Productos"
  },
  profile: {
    bio: "Biografía",
    cardPayments: "Pagos con tarjeta",
    change: "Cambiar imagen",
    email: "Email",
    logout: "Cerrar sesión",
    modal: {
      account: {
        title: "Cuenta"
      },
      notifications: {
        description: "Selecciona tus preferencias por tipo de notificaciones.",
        emailNotifications: "Notificaciones de correo",
        pushNotifications: "Notificaciones push",
        title: "Notificaciones"
      },
      title: "Mi cuenta",
      updated: "Perfil actualizado correctamente"
    },
    phoneNumber: "Número telefónico",
    remove: "Eliminar",
    role: "Rol",
    save: "Guardar cambios",
    surnames: "Apellidos",
    title: "Mi perfil",
    updateTitle: "Detalles del perfil",
    upload: "Subir imagen",
    version: "Versión",
    videoUrl: "URL de vídeo"
  },
  providers: {
    common: {
      addedOn: "Añadido el",
      address: "Dirección",
      contact: "Número de contacto",
      details: "Detalles del proveedor",
      edit: "Editar proveedor",
      services: "Servicios",
      taxName: "Nombre o Nombre fiscal"
    },
    modals: {
      edition: {
        addButton: "Agregar proveedor",
        services: "Servicios ofrecidos",
        title: "Agregar nuevo proveedor"
      },
      remove: {
        description: "Esto eliminará el proveedor seleccionado de forma permanente. Esta acción no se puede deshacer.",
        successMessage: "Proveedor eliminado con éxito",
        title: "¿Eliminar proveedor?"
      }
    },
    services: {
      antennaOperator: " Operador de antena",
      boilers: "Calderas",
      carpentry: "Carpintería",
      cee: "Certificado de eficiencia energética",
      cleaning: "Limpieza",
      elevator: "Ascensor",
      fireExtinguishers: "Extintores",
      gardening: "Jardinería",
      handyman: "Manitas",
      insurances: "Seguros",
      intercom: "Interfono",
      locksmith: "Cerrajero",
      others: "Otros",
      painter: "Pintor",
      personalShopper: "Personal Shopper",
      pestControl: "Control de Plagas",
      photography: "Fotografía",
      plumbing: "Fontanería",
      realStateAgent: "Agente inmobiliario",
      repairSkylights: "Reparación de tragaluces",
      supplies: "Suministros",
      swimmingPools: "Piscinas"
    },
    table: {
      add: "Añadir nuevo proveedor",
      emptyState: "Aún no se ha añadido ningún proveedor",
      headers: {
        name: "Nombre",
        phone: "Teléfono",
        services: "Servicios"
      },
      options: {
        edit: "Editar proveedor"
      }
    },
    title: "Proveedores"
  },
  reconciliation: {
    newPayment: {
      button: "Crear",
      options: {
        manually: "Agregar el pago manualmente",
        withBankTransaction: "Selecciona el pago de los movimientos de cuenta bancaria"
      },
      title: "Añadir pago"
    },
    newReconciliation: {
      button: "Crear",
      message: "No hay cuentas bancarias vinculadas. <1>Vincula una desde cuentas bancarias</1> y reconcilia pagos con transacciones bancarias.",
      options: {
        loss: "Añadir importe de pérdida",
        manually: "Añadir reconciliación manualmente",
        withBankTransaction: "Reconciliación a partir de los movimientos de la cuenta bancaria"
      },
      title: "Añadir reconciliación"
    },
    paymentToBankTranstactions: {
      step1: {
        description: "Selecciona un movimiento bancario para vincularlo al pago elegido",
        table: {
          selectedRows: {
            plural: "transacciones bancarias pendientes seleccionadas",
            singular: "transacción bancaria pendiente seleccionada"
          }
        },
        title: "Movimientos bancarios pendientes"
      },
      step2: {
        cta: "Reconciliar pago",
        descriptions: {
          line1_plural: "¿Quieres vincular estos movimientos bancarios...",
          line1_singular: "¿Quieres vincular este movimiento bancario...",
          line2: "... a este pago?"
        },
        messages: {
          error: "El movimiento bancario no se ajusta al importe total de este pago",
          paid: "Felicidades. Este movimiento bancario cubre el importe total de este pago. Su estado será pagado",
          partiallyPaid: "El movimiento bancario no se ajusta al importe total de este pago. Su estado será parcialmente pagado"
        },
        title: "Reconciliar pagos"
      }
    }
  },
  recurrent: {
    commonForm: {
      breakdown: "Desglose del próximo cargo",
      linkedToLease: "Vinculado a un contrato",
      nextCharge: {
        errors: {
          lastChargeDateExceeded: "La próxima fecha de cobro es posterior a la fecha de finalización de la recurrencia. Reduzca el número de ciclos."
        },
        nextChargeAmount: "Importe del siguiente cargo (IVA no incluido)",
        nextChargeAmountWithVAT: "Importe del siguiente cargo",
        nextChargeDate: "El importe del próximo cargo será el",
        skippedCycles: "¿Cuántos {{frequency}} quieres eximir?",
        title: "Próximo cargo",
        unitTimeFromFrecuency: {
          annually: "años",
          bimonthly: "bimestres",
          monthly: "meses",
          quarterly: "cuatrimestres",
          semiyearly: "años",
          weekly: "semanas"
        }
      },
      repeatTimes: "¿Por cuántas veces?",
      repeatTimesWithFrequency: {
        annually: "¿Por cuantos años?",
        bimonthly: "¿Por cuántos bimestres?",
        monthly: "¿Por cuántos meses?",
        quarterly: "¿Por cuántos trimestres?",
        semiyearly: "¿Por cuántos semestres?",
        weekly: "¿Por cuántas semanas?"
      },
      status: {
        active: "Activo",
        ended: "Terminado"
      }
    },
    expense: {
      addRecurrentExpense: "Añadir gasto recurrente",
      cancelModal: {
        actionButton: "Cancelar gasto recurrente",
        description: "Este gasto recurrente se cancelará. Dejaremos de solicitarlas a partir de hoy. Los gastos que se habían creado hasta este momento no se eliminarán. Esta acción no se puede deshacer.",
        title: "¿Desea cancelar este gasto recurrente?",
        toast: "Gasto recurrente cancelado"
      },
      detail: {
        generatedExpenses: "Gastos generados",
        nextCharges: "Próximos cargos",
        nextChargesTitle: "Próximos cargos ({{max}} máximo)",
        title: "Detalle de gasto recurrente"
      },
      editModal: {
        messages: {
          error: "Error en la solicitud de gasto recurrente",
          success: "Gasto recurrente editado"
        }
      },
      editRecurrentExpense: "Editar gasto recurrente",
      form: {
        endsOn: "Termina el",
        errorMessages: {
          invalidBeneficiary: "El beneficiario y el pagador deben ser diferentes"
        },
        firstChargeDate: "Comienza el",
        frequency: "Frecuencia",
        frequencyPlaceholder: "Selecciona una frecuencia",
        title: "Programación del gasto"
      },
      infoMessages: {
        linkedToLease: "Este gasto recurrente se ha creado a partir de un contrato, <1>por lo que algunos campos no se pueden editar.</1>",
        nonLinkedToLease: "Este es un gasto recurrente, por lo que algunos campos no se pueden editar. Si desea editarlos, debe cancelar el gasto recurrente y crearlo de nuevo. <1>Los cambios se aplicarán a los gastos de próxima generación a partir de hoy.</1>"
      },
      pageTitle: "Gastos recurrentes",
      table: {
        cancel: "Cancelar gasto recurrente",
        linkedToLeaseTooltip: "Este es un gasto recurrente automático basado en el alquiler actual. No se puede eliminar ni editar.",
        listingEmptyMessage: "No hay gastos recurrentes programados.",
        tooltip: "Gasto recurrente"
      }
    },
    frequency: {
      annually: "Anualmente",
      bimonthly: "Bimestral",
      each: {
        annually: "/año",
        bimonthly: "/dos meses",
        monthly: "/mes",
        quarterly: "/trimestre",
        semiyearly: "/medio año",
        weekly: "/semana"
      },
      monthly: "Mensual",
      quarterly: "Trimestral",
      semiyearly: "Semestral",
      weekly: "Semanal"
    },
    income: {
      addRecurrentIncome: "Añadir ingreso recurrente",
      cancelModal: {
        actionButton: "Cancelar ingreso recurrente",
        description: "Este ingreso recurrente se cancelará. Dejaremos de solicitarlas a partir de hoy. Los ingresos que se habían creado hasta este momento no se eliminarán. Esta acción no se puede deshacer.",
        title: "¿Desea cancelar este ingreso recurrente?",
        toast: "Ingreso recurrente cancelado"
      },
      detail: {
        breakdown: {
          fee: "Comisión",
          principal: "Principal"
        },
        generatedIncomes: "Ingresos generados",
        nextCharges: "Próximos cargos",
        nextChargesTitle: "Próximos cargos ({{max}} máximo)",
        title: "Detalle de ingreso recurrente"
      },
      editRecurrentIncome: "Editar ingreso recurrente",
      form: {
        cycles: "Ciclos",
        endsOn: "Termina el",
        firstChargeDate: "Comienza el",
        frequency: "Frecuencia",
        frequencyPlaceholder: "Selecciona una frecuencia de programación",
        nextCharge: "Próximo cargo",
        nextDueDate: "Próxima fecha de vencimiento",
        title: "Programación del ingreso"
      },
      infoMessages: {
        linkedToLease: "Este ingreso recurrente ha sido creado a partir de un contrato, <1>por lo que algunos campos no se pueden editar.</1>",
        nonLinkedToLease: "Este es un ingreso recurrente, por lo que algunos campos no se pueden editar. Si desea editarlos, debe cancelar el ingreso recurrente y crearlo de nuevo. <1>Los cambios se aplicarán a los ingresos de próxima generación a partir de hoy.</1>"
      },
      pageTitle: "Ingresos recurrentes",
      table: {
        cancel: "Cancelar ingreso recurrente",
        linkedToLeaseTooltip: "Este es un ingreso recurrente automático basado en el alquiler actual. No se puede eliminar ni editar.",
        listingEmptyMessage: "No hay ingresos recurrentes programados.",
        tooltip: "Ingreso recurrente"
      }
    },
    payout: {
      actionButton: "Liquidaciones recurrentes",
      cancelModal: {
        actionButton: "Cancelar liquidación recurrente",
        description: "Esta liquidación recurrente se cancelará. Dejaremos de crearlos a partir de hoy. Las liquidaciones que se hayan creado hasta este momento no se eliminarán. Esta acción no se puede deshacer.",
        title: "¿Quieres cancelar esta liquidación recurrente?",
        toast: "Liquidación recurrente cancelada"
      },
      detail: {
        generatedPayouts: "Liquidaciones generadas",
        title: "Detalle de liquidación recurrente"
      },
      pageTitle: "Liquidación recurrente",
      table: {
        cancel: "Cancelar liquidación recurrente",
        linkedToLeaseTooltip: "Esta es una liquidación recurrente automática basado en el alquiler actual. No se puede eliminar ni editar.",
        listingEmptyMessage: "No hay liquidaciones recurrentes programadas.",
        nextDueDate: "Próximo vencimiento",
        tooltip: "Liquidación recurrente"
      }
    },
    table: {
      frequency: "Frecuencia",
      nextPayment: "Próxima generación",
      payer: "Pagador"
    }
  },
  review: {
    bookingDate: "{{day}} a las {{time}}",
    commentField: "¿Quieres compartir más sobre esto?",
    ratingField: "¿Cómo calificaría el servicio?",
    selectRating: "Por favor, selecciona una calificación",
    submit: "Enviar valoración"
  },
  reviewPlatform: {
    requestPlatformRating: "Solicitar reseña de {{platform}}",
    requestPlatformRatingSuccess: "Reseña de {{platform}} solicitada"
  },
  reviewViewing: {
    button: "Enviar valoración",
    message: "Añadir un mensaje",
    messagePlaceholder: "¿Algo más que quieras decirnos?",
    ratingField: "¿Cómo calificarías la visita?"
  },
  search: {
    byRecentlyInvited: "Invitados recientemente",
    byRecentlySearched: "Buscado recientemente",
    person: "Buscar persona",
    product: "Producto",
    products: "Productos",
    results: "Resultados de la búsqueda",
    title: "Buscar {{role}}"
  },
  sepa: {
    filters: {
      clear: "Limpiar"
    },
    status: {
      pending: "Pendiente",
      uploaded: "Subido"
    },
    table: {
      buttons: {
        "delete": "Eliminar archivo",
        uploaded: "Marcar como subido"
      },
      deleteModal: {
        description: "¿Seguro que quieres eliminarlo? Esta acción no se puede deshacer."
      },
      empty: "Ningún archivo XML exportado",
      status: {
        download: "Descargar archivo XML",
        uploaded: "Archivo subido"
      },
      updateModal: {
        description: "¿Seguro que quieres marcarlo como subido? Esta acción no se puede deshacer."
      }
    },
    viewerModal: {
      dueDate: "Fecha de vencimiento del pago",
      title: "Detalles del archivo XML"
    }
  },
  serviceDetail: {
    serviceHighlights: "Aspectos destacados",
    title: "Detalle del servicio"
  },
  services: {
    availabilityType: {
      week: "Semana",
      weekend: "Fin de semana",
      workweek: "Semana Laboral"
    },
    cancellationPolicy: "Política de cancelación",
    cancellations: "Cancelaciones",
    categories: {
      beauty: "Belleza",
      care: "Cuidados",
      cleaning: "Limpieza",
      generic: "Genérica",
      laundry: "Lavandería",
      maintenance: "Mantenimiento"
    },
    configuration: "Configuración",
    playbook: "Playbook",
    priceFree: "Gratis",
    products: "Productos",
    servicePlaybook: "Playbook"
  },
  settings: {
    accounting: {
      ledgerAccount: {
        subtitle: "Rellena la cuenta contable para cada tipo de ingreso y gasto. Esto se utilizará para generar los asientos contables.",
        title: "Cuenta contable",
        update: {
          error: "Error al actualizar la cuenta contable",
          success: "La cuenta de contabilidad se actualizó correctamente"
        }
      }
    },
    accountingData: {
      accountingHelp: "Los siguientes ajustes determinarán la fecha de generación de los gastos/ingresos y liquidaciones recurrentes relacionados con un arrendamiento.",
      accountingPayoutBehaviorHelp: "La siguiente configuración determinará el comportamiento de generación de liquidaciones recurrentes relacionadas con un arrendamiento.",
      delayInterest: {
        automaticLatePaymentsRequestHelp: "Activa la solicitud automática de intereses de demora indicando los días transcurridos desde que un pago pasa a mora tras los cuales se realizará dicha solicitud.",
        help: "La siguiente configuración determinará el interés aplicado a los ingresos en mora cuando se soliciten.",
        minAmount: "Importe mínimo",
        numberOfDays: "Número de días",
        percentage: "Intereses de demora",
        title: "Intereses de demora",
        toggle: "Solicitud automática de intereses de demora."
      },
      includeAllIncomes: "Incluir todos los ingresos en la generación de liquidaciones",
      includePaidOrGuarenteedIncomes: "Incluir los ingresos pagados o garantizados en la generación de liquidaciones",
      incomeDayGeneracion: "Día de generación de ingresos",
      payoutDayGeneracion: "Día de generación de liquidaciones",
      subtitle: "Pagos recurrentes",
      title: "Contabilidad"
    },
    addIntegration: "Añadir integración",
    agent: {
      agentSection: "Propiedades captadas por el agente",
      commercialization: "Captación de propiedades en comercialización",
      concept: "Concepto",
      editCommission: {
        basic: "Comisión Agentes Basic",
        disable: {
          action: "Desactivar comisiones",
          description: "Si desactivas las comisiones, esto solo aplicará a futuras acciones. Las acciones en curso o pendientes de facturar no cambiarán.",
          state: "Comisiones desactivadas",
          title: "¿Seguro que quieres desactivar las comisiones?"
        },
        enable: {
          action: "Activar comisiones",
          helper: "Para activar las comisiones para este agente, <1> haz clic aquí </1>",
          initiateDescription: "Si activas las comisiones por primera vez estas se rellenarán con los valores por defecto de la organización.",
          initiateNote: "Si las reactivas, las comisiones mantendrán la última configuración guardada.",
          state: "Comisiones activadas",
          title: "¿Quieres activar las comisiones?"
        },
        gold: "Comisión Agentes Gold",
        title: "Editar Comisión"
      },
      enterValue: "Introducir valor",
      organizationSection: "Propiedades captadas por la organización",
      organizationVisits: "Visitas en propiedades aportadas por la organización",
      paymentGuarantee: "Garantía de pago",
      subtitle: "Configura las comisiones que se aplicarán a aquellos agentes que colaboren con tu organización.",
      title: "Comisiones",
      unitManagementWithGuarantee: "Captación de propiedades en gestión con garantía de pago",
      updateSuccess: "Comisión actualizada con éxito",
      visits: "Visitas"
    },
    bankAccounts: {
      accountName: "Nombre de cuenta",
      addBankAccount: "Agregar una cuenta bancaria",
      alertExpired: "Las credenciales han caducado. Por favor, vuelva a sincronizarlos. De lo contrario, no podemos mostrarte una vista actualizada de tus movimientos bancarios.",
      credentialsExpireOn: "Las credenciales caducan el",
      deleteAccount: {
        description: "¿Seguro que quieres eliminar esta cuenta? Esta acción no se puede deshacer.",
        removeTransactions: "Quiero eliminar todas las transacciones bancarias relacionadas con esta cuenta bancaria",
        title: "Borrar cuenta"
      },
      expireOn: "Las credenciales caducan el: ",
      lastSynced: "Última sincronización",
      notSyncing: "No sincronizado",
      notifications: {
        accountDeleted: "¡Cuenta eliminada correctamente!",
        aliasUpdated: "Se actualizó el alias de la cuenta",
        errorSync: "Error al intentar sincronizar, inténtalo de nuevo",
        successSync: "Sincronizado correctamente"
      },
      reSyncCredentials: "Volver a sincronizar las credenciales",
      selectYourBank: {
        subtitle: "Selecciona un banco para vincularlo al pago elegido",
        title: "Selecciona tu banco"
      },
      subtitle: "Configura las credenciales de tus cuentas bancarias para ver tus transferencias",
      title: "Cuentas bancarias",
      warningExpired: "Las credenciales caducarán a los {{daysCount}} días. Por favor, vuelva a sincronizarlos. De lo contrario, no podemos mostrarte una vista actualizada de tus movimientos bancarios."
    },
    billingData: {
      billing: {
        bankAccounts: {
          create: "Añadir cuenta bancaria",
          createModal: {
            title: "Añadir cuenta bancaria"
          },
          deleteButton: "Eliminar cuenta bancaria",
          deleteModal: {
            description: "De este modo, se eliminará la cuenta bancaria de forma permanente. No te preocupes, todos los pagos vinculados a esta cuenta bancaria conservarán la misma información de la cuenta bancaria a menos que la modifiques manualmente.\nEsta acción no se puede deshacer.",
            title: "¿Quieres eliminar esta cuenta bancaria?"
          },
          editButton: "Editar cuenta bancaria",
          editModal: {
            form: {
              alias: "Nombre de la cuenta bancaria",
              setAsDefault: "Establecer como cuenta bancaria por defecto"
            },
            title: "Editar cuenta bancaria"
          },
          setAsDefault: "Establecido como predeterminado",
          title: "Cuentas bancarias"
        },
        title: "Facturación"
      },
      forms: {
        legalName: "Nombre legal",
        save: "Guardar datos de facturación",
        successMessage: "Datos de facturación actualizados con éxito"
      },
      invoice: {
        addYear: "Añadir año",
        clickHere: "pulsa aquí",
        create: "Crear serie de facturas",
        createSerieModal: {
          cta: "Añadir nueva serie",
          title: "Crear serie de facturas"
        },
        cta: "Para añadir una serie de facturas",
        editSerie: "Editar serie",
        includeYear: "Incluir año en el número de facturación",
        includeYearTooltip: "El año actual se añadirá a la factura y, cada año, el número de factura se restablecerá a 1",
        nextInvoiceNumber: "Siguiente número de factura",
        nextInvoiceNumberWillBe: "El siguiente número de factura será",
        separator: "Separador",
        serie: "Serie",
        subtitle: "Cree su serie de facturas para enumerarlas.",
        title: "Serie de facturas",
        updateSerieModal: {
          cancelButton: "No, restablécelo",
          cta: "Sí, aplicar cambios",
          title: "Editar serie",
          warning1: "La edición de esta serie de facturas no cambiará las facturas existentes. Los cambios se aplicarán a las nuevas facturas.",
          warning2: "TEN EN CUENTA - ",
          warning3: "El número de cada factura debe ser único en el año fiscal, correlativo y coherente con la fecha de emisión de la factura. Los cambios en las series pueden dar lugar a errores al pedirlas o presentarlas."
        }
      },
      invoices: {
        series: {
          title: "Serie de facturas"
        },
        settings: {
          no: "No, prefiero hacerlo manualmente",
          pendingInvoices: "Facturas pendientes de generar.",
          title: "Ajustes",
          yes: "Me gustaría que los ingresos reconciliados con transferencia bancaria, y que no se ha generado factura, al final del mes, se generasen automáticamente."
        },
        title: "Facturas"
      },
      title: "Datos de facturación",
      vatNumber: "Número CIF"
    },
    integrationName: "{{integrationName}} integración",
    lease: {
      leaseExtension: {
        notifications: {
          activeNotificationLabel: "Notificar el fin del contrato (y la prórroga)",
          subtitle: "La notificación llegará a los miembros del equipo y a los firmantes del contrato.",
          title: "Notificaciones"
        },
        title: "Prórroga del contrato de arrendamiento"
      },
      longTerm: {
        leaseExtension: {
          activeExtension: "Añadir prórroga al contrato",
          maxMonthsExtensionLabel: "Máx. meses de prórroga",
          monthsEndContractLabel: "Fin del contrato (meses)",
          monthsEndExtensionLabel: "Fin de la prórroga (meses)",
          monthsExtensionLabel: "Meses de prórroga"
        },
        subtitle: "Contratos cuya duración supere los 11 meses.",
        title: "Contrato de arrendamiento a largo plazo",
        updateSuccess: "Actualizado con éxito"
      },
      midTerm: {
        leaseExtension: {
          activeExtension: "Añadir prórroga al contrato",
          maxMonthsExtensionLabel: "Meses máximos de prórroga",
          monthsExtensionLabel: "Meses de prórroga",
          title: "Prórroga del contrato de arrendamiento"
        },
        subtitle: "Contratos cuya duración no supere los 11 meses.",
        title: "Contrato de arrendamiento a medio plazo",
        updateSuccess: "Actualizado con éxito"
      }
    },
    messagingIntegration: {
      title: "Mensajes"
    },
    modifyAccessToken: "Modificar el token de acceso",
    nonPayments: {
      automatizationSection: {
        daysSinceFirstDefault: "¿Cuántos dias deben pasar desde la primera mora para crear un impago?",
        daysSinceNextDefault: "¿Cuántos días deben pasar para añadirlo al impago?",
        options: {
          createAutomatically: "Si, crea automáticamente los impagos",
          createManually: "No, prefiero crearlos de forma manual"
        },
        selectionLabel: "¿Quieres automatizar la creación de impagos?",
        title: "Automatización de la creación de impagos"
      },
      nextPaymentsSection: {
        options: {
          includeAutomatically: "Si, incluye automáticamente en el impago los futuros pagos en mora",
          includeManually: "No, prefiero incluir los pagos en mora de forma manual"
        },
        selectionLabel: "Una vez creado el impago, si aparecen nuevos pagos en mora ¿quieres que se incluyan de forma automática?",
        title: "Futuros impagos"
      },
      title: "Configuración de impagos"
    },
    organization: {
      signer: {
        description: "Configura un firmante para documentos legales (como los honorarios de comercialización o la reserva)",
        title: "Firmante de la organización"
      }
    },
    otherIntegrations: {
      title: "Integraciones adicionales"
    },
    paymentData: {
      annualRentPercentage: "{{value}}% del alquiler anual",
      comercializationFee: "Honorarios de comercialización",
      commercializationService: "Servicio de comercialización",
      creditorIdentifier: "Identificador del acreedor",
      creditorIdentifierTooltip: "Es un número de referencia único para cobrar pagos mediante domiciliación bancaria SEPA. Lo calculamos automáticamente en base al número de identificación o CIF.",
      fees: "Comisiones",
      feesHelp: "Los siguientes ajustes aparecerán por defecto en los detalles de cada nueva unit.",
      iban: "Número IBAN",
      oneMonthRent: "1 mes de alquiler",
      other: "Otro...",
      otherWithValue: "Otro: {{value}}{{unit}}",
      propertyManagementFee: "Comisión por la administración de la propiedad",
      serviceType: {
        manageCurrentTenants: "Gestión de la propiedad",
        manageNewTenants: "Comercialización",
        title: "Intención inicial del tipo de servicio"
      },
      tenPercentAnnualRent: "10% del alquiler anual"
    },
    portalIntegrations: {
      enable: "Activar la sincronización",
      errors: {
        maxUnitsPublished: "No puede tener más propiedades publicadas que las que puede publicar. Por favor, ",
        maxUnitsPublishedBold: "desublique primero suficientes propiedades, o aumente el número de propiedades que puede publicar en el portal.",
        maxUnitsPublishedExceed: "Superadas las propiedades de publicación",
        maxUnitsPublishedMinValue: "Al menos una propiedad se debe poder publicar"
      },
      idealista: {
        alreadyExists: "Ya existe una organización con este código de cliente",
        help: "Puedes encontrar este código en Idealista",
        helpMaxUnitsPublished: "Puede encontrarlo en los detalles de su plan en Idealista",
        label: "Código de cliente",
        requiredPortalId: "Se requiere un código de cliente válido"
      },
      inbox: {
        copied: "¡Copiado al portapapeles!",
        description: "Redirige los correos electrónicos de tu portal a esta bandeja de entrada y Zazume podrá generar solicitudes por tí. En pocos pasos, puedes conectar tu correo electrónico con Zazume",
        here: "aquí",
        title: "Lleva tus leads de {{portals}} a Zazume automáticamente"
      },
      inmofactory: {
        agencyId: "ID de agencia",
        agencyIdPlaceholder: "Por ejemplo: 123456",
        agentId: "Id de agente",
        agentIdPlaceholder: "Por ejemplo: 123456",
        alreadyExists: "Ya existe una organización con esta clave de API",
        help: "Puedes encontrar este código en {{portal}}",
        helpMaxUnitsPublished: "Puedes encontrarlo en los detalles de tu plan en {{portal}}",
        label: "API Key",
        notEnabled: "{{portal}} no está disponible para sincronizarse con Inmofactory",
        providedByInmofactory: "Esta información es proporcionada por {{portal}}",
        requiredAgencyId: "Se requiere un Id de agencia válido",
        requiredAgentId: "Se requiere un Id de agente válido",
        requiredApiKey: "Se requiere una clave de API válida"
      },
      lastSynced: "Última sincronización",
      maxUnitsPublished: "Número de propiedades que puedes publicar",
      messages: {
        saved: "Guardado con éxito",
        success: "Sincronización activada con éxito"
      },
      neverSynced: "Nunca ha sido sincronizado",
      saveAndConnect: "Guardar y conectar",
      subtitle: "Conecta tus cuentas de portales de terceros con Zazume para publicar y distribuir tus propiedades",
      synced: "Sincronizado",
      title: "Integraciones con portales",
      unitsPublished: "Propiedades publicadas",
      useOrganizationContact: "Utilizar el contacto de la organización en las publicaciones",
      warning: "Has alcanzado el número máximo de propiedades que puedes publicar en este portal con tu plan"
    },
    supportEmail: {
      contactEmail: "Email de contacto",
      help: "Configurar un correo electrónico para recibir notificaciones de incidencias o procesos de solicitudes",
      title: "E-mail de soporte"
    },
    tabs: {
      accounting: "Contabilidad",
      agents: "Agentes",
      billing: "Facturación",
      general: "General",
      integrations: "Integraciones",
      lease: "Arrendamiento"
    },
    title: "Configuración de la organización",
    updatedMessage: "La configuración de la organización se ha actualizado con éxito",
    zendesk: {
      helpCenterEnabled: "Conectar con centros de ayuda"
    }
  },
  signatures: {
    addAnotherSigner: "Añadir otro firmante",
    editSigner: "Editar firmante",
    rejectWithEmailError: {
      description: "Para poder rechazar este documento es necesario que introduzcas un email válido. Prueba a poner tu correo y una vez solucionado el error podrás rechazar el documento.",
      title: "No se ha podido rechazar el documento"
    },
    sendToSignAgain: "Reenviar petición de firma",
    signerCount: "Firmante {{number}}",
    signerEmailError: "<1>{{email}}</1> el correo no es válido"
  },
  signup: {
    text: "¡Hola {{name}}! Último paso antes de iniciar sesión en su cuenta, cree una contraseña."
  },
  solvency: {
    consent: {
      sign: "Firmar consentimiento",
      signed: "Consentimiento firmado"
    },
    detail: "Detalle de solvencia",
    listing: {
      emptyState: "Aún no se han añadido solvencias",
      headers: {
        lastUpdate: "Última actualización",
        maxPrice: "Precio máximo",
        result: "Resultado"
      }
    },
    modals: {
      create: {
        createSolvency: "Crear solvencia",
        successMessage: "Solvencia creada con éxito",
        tenantDetails: "Detalles del inquilino",
        unitDetails: "Detalles de la propiedad"
      }
    },
    newSolvency: "Nueva solvencia",
    scoringPassedStatus: {
      "false": "Necesita refuerzo",
      "true": "Solvente"
    },
    send: "Finalizar y enviar a la aseguradora",
    statuses: {
      issued: "Póliza emitida",
      "needs-reinforcement": "Necesita refuerzo",
      "needs-review": "A revisar",
      "not-finished": "Sin finalizar",
      "pending-approval": "Pdte aceptación del seguro",
      "pending-completion": "Pendiente completar",
      "pending-consent": "Pdte consentimiento",
      "pending-submit": "Pdte enviar a la aseguradora",
      "ready-to-issue": "Listo para emitir",
      rejected: "Rechazado"
    },
    tabs: {
      about: {
        maxRent: {
          description: "Es el máximo precio de alquiler que podria pagar y ser solvente.",
          monthlyRent: "alquiler mensual",
          title: "Renta máxima permitida"
        }
      },
      people: {
        authorSection: {
          title: "Autor de la solvencia"
        },
        mainTenant: "Arrendatario principal",
        otherTenants: "Otros inquilinos"
      }
    },
    warnings: {
      badScoring: "Para continuar con el proceso de verificación de solvencia es necesario añadir un refuerzo a la solicitud, ya sea otro co-inquilino o un avalista. Una vez añadido, puedes volver a enviar a la aseguradora."
    }
  },
  tasks: {
    assignStaff: "Asignar a Staff",
    assignUnit: "Asignar a propiedad",
    assignZazu: "Asignar a Zazu",
    assignedTo: "Asignado a",
    attach: "Adjuntar archivo",
    categories: {
      checkout: "Checkout",
      deposits: "Fianzas",
      generic: "Genérica",
      incident: "Incidencia",
      offboarding: "Offboarding",
      onboarding_move_in: "Instalación",
      onboarding_protection_insurance: "Seguro Alquiler",
      onboarding_viewing: "Visita",
      rent_advance: "Adelanto de rentas",
      service_booking: "Reservas Servicio"
    },
    checklist: "Subtareas",
    close: "Cerrar tarea",
    closedTask: "Tarea cerrada",
    createToast: "Tarea creada con éxito",
    dashboard: {
      view: "Ver todas las tareas"
    },
    deleteTask: "Eliminar tarea",
    deleteToast: "Tarea eliminada correctamente",
    description: "Descripción",
    detail: {
      title: "Detalle de la tarea"
    },
    edit: {
      title: "Editar tarea"
    },
    editDetails: "Editar detalles",
    editTask: "Editar tarea",
    empty: "No hay tareas programadas",
    emptyState: "Para añadir tareas a esta sección",
    incident: {
      required: "Se requiere una incidencia"
    },
    newChecklistModal: {
      addDescription: "Añadir una descripción",
      externalLinkPlaceholder: "Escribe o pega el enlace aquí",
      externalLinkToggle: "Añadir un enlace externo",
      teamMemberPlaceholder: "Elige a un miembro del equipo",
      teamMemberToggle: "Asignar a un miembro del equipo"
    },
    newTask: {
      add: "Añadir tarea",
      addChecklist: "Añadir sub-tarea",
      description: "Descripción aún no configurada",
      descriptionPlaceholder: "Añadir la descripción de la tarea",
      linkError: "El enlace debe incluir http:// o https://",
      title: "Nueva tarea",
      titlePlaceholder: "Asunto de la tarea",
      today: "Hoy"
    },
    newTaskModalSelector: {
      confirm: "Crear tarea",
      createFromTemplate: "Crear tarea a partir de una plantilla",
      createFromblank: "Crear nueva tarea",
      templateSelect: {
        label: "Seleccionar plantilla",
        placeholder: "Elegir plantilla"
      }
    },
    noDescriptionPlaceholder: "Tarea sin descripción",
    postpone: "Snooze/Posponer",
    readMore: "Leer más",
    relatedTasks: "Tareas relacionadas",
    reopen: "Volver a abrir la tarea",
    reopenToast: "La tarea se ha abierto correctamente",
    reschedule: "Reprogramar",
    rescheduleTask: "Reprogramar tarea",
    rescheduleToast: "Nueva fecha seleccionada {{date}}",
    scheduledTasks: "Tareas programadas",
    setAsCompleted: "Marcar como completado",
    statuses: {
      completed: "Completado",
      pending: "Pendiente"
    },
    submitBtn: {
      createBtn: "Crear tarea",
      updateBtn: "Actualizar tarea"
    },
    tasksAndEvents: "Tareas y eventos",
    time: {
      afternoon: "Tarde - 17:00pm",
      midday: "Medio día - 12:00pm",
      morning: "Mañana - 9:00am"
    },
    toggleLabel: "Enlace externo",
    unassigned: "Sin asignar",
    updateToast: "Tarea actualizada",
    viewing: {
      byApplicant: "por el solicitante",
      byOrganization: "por la organización",
      calendar: "Descargar archivo de calendario",
      cancel: "Cancelar visita",
      cancelDescription: "¿Seguro que quieres cancelar esta visita?",
      cancelled: "Cancelado",
      confirmButton: "Sí, cancelar la visita",
      descriptionTitle: "Detalles de la visita",
      goBack: "Volver atrás",
      location: "Ver ubicación en Maps",
      reschedule: "Reagendar visita",
      title: "Visita",
      waitingReschedule: "Esperando para reagendar"
    },
    waitingReschedule: "Esperando para reagendar"
  },
  taxes: {
    areTaxesIncluded: "¿Ya están incluidos los impuestos en el importe?",
    areTaxesIncludedInDiscountAmount: "¿Están incluidos los impuestos en el importe (con el descuento ya aplicado)?",
    retentions: "Retenciones",
    retentionsAmount: "Retenciones ({{amount}}%)",
    title: "Impuestos",
    vat: "IVA",
    vat0InfoAlert: "IVA igual a 0 significa operación exenta de IVA, está en el ámbito de aplicación del impuesto, pero se exime de su pago. Si la operación no está sujeta a IVA, es decir, no es aplicable a la operación, no completar este campo.",
    vatAmount: "IVA ({{amount}}%)"
  },
  team: {
    access: {
      all: "Todos los workspaces",
      many: "{{count}} workspaces",
      one: "1 workspace"
    },
    editModal: {
      title: "Editar {{email}}"
    },
    invite: "Invitar a un miembro",
    inviteModal: {
      categories: "Elegir categorías",
      mandatory: "Campo obligatorio",
      send: "Enviar invitación",
      tenantLanguage: "Idioma",
      title: "Invitar a un nuevo miembro",
      workspaces: "Acceso a workspaces (opcional)"
    },
    noRoleSelected: "Rol no seleccionado",
    pending: "Invitación pendiente",
    removeDescription: "Esto eliminará al miembro del equipo seleccionado. Esta acción no se puede deshacer.",
    removeTitle: "¿Eliminar miembro del equipo?",
    removed: "Miembro del equipo eliminado",
    roles: {
      admin: "Admin",
      agent: "Agente",
      buildingManager: "Gestor del edificio",
      commercial: "Ventas",
      managerOwner: "Gerente Propietario",
      managerOwnerBasic: "Propietario básico",
      managerOwnerMiddle: "Propietario avanzado",
      owner: "Propietario",
      ownerBasic: "Propietario básico",
      ownerManager: "Gerente Propietario",
      ownerMiddle: "Propietario avanzado",
      smallOwner: "Propietario básico",
      staff: "Personal",
      tenant: "Residente",
      viewer: "Sólo lectura",
      zazume: "Zazume"
    },
    table: {
      emptyState: "Aún no se ha creado ningún usuario.",
      headers: {
        access: "Acceso",
        name: "Nombre",
        role: "Rol"
      },
      options: {
        remove: "Eliminar",
        view: "Ver miembro"
      }
    },
    tags: {
      cleaning: "Limpieza",
      concierge: "Conserje",
      maintenance: "Mantenimiento"
    },
    title: "Miembros"
  },
  templates: {
    addTemplate: "Añadir plantilla",
    created: "Plantilla creada con éxito",
    customVariables: {
      inputs: {
        label: "Descripción"
      },
      title: "Variables personalizadas",
      types: {
        "boolean": "Booleano",
        number: "Número",
        text: "Texto"
      }
    },
    deleted: "Plantilla eliminada con éxito",
    details: "Detalles de la plantilla",
    editTemplate: "Editar plantilla",
    edited: "Plantilla editada con éxito",
    emptyState: "Aún no se han creado plantillas",
    filter: {
      type: "Tipo de documento"
    },
    language: "Idioma",
    mergeTags: {
      address: "{{entity}} - Dirección",
      annualRent: "Alquiler anual",
      bank: "{{entity}} - Entidad bancaria",
      daysBonification: "Días de bonificación para traslado y limpieza",
      effectiveDate: "{{entity}} - Fecha de inicio",
      email: "{{entity}} - Email",
      endingDate: "{{entity}} - Fecha de finalización",
      entryMonth: "Mes de entrada",
      entryMonthRentAmount: "Importe del alquiler del mes de entrada",
      iban: "{{entity}} - IBAN",
      id: "{{entity}} - Identificación",
      name: "{{entity}} - Nombre",
      nationality: "{{entity}} - Nacionalidad",
      phone: "{{entity}} - Teléfono",
      tenantsCount: "Cantidad de inquilinos"
    },
    name: "Nombre de la plantilla",
    newTemplate: "Nueva plantilla",
    placesholders: {
      language: "Idioma",
      name: "Dale un nombre a tu plantilla",
      type: "Selecciona un tipo de documento"
    },
    removeMessage: "Esto eliminará la plantilla seleccionada de forma permanente. Esta acción no se puede deshacer.",
    removeTitle: "¿Eliminar plantilla?",
    tabs: {
      documents: "Documentos"
    },
    template: "Plantilla",
    title: "Plantillas",
    type: "Tipo de documento",
    types: {
      commercialization: "Comercialización",
      leasing: "Arrendamiento",
      management: "Gestión",
      other: "Otro"
    }
  },
  tenants: {
    detail: {
      aboutTheApplicant: {
        editApplicant: "Editar solicitante",
        employmentAndEconomicSituation: {
          employmentRole: {
            employee: "Empleado/a",
            freelance: "Autónomo",
            official: "Funcionario/a",
            pensions: "Pensionista",
            student: "Estudiante",
            temporalEmployee: "Empleado temporal",
            withoutIncomes: "Sin ingresos"
          },
          employmentSituation: "Situación laboral",
          guarantor: "Avalista",
          inputPlaceholder: "€ Ingresos netos totales de todos los inquilinos",
          selectAnEmploymentSituation: "Selecciona una situación laboral",
          title: "Situación laboral y económica",
          totalNetIncome: "Ingresos netos totales"
        },
        rentalDetails: {
          contractType: {
            longTerm: "Larga estancia",
            shortTerm: "Corta estancia"
          },
          entryDate: "Fecha de entrada",
          rentalType: "Tipo de alquiler",
          selectARentalType: "Selecciona un tipo de alquiler",
          title: "Detalles del alquiler"
        },
        tenantsAndPetInformation: {
          adults: "Adultos",
          children: "Niños",
          doesTheApplicantHavePets: "¿El solicitante tiene mascotas?",
          petType: {
            cat: "Gato",
            dog: "Perro",
            others: "Otros"
          },
          pets: "Mascotas",
          roomateRelation: {
            couple: "Pareja",
            family: "Familia",
            individual: "Individual",
            roomates: "Compañeros de piso",
            students: "Estudiantes"
          },
          selectATenantProfile: "Selecciona un perfil de inquilino",
          tenantProfile: "Perfil del inquilino",
          tenants: "Los inquilinos",
          title: "Información sobre inquilinos y mascotas",
          totalTenants: "Inquilinos totales"
        },
        title: "Precualificación del solicitante"
      },
      assignProperty: "Asignar propiedad",
      assignUnit: "Asignar propiedad",
      changeUnit: "Cambiar propiedad",
      edit: "Editar inquilino",
      editNofificationSettings: "Editar la configuración de notificaciones",
      editPreScoring: "Editar cualificación del solicitante",
      editTenantModalBtn: "Actualizar inquilino",
      editTenantModalTitle: "Editar inquilino",
      editTenantNotificationSettingsModalTitle: "Editar configuración de notificaciones",
      editTenantPreScoringModalBtn: "Actualizar precualificación",
      editTenantPreScoringModalTitle: "Editar precualificación",
      emailNotifications: "Notificaciones de correo",
      emptyUnits: "Propiedades vacías",
      endDate: "Fecha de salida",
      inviteSent: "Invitación enviada",
      inviteTip: "{{name}} aún no tiene acceso a la plataforma",
      inviteTipDescription: "Asegúrate que la han recibido, enviando una invitación por email",
      notificationSettings: "Configuración de notificaciones",
      personalDetails: "Datos personales",
      preScoringLinkButton: "Enlace de precualificación",
      preScoringLinkButtonSuccess: "¡Enlace copiado al portapapeles!",
      pushNotifications: "Notificaciones push",
      removeTenantModalText: "Esto eliminará el inquilino seleccionado permanentemente. Esta acción no se puede deshacer.",
      removeTenantModalTitle: "¿Eliminar inquilino?",
      removeUnit: "Eliminar propiedad",
      resendInvite: "Volver a enviar",
      searchProperties: "Buscar propiedades",
      searchUnits: "Buscar propiedades",
      sendInvite: "Enviar invitación",
      startDate: "Fecha de entrada",
      suggestedProperties: "Propiedades sugeridas",
      suggestedUnits: "Propiedades sugeridas",
      tab: {
        about: "Sobre",
        bookings: "Servicios reservados",
        docs: "Documentos",
        notificationSettings: "Notificaciones"
      },
      tenantAlreadyAssigned: "Inquilino ya asignado",
      title: "Detalle del inquilino",
      unassignUnitText: "Esto eliminará al inquilino de la propiedad seleccionada. Puede reasignarlos en cualquier momento.",
      unassignUnitTitle: "¿Eliminar Propiedad?",
      unitAssignSuccess: "Propiedad asignada correctamente",
      unitAssigned: "Propiedad asignada",
      unitUnassignSuccess: "Propiedad no asignada correctamente",
      updatedTenant: "Inquilino actualizado correctamente",
      updatedTenantPreScoring: "La precualificación se ha actualizado con éxito"
    },
    empty: "Aún no se han añadido inquilinos",
    form: {
      contactNumber: "Número de contacto",
      emailError: "Correo electrónico no válido: name@email.com",
      emailNotifications: "Enviar notificaciones por correo electrónico",
      mandatory: "Campo obligatorio",
      noWorkspace: "Sin workspace",
      pushNotifications: "Enviar notificaciones push",
      "short": "Campo demasiado corto",
      tenantLanguage: "Idioma",
      tenantName: "Nombre del inquilino"
    },
    globalNotifications: "Notificaciones globales",
    invitationAccepted: "Se aceptó invitación",
    invitationNotSent: "Invitación no enviada",
    invitationPending: "Invitación pendiente",
    list: {
      addTenantBtn: "Añadir un inquilino",
      addTenantModalBtn: "Añadir inquilino",
      addTenantModalTitle: "Añadir un nuevo inquilino",
      appAccess: "Acceso a la aplicación",
      invitationNotAllowed: "No disponible",
      invitationNotAllowedTooltip: "Para enviar una invitación es obligatorio tener una aplicación o propiedad vinculada",
      staffAssigned: "Personal asignado",
      successfullAdd: "El inquilino se ha añadido correctamente",
      tenantDetails: "Detalles del inquilino",
      tenantRemoved: "Inquilino eliminado correctamente",
      title: "Inquilinos",
      unassigned: "Sin asignar"
    },
    types: {
      applicant: "Solicitante",
      living: "Instalado",
      none: "Ninguna"
    }
  },
  units: {
    acquisitionConditions: {
      acquisitionAt: "Fecha de adquisición",
      acquisitionType: {
        inheritance: "Lucrativa (Herencia, legado, donación, etc.)",
        sale: "Onerosa (Compraventa, permuta , etc)"
      },
      mortgage: {
        amount: "Cantidad total",
        amountPlaceholder: "Ej. 300000",
        durationInMonths: "Duración total de la hipoteca (en número de meses)",
        durationInMonthsPlaceholder: "Ej. 180",
        firstChargeAt: "Primera cargo",
        interestRate: "Tipo de interés",
        otherExpenses: "Otros gastos",
        otherExpensesPlaceholder: "Ej. 12000",
        title: "Hipoteca"
      },
      otherExpenses: "Gastos de adquisición",
      otherExpensesPlaceholder: "Ej. 12000",
      ownership: "¿Qué porcentaje de la propiedad posee?",
      price: "Importe de adquisición",
      pricePlaceholder: "Ej. 360000",
      taxes: "Impuestos",
      taxesPlaceholder: "Ej. 12000",
      title: "Detalles de la adquisición",
      type: "¿Cómo se adquiere la propiedad?"
    },
    address: "Dirección",
    alarm: "Alarma",
    availabilityDate: "Fecha de disponibilidad",
    availabilitySet: "Configura la disponibilidad de la propiedad",
    baths: "Baños",
    block: "Bloque",
    building: "Edificio",
    buildingFacade: "Fachada del edificio",
    bulkActions: {
      assignPeople: {
        confirmationTitle: "¿Deseas asignar a {{name}} como {{role}} en estas propiedades?",
        description: "Estás a punto de asignar a {{name}} como {{role}} en <1>{{unitsCount}}</1>.",
        success: "Solicitud de asignación enviada",
        title: "Asignar personas"
      }
    },
    cadastralData: {
      cadastralOffice: "Sede electrónica del Catastro",
      cadastralValue: "Valor catastral",
      constructionValue: "Valor de construcción",
      constructionValuePlaceholder: "Ej. 250000",
      entryDoorMaterial: "Material de la puerta de entrada",
      facadeAndStructure: "Fachada y estructura",
      facadeMaterial: "Material de fachada del edificio",
      introduction: "Para completar la información solicitada en este apartado necesitarás iniciar sesión con tu DNI en la",
      landValue: "Valor del terreno",
      landValuePlaceholder: "Ej. 90000",
      location: "Ubicación",
      quality: "Calidad",
      reference: "Número de referencia catastral",
      referencePlaceholder: "Ej. EGS736SGTEY36",
      restaurationYear: "¿En qué año?",
      restored: "Restaurado",
      title: "Datos catastrales",
      windowsMaterial: "Material de ventanas",
      windowsOrientation: "Orientación de ventanas",
      woodenStructure: "Estructura de madera"
    },
    characteristics: {
      bath: "Baño",
      bath_plural: "Baños",
      bathrooms: "Baños",
      bed: "hab",
      bed_plural: "habs",
      bedrooms: "Dormitorios",
      checkbox: {
        airConditioning: "Aire acondicionado",
        alarm: "Alarma",
        balcony: "Balcón",
        checkboxTitle: "Esta propiedad incluye:",
        checkboxTitle2: "Otras características de esta propiedad:",
        concierge: "Conserje",
        equipedKitchen: "Cocina equipada",
        fridge: "Frigorífico",
        garage: "Garaje",
        garden: "Jardín",
        heating: "Calefacción",
        homeAppliances: "Electrodomésticos",
        internet: "Internet",
        jacuzzi: "Jacuzzi",
        lift: "Ascensor",
        microwaves: "Microondas",
        oven: "Horno",
        parking: "Aparcamiento",
        parquet: "Parquet",
        petsAllowed: "Admite mascotas",
        playground: "Área de juegos",
        security24h: "Seguridad 24h",
        storageRoom: "Trastero",
        swimmingPool: "Piscina",
        terrace: "Terraza",
        tv: "Tv",
        wardrobe: "Armario",
        washingMachine: "Lavadora"
      },
      condition: "Condición",
      conditionPlaceholder: "Selecciona la condición de la propiedad",
      conditions: {
        "new": "Nueva",
        ruin: "Ruina",
        used: "Usado",
        very_good: "Muy buena"
      },
      constructionYear: "Año de construcción",
      direction: "Dirección",
      directions: {
        exterior: "Exterior",
        interior: "Interior"
      },
      emissionsConsumptionRating: "Escala eficiencia emisiones",
      emissionsConsumptionUnitOfMeasure: "CO2/m² año",
      emissionsConsumptionValue: "Valor eficiencia emisiones",
      energyCertificate: "Certificado energético",
      energyEfficiencyRating: "Escala eficiencia consumo",
      equipment: "Equipamiento",
      equipmentOptions: {
        furnished: "Amueblado",
        notFurnished: "No amueblado"
      },
      floor: {
        ground: "Planta baja",
        label: "Planta",
        middle: "Planta media",
        not_applicable: "No aplica",
        top: "Última planta"
      },
      fuelType: {
        butane: "Butano",
        electricity: "Electricidad",
        gas: "Gas",
        gasOil: "Gas-oil",
        label: "Tipo de combustible",
        propane: "Propano",
        solar: "Solar"
      },
      heater: "Calefacción",
      heatingType: {
        central: "Central",
        individual: "Individual",
        label: "Tipo de calefacción",
        none: "Sin calefacción"
      },
      mainUse: "Selecciona el uso principal de esta propiedad",
      newDevelopment: "Obra nueva",
      orientation: {
        east: "Este",
        north: "Norte",
        orientation: "Orientación",
        south: "Sur",
        west: "Oeste"
      },
      otherCharacteristics: "Otras características de esta propiedad",
      powerConsumptionUnitOfMeasure: "kWh/m² año",
      powerConsumptionValue: "Valor eficiencia consumo",
      propertyType: "Tipo de propiedad",
      referenceIndex: "Índice de referencia",
      referenceIndexToolTip: "Puedes conseguirlo de forma gratuita rellenando el formulario oficial de la Agencia de la Vivienda de Cataluña ",
      referenceIndexUnit: "€/m²",
      residentialPropertyTypes: {
        apartment: "Apartamento",
        chalet: "Chalet",
        country_house: "Casa de campo",
        detached_chalet: "Chalet independiente",
        detached_house: "Casa unifamiliar",
        duplex: "Dúplex",
        duplex_apartment: "Apartamento dúplex",
        duplex_flat: "Piso dúplex",
        flat: "Piso",
        ground_house: "Casa de campo",
        house: "Casa",
        independent_chalet: "Chalet independiente",
        independent_house: "Casa independiente",
        loft: "Loft",
        mansion: "Mansión",
        penthouse: "Ático",
        rustic_house: "Casa rústica",
        semidetached_chalet: "Chalet adosado",
        semidetached_house: "Casa adosada",
        studio: "Estudio",
        town_house: "Casa de pueblo",
        undefined: "-"
      },
      residentialType: "Tipo residencial",
      selectMainUse: "Selecciona el uso principal",
      surfaceArea: "Superficie",
      title: "Características",
      unitIncludes: "Esta propiedad incluye"
    },
    city: "Ciudad",
    confirmationModal: {
      description: "Esto eliminará la propiedad seleccionada permanentemente, anulando la asignación de todos los inquilinos relacionados con ella. Esta acción no se puede deshacer.",
      title: "¿Eliminar propiedad?",
      toast: "Esta propiedad tiene aplicaciones y no se puede eliminar"
    },
    consent: {
      filter: "Consentimiento",
      tag: "Sin consentimiento",
      toggle: "¿Hay consentimiento para publicar?"
    },
    detail: {
      about: {
        addMultimedia: "Añadir multimedia",
        addPhotos: "Añadir imágenes",
        cadastralTitle: "Datos catastrales",
        characteristicsTitle: "Características de la propiedad",
        cupsElectricity: "CUPS de electricidad",
        cupsElectricityTooltip: "Código alfanumérico de 20 o 22 dígitos que aparece en la factura de la luz. No varia de una factura a otra y en España empieza por ES",
        cupsGas: "CUPS de Gas",
        cupsGasTooltip: "Código alfanumérico de 20 o 22 dígitos que aparece en la factura del gas. No varia de una factura a otra y en España empieza por ES",
        editVirtualTour: "Editar tour virtual",
        guaranteesTitle: "Garantías",
        infoTitle: "Información básica",
        insuranceAndSupplies: "Seguros y suministros",
        locationTitle: "Ubicación",
        paymentMethodTitle: "Método de pago",
        photoDeleteDescription: "Esto eliminará la foto seleccionada de forma permanente. Esta acción no se puede deshacer.",
        photoDeleteDescriptionAll: "Se eliminarán todas las fotos de forma permanente. Esta acción no se puede deshacer.",
        photoDeleteTitle: "¿Eliminar foto?",
        photoDeleteTitleAll: "¿Eliminar todas las fotos?",
        photoSelectPlace: "Elige un lugar...",
        photoUpdatedSucessfully: "Foto actualizada correctamente",
        photosModalDescription: "Selecciona las fotos que quieres añadir a esta propiedad.",
        photosModalTitle: "Añadir nueva imagen",
        photosSort: {
          deleteAll: "Borrar todas",
          drag: "Puedes arrastrar las imágenes para ordenarlas como quieras.",
          dropdown: "Editar fotos",
          photo: "foto",
          photos: "Fotos ({{count}})",
          photosLowerCase: "fotos",
          title: "Editar fotos"
        },
        photosTag: {
          appraisalplan: "Plan de tasación",
          archive: "Archivo",
          atmosphere: "Ambiente",
          balcony: "Balcón",
          basement: "Sótano",
          bathroom: "Baño",
          bedroom: "Dormitorio",
          buildingwork: "Obras de construcción",
          cellar: "Bodega",
          communalareas: "Zonas comunes",
          corridor: "Pasillo",
          details: "Detalles",
          dining_room: "Comedor",
          energycertificate: "Certificado energético",
          facade: "Fachada",
          garage: "Garaje",
          garden: "Jardín",
          gateway: "Portal",
          hall: "Entrada",
          kitchen: "Cocina",
          land: "Terreno",
          lifts: "Ascensores",
          living: "Salón",
          loft: "Buhardilla",
          mates: "Compañeros",
          meeting_room: "Sala de reuniones",
          office: "Oficina",
          open_plan: "Plano abierto",
          patio: "Patio",
          penthouse: "Ático",
          plan: "Plano",
          pool: "Piscina",
          porch: "Porche",
          pressphoto: "Foto de prensa",
          reception: "Recepción",
          room: "Habitación",
          shop_window: "Escaparate",
          staircase: "Escalera",
          storage: "Almacén",
          storage_space: "Espacio de almacenamiento",
          studio: "Estudio",
          surroundings: "Alrededores",
          terrace: "Terraza",
          unknown: "Desconocido",
          views: "Vistas",
          waitingroom: "Sala de espera",
          walk_in_wardrobe: "Vestidor"
        },
        photosTitle: "Fotos",
        rentTitle: "Alquiler",
        rentalConditionsTitle: "Condiciones de alquiler",
        saleConditionsTitle: "Condiciones de venta",
        unitTypeTitle: "Tipo de propiedad",
        videoSection: {
          addVideos: "Añadir vídeos",
          dropdown: "Editar vídeos",
          title: "Vídeos ({{count}})",
          videoDeleteDescription: "Esto eliminará el vídeo seleccionado de forma permanente. Esta acción no se puede deshacer.",
          videoDeleteTitle: "¿Eliminar vídeo?"
        },
        videosModalDescription: "Selecciona los vídeos que quieres añadir a esta propiedad.",
        videosModalTitle: "Subir vídeo nuevo",
        viewLess: "Ver menos detalles ",
        viewMore: "Ver más detalles",
        virtualTour: {
          "delete": "Eliminar tour virtual",
          deleted: "Tour virtual eliminado correctamente",
          description: "Agregar un tour virtual permite a los solicitantes caminar virtualmente por su futuro hogar como si estuvieran allí.",
          invalidUrl: "Debes introducir una url válida",
          label: "Añadir la URL del tour virtual",
          placeholder: "introduce la URL del tour virtual",
          smallTitle: "Tour virtual",
          title: "Editar tour virtual",
          updated: "Tour virtual actualizado correctamente"
        }
      },
      assign: "Asignar {{role}}",
      assignPerson: "Asignar persona",
      assignPersonSuccess: "Persona asignada correctamente",
      assignSuccess: "{{role}} correctamente asignado",
      bathroom: "baño",
      bathrooms: "baños",
      bedroom: "dormitorio",
      bedrooms: "dormitorios",
      blockPayouts: {
        alert: "Los pagos de esta propiedad están bloqueados",
        block: "Bloquear pagos",
        unblock: "Desbloquear pagos"
      },
      change: "Cambiar {{role}}",
      changeOwnership: "Cambiar % propiedad",
      changePerson: "Cambiar persona",
      contract: {
        availabilityDates: "Fechas de disponibilidad",
        basicTitle: "Información básica",
        contractTitle: "Contrato",
        currentContract: "Contrato vigente",
        signers: "Firmantes",
        viewOld: "Ver contratos antiguos"
      },
      edit: "Editar propiedad",
      editQualification: {
        contractTypePlaceholder: "Selecciona un tipo de alquiler",
        employmentSituation: "Situación laboral y económica",
        ownerAllowPets: "¿El propietario admite mascotas?",
        rentalDetails: "Detalles del alquiler",
        tenantsPetInformation: "Información sobre inquilinos y mascotas",
        title: "Editar la cualificación del solicitante",
        updateCTA: "Actualizar cualificación"
      },
      id: "ID: {{unitId}}",
      inventory: {
        addItem: "Añadir",
        allItems: "Todos",
        documents: "Inventario",
        editItem: "Editar",
        itemAdded: "El artículo se ha añadido correctamente",
        itemEdited: "El artículo se ha editado correctamente",
        itemNotAdded: "No se puede añadir el artículo. Pruebe más tarde.",
        itemNotEdited: "No se puede editar el artículo. Pruebe más tarde.",
        itemNotRemoved: "No se puede eliminar el artículo. Pruebe más tarde.",
        itemRemoved: "El artículo se eliminó correctamente",
        modal: {
          input: {
            images: {
              helper: "Solo archivos JPG o PNG con un tamaño máximo de 2MB",
              label: "Imágenes"
            },
            name: "Nombre",
            notes: "Notas",
            place: {
              label: "Lugar",
              options: {
                attic: "Ático",
                balcony: "Balcón",
                basement: "Sótano",
                bathroom: "Baño",
                bedroom: "Dormitorio",
                cellar: "Bodega",
                chimney: "Chimenea",
                dining_room: "Comedor",
                garage: "Garaje",
                garden: "Jardín",
                kitchen: "Cocina",
                living_room: "Salón",
                other: "Otros",
                pool: "Piscina",
                roof: "Techo",
                room: "Habitación",
                sitting_room: "Cuarto de estar",
                staircase: "Escalera",
                study: "Estudio",
                toilet: "Baño",
                window: "Ventana"
              },
              placeholder: "Selecciona uno"
            },
            price: "Precio",
            quantity: "Cantidad",
            status: {
              label: "Estado",
              options: {
                damaged: "Dañado",
                "new": "Nuevo",
                used: "Usado"
              }
            }
          },
          modalTitle: "Añadir nuevo artículo",
          title: "Detalle del artículo"
        },
        removeItem: "Eliminar",
        removeModalContent: "Esto eliminará el artículo seleccionado de forma permanente. Esta acción no se puede deshacer.",
        table: {
          empty: "No hay artículos en el inventario :)",
          headers: {
            name: "Nombre",
            place: "Lugar",
            quantity: "Cantidad"
          }
        },
        title: "Lista de inventario",
        unit: "propiedad",
        unitCount: "{{count}} unidad",
        unitCount_other: "{{count}} unidades",
        units: "unidades"
      },
      lease: {
        attachDocument: {
          files: {
            description: "Sube el documento adjunto para firmar. Una vez hecho, envíalo a los destinatarios para que lo firmen.",
            fileType: "Solo archivos PDF con un tamaño máximo de {{maxFileSizeMB}} MB",
            title: "Documento"
          },
          notToSignButton: "Guardar documento",
          signers: {
            addNewSigner: "Añadir nuevo firmante",
            hasBeSigned: "¿Hay que firmar este documento?",
            title: "Firmantes"
          },
          subtitle: "Adjuntar documento",
          title: "Adjuntar documento",
          toSignButton: "Enviar documento para firmar",
          toast: "Documento adjunto"
        },
        "break": {
          action: "Ruptura de contrato",
          modal: {
            confirmation: {
              cta: "Sí, quiero",
              description: "Una vez iniciado el proceso, esta acción no se puede deshacer.",
              title: "¿Quieres romper el contrato de alquiler actual?"
            },
            contractTitle: "Documento contractual definitivo",
            cta: "Enviar documento a firmar",
            endingLease: "Fin del arrendamiento",
            endingLeaseDate: "Fecha de finalización",
            endingLeaseDateTitle: "Escoge cuándo será el último día del alquiler",
            keyDeliveryTitle: "Entrega de llaves",
            signers: "Firmantes",
            success: "Contrato roto",
            title: "Romper el contrato",
            uploadContractTitle: "Sube el contrato",
            uploadDocumentDescription: "Sube y selecciona si este documento debe ser firmado.\nUna vez hecho, se enviará a los destinatarios para que lo firmen.",
            uploadDocumentDescription2: "Se adjuntará al contrato de arrendamiento actual."
          }
        },
        breakRequested: {
          alert: "Los inquilinos(as) han solicitado rescindir el contrato de arrendamiento actual el <1>{{date}}</1>."
        },
        checkout: {
          endingLeaseDate: "Fecha de finalización:",
          title: "Salida"
        },
        currentLease: {
          detailsModal: {
            basicInformation: {
              cpiNotification: "El precio actualizado se aplicará a partir de <strong>{{date}}</strong>. Todos los pagos anteriores serán de <strong>{{price}}</strong>.",
              finish: "Fin",
              price: "Precio",
              start: "Inicio",
              title: "Información básica"
            },
            breakContract: {
              title: "Romper el contrato"
            },
            buttonNewAttachDocument: "Adjuntar nuevo documento",
            documents: {
              tag: {
                expired_signature: "Firma expirada",
                rejected: "rechazado",
                signed: "Firmado",
                waiting: "En espera"
              },
              title: "Documentos"
            },
            expiresAt: "Expira el",
            insuranceContract: {
              title: "Contrato de seguro"
            },
            tenants: {
              admin: "Administrador",
              coTenants: "Co-inquilinos",
              title: "Inquilinos"
            },
            title: "Contrato"
          },
          title: "Contrato actual",
          viewDetails: "Ver detalles"
        },
        editLease: {
          modal: {
            disclaimer: "Los detalles del contrato deben especificar lo que aparece en el documento escrito y firmado. Te recomendamos que, al modificar cualquier información del formulario, añadas también un anexo firmado por ambas partes. De esta forma, el cambio también será válido a efectos legales.",
            onSuccess: "Contrato actualizado correctamente",
            saveButton: "Guardar contrato",
            title: "Editar contrato"
          }
        },
        empyState: "Aún no hay contrato de alquiler",
        existingLease: {
          button: "Añadir contrato",
          dates: {
            end: "Finalización del contrato",
            start: "Inicio del contrato",
            title: "Fechas del alquiler"
          },
          files: {
            description: "Sube el contrato de alquiler firmado por el inquilino y co-inquilinos, para tenerlo disponible en Zazume.",
            fileType: "Solo archivos PDF con un tamaño máximo de {{maxFileSizeMB}} MB",
            title: "Contrato de alquiler"
          },
          managementContractMessages: {
            error: {
              message1: "El contrato de arrendamiento está configurada para que el pago del alquiler mensual esté dirigido a la Organización, sin embargo no hay ningún contrato de gestión creado. Edita hacia quien va dirigido el alquiler o sube un contrato de gestión para continuar."
            },
            info: {
              message1: "Hay un contrato de gestión creado para esta propiedad que se aplicará, con un servicio <1>{{serviceName}}</1> y una tarifa de <2>{{managementFee}}</2>"
            },
            warning: {
              message1: "El contrato de arrendamiento está configurado para que el pago del alquiler mensual esté dirigido al propietario, sin embargo hay un contrato de gestión creado. Edita hacia quien va dirigido el alquiler si es necesario o si no, haz caso omiso a este mensaje.",
              message2: "Esta propiedad tiene un contrato de gestión como borrador, recuerda que el contrato debe estar subido y firmado por todas las partes.",
              message3: "Esta propiedad tiene un contrato de gestión pendiente de firma, recuerda que el contrato debe estar subido y firmado por todas las partes."
            }
          },
          maxMonthsExtension: "Meses máximos de prórroga",
          monthsExtension: "Meses de prórroga",
          notify: {
            endLease: "Fin del contrato (meses)",
            endLeaseExtension: "Fin de la prórroga (meses)",
            label: "Notificar el final del arrendamiento (y la extensión)"
          },
          rental: {
            question: {
              negativeAnswer: "No, déjame cambiarlos",
              positiveAnswer: "Sí, quiero utilizar las condiciones por defecto",
              title: "¿Desea utilizar las condiciones de alquiler por defecto de esta propiedad?"
            },
            title: "Condiciones del alquiler"
          },
          subtitle: "Detalle del alquiler",
          tenants: {
            button: "Agregar nuevo co-inquilino",
            deleteModal: {
              description: "Se eliminará al co-inquilino de la definición del contrato de alquiler",
              title: "Co-Inquilino va a ser eliminado"
            },
            descriptionAdmin: "Este firmante no se puede eliminar ni editar",
            selectAdmnin: "Marcar como inquilino principal",
            titleAdmin: "Administrador de inquilinos",
            titleCoTenant: "Co-Inquilino"
          },
          title: "Registrar contrato de alquiler existente",
          toast: "Contrato registrado",
          withLeaseExtensionLabel: "Añadir prórroga al contrato"
        },
        extraEmptyStateMessage: {
          invited: {
            item1: "Añadir nuevos inquilinos",
            item2: " enviarles una invitación."
          }
        },
        historical: {
          end: "Final",
          hideTable: "Ocultar contratos antiguos",
          price: "Precio",
          showTable: "Mostrar contratos antiguos",
          start: "Inicio"
        },
        modal: {
          buttons: {
            currentLease: "Registrar contrato existente",
            newLease: "Nuevo contrato de alquiler a través de una solicitud"
          },
          subtitle: "Puede agregar un nuevo contrato de alquiler creando una aplicación o registrando una existente.",
          title: "Añadir contrato"
        },
        newLease: "Nuevo contrato",
        servicesContract: {
          addServiceTypeSelectorModal: {
            subtitle: "Elija el servicio que desea configurar en esta propiedad",
            title: "Agregar servicio"
          },
          emptyState: "Para agregar un servicio nuevo",
          emptyStateWihoutAction: "Aún no hay contractos activos",
          infoModal: {
            message: "Ya existe un contrato de gestión activo o en borrador para esta propiedad. Si desea crear un nuevo contrato de gestión, primero debes romper el contrato existente.",
            title: "No se puede crear un nuevo contrato de gestión"
          },
          title: "Contrato de servicios"
        },
        warning: "No se puede registrar un nuevo contrato de arrendamiento sin propietario. Añada el propietario para continuar."
      },
      notAssigned: "No asignado",
      ownershipPercentage: "% de propiedad",
      people: {
        accountManager: "Gestor de cuenta",
        addAccountManager: "Añadir gestor de cuenta",
        addAgent: "Añadir agente",
        addIncidentsManager: "Añadir gestor de incidencias",
        addOwner: "Añadir propietario",
        addProvidedBy: "Añadir agregado por",
        addStaff: "Añadir staff",
        addTenant: "Añadir inquilino",
        addUnitManager: "Añadir gestor de la propiedad",
        agentTitle: "Agente",
        changeIncidentsManager: "Cambiar Gestor de incidencias",
        changeOwner: "Cambiar propietario",
        changeStaff: "Cambiar personal",
        changeTenant: "Cambiar inquilino",
        incidentsManager: "Gestor de incidencias",
        ownerLeadTitle: "Futuro propietario",
        ownerTitle: "Propietarios",
        ownershipPercentage: "{{value}}% de propiedad",
        providedByTitle: "Proporcionado por",
        staffTitle: "Personal",
        tenantsTitle: "Inquilinos",
        unitManager: "Gestor de la propiedad"
      },
      peopleTooltipDescription: {
        accountManager: "Persona que se encarga de la cuenta de esta propiedad, como servicios o tarifas asociadas a la misma.",
        agent: "Persona que se encarga de realizar las visitas a la propiedad.",
        incidentsManager: "Persona que se encarga de realizar la gestión de incidencias de la propiedad.",
        providedBy: "Persona que ha proporcionado esta propiedad.",
        unitManager: "Persona que se encarga de gestionar la propiedad durante el proceso de búsqueda de inquilinos."
      },
      portals: {
        addressVisibility: {
          hideAddress: "Ocultar dirección",
          showFullAddress: "Mostrar calle y número",
          showFullAddressAndComplements: "Mostrar dirección completa",
          showOnlyTheStreet: "Mostrar solo la calle",
          title: "Visibilidad de la dirección"
        },
        description: {
          descriptionDefaultValue: "Apartamento de nueva construcción, tres dormitorios y dos baños...",
          title: "Descripción",
          warning: "Este campo es obligatorio para publicar en portales"
        },
        errorModal: {
          message: "No se puede publicar una propiedad con disponibilidad de visitas sin configurar primero los datos de cualificación del solicitante. Completa esta información en la pestaña 'Sobre' para crear la disponibilidad de visitas.",
          title: "La propiedad no se puede publicar"
        },
        footerTitle: "¿Necesita comprobar las integraciones?",
        footerTitleLink: "Ir a integraciones",
        modalSync: {
          actionOptionNo: "No, créala",
          actionOptionYes: "Si, actualízala",
          actionTitle: "¿Desea actualizar una propiedad ya publicada?",
          buttonCTA: "Sincronizar ahora",
          errors: {
            referenceAlreadyExists: "Este código de referencia ya existe, por favor introduzca otro código de referencia personalizado."
          },
          idealista: {
            body: "Está listo para comenzar a sincronizar la información de esta propiedad con su cuenta de {{name}}. Si esta propiedad existe en {{name}} y desea actualizarla, necesitará el código de propiedad de referencia. De lo contrario, se creará otra propiedad en {{name}}, perdiendo sus estadísticas."
          },
          inmofactory: {
            body: "Está listo para comenzar a sincronizar la información de esta propiedad con su cuenta de {{name}}. Si esta propiedad existe en {{name}}, ve a {{name}} y anula la publicación y elimínala.",
            bodyInfoMessage: "Fotocasa, Habitaclia y Pisos.com comparten la misma referencia de propiedad, la actualización de una también cambiará la otra.",
            bodyWarn: "Esto es muy importante porque {{name}} sólo permite una única referencia de propiedad.",
            disableSync: {
              buttonCTA: "Desincronizar",
              description: "Esto detendrá la sincronización de esta propiedad de los portales de Inmofactory (Fotocasa, Habitaclia y Pisos.com).",
              title: "Desincronización de portales"
            },
            inputHelperText: "Recuerde usar una referencia que no exista en {{name}}."
          },
          inputLabel: "Código de referencia de propiedad",
          noInputHelperText: "Introduce el código de referencia personalizado que mejor se ajuste.",
          propertyStateLabel: "Etiqueta de estado de la propiedad",
          states: {
            available: "Disponible",
            rented: "Alquilado",
            reserved: "Reservado"
          },
          title: "Sincronizar con {{name}}",
          yesInputHelperText: "Puede encontrar esta información en {{name}} en la lista de propiedades publicadas, le pediremos este código solo una vez."
        },
        syncNever: "Nunca se ha sincronizado",
        syncStopped: "Sincronización detenida",
        syncSynced: "Sincronizado {{lastTimeAgo}}",
        syncSyncing: "Sincronizando",
        table: {
          empty: "No hay portales para sincronizar",
          emptyPropertyManager: {
            boldDescription: "administrador de la organización",
            description: "No hay portales para sincronizar, póngase en contacto con su"
          },
          headers: {
            lastSynced: "Última sincronización",
            name: "Nombre",
            propertyReference: "Referencia",
            "synchronized": "Sincronizado"
          },
          invalidToSync: "No puede sincronizar y publicar hasta que complete la <0>información</0> requerida por el portal",
          invalidToSyncExceed: "No puede tener más propiedades publicadas de las que puede publicar. Anule la publicación de suficientes propiedades primero o actualice la cantidad de propiedades que puede publicar en el portal."
        },
        title: "Portales",
        titleHelperText: "Esta propiedad se sincroniza con estos portales",
        warning: "Este campo es obligatorio para publicar en portales"
      },
      portalsOwner: {
        contractSerivice: "Para publicar y/o gestionar el anuncio de tu piso en los distintos portales es necesario contratar un servicio de búsqueda de inquilinos.",
        paymentSuccessMessage: "Tu suscripción al servicio de “particular a particular” está activada. Puedes cancelarla cuando quieras desde el <1><2>Gestor de suscripciones</2></1>",
        portalsTable: {
          title: "Esta propiedad se sincroniza con los siguientes portales"
        },
        portalsTitle: "Publicación en portales",
        portalsUpdate: {
          firstPublish: {
            cta: "Publicar en portales",
            text: "Haz clic en el siguiente botón para publicar tu anuncio en los mejores portales"
          },
          publish: {
            cta: "Publicar anuncio",
            text: "¿Quieres volver a publicar tu vivienda en los portales?"
          },
          unpublish: {
            cta: "Retirar anuncio",
            text: "¿Quieres retirar tu anuncio de los portales?"
          }
        },
        unitValidation: {
          availability: "Para publicar necesitas configurar antes tu disponibilidad para visitas. <1><2>Completa la información haciendo clic aquí</2></1>.",
          billingData: "Para publicar su propiedad, debe completar la información de facturación <1><2>haciendo clic aquí</2></1>.",
          information: "Para publicar tu vivienda es necesario completar información. <1><2>Complétala haciendo clic aquí</2></1>.",
          photos: "Para publicar tu vivienda es necesario tener un mínimo de 12 a 16 fotografías. <1><2>Añádelas haciendo clic aquí</2></1>."
        }
      },
      readOnly: "Lo sentimos. No tienes permiso para editar esta propiedad. Si necesita editar esta propiedad, póngase en contacto con el administrador de su organización.",
      relatedIncidents: "Incidencias relacionadas",
      remove: "Eliminar",
      removeUnitText: "Esta acción eliminará la propiedad seleccionada de forma permanente. Este cambio no se podrá deshacer.",
      removeUnitTitle: "Eliminar Propiedad?",
      seeValuation: "Ver valoración",
      tab: {
        about: "Sobre",
        docs: "Documentos",
        inventory: "Inventario",
        lease: "Contratos",
        people: "Personas",
        portals: "Portales"
      },
      tags: {
        empty: "Vacía",
        living: "Alquilada"
      },
      title: "Detalle de la Propiedad",
      titleRoom: "Detalle de la habitación",
      unassignProvidedBy: "Desasignar proporcionado por",
      unassignProvidedByDescription: "Esto eliminará de la propiedad la persona que la ha proporcionado. Puedes cambiarlo en cualquier momento.",
      unassignUserTextNotUndone: "Esta acción eliminará el {{role}} seleccionado de la propiedad. Este cambio no se podrá deshacer.",
      unassignUserTextUndone: "Esta acción eliminará el {{role}} seleccionado de la propiedad. Puede reasignarlos en cualquier momento.",
      unassignUserTitle: "Eliminar {{role}}?",
      unitDetails: "Detalles de la propiedad",
      upcomingLease: {
        title: "Próximo Contrato"
      },
      updateUnitTitle: "Actualizar una propiedad"
    },
    door: "Puerta",
    emissionsConsumptionRatingOptions: {
      a: "A",
      b: "B",
      c: "C",
      d: "D",
      e: "E",
      f: "F",
      g: "G"
    },
    empty: "Aún no se han creado propiedades",
    energyCertificateOptions: {
      exempt: "Exento",
      inProcess: "En proceso",
      yes: "Sí"
    },
    energyEfficiency: "Clasificación de la eficiencia energética",
    energyEfficiencyRatingOptions: {
      a: "A",
      b: "B",
      c: "C",
      d: "D",
      e: "E",
      f: "F",
      g: "G"
    },
    entryDoor: "Puerta de entrada",
    externalId: "ID externa",
    externalIdPlaceholder: "El identificador de tu propiedad",
    fantasy: {
      tag: "Fantasía",
      toggle: "¿Es este un piso fantasía?"
    },
    fees: {
      changeManagementFeeWarning: "Parece que has cambiado la tarifa del servicio. Los cambios se aplicarán solo a nivel de propiedad. Ningún pago actual será modificado. Si deseas modificar la tarifa o servicio del contrato de gestión en vigor, puedes realizarlo desde la pestaña de contratos, apartado Contrato de servicios.",
      commercializationService: "Servicio de comercialización:",
      customFee: "Tarifa personalizada",
      customSettingsMessage: "Si no, puedes personalizarlo en cada propiedad nueva.",
      feesFromOrganization: "Tarifa estándar de la organización",
      fillOrganizationSettings: "Falta información sobre las tarifas de configuración de la organización. Por favor, rellene estos datos en",
      managementService: "Servicio de gestión:",
      missingOrganizationSettingsMessage: "Falta información sobre las tarifas de configuración de la organización. Ponte en contacto con el administrador para completar estos datos. Si no, puedes personalizarlo en cada propiedad nueva.",
      title: "Tarifas"
    },
    floor: "Planta",
    garage: "Garaje",
    id: "ID de Propiedad",
    idPlaceholder: "Nombra tu propiedad",
    imageTitle: "Imagen",
    importer: {
      portal: {
        consent: "Consentimiento para publicar",
        help: "Esta referencia puedes encontrarla en la URL de la página del detalle de la propiedad en Idealista/Fotocasa",
        portal: "Portal",
        reference: "Referencia de la propiedad"
      },
      title: "Importación desde portales externos"
    },
    insurance: {
      company: "Compañía de seguros",
      details: "Detalles del seguro",
      phoneNumber: "Número de teléfono",
      policy: "Número de póliza"
    },
    lift: "Ascensor",
    list: {
      addUnit: "Añadir",
      addUnitModalTitle: "Añadir una nueva propiedad",
      addUnitModelBtn: "Añadir propiedad",
      emptyUnit: "Vacío",
      removeUnitSuccess: "Propiedad eliminada correctamente",
      title: "Propiedades",
      unassignedUnit: "Sin asignar"
    },
    location: "Ubicación",
    managerTypes: {
      accountManager: "Gestor de cuenta",
      agent: "Agente",
      incidentsManager: "Gestor de incidencias",
      manager: "Gestor de la propiedad",
      providedBy: "Proporcionado por"
    },
    neighborhood: "Barrio",
    notes: {
      cancel: "Cancelar",
      edit: "Editar nota",
      lastUpdated: "Última actualización",
      save: "Guardar nota",
      success: "Nota guardada correctamente",
      title: "Notas"
    },
    occupation: {
      aboutPeople: "Sobre las personas que viven en la propiedad",
      ageRangeLabel: "Rango de edad de las personas que viven actualmente en la propiedad",
      detail: {
        ageRange: "Rango de edad",
        currentlyLiving: "Personas que viven actualmente",
        genders: "Géneros",
        howMany: "Cuántos compañeros de piso",
        occupation: "Ocupación",
        ownerLiving: "El propietario vive en la casa",
        people: "Personas que viven en la casa",
        title: "Sobre los compañeros de piso"
      },
      genders: {
        all: "Todos los géneros",
        female: "Sólo chica(s)",
        label: "Género de las personas que viven allí actualmente",
        male: "Sólo chico(s)"
      },
      isOccupiedLabel: "¿Vive alguien actualmente en la propiedad?",
      isOwnerResidentLabel: "¿Vive el propietario en la casa?",
      numberOfPeopleLabel: "Número de personas que compartirán la propiedad (incluidos los inquilinos que faltan)",
      tenantsOccupation: {
        employed: "Empleado",
        label: "Ocupación de las personas que viven en la propiedad",
        student: "Estudiante(s)"
      },
      title: "Sobre los compañeros de piso"
    },
    optionalsTitle: "Todo sobre esta propiedad",
    orientation: "Orientación de ventanas",
    propertyType: "Tipo de propiedad",
    propertyTypePlaceholder: "Selecciona un tipo de propiedad",
    propertyTypes: {
      local: "Local",
      office: "Oficina",
      parking: "Aparcamiento",
      residential: "Residencial",
      room: "Habitación"
    },
    publishedStatus: {
      published: "Publicado",
      publishedFotocasa: "Publicado en Fotocasa",
      publishedHabitaclia: "Publicado en Habitaclia",
      publishedIdealista: "Publicado en Idealista",
      publishedPisosCom: "Publicado en Pisos.com",
      publishedZazume: "Publicado en Zazume",
      title: "Estado publicado",
      unpublished: "No publicado"
    },
    quality: "Calidad",
    ref: "Ref. Número",
    rentControlled: "Renta Antigua",
    rentalConditions: {
      availabilityDate: "Disponible desde",
      dueDate: "Fecha de vencimiento",
      dueDateBefore: "Antes del",
      dueDateEachMonth: "de cada mes",
      guarantees: {
        additionalGuarantee: "Garantía adicional",
        additionalGuaranteeLabel: "Importe del depósito",
        additionalGuaranteeOptions: {
          none: "Ninguna",
          one: "1 alquiler mensual",
          other: "Otro...",
          two: "2 alquileres mensuales"
        },
        additionalGuaranteeTooltip: "Es opcional. La ley establece que esta cantidad no puede exceder más de 2 alquileres mensuales.",
        deposit: "Fianza",
        depositAmount: "Importe de la fianza",
        depositTooltip: "La ley en España establece que debes depositar un mes de fianza.",
        holdingDeposit: "Reserva",
        holdingDepositAmount: "Importe de la reserva",
        modal: {
          title: "¿Qué cantidad?"
        },
        title: "Garantías"
      },
      monthlyRent: "Alquiler mensual",
      paymentMethod: {
        bankTransfer: "Realizar transferencia bancaria a",
        creditorIdentifier: "Identificador del acreedor",
        creditorIdentifierPlaceholder: "Campo automático",
        creditorIdentifierTooltip: "Es un número de referencia único para cobrar pagos mediante domiciliación bancaria SEPA. Lo calculamos automáticamente en base al número de identificación o CIF.",
        defaultPaymentMethod: "Método de pago por defecto",
        directDebitOrderFrom: "Hacer una orden de domiciliación bancaria desde",
        directDebitOrderFromOptions: {
          organization: "Cuenta de la organización",
          owner: "Cuenta del propietario"
        },
        directDebitOrderFromPlaceholder: "Selecciona el acreedor",
        method: "Método",
        ownersAddress: "Dirección actual del propietario",
        ownersAddressPlaceholder: "Escribe la dirección del propietario",
        ownersIdNumber: "Número de tarjeta de identidad",
        ownersIdNumberPlaceholder: "Escribe el número del documento de identidad del propietario",
        ownersName: "Nombre del propietario",
        ownersNamePlaceholder: "Escribe el nombre del propietario",
        paymentMethodOptions: {
          bank_transfer: "Transferencia bancaria",
          direct_debit: "Domiciliación bancaria"
        },
        paymentMethodPlaceholder: "Selecciona el método de pago",
        selectBankAccount: "Selecciona una cuenta bancaria",
        selectBankAccountPlaceholder: "Seleccione una cuenta bancaria de la organización",
        title: "Método de pago"
      },
      paymentsDueDate: "Fecha de vencimiento de todos los pagos",
      paymentsDueDateInfo: "La fecha de vencimiento aplica a todos los pagos que has configurado en este paso",
      rent: {
        checkbox: "Actualizar solo si el IPC es positivo (el alquiler nunca bajará)",
        dueDate: "Fecha de vencimiento (último día del mes para pagar el alquiler)",
        dueDatePlaceholder: "Selecciona la fecha de vencimiento",
        monthlyRent: "Alquiler mensual",
        rentUpdate: "Actualización de alquiler",
        rentUpdateOptions: {
          automatically: "Automáticamente con el IPC",
          doNotUpdate: "No actualizar"
        },
        rentUpdatePlaceholder: "Selecciona una opción...",
        title: "Alquiler"
      },
      selectOwner: {
        error: "Debes seleccionar un propietario/a",
        selectOwner: "Selecciona un propietario/a"
      },
      tenantSearchServiceFee: {
        amount: "Importe de la tarifa del servicio (50% del alquiler mensual)",
        subtitle: {
          "0": "Tarifa de servicio",
          "1": "Impuestos"
        },
        title: "Servicio de búsqueda de piso para inquilinos"
      },
      title: "Condiciones de alquiler"
    },
    rentalType: "Tipo de alquiler de la propiedad",
    rentalTypePlaceholder: "Selecciona un tipo de alquiler",
    rentalTypesOptions: {
      "long": "Alquiler larga estancia",
      mid: "Alquiler media estancia"
    },
    rentalTypesValues: {
      "long": "Larga estancia",
      mid: "Media estancia"
    },
    restaurationYear: "Año de restauración",
    restored: "Restaurado",
    roomCharacteristics: {
      ageRangeLooking: "Rango de edad que estás buscando",
      beds: {
        "double": "Cama doble",
        individual: "Cama individual",
        noBed: "Sin cama",
        twoBeds: "Dos camas"
      },
      detail: {
        balcony: "Balcón",
        bed: "Cama",
        fittedWardrobe: "Armario empotrado",
        furnished: "Habitación amueblada",
        otherFeatures: "Otras características de la habitación",
        privateBathroom: "Baño privado",
        roomSize: "Tamaño de habitación",
        title: "Características de la habitación",
        views: "Vistas"
      },
      features: {
        balcony: "Balcón",
        fittedWardrobe: "Armario empotrado",
        furnished: "Amueblada",
        privateBathroom: "Baño privado"
      },
      gender: {
        female: "Chica",
        male: "Chico",
        notSpecified: "No importa"
      },
      lookingFor: "Estás buscando...",
      occupation: {
        label: "Ocupación:",
        notSpecified: "No importa",
        student: "Estudiante",
        worker: "Trabajador"
      },
      otherRoomFeatures: "Otras características de la habitación:",
      preferences: {
        couplesAllowed: "Parejas permitidas",
        label: "Preferencias:",
        lgbt: "Apto para personas LGBT+",
        minorsAllowed: "Menores permitidos",
        petsAllowed: "Mascotas permitidas",
        smokingAllowed: "Fumar está permitido"
      },
      roomFeatures: "Características de la habitación",
      roomSizeLabel: "Tamaño de la habitación m²",
      roomViewsLabel: "Vistas de la habitación",
      title: "Características de la habitación",
      typeOfBedLabel: "Tipo de cama",
      views: {
        courtyard: "Vista al patio",
        noWindow: "Sin ventana",
        street: "Vista de la calle"
      }
    },
    roomWithId: "Habitación {{id}}",
    rooms: "Habitaciones",
    saleConditions: {
      profitability: "Rentabilidad",
      profitabilityValue: "{{profitability}}%",
      sale: "Venta",
      sellingPrice: "Precio de venta",
      title: "Condiciones de venta"
    },
    security: "Seguridad 24h",
    selectBuildingPlaceholder: "Seleccionar edificio",
    selectPlaceholder: "Selecciona un espacio de trabajo",
    storage: "Trastero",
    surface: "Superficie",
    tags: {
      entryDoorMaterial: {
        fiberglass: "Fibra de vidrio",
        steel: "Acero",
        wood: "Madera"
      },
      facadeMaterial: {
        brick: "Ladrillo",
        concrete: "Hormigón",
        mortar: "Mortero",
        stone: "Piedra",
        tile: "Azulejo",
        wood: "Madera"
      },
      location: {
        privateUrbanization: "Urbanización privada",
        urban: "Urbano"
      },
      mainUse: {
        commercial: "Comercial",
        residential: "Residencial"
      },
      quality: {
        luxury: "De lujo",
        normal: "Normal",
        simple: "Simple"
      },
      windowsMaterial: {
        aluminum: "Aluminio",
        composite: "Compuesto",
        fiberglass: "Fibra de vidrio",
        vinyl: "Vinilo",
        wood: "Madera",
        woodclad: "Revestido de madera"
      },
      windowsOrientation: {
        inside: "Interior",
        outside: "Exterior"
      }
    },
    toggleBuilding: "¿Pertenece a un edificio?",
    toggleRentControlled: "¿Es una propiedad con renta controlada?",
    tooltips: {
      agentReadOnlyUnit: "Solo tienes permisos de lectura para esta propiedad."
    },
    unitWithId: "Propiedad {{id}}",
    use: "Uso",
    warning: {
      noBillingData: {
        admin: {
          cta: "Ir a la Configuración de la organización",
          message: "Falta información requerida. Por favor, completa la <strong>Configuración de la organización</strong> antes de crear una propiedad."
        },
        other: {
          message: "Falta información requerida. Ponte en contacto con el administrador de tu cuenta para rellenar toda la información en <strong>Configuración de la organización</strong> antes de crear una propiedad."
        },
        owner: {
          cta: "Ir a configuración",
          message: "Para añadir una propiedad, debes completar tu <strong>información de facturación</strong>. <br/><br/>Puedes completar esta información en la página <strong>de configuración</strong>. Cuando hayas terminado, podrás volver a añadir una propiedad."
        }
      }
    },
    windowsMaterial: "Material de las ventanas",
    woodStructure: "Estructura de madera",
    workspace: "Workspace",
    workspacePlaceholder: "Selecciona un espacio de trabajo",
    zip: "Código Postal"
  },
  users: {
    roles: {
      accountManager: "Gestor de cuenta",
      agent: "Agente",
      applicationManager: "Gestor de la solicitud",
      incidentsManager: "Gestor de incidencias",
      ownerAccount: "Propietario/a",
      ownerAccountCount: "{{count}} propietario/a",
      ownerAccountCount_other: "{{count}} propietarios/as",
      ownerAccount_other: "Propietarios/as",
      staff: "Personal",
      tenant: "Inquilino",
      tenantCount: "{{count}} inquilino/a",
      tenantCount_other: "{{count}} inquilinos/as",
      tenant_other: "Inquilinos/as",
      unitManager: "Gestor de la propiedad",
      zazume: "Zazume"
    }
  },
  valuations: {
    additionalCharacteristics: "Características adicionales",
    comparableUnits: "Propiedades comparables",
    details: {
      averageListing: "Núm. promedio de anuncios por propiedad",
      averageListingValue: "{{count}} anuncio",
      averageListingValue_other: "{{count}} anuncios",
      averagePrice: "Precio medio/m2",
      averageRent: "Alquiler promedio (de propiedades alrededor)",
      averageTime: "Tiempo promedio para alquilar",
      averageTimeValue: "{{count}} día",
      averageTimeValue_other: "{{count}} días",
      created: "Creado: {{date}}",
      disclaimerMessage: "La presente valoración tiene un carácter meramente orientativo y en ningún caso constituye una tasación oficial. Zazume no asume responsabilidad alguna por el uso de esta información ni por las decisiones que se tomen en base a ella",
      estimatedPrices: "Precios estimados",
      fairRent: "Alquiler justo",
      fairSell: "Venta justa",
      fastRent: "Alquiler rápido",
      fastSell: "Venta rápida",
      menu: {
        edit: "Editar valoración",
        remove: "Eliminar valoración"
      },
      otherValuation: "Otras valoraciones",
      outOfMarket: "Fuera del mercado",
      title: "Detalles de valoración",
      unitsRented: "Propiedades alquiladas (últimos 6 meses)",
      unitsRentedValue: "{{count}} propiedad",
      unitsRentedValue_other: "{{count}} propiedades"
    },
    editValuation: "Editar valoración",
    editValuationSuccess: "Valoración editada correctamente",
    emptyState: "Aún no se ha creado ninguna valoración",
    errors: {
      cityNotAvailable: "La ciudad proporcionada no está disponible para la valoración"
    },
    menu: {
      edit: "Editar valoración",
      remove: "Eliminar valoración"
    },
    "new": {
      type: {
        label: "Tipo de valoración",
        placeholder: "Selecciona un tipo de valoración"
      }
    },
    newValuation: "Nueva valoración",
    promoteToUnit: "Promocionar a propiedad",
    reliability: {
      detail: "Fiabilidad {{value}}",
      high: "Muy precisa",
      low: "Orientativa",
      medium: "Precisa",
      title: "Fiabilidad"
    },
    removeValuationSuccess: "Valoración eliminada correctamente",
    removeValuationText: "Esto eliminará la valoración seleccionada de forma permanente. Esta acción no se puede deshacer.",
    removeValuationTitle: "¿Eliminar la valoración?",
    reports: "Informes",
    reportsDetail: {
      addCTA: "Agregar CTA en la última página del informe",
      create: "Crear informe",
      createNew: "Crear nuevo informe",
      ctaNameLabel: "Área de texto",
      ctaNamePlaceholder: "Este texto aparecerá encima de la URL",
      ctaUrlLabel: "URL",
      ctaUrlPlaceholder: "Copia y pega la URL",
      deleteMessage: "Esto eliminará el informe seleccionado de forma permanente. Esta acción no se puede deshacer.",
      deleteReport: "¿Eliminar informe?",
      deletedReport: "Informe eliminado con éxito",
      editReport: "Editar informe",
      emptyState: "Aún no hay informe",
      estimatedValuation: "Valoración estimada",
      presentation: "Presentación",
      presentationPlaceholder: "Escribe una presentación sobre la organización",
      sendReport: "Enviar informe",
      sendReportDescription: "Antes de enviar el informe necesitamos algunos datos",
      sendReportEmailPlaceholder: "nombre@mail.com",
      sendReportNamePlaceholder: "Escribe el nombre sin apellidos Ej.: Pedro",
      title: "Informe",
      update: "Actualizar informe",
      withAgent: "Incluir información del agente",
      withCTA: "Incluir CTA",
      withCharacteristics: "Incluir características de la propiedad en el informe",
      withComparables: "Incluir propiedades comparables al informe",
      withEstimation: "Incluir valoraciones estimadas al informe"
    },
    title: "Valoraciones",
    unit: {
      otherCharacteristics: "Otras características",
      unitCharacteristics: "Características de la propiedad"
    },
    updateValuation: "Actualizar valoración",
    updateValuationSuccess: "Valoración actualizada correctamente",
    valuateUnit: "Valorar la propiedad",
    valuatedOn: "Valorado en",
    valuation: "Valoración"
  },
  walkthrough: {
    progress: "perfil completado",
    selfManagement: {
      cards: {
        addTenants: "Añade a tus inquilinos",
        billingData: "Completa tus datos de facturación",
        connectBankAccount: "Conecta tu cuenta bancaria",
        unitDetails: "Completa los detalles de tu propiedad",
        uploadCurrentContract: "Sube el contrato de alquiler vigente"
      }
    },
    steps: {
      addTenants: "Añade a tus inquilinos",
      billingData: "Completa tus datos de facturación",
      connectBankAccount: "Conecta tu cuenta bancaria",
      createExpense: "Crea tu primer gasto",
      prequalification: "Añade los criterios de cualificación de inquilinos",
      unitDetails: "Completa los detalles de tu propiedad",
      unitDocuments: "Sube los documentos de tu propiedad",
      uploadCurrentContract: "Sube el contrato de alquiler vigente"
    },
    tenantSearch: {
      cards: {
        billingData: "Completa tus datos de facturación",
        prequalification: "Añade los criterios de cualificación de inquilinos",
        unitDetails: "Completa los detalles de tu propiedad",
        unitDocuments: "Sube los documentos de tu propiedad"
      }
    },
    title: "Completa tu perfil"
  },
  whatsapp: {
    message: "Hola, {{name}}"
  },
  workspaceServices: {
    addAService: "Agregar un servicio",
    addService: "Agregar servicio",
    hideServiceModal: {
      button: "Ocultar",
      text: "Esto hará que el servicio no esté disponible para reservar y se ocultará de la aplicación de su inquilino inmediatamente. Puede publicarlo de nuevo en cualquier momento.",
      title: "¿Ocultar servicio?"
    },
    playbook: {
      description: "Descripción",
      editTask: "Editar tarea",
      externalLink: "Enlace externo",
      link: "Enlace"
    },
    publishServiceModal: {
      button: "Publicar",
      text: "Esto hará que el servicio esté disponible para reservar desde la aplicación de su inquilino inmediatamente. Puedes ocultarlo de nuevo en cualquier momento.",
      title: "¿Publicar servicio?"
    },
    searchServices: "Buscar servicios",
    serviceHidden: "Oculto en la aplicación",
    servicePublished: "Publicado",
    title: "Servicios ofrecidos"
  },
  workspaces: {
    add: "Añadir un nuevo workspace",
    addCta: "Añadir workspace",
    addWorkspaceCardLabel: "Añadir un workspace",
    address: "Dirección",
    all: "Ver todos",
    confirmationModal: {
      description: "Esto eliminará el espacio de trabajo seleccionado de forma permanente. Esta acción no se puede deshacer.",
      title: "¿Eliminar espacio de trabajo?",
      toast: "Este espacio de trabajo tiene propiedades y/o inquilinos asignados y no se puede eliminar",
      workspaceDeleted: "Workspace eliminado correctamente"
    },
    deleteCta: "Eliminar workspace",
    description: "Descripción",
    edit: "Editar workspace",
    editCta: "Guardar cambios",
    name: "Dale un nombre",
    title: "Workspaces",
    units: "propiedades"
  },
  xmlExports: {
    "delete": {
      description: "¿Eliminar la exportación XML?",
      title: "Esto eliminará la exportación XML seleccionada y actualizará las liquidaciones enlazados al estado de espera. Esta acción no se puede deshacer."
    },
    downloadCTA: "Descargar fichero XML",
    file: "Fichero XML",
    linkedDescription: "Estos son los pagos vinculados a este fichero XML",
    linkedTitle: "Pagos vinculados",
    processedPayout: "Liquidación procesada",
    title: "Exportaciones XML"
  },
  zazume: {
    add: "Agregar usuario Zazume",
    removeDescription: "Esto eliminará el usuario zazume seleccionado. Esta acción no se puede deshacer.",
    removeTitle: "¿Eliminar a Zazume?",
    removed: "Zazume eliminado",
    title: "Usuarios Zazume"
  },
  zazumeCommercializationServices: {
    peer_to_peer: {
      name: "Particular a particular"
    },
    tenant_search: {
      name: "Búsqueda de inquilinos"
    }
  },
  zazumeServices: {
    contractPlanInvestorModal: {
      acceptConditions: {
        acceptConditions: "Acepto los términos del plan",
        secureTransaction: "Transacción segura",
        serviceConditions: "Condiciones del plan:",
        serviceIncludes: "<1>El plan <2>{{service}}</2></1> <1> incluye: </1>",
        serviceName: "Plan {{service}}",
        title: "¿Quieres contratar el plan {{service}}?"
      },
      areas: "Áreas",
      areasPlaceholder: "Selecciona un área",
      selectPlan: {
        benefitsTitle: "Este plan incluye:",
        selectArea: "Selecciona el área que deseas contratar",
        selectService: "Selecciona un plan",
        title: "Obtén acceso ilimitado a oportunidades de inversión"
      }
    },
    contractPlanModal: {
      acceptConditions: {
        acceptConditions: "Acepto las condiciones del servicio",
        secureTransaction: "Transacción segura",
        serviceConditions: "Condiciones del servicio:",
        serviceIncludes: "<1>El servicio</1> <2>{{service}}</2> <1>incluye:</1>",
        serviceName: "Servicio {{service}}",
        title: "¿Quieres contratar el servicio {{service}}?"
      },
      selectPlan: {
        benefitsTitle: "Este plan incluye:",
        description: "Para poder publicar tu piso en los mejores portales necesitas escoger un plan de búsqueda de inquilinos.",
        selectService: "Selecciona un servicio",
        selectUnit: "Selecciona la propiedad que te gustaría comercializar",
        title: "Contratar servicio de búsqueda de inquilinos"
      },
      tenantSearchConfirmation: {
        description: "Un miembro de nuestro equipo se pondrá en contacto contigo en las próximas 24/48h para empezar a publicar tu vivienda ¡y encontrar al mejor inquilino!",
        title: "Hemos recibido tu solicitud correctamente"
      }
    },
    free: "GRATIS",
    full_coverage: {
      benefits: [
        "Control y visibilidad de pagos en tiempo real",
        "Comunicación con el inquilino",
        "Gestión de incidencias",
        "Financiación de gastos hasta 3000€ (12 meses)",
        "Incluye seguro de impago",
        "Cobertura por vandalismo: 6000€",
        "Cobertura legal ante impago: 15000€",
        "Cobro garantizado del alquiler el 10 de cada mes",
        "Descuento del 25% en los honorarios de búsqueda de inquilinos",
        "Recibe tu alquiler incluso si el piso se queda vacío"
      ],
      name: "Cobertura total",
      title: "Cobertura",
      titleHighlight: "total"
    },
    guaranteed_rental: {
      benefits: [
        "Control y visibilidad de pagos en tiempo real",
        "Comunicación con el inquilino",
        "Gestión de incidencias",
        "Financiación de gastos hasta 2000€ (9 meses)",
        "Incluye seguro de impago",
        "Cobertura por vandalismo: 6000€",
        "Cobertura legal ante impago: 15000€",
        "Cobro garantizado del alquiler el 10 de cada mes"
      ],
      name: "Gestión garantizada",
      title: "Gestión",
      titleHighlight: "Garantizada"
    },
    guaranteed_rental_self_management: {
      benefits: [
        "Control y visibilidad de  pagos en tiempo real",
        "Comunicación con el inquilino",
        "Financiación de gastos de hasta 2000€ (9 meses)",
        "Incluye seguro de impago",
        "Cobertura por vandalismo: 6000€",
        "Cobertura legal ante impago: 15000€",
        "Cobro garantizado del alquiler el 10 de cada mes"
      ],
      name: "Alquiler garantizado",
      title: "Alquiler",
      titleHighlight: "Garantizado"
    },
    monthly: "Al mes",
    moreInformation: "Más información",
    mostPopular: "El más popular",
    noManagement: "No quiero gestión",
    noSmallPrint: "Sin letra pequeña",
    ofTheServie: "{{quota}}% + IVA (del servicio incluido)",
    peer_to_peer: {
      benefits: [
        "Acceso a la plataforma cloud",
        "Publicación en los mejores portales",
        "Gestión digital de visitas",
        "Scoring y verificación del inquilino",
        "Gestión documental y firma digital",
        "Online y sin burocracia: del anuncio a la firma del contrato"
      ],
      conditions: [
        "• Plan de suscripción de 59€/semana por cada propiedad.",
        "• Puedes anular la suscripción en cualquier momento desde el Gestor de suscripciones."
      ],
      name: "De particular a particular",
      price: "<1>desde</1> <2>{{price}}</2>",
      title: "De particular",
      titleHighlight: "a particular"
    },
    real_estate_opportunities: {
      banner: {
        benefits: [
          "Oportunidades en todas las provincias de España",
          "Estimación del precio de alquiler y rentabilidad",
          "Datos económicos y financieros para ayudarte a decidir",
          "¡26.487 oportunidades de inversión te están esperando!"
        ],
        cta: "Quiero acceso ilimitado",
        title: "Obtén acceso ilimitado a todas las oportunidades de inversión"
      },
      benefits: [
        "Alertas por email o WhatsApp",
        "Acceso a todo el mercado español",
        "Datos económicos y financieros en cada oportunidad",
        "Guarda tus oportunidades con tus ajustes de la calculadora financiera",
        "Filtros avanzados para explorar las oportunidades"
      ],
      conditions: [
        "• Suscripción de 75€/mes",
        "• Puedes darte de baja en cualquier momento en el Gestor de suscripciones."
      ],
      name: "Oportunidades de inversión inmobiliarias",
      price: "<1>{{price}}</1><2>/mes</2>",
      title: "Oportunidades",
      titleHighlight: "de inversión"
    },
    real_estate_opportunities_area: {
      banner: {
        benefits: [
          "Oportunidades en la comunidad autónoma que elijas",
          "Estimación del precio de alquiler y rentabilidad",
          "Datos económicos y financieros para ayudarte a decidir",
          "¡Nuevas oportunidades de inversión todos los días!"
        ],
        cta: "Quiero acceso ilimitado",
        title: "Obtén acceso ilimitado a todas las oportunidades de inversión"
      },
      benefits: [
        "Alertas por email o WhatsApp",
        "Acceso a la comunidad autónoma que elijas",
        "Datos económicos y financieros en cada oportunidad",
        "Guarda tus oportunidades con tus ajustes de la calculadora financiera",
        "Filtros avanzados para explorar las oportunidades"
      ],
      conditions: [
        "• Suscripción de 20€/mes",
        "• Puedes darte de baja en cualquier momento en el Gestor de suscripciones."
      ],
      name: "Oportunidades de inversión inmobiliarias por área",
      price: "<1>{{price}}</1><2>/mes</2>",
      title: "Oportunidades",
      titleHighlight: "de inversión (1 área)"
    },
    receivedEachMonth: "Recibirás cada mes",
    rental_management: {
      benefits: [
        "Control y visibilidad de pagos en tiempo real",
        "Comunicación con el inquilino",
        "Gestión de incidencias",
        "Financiación de gastos hasta 1000€ (3 meses)"
      ],
      name: "Gestión del alquiler",
      title: "Gestión",
      titleHighlight: "del alquiler"
    },
    self_management: {
      benefits: [
        "Comercialización de propiedades",
        "Publicación en los mejores portales",
        "Gestión de visitas",
        "Gestión de pagos",
        "Registro de ingresos y gastos",
        "Conecta tu cuenta bancaria",
        "Conciliación de pagos",
        "Gestión de documentos y firma digital"
      ],
      name: "Autogestión",
      title: "",
      titleHighlight: "Autogestión"
    },
    showAllServices: "Mostrar todos los servicios",
    showSuggestedServices: "Mostrar servicios sugeridos",
    tenant_search: {
      benefits: [
        "Posicionamiento premium en portales",
        "Valoración de la propiedad",
        "Fotos profesionales",
        "Asesoramiento inmobiliario de principio a fin",
        "Encontramos a los mejores inquilinos por ti",
        "Te garantizamos 12 meses, si el inquilino se va antes del primer año, te encontramos otro gratis",
        "No tendrás que adelantar los honorarios, lo descontaremos del primer mes de alquiler"
      ],
      conditions: [
        "• Pago único al alquilar tu propiedad.",
        "• Incluye una garantía de 12 meses, si el inquilino se va antes del primer año no tendrás que volver a pagar.",
        "• No tendrás que adelantar dinero, restaremos el mes de honorarios del primer mes de alquiler del inquilino.",
        "• Si escoges este plan, uno de nuestros comerciales se pondrá en contacto contigo en las próximas 24/48h para preparar todo."
      ],
      name: "Búsqueda de inquilinos",
      tag: "Más popular",
      title: "Búsqueda",
      titleHighlight: "de inquilinos"
    },
    theSafest: "El más seguro"
  }
};
